import PageHeader from "../../../components/Page-Header/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import React, { useCallback, useEffect, useState } from "react";
import "../add-vendor/addVendor.scss";
import { HashLink as Link } from "react-router-hash-link";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import { useAuth } from "../../../contexts/auth";
import { verifyVendor } from "../../../api/vendor";
import { rejectIcon, saveVendorIcon } from "../../../assets/icon";
import pdf from "../../../assets/images/pdf.png";
import ApprovePopup from "../../../components/Approval-popup/ApprovePopup";
import { LoadPanel } from "devextreme-react";
import { requestAddressFromPin } from "../../../api/registerCustomer";

import { Validator, RequiredRule } from "devextreme-react/validator";
import {
  GetSAPCurrency,
  getCountryData,
  getPaymentTerms,
  getStateData,
} from "../../../api/commonAPI";
import { UseConfigContext } from "../../../contexts/cofigContext";
import { allCurrencies } from "../../../utils/currency";

function VendorStatus() {
  const location = useLocation();
  const navigate = useNavigate();
  const [vendorDetail, setVendorDetail] = useState([]);
  const [isTxtFieldDisable, setIsTxtFieldDisable] = useState(true);
  const { state, pathname } = location;
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [activeLink, setActiveLink] = useState("Reason");
  const [approvalPopUp, setApprovalPopUp] = useState(false);
  const [rejectPopUp, setRejectPopUp] = useState(false);
  const [formData, setFormData] = useState({
    createdByUserName: user["UserData"],
    u_QIT_MSME_REGNO: "",
    u_QIT_MSME_TYPE: "",
  });
  const [from, setFrom] = useState(false);

  const msmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];
  const ismsmeType = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];

  const [rejectComment, setRejectComment] = useState(false);
  const [approveComment, setApprovecomment] = useState(false);

  const [paymentTerms, setPaymentTerms] = useState([]);

  const { getSourceData } = UseConfigContext();
  const [sourceConfigData, setSourceConfigData] = useState(getSourceData[0]);
  const [currency, setCurrency] = useState([]);

  const getPayemntTerms = async () => {
    var apiRes = await getPaymentTerms();
    if (!apiRes.hasError) {
      setPaymentTerms(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  useEffect(() => {
    getPayemntTerms();
    // getCatData();
    getAllContryData();
    getCurrencyData();
  }, []);

  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [ShipStateData, setShipStateData] = useState([]);

  const getAllContryData = async () => {
    var apiRes = "";
    if (sourceConfigData["countrySource"] == "L") {
      apiRes = await getCountryData("L");
    } else {
      // SAP
      apiRes = await getCountryData("S");
    }
    if (!apiRes.hasError) {
      setCountryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCountryWiseStateData = async (country_code, fieldName) => {
    if (country_code != "" && country_code != null) {
      var apiRes = "";
      if (sourceConfigData["countrySource"] == "L") {
        apiRes = await getStateData(country_code);
      } else {
        // SAP
        apiRes = await getStateData(country_code);
      }

      if (!apiRes.hasError) {
        if (fieldName == "bCountry") {
          setStateData(apiRes.responseData);
        }
        if (fieldName == "sCountry") {
          setShipStateData(apiRes.responseData);
        }
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const getCurrencyData = async () => {
    if (sourceConfigData["currencySource"] == "L") {
      setCurrency(allCurrencies);
    } else {
      var apiRes = await GetSAPCurrency();
      if (!apiRes.hasError) {
        setCurrency(apiRes.responseData);
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  useEffect(() => {
    if (state == null) {
      if (pathname == "/vendor/addvendor/VendorStatus")
        navigate("/vendor/addvendor");
      else navigate("/vendor/verifyvendor");
    } else {
      setFrom(state.From);
      setVendorDetail(state.vendordetail[0]);
      // if (state.From == "V" && state.vendordetail[0].status == "R") {
      //   setIsTxtFieldDisable(false);
      // }
    }
  }, [vendorDetail]);

  const btnOption = {
    icon: "",
    text: "Approve",
  };
  const btnOption2 = {
    icon: "",
    text: "Reject",
  };
  const btnOption3 = {
    icon: "",
    text: "For Approval",
  };

  const handleLinkClick = (activeLinkName) => {
    setActiveLink(activeLinkName);
  };

  const handleCloseConformPopup = () => {
    setApprovalPopUp(false);
  };
  const handleRejectPopup = () => {
    setRejectPopUp(false);
  };

  const handleSaveClick = async (status, comment) => {
    setLoading(true);
    var reqBody = {
      loginUserName: user["UserData"],
      cardCode: vendorDetail.vendorCode,
      action: status,
      message: comment,
    };
    var apiRes = await verifyVendor(reqBody, vendorDetail.vendorName);
    if (apiRes.hasError) {
      setLoading(false);
      return toastDisplayer("error", apiRes.errorMessage);
    } else {
      setLoading(false);
      handleCloseConformPopup();
      toastDisplayer("success", "Vendor verified..!!");
      if (state?.From == "V") {
        navigate("/vendor/verifyvendor");
      } else if (state?.From == "A") {
        navigate("/vendor/addvendor");
      }
    }
  };
  const handleRejectBtnClick = async () => {
    if (rejectComment == "" || rejectComment == null) {
      return toastDisplayer("error", "Reason is requied..!!");
    }
    handleSaveClick("R", rejectComment);
    setRejectComment("");
    setRejectPopUp(false);
  };

  const handleApproveBtnClick = async () => {
    if (approveComment == "" || approveComment == null) {
      return toastDisplayer("error", "Reason is requied..!!");
    }
    handleSaveClick("A", approveComment);
    setApprovecomment("");
    setApprovalPopUp(false);
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    vendorDetail[fieldName] = value;
    if (fieldName == "bCountry" || fieldName == "sCountry") {
      getCountryWiseStateData(value, fieldName);
    }
  };

  const vendorType = [
    { value: "I", text: "Import" },
    { value: "D", text: "Domestic" },
  ];
  const cmpCatType = [
    { value: "M", text: "Manufacturer" },
    { value: "T", text: "Trader" },
  ];

  const handleScroll = () => {
    const sections = document.querySelectorAll(".form-section");
    let foundAnySection = false;

    sections.forEach((section) => {
      const bounding = section.getBoundingClientRect();
      if (bounding.top >= 0 && bounding.top <= window.innerHeight) {
        if (!foundAnySection) {
          setActiveLink(section.id);
          foundAnySection = true;
        }
      }
    });
  };

  const AccNoComparison = useCallback(() => {
    return formData?.account;
  }, [formData?.account]);

  const handleApproveClick = () => {
    setApprovalPopUp(true);
  };
  const handleRejectClick = () => {
    setRejectPopUp(true);
  };

  const handleApproveComment = (e) => {
    setApprovecomment(e);
  };
  const handleRejectComment = (e) => {
    setRejectComment(e);
  };

  const handleClick = () => {
    let pdfWindow = window.open(vendorDetail?.cmpDocs);
    if (pdfWindow) {
      pdfWindow.document.write(`<body>
      <embed src="${vendorDetail?.cmpDocs}" width="100%" height="100%">
      </body>
    `);
    }
  };

  return (
    <>
      {loading ? <LoadPanel visible="true" /> : ""}
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={
          from == "V" ? (vendorDetail.status == "P" ? true : false) : false
        }
        isBtn2Visisble={
          from == "V" ? (vendorDetail.status == "P" ? true : false) : false
        }
        pageTitle={
          vendorDetail.status == "R"
            ? "Vendor Details : Decision Rejected"
            : vendorDetail.status == "A"
            ? "Vendor Details : Decision Approved"
            : vendorDetail.status == "P"
            ? "Vendor Details : Decision Pending"
            : ""
        }
        statusPage={
          vendorDetail.status == "R"
            ? "Rejected"
            : vendorDetail.status == "A"
            ? "Approved"
            : vendorDetail.status == "P"
            ? "Pending"
            : ""
        }
        btnOption={vendorDetail.status == "P" ? btnOption : btnOption3}
        btnOption2={btnOption2}
        clickHandler={vendorDetail.status == "P" ? handleApproveClick : ""}
        clickHandler2={handleRejectClick}
      />

      <div className="content-add-form">
        <div className="form-header-panel">
          <ul type="none">
            <li className={activeLink == "Reason" ? "active" : ""}>
              <Link to="#Reason" onClick={() => handleLinkClick("Reason")}>
                Verification
              </Link>
            </li>
            <li className={activeLink == "Vendor" ? "active" : ""}>
              <Link to="#Vendor" onClick={() => handleLinkClick("Vendor")}>
                Vendor
              </Link>
            </li>
            <li className={activeLink == "Address" ? "active" : ""}>
              <Link to="#Address" onClick={() => handleLinkClick("Address")}>
                Registered Address
              </Link>
            </li>
            <li className={activeLink == "Details" ? "active" : ""}>
              <Link to="#Details" onClick={() => handleLinkClick("Details")}>
                Bank Details
              </Link>
            </li>
            <li className={activeLink == "Communication" ? "active" : ""}>
              <Link
                to="#Communication"
                onClick={() => handleLinkClick("Communication")}
              >
                Communication
              </Link>
            </li>
            <li className={activeLink == "Others" ? "active" : ""}>
              <Link to="#Others" onClick={() => handleLinkClick("Others")}>
                Others
              </Link>
            </li>
          </ul>
        </div>
        <form>
          <div className="form-body-content" onScroll={handleScroll}>
            {/* ----------- Verification Reason --------------*/}
            <section id="Reason" className="form-section">
              <span className="header-text">Verification</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Reason"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("officialPhone", e.value)
                    }
                    // value={formData?.OfficialPhone || ""}
                    value={vendorDetail.reason || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="SAP Vendor Code"
                    labelMode="outside"
                    width={"33%"}
                    
                    value={vendorDetail.sapcardCode || "--"}
                    visible={vendorDetail.status == "A" ? true : false}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>
            {/* ----------- Vendor --------------*/}
            <section id="Vendor" className="form-section">
              <span className="header-text">Vendor</span>
              <div className="section-title">
                <div
                  className={"setion-first-row common-row-check"}
                  id="vendor"
                >
                  <SelectBox
                    label="Vendor Type "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("vendorType", e.value)
                    }
                    value={vendorDetail.vendorType || "--"}
                    // value={formData?.vendorType || ""}
                    items={vendorType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <TextBox
                    label="Vendor Name as per GST"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("vendorName", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={vendorDetail.vendorName || "--"}
                    // value={formData?.vendorName || ""}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Business Structure of Vendor"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpType", e.value)
                    }
                    value={vendorDetail.cmpType || "--"}
                    // value={formData?.vendorName || ""}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  {/* <SelectBox
                    label="Category of Vendor"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    value={vendorDetail.cmpCatType || "--"}
                    // value={formData?.cmpCatType || ""}
                    items={cmpCatType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox> */}

                  <TextBox
                    label="Category of Vendor"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    value={vendorDetail.cmpCatType || "--"}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                  ></TextBox>
                  <TextBox
                    label="Pan Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("panNum", e.value)}
                    value={vendorDetail.panNum || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="GST Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("gstregNum", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={vendorDetail?.gstregNum || "--"}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Official Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("officialPhone", e.value)
                    }
                    value={vendorDetail?.officialPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Official Email Address "
                    labelMode="outside"
                    width={"33%"}
                    className="verify-email-text"
                    onValueChanged={(e) => handleInputChange("eMail", e.value)}
                    value={vendorDetail?.eMail || "--"}
                    readOnly={true}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>

            {/* ----------- Office Address --------------*/}
            <section id="Address" className="form-section">
              <div className="header-text">Registered Address</div>
              <div className="header-sub-text">Billing Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Address 1 "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress1", e.value)
                    }
                    value={vendorDetail?.bAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Address 2"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress2", e.value)
                    }
                    value={vendorDetail?.bAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bStreet", e.value)
                    }
                    value={vendorDetail?.bStreet || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={vendorDetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>

                  {/* <TextBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    value={vendorDetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={vendorDetail?.bState || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}

                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    searchEnabled={true}
                    id="bCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    value={vendorDetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={vendorDetail?.bState || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bState"
                    searchEnabled={true}
                    items={StateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={vendorDetail?.bCountry ? false : true}
                  ></SelectBox>
                </div>

                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="City "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bCity", e.value)}
                    value={vendorDetail?.bCity || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="header-sub-text">Shipping Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Ship From 1 "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress1", e.value)
                    }
                    value={vendorDetail?.sAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Ship From 2"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress2", e.value)
                    }
                    value={vendorDetail?.sAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sStreet", e.value)
                    }
                    value={vendorDetail?.sStreet || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sZipCode", e.value)
                    }
                    value={vendorDetail?.sZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  {/* <TextBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={vendorDetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={vendorDetail?.sState || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={vendorDetail?.sCountry || "--"}
                    readOnly={true}
                    id="sCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={vendorDetail?.sState || "--"}
                    readOnly={true}
                    id="sState"
                    items={ShipStateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={vendorDetail?.sCountry ? false : true}
                    searchEnabled={true}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                </div>

                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="City "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sCity", e.value)}
                    value={vendorDetail?.sCity || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>

            {/* -----------  Bank Details --------------*/}

            <section id="Details" className="form-section">
              <span className="header-text">Bank Details</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    label="Currency "
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={currency}
                    value={vendorDetail?.transCry || ""}
                    valueExpr={"currCode"}
                    displayExpr={(item) =>
                      item ? `${item.currCode} - ${item.currName}` : ""
                    }
                    id="transCry"
                    readOnly={true}
                  />
                  <TextBox
                    label="Bank Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("acctName", e.value)
                    }
                    value={vendorDetail?.acctName || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Account Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("account", e.value)
                    }
                    value={vendorDetail?.account || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>

                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="IFSC Code "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("ifscNum", e.value)
                    }
                    value={vendorDetail?.ifscNum || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Bank Branch/Address"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("branch", e.value)}
                    value={vendorDetail?.branch || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  {/* <TextBox
                    label="Payment Terms"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value)
                    }
                    value={vendorDetail?.pymntTerms || "--"}
                    readOnly={isTxtFieldDisable}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value.toString())
                    }
                    label="Payment Terms"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={paymentTerms}
                    // value={formData?.pymntTerms || ""}
                    value={parseInt(vendorDetail?.pymntTerms) || ""}
                    valueExpr={"groupNum"}
                    displayExpr={"pymntGroup"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Communication --------------*/}

            <section id="Communication" className="form-section">
              <span className="header-text">Communication</span>
              <div className="header-sub-text">Primary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPrsn", e.value)
                    }
                    value={vendorDetail?.pCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPhone", e.value)
                    }
                    value={vendorDetail?.pCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctEmail", e.value)
                    }
                    value={vendorDetail?.pCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctDesg", e.value)
                    }
                    value={vendorDetail?.pCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
              <div className="header-sub-text">Secondary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPrsn", e.value)
                    }
                    value={vendorDetail?.sCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPhone", e.value)
                    }
                    value={vendorDetail?.sCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctEmail", e.value)
                    }
                    value={vendorDetail?.sCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctDesg", e.value)
                    }
                    value={vendorDetail?.sCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Other --------------*/}

            <section id="Others" className="form-section">
              <span className="header-text">Others</span>
              <div className="header-sub-text">Taxation Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row-check">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_IS_MSME", e.value)
                    }
                    label="Micro, Small & Medium Enterprises"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    value={vendorDetail?.u_QIT_IS_MSME || ""}
                    readOnly={isTxtFieldDisable}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_TYPE", e.value.toString())
                    }
                    label="MSME Types"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    readOnly={isTxtFieldDisable}
                    items={msmeType}
                    value={vendorDetail?.u_QIT_MSME_TYPE || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    disabled={
                      vendorDetail?.u_QIT_IS_MSME
                        ? vendorDetail?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                  />
                  <TextBox
                    label="MSME Registration No."
                    labelMode="outside"
                    width={"33%"}
                    disabled={
                      vendorDetail?.u_QIT_IS_MSME
                        ? vendorDetail?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_REGNO", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={vendorDetail?.u_QIT_MSME_REGNO || ""}
                  />
                </div>
              </div>
              {/* <div className="header-sub-text">ISO Certificate Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("isoCheck", e.value)
                    }
                    label="Is ISO Certified?"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    // value={formData?.msmeCheck || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                 <TextBox
                    label="ISO no."
                    labelMode="outside"
                    width={"49%"}
                    disabled={
                      formData?.isoCheck
                        ? formData?.isoCheck == "N"
                          ? true
                          : false
                        : true
                    }
                    // onValueChanged={(e) =>
                    //   handleInputChange("msmeRegistrationNo", e.value)
                    // }
                    // value={formData?.msmeCheck || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>

                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload ISO Certificate."
                    )}
                  </label>
                  <input
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    // onChange={handleFileUpload}
                    accept=".pdf"
                    disabled={formData?.isoCheck
                      ? formData?.isoCheck == "N"
                        ? true
                        : false
                      : true}
                  />
                </div>
              </div> */}
              <div className="header-sub-text">More Details</div>

              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Remarks"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("remark", e.value)}
                    value={vendorDetail?.remark || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Website Link"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("websiteLink", e.value)
                    }
                    value={vendorDetail?.websiteLink || "--"}
                    readOnly={isTxtFieldDisable}
                  />

                  {vendorDetail?.cmpDocs && (
                    <div className="docSection">
                      <label className="text-section">File</label>
                      <div className="doc-sub-section">
                        {/* <a
                          href={vendorDetail?.cmpDocs}
                          download
                          className="sub-section"
                        >
                          <img src={pdf} />
                          <label className="text">QIT.pdf</label>
                        </a> */}
                        <a
                          href={vendorDetail?.cmpDocs}
                          className="sub-section"
                          onClick={handleClick}
                        >
                          <img src={pdf} alt="PDF icon" />
                          <label className="text">{`QIT_${vendorDetail?.vendorName}.pdf`}</label>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>

      {approvalPopUp && (
        <ApprovePopup
          headertext={"Approval"}
          subtext={"Add reasons"}
          isVisible={approvalPopUp}
          onHide={handleCloseConformPopup}
          saveFunction={handleApproveBtnClick}
          saveIcon={saveVendorIcon}
          saveBtnTxt={"Approve"}
          handleInputChange={handleApproveComment}
        />
      )}
      {rejectPopUp && (
        <ApprovePopup
          headertext={"Rejection"}
          subtext={"Add reasons"}
          isVisible={rejectPopUp}
          onHide={handleRejectPopup}
          saveFunction={handleRejectBtnClick}
          saveIcon={rejectIcon}
          cancelBtnTxt={"Reject"}
          handleInputChange={handleRejectComment}
        />
      )}
    </>
  );
}

export default VendorStatus;
