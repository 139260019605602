import React from "react";
import PageHeader from "../../components/Page-Header/PageHeader";
import TabPanel from "devextreme-react/tab-panel";
import "./settings-main.scss";
import TabPanelItem from "./TabPanelItem";
export const SettingsMain = () => {
  const dataSource = [
    {
      title: "General Settings",
    },
    {
      // icon: "taskhelpneeded",
      title: "Additional Settings",
    },
  ];
  return (
    <>
      <PageHeader isBreadcrumbsVisible={true} pageTitle={"System Settings"} />
      <div className="tabpanel">
        <div className="widget-container">
          <TabPanel
            className="dx-theme-background-color"
            animationEnabled={true}
            swipeEnabled={true}
            dataSource={dataSource}
            tabsPosition={"top"}
            stylingMode={"secondary"}
            iconPosition={"top"}
            itemComponent={TabPanelItem}
          />
        </div>
      </div>
    </>
  );
};
