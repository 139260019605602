import React, { useEffect, useMemo, useState } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/UserPanel";
import "./Header.scss";
import { Template } from "devextreme-react/core/template";
import { useAuth } from "../../contexts/auth";
import SVG1 from "../../assets/SVGs/SVG1.svg";
import * as signalR from "@microsoft/signalr";
import { UseHeaderContext } from "../../contexts/headerContext";
import NotificationDropdown from "../notification-dropdown/NotificationDropdown";
import {
  GetALLNotification,
  readAllNotificationStatus,
  updateNotificationStatus,
} from "../../api/notification";
import List from "devextreme-react/list";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../../contexts/navigation";
import { UseConfigContext } from "../../contexts/cofigContext";
export default function Header({ menuToggleEnabled, toggleMenu }) {
  const [showMenuItems, setshowMenuItems] = useState(false);
  const { user, AuthRuleContect, signOut } = useAuth();
  const [userData, setUserData] = useState(null);
  const { isNotifyDropdownOpen, toggleNotifyDropdown } = UseHeaderContext();
  const [notificationData, setNotificationData] = useState([]);
  const [GetALLNotificationData, setALLNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState([]);
  const [GetALLnotificationCount, setALLNotificationCount] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();
  const navigatepath = AuthRuleContect;
  const { setNavigationData } = useNavigation();

  const handleNavigationClick = (data) => {
    setNavigationData(data);
  };
  function navigateToAddVendor() {
    handleNavigationClick("/vendor/addvendor")
    navigate("/vendor/addvendor/registration");
  }
  function navigateToAddCustomer() {
    handleNavigationClick("/customer/addcustomer")
    navigate("/customer/addcustomer/registration");
  }


  const [logo, setLogo] = useState("");
  const { getSourceData } = UseConfigContext();
  useEffect(() => {

    if (getSourceData[0]?.atcPath != "" ) {

      setLogo(getSourceData[0]?.atcPath);
    }
  }, [getSourceData]);



  const setUniqueMenuItems = (newItems) => {
    setMenuItems((prevItems) => {
      const uniqueTexts = new Set();
      prevItems.forEach((item) => uniqueTexts.add(item.text));
      const uniqueNewItems = newItems.filter(
        (item) => !uniqueTexts.has(item.text)
      );
      return [...prevItems, ...uniqueNewItems];
    });
  };

  useEffect(() => {
    setUniqueMenuItems([{ text: "Quick Add", disabled: true }]);
    navigatepath.forEach((element) => {
      if (element.text == "Vendor") {
        setshowMenuItems(true);
        setUniqueMenuItems([
          {
            text: "Add Vendor",
            icon: "add",
            onClick: navigateToAddVendor,
          },
        ]);
      }
      if (element.text == "Customer") {
        setshowMenuItems(true);
        setUniqueMenuItems([
          {
            text: "Add Customer",
            icon: "add",
            onClick: navigateToAddCustomer,
          },
        ]);
      }
    });
  }, [navigatepath]);

  useEffect(() => {
    setUserData(user["UserData"]);
  }, [user]);

  useEffect(() => {
    if (userData) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_WEBSOCKET}/Hubs/notificationHub?userId=${userData}`
        )
        .withAutomaticReconnect()
        .build();

      connection
        .start()
        .then(() => {})
        .catch((error) => {
          console.error("Error connecting to SignalR hub:", error);
        });

      connection.on("LoadData", function (messages) {
        setNotificationData(messages[0].data);
        setNotificationCount(messages[0].dataCount);
      });

      connection.on("newEntryAdded", function (newEntity) {
        setNotificationData((prevData) => [newEntity[0], ...prevData]);
        setALLNotificationData((prevData) => [newEntity[0], ...prevData]);
        var cnt = notificationCount + 1;
        setNotificationCount(cnt);
        var cnt1 = GetALLnotificationCount + 1;
        setALLNotificationCount(cnt1);
      });
      return () => {
        connection.stop();
      };
    }
  }, [userData]);

  const updateChkStatus = async (id) => {
    const apiResponse = await updateNotificationStatus(id);
    if (!apiResponse.hasError) {
      const updatedData = notificationData.map((item) => {
        if (item.n_Id === id) {
          return { ...item, chk_Status: "1" };
        }
        return item;
      });
      setNotificationData(updatedData);
      setNotificationCount(
        notificationCount == 0 ? notificationCount : notificationCount - 1
      );
    }
  };

  useEffect(() => {
    async function getNotificationDataAPI() {
      if (userData) {
        try {
          const ALlNotification = await GetALLNotification(userData);
          const N_data = ALlNotification.responseData;
          setALLNotificationData(N_data[0].data);
          setALLNotificationCount(N_data[0].dataCount);
        } catch (err) {}
      }
    }
    getNotificationDataAPI();
  }, [userData]);
  const [menuDataItems, setMenuDataItems] = useState([]);

  function navigateToProfile() {
    navigate("/profile");
  }
  useEffect(() => {
    if (showMenuItems) {
      setMenuDataItems([
        {
          text: "Profile",
          icon: "user",
          onClick: navigateToProfile,
        },
        {
          text: "Logout",
          icon: "runner",
          onClick: signOut,
        },
      ]);
    } else {
      setMenuDataItems([
        {
          text: "Logout",
          icon: "runner",
          onClick: signOut,
        },
      ]);
    }
  }, [showMenuItems]);

  const readAllnotification = async () => {
    const apiResponse = await readAllNotificationStatus(userData);
    if (!apiResponse.hasError) {
      const updatedData = notificationData.map((item) => {
        return { ...item, chk_Status: "1" };
      });
      const updatedData1 = GetALLNotificationData.map((item) => {
        return { ...item, chk_Status: "1" };
      });
      setNotificationData(updatedData);
      setALLNotificationData(updatedData1);
    }
  };

  return (
    <>
      <header className={"header-component"}>
        <Toolbar className={"header-toolbar"}>
          <Item
            visible={menuToggleEnabled}
            location={"before"}
            widget={"dxButton"}
            cssClass={"menu-button"}
          >
            <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
          </Item>
          <Item location={"before"} cssClass={"header-title logoClass"}>
          {logo?
              <img src={logo} alt="site logo" className="site-logo" height={"50px"} />
              :
              <img src={SVG1} alt="site logo" className="site-logo" />
              }
          </Item>
          <Item
            location={"before"}
            cssClass="txtlabel"
            text={`HEY! Welcome ${userData}`}
          />

          {showMenuItems ? (
            <Item location={"after"}>
              <div className="AddBtn">
                <Button
                  className="AddbtnClass"
                  borderRadius={"50%"}
                  // backgroundColor={"#318280"}
                  icon={"add"}
                  // onClick={clickHandler}
                ></Button>
                <ContextMenu
                  items={menuItems}
                  target={".AddbtnClass"}
                  showEvent={"dxclick"}
                  width={210}
                  cssClass={"user-menu"}
                >
                  <Position my={"top right"} at={"bottom center"} />
                </ContextMenu>
              </div>
            </Item>
          ) : (
            ""
          )}
          <Item location={"after"} cssClass={"nav-icons"}>
            <div className="notification">
              <span
                className="material-symbols-outlined bell-icon"
                onClick={toggleNotifyDropdown}
              >
                notifications
              </span>
              {notificationCount == 0 ? (
                " "
              ) : (
                <div
                  className="unread-noti"
                  style={{
                    backgroundColor: "#318280",
                    height: "7px",
                    width: "7px",
                    borderRadius: "50%",
                  }}
                ></div>
                // <span className="notify-badge">{notificationCount}</span>
              )}
            </div>
          </Item>
          <Item location={"after"}>
            <div className="vertical-line"></div>
          </Item>

          <Item
            location={"after"}
            locateInMenu={"auto"}
            menuItemTemplate={"userPanelTemplate"}
            cssClass={"nav-icons"}
          >
            <Button
              className={"user-button authorization"}
              height={"100%"}
              stylingMode={"text"}
            >
              <UserPanel menuMode={"context"} menuItems={menuDataItems} />
            </Button>
          </Item>

          <Template name={"userPanelTemplate"}>
            <UserPanel menuMode={"list"} menuItems={menuDataItems} />
          </Template>
        </Toolbar>
      </header>
      {isNotifyDropdownOpen && (
        <NotificationDropdown
          notificationData={notificationData}
          GetALLNotificationData={GetALLNotificationData}
          countnotification={notificationCount}
          countAllnotification={GetALLnotificationCount}
          updateChkStatus={updateChkStatus}
          readAllnotification={readAllnotification}
        />
      )}
    </>
  );
}
