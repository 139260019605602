import { RoundaboutRight } from "@mui/icons-material";
import {
  Button,
  RadioGroup,
  SelectBox,
  TextBox,
  Validator,
} from "devextreme-react";
import React, { useState } from "react";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import { addUser } from "../../../api/user-setting";
import { RequiredRule } from "devextreme-react/cjs/form";

const AddUserPopup = ({ hanldePopupClose, userAdded, setUserAdded }) => {
  const gender = ["Male", "Female", "Prefer not to say"];
  const [formData, setFormData] = useState({
    userEmail: null,
    userPassword: null,
    username: null,
    mobileNo: null,
    gender: null,
  });

  const handleUserEmailChange = (e) => {
    return setFormData({
      ...formData,
      ["userEmail"]: e.value,
    });
  };
  const handleUserNameChange = (e) => {
    return setFormData({
      ...formData,
      ["username"]: e.value,
    });
  };
  const handleUserMobileChange = (e) => {
    return setFormData({
      ...formData,
      ["mobileNo"]: e.value,
    });
  };

  const handlePWDChange = (e) => {
    return setFormData({
      ...formData,
      ["userPassword"]: e.value,
    });
  };

  const handleGender = (e) => {
    setFormData({
      ...formData,
      ["gender"]: e.itemData,
    });
  };

  const handleAddUser = async () => {
    if (
      formData?.username == null ||
      formData?.username == "" ||
      formData?.username == undefined
    ) {
      return toastDisplayer("error", "User Name is required..!!");
    }
    if (
      formData?.userPassword == null ||
      formData?.userPassword == "" ||
      formData?.userPassword == undefined
    ) {
      return toastDisplayer("error", "Password is required..!!");
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData["userEmail"])) {
      return toastDisplayer("error", "Invalid Email Address");
    }
    // for (const key in formData) {
    //   if (formData[key] === null) {
    //     return toastDisplayer("error", `${key} is required..!!`);
    //   }
    // }

    const response = await addUser(formData);
    if (response.hasError) {
      return toastDisplayer("error", response.errorMessage);
    } else {
      hanldePopupClose();
      setUserAdded(!userAdded);
      setFormData({
        userEmail: null,
        userPassword: null,
        username: null,
        mobileNo: 0,
        gender: null,
      });
      return toastDisplayer("success", response.responseData["statusMsg"]);
    }
  };

  return (
    <>
      <>
        <div className="popup-container userPopup">
          <div className="PopupHeader">
            <div className="PopupBody">
              <div className="body-main-container">
                <span>Create New user</span>
              </div>
              <div className="body-sub-container">
                you can create new user profile
              </div>
            </div>
            <div className="header-close-btn">
              <Button icon="close" height={44} onClick={hanldePopupClose} />
            </div>
          </div>

          <div className="user-popup">
            <div className="popup-body-main-container">
              <TextBox
                placeholder="User Name"
                label="User Name"
                labelMode="outside"
                width={"95%"}
                showClearButton={true}
                valueChangeEvent="keyup"
                onValueChanged={handleUserNameChange}
                value={formData["username"] ? formData["username"] : ""}
              >
                <Validator>
                  <RequiredRule message="Required" />
                </Validator>
              </TextBox>

              <TextBox
                placeholder="Password"
                width={"95%"}
                label="Password"
                labelMode="outside"
                showClearButton={true}
                valueChangeEvent="keyup"
                onValueChanged={handlePWDChange}
                value={formData["userPassword"] ? formData["userPassword"] : ""}
              >
                <Validator>
                  <RequiredRule message="Required" />
                </Validator>
              </TextBox>
            </div>

            <div className="popup-body-main-container">
              <TextBox
                placeholder="User Email ID"
                label="User Email"
                labelMode="outside"
                width={"33%"}
                showClearButton={true}
                valueChangeEvent="keyup"
                onValueChanged={handleUserEmailChange}
                value={formData["userEmail"] ? formData["userEmail"] : ""}
              >
                <Validator>
                  <RequiredRule message="Required" />
                </Validator>
              </TextBox>
              <TextBox
                placeholder="Mobile no"
                width={"33%"}
                label="Mobile No."
                labelMode="outside"
                showClearButton={true}
                valueChangeEvent="keyup"
                onValueChanged={handleUserMobileChange}
                value={formData["mobileNo"] ? formData["mobileNo"] : ""}
              />
              <SelectBox
                items={gender}
                width={"33%"}
                labelMode="outside"
                label={"Gender"}
                onItemClick={handleGender}
                text={formData["gender"] ? formData["gender"] : "Select Gender"}
              ></SelectBox>
            </div>
          </div>

          <div className="popupfooter user-popup-footer">
            <Button
              text="Cancel"
              width={144}
              height={44}
              className="popop-discard-btn"
              style={{
                backGroundColor: "white",
                border: "2px solid #C6C6C6",
                borderRadius: "4px",
              }}
              onClick={hanldePopupClose}
            />
            <Button
              text="Add"
              width={144}
              height={44}
              className="popop-confirm-btn"
              useSubmitBehavior={true}
              onClick={handleAddUser}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default AddUserPopup;
