import axios from "axios";
import { logToServer } from "./logger";
import axiosInstance from "./axiosInstance";
const API_URL = process.env.REACT_APP_API;
const userData = localStorage.getItem("User");
// Add user API
export const addUser = async (payload) => {
  const token = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    if (token != null && userData != null) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axiosInstance.post(`${API_URL}/User/AddUser`, payload);
      await logToServer(
        "User",
        "AddUser",
        "AddUser",
        "S",
        "Add user successfully..",
        JSON.stringify(payload),
        userData
      );
      responseBody.responseData = await response.data;
      return responseBody;
    } else {
      await logToServer(
        "User",
        "AddUser",
        "AddUser",
        "E",
        "Add user Error..",
        JSON.stringify(payload),
        userData
      );
      responseBody.hasError = true;
      responseBody.errorMessage = "UnAuthorise for add user";
      return responseBody;
    }
  } catch (error) {
    await logToServer(
      "User",
      "AddUser",
      "AddUser",
      "E",
      "Add user Error..",
      JSON.stringify(payload),
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage = responseBody.errorMessage =
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

// Get All User API
export async function getUserData() {
  const token = localStorage.getItem("accessToken");
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  if (token != null) {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axiosInstance.get(`${API_URL}/User`);
      await logToServer(
        "User",
        "GetUser",
        "GetUser",
        "S",
        "Get User successfully.",
        "",
        userData
      );
      responseBody.responseData = response.data;
      return responseBody;
    } catch (error) {
      await logToServer(
        "User",
        "GetUser",
        "GetUser",
        "E",
        "Get User Error.",
        "",
        userData
      );
      responseBody.hasError = true;
      responseBody.errorMessage = responseBody.errorMessage =
        error.response?.data?.statusMsg || error.response?.data?.errors;
      return responseBody;
    }
  } else {
    await logToServer(
      "User",
      "GetUser",
      "GetUser",
      "E",
      "Get User Error..",
      "",
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage = "something wrong";
    return responseBody;
  }
}

// Change Password
export const changeUserPassword = async (reqObj) => {
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.put(`${API_URL}/User/ChangePassword`, reqObj);
    await logToServer(
      "User",
      "ChangePassword",
      "ChangePassword",
      "S",
      "Change Password Successfully..",
      JSON.stringify(reqObj),
      userData
    );
    responseBody.responseData = response.data;
    return responseBody;
  } catch (error) {
    await logToServer(
      "User",
      "ChangePassword",
      "ChangePassword",
      "E",
      "Change Password Error..",
      JSON.stringify(reqObj),
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage = responseBody.errorMessage =
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

// Edit user
export async function editUserDetails(payload) {
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };

  try {
    const response = await axiosInstance.put(`${API_URL}/User/UpdateUser`, payload);
    responseBody.responseData = response.data;
    await logToServer(
      "User",
      "UpdateUser",
      "UpdateUser",
      "S",
      "Update User Successfully..",
      JSON.stringify(payload),
      userData
    );
    return responseBody;
  } catch (error) {
    await logToServer(
      "User",
      "UpdateUser",
      "UpdateUser",
      "E",
      "Update User Error..",
      JSON.stringify(payload),
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage = responseBody.errorMessage =
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

// Get User Details
export async function getUserDetails() {
  const userData = localStorage.getItem("User");
  const token = localStorage.getItem("accessToken");
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.get(
      `${API_URL}/User/GetuserDetail?username=${userData}`
      // {
      //   headers: headers,
      // }
    );
    await logToServer(
      "User",
      "GetuserDetail",
      "GetuserDetail",
      "S",
      "Get user detail Successfully..",
      JSON.stringify(userData),
      userData
    );
    responseBody.responseData = response.data;
    return responseBody;
  } catch (error) {
    await logToServer(
      "User",
      "GetuserDetail",
      "GetuserDetail",
      "E",
      "Get user detail Error..",
      JSON.stringify(userData),
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage = responseBody.errorMessage =
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

// Get auth rule by user
export const getUserAuthRole = async (reqObj) => {
  // const myCookieValue = Cookies.get("token");
  // const userData = Cookies.get("User");
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    if (myCookieValue != null && userData != null) {
      const headers = {
        Authorization: `Bearer ${myCookieValue}`,
      };
      const response = await axiosInstance.post(
        `${API_URL}/AuthUser/GetAuthRule`,
        reqObj
        // {
        //   headers: headers,
        // }
      );
      await logToServer(
        "AuthUser",
        "GetAuthRule",
        "GetAuthRule",
        "S",
        "Get auth rule Successfully..",
        JSON.stringify(reqObj),
        userData
      );
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      await logToServer(
        "AuthUser",
        "GetAuthRule",
        "GetAuthRule",
        "E",
        "Get auth rule Error..",
        JSON.stringify(reqObj),
        userData
      );
      responseBody.hasError = true;
      responseBody.errorMessage = "UnAuthorise for add user";
      return responseBody;
    }
  } catch (error) {
    await logToServer(
      "AuthUser",
      "GetAuthRule",
      "GetAuthRule",
      "E",
      "Get auth rule Error..",
      JSON.stringify(reqObj),
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage =
      error.response?.data?.errorMessage || error.response?.data?.errors;
    return responseBody;
  }
};

// Add auth rule
export async function postAuthenticationRule(payload) {
  // const myCookieValue = Cookies.get("token");
  // const userData = Cookies.get("User");
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  const storedData = localStorage.getItem("AuthRule");
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  if (myCookieValue != null && userData != null && storedData != null) {
    try {
      const headers = {
        Authorization: `Bearer ${myCookieValue}`,
      };
      const response = await axiosInstance.post(
        `${API_URL}/AuthUser/SaveAuthRule`,
        payload
        // {
        //   headers: headers,
        // }
      );
      await logToServer(
        "AuthUser",
        "SaveAuthRule",
        "SaveAuthRule",
        "S",
        "Save auth rule Success..",
        JSON.stringify(payload),
        userData
      );
      responseBody.responseData = response.data;
      return responseBody;
    } catch (error) {
      await logToServer(
        "AuthUser",
        "SaveAuthRule",
        "SaveAuthRule",
        "E",
        "Save auth rule Error..",
        JSON.stringify(payload),
        userData
      );
      responseBody.hasError = true;
      responseBody.errorMessage = responseBody.errorMessage =
        error.response?.data?.statusMsg || error.response?.data?.errors;
      return responseBody;
    }
  } else {
    await logToServer(
      "AuthUser",
      "SaveAuthRule",
      "SaveAuthRule",
      "E",
      "Save auth rule Error..",
      JSON.stringify(payload),
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage = "something wrong";
    return responseBody;
  }
}

// Notification Rule by user name
export const getUserNotificationRule = async (reqObj) => {
  // const myCookieValue = Cookies.get("token");
  // const userData = Cookies.get("User");
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    if (myCookieValue != null && userData != null) {
      // const headers = {
      //   Authorization: `Bearer ${myCookieValue}`,
      // };
      const response = await axiosInstance.get(
        `${API_URL}/NotificationRule/NotificationRule?username=${reqObj.userName}`
      );
      await logToServer(
        "NotificationRule",
        "NotificationRule",
        "NotificationRule",
        "S",
        "Notification auth rule success..",
        JSON.stringify(reqObj),
        userData
      );
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      await logToServer(
        "NotificationRule",
        "NotificationRule",
        "NotificationRule",
        "E",
        "Notification auth rule error..",
        JSON.stringify(reqObj),
        userData
      );
      responseBody.hasError = true;
      responseBody.errorMessage = "UnAuthorise for add user";
      return responseBody;
    }
  } catch (error) {
    await logToServer(
      "NotificationRule",
      "NotificationRule",
      "NotificationRule",
      "E",
      "Notification auth rule error..",
      JSON.stringify(reqObj),
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage =
      error.response?.data?.errorMessage || error.response?.data?.errors;
    return responseBody;
  }
};

// Add notification rule for user
export async function addNotificationRule(payload) {
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    if (myCookieValue != null && userData != null) {
      const headers = {
        Authorization: `Bearer ${myCookieValue}`,
      };
      const response = await axiosInstance.post(
        `${API_URL}/NotificationRule/NotificationSaveRule`,
        payload
        // {
        //   headers: headers,
        // }
      );
      await logToServer(
        "NotificationRule",
        "NotificationSaveRule",
        "NotificationSaveRule",
        "S",
        "Notification save auth rule success..",
        JSON.stringify(payload),
        userData
      );
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      await logToServer(
        "NotificationRule",
        "NotificationSaveRule",
        "NotificationSaveRule",
        "E",
        "Notification save auth rule error..",
        JSON.stringify(payload),
        userData
      );
      responseBody.hasError = true;
      responseBody.errorMessage = "UnAuthorise for add user";
      return responseBody;
    }
  } catch (error) {
    await logToServer(
      "NotificationRule",
      "NotificationSaveRule",
      "NotificationSaveRule",
      "E",
      "Notification save auth rule error..",
      JSON.stringify(payload),
      userData
    );
    responseBody.hasError = true;
    responseBody.errorMessage = responseBody.errorMessage =
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}
