// ApprovalPopup.js
import React, { useEffect, useRef, useState } from "react";
import { Popup, TextBox, Button } from "devextreme-react";
import "./ApprovePopup.scss";

const ApprovePopup = ({
  headertext,
  subtext,
  isVisible,
  onHide,
  saveFunction,
  saveIcon,
  cancelBtnTxt,
  saveBtnTxt,
  isBtnDisable,
  handleInputChange,
}) => {
  const [remark1, setRemark1] = useState("");
  const textBoxRef = useRef(null);
  useEffect(() => {
    if (isVisible && textBoxRef.current) {
      textBoxRef.current.instance.focus();
    }
  }, [isVisible]);
  const handleReamrk = async (e) => {
    await setRemark1(e);
  };
  // const handleSubmit = () => {
  //   saveFunction(remark1);
  //   return setRemark1("");
  // };

  return (
    <Popup
      visible={isVisible}
      onHiding={onHide}
      // width={"auto"}
      height={337}
      width={480}
      showCloseButton={false}
      dragEnabled={false}
      showTitle={false}
      className="confirm-popup"
    >
      <div className="conform-popup-main-container">
        <div className="release-popup-main">
          {cancelBtnTxt != undefined ? (
            <div
              style={{ backgroundColor: "rgb(218,30,40,6%)" }}
              className="iconClass"
            >
              <img src={saveIcon} style={{ padding: "8px" }} alt="saveIcon" />
            </div>
          ) : (
            <div style={{ backgroundColor: "#F0F7FF" }} className="iconClass">
              <img src={saveIcon} style={{ padding: "8px" }} alt="saveIcon" />
            </div>
          )}

          <div className="popup-close-btn">
            <Button icon="close" onClick={onHide} />
          </div>
        </div>
        <div
          style={{
            gap: "5px",
            padding: "16px 0px",
          }}
        >
          <div className="conform-popup-header-text">{headertext}</div>
          <div className="conform-popup-sub-text">{subtext}</div>
        </div>

        <TextBox
          label="Reason"
          labelMode="outside"
          width={"100%"}
          onValueChanged={(e) => handleInputChange(e.value)}
        ></TextBox>
      </div>
      <div className="popup-footer">
        {cancelBtnTxt != undefined && (
          <Button
            text={cancelBtnTxt}
            width={"100%"}
            height={44}
            onClick={saveFunction}
            type="Cancel"
            className="cancelBtn"
          />
        )}

        {saveBtnTxt != undefined && (
          <Button
            text={saveBtnTxt}
            type="default"
            width={"100%"}
            height={44}
            onClick={saveFunction}
            className="saveBtn"
            disabled={isBtnDisable}
          />
        )}
      </div>
    </Popup>
  );
};

export default ApprovePopup;
