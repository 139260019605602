import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useAuth } from "../../contexts/auth";
import "./UserPanel.scss";
// import UserImage from "../../assets/images/user.png";
import UserImage from "../../assets/images/user.png";
import { downArrow } from "../../assets/icon";
export default function UserPanel({ menuMode, menuItems }) {
  const { user, signOut } = useAuth();
  const [image, setImage] = useState();
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    setUserData(user);
    setImage("data:image/png;base64," + user["Profile"]);
  }, [user]);

  const navigate = useNavigate();

  function navigateToProfile() {
    navigate("/profile");
  }
  // const menuItems = useMemo(
  //   () => [
  //     {
  //       text: "Profile",
  //       icon: "user",
  //       onClick: navigateToProfile,
  //     },
  //     {
  //       text: "Logout",
  //       icon: "runner",
  //       onClick: signOut,
  //     },
  //   ],
  //   [signOut]
  // );
  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <img
          src={user["Profile"] != null ? image : UserImage}
          alt="Profile"
          style={{ width: "24px", height: "26px", borderRadius: "50%" }}
        />
        <div className={"user-name"}>{userData["UserData"]}</div>
        <img src={downArrow} />
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
