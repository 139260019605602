import PageHeader from "../../../components/Page-Header/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import React, { useCallback, useEffect, useState } from "react";
// import "../add-vendor/addVendor.scss";
import { HashLink as Link } from "react-router-hash-link";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import { useAuth } from "../../../contexts/auth";
import { saveVendor } from "../../../api/vendor";
import { saveVendorIcon } from "../../../assets/icon";
import { Autocomplete, LoadPanel } from "devextreme-react";
import { requestAddressFromPin } from "../../../api/registerCustomer";

import {
  Validator,
  RequiredRule,
  CompareRule,
  EmailRule,
} from "devextreme-react/validator";
import { CheckBox } from "devextreme-react/check-box";
import ConformationPopup from "../../../components/conformation-popup/conformation-popup";
import { saveCustomer, updateCustomer } from "../../../api/customer";
import { getAllCategory } from "../../../api/setting";
import {
  GetSAPBankDetail,
  GetSAPCurrency,
  getCityData,
  getCountryData,
  getPaymentTerms,
  getSalesEmp,
  getStateData,
} from "../../../api/commonAPI";
import { UseConfigContext } from "../../../contexts/cofigContext";
import { allCurrencies, allPincode } from "../../../utils/currency";

function CustomerEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const [customerdetail, setcustomerdetail] = useState([]);
  const [isTxtFieldDisable, setIsTxtFieldDisable] = useState(false);
  const { state, pathname } = location;
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [activeLink, setActiveLink] = useState("Reason");

  const [formData, setFormData] = useState({
    createdByUserName: "",
    bState: "",
    sState: "",
  });
  const [approvalRejectPopUp, setApprovalRejectPopUp] = useState(false);
  const [saveBtnDisable, setSaveBtnDisable] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [bankDetail, setBankDetail] = useState([]);
  const { getSourceData } = UseConfigContext();
  const [sourceConfigData, setSourceConfigData] = useState(getSourceData[0]);
  const [currency, setCurrency] = useState([]);

  useEffect(() => {
    if (state == null) {
      //   if (pathname == "/customer/addcustomer/CustomerReject")
      navigate("/DetailCustomer");
      //   else navigate("/customer/verifycustomer");
    } else {
      // setFrom(state.From);
      setcustomerdetail(state.customerDetail);
      setcustomerdetail((prevState) => ({
        ...prevState,
        ["createdByUserName"]: "",
      }));
      if (state.customerDetail.status == "R") {
        setIsTxtFieldDisable(false);
      }
    }
  }, []);

  const msmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];
  const ismsmeType = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];

  const btnOption3 = {
    icon: "",
    text: "For Approval",
  };
  const btnOption2 = {
    icon: "",
    text: "Cancel",
  };

  const cancelHandler = () => {
    navigate("/DetailCustomer");
  };

  const handleLinkClick = (activeLinkName) => {
    setActiveLink(activeLinkName);
  };
  const scrollToFieldWithError = (fieldName) => {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const [categoryData, setCategoryData] = useState([]);

  const getCatData = async () => {
    var apiRes = await getAllCategory();
    if (!apiRes.hasError) {
      setCategoryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const [paymentTerms, setPaymentTerms] = useState([]);

  const getPayemntTerms = async () => {
    var apiRes = await getPaymentTerms();
    if (!apiRes.hasError) {
      setPaymentTerms(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const [salesEmp, setSalesEmp] = useState([]);

  const getSalesEmpdata = async () => {
    var apiRes = await getSalesEmp();
    if (!apiRes.hasError) {
      setSalesEmp(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  useEffect(() => {
    getCatData();
    getPayemntTerms();
    getSalesEmpdata();
    getAllContryData();
    getCurrencyData();
    if (sourceConfigData && sourceConfigData["bankSource"] == "S") {
      GetBankDetail();
    }
    getAllCityData();
  }, []);

  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [ShipStateData, setShipStateData] = useState([]);
  const [bankDetailDisabled, setBankDetailDisabled] = useState({
    account: true,
    accountC: true,
    branch: true,
    swiftNum: true,
  });

  const getAllContryData = async () => {
    var apiRes = "";
    if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
      apiRes = await getCountryData("L");
    } else {
      // SAP
      apiRes = await getCountryData("S");
    }
    if (!apiRes.hasError) {
      setCountryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCountryWiseStateData = async (country_code, fieldName) => {
    if (country_code != "" && country_code != null) {
      var apiRes = "";
      if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
        apiRes = await getStateData(country_code);
      } else {
        // SAP
        apiRes = await getStateData(country_code);
      }
      if (!apiRes.hasError) {
        if (fieldName == "bCountry") {
          setStateData(apiRes.responseData);
        }
        if (fieldName == "sCountry") {
          setShipStateData(apiRes.responseData);
        }
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const getAllCityData = async () => {
    var apiRes = await getCityData();
    if (!apiRes.hasError) {
      setCityData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCurrencyData = async () => {
    if (sourceConfigData && sourceConfigData["currencySource"] == "L") {
      setCurrency(allCurrencies);
    } else {
      var apiRes = await GetSAPCurrency();
      if (!apiRes.hasError) {
        setCurrency(apiRes.responseData);
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const GetBankDetail = async () => {
    var apiRes = await GetSAPBankDetail();
    if (!apiRes.hasError) {
      setBankDetail(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const validatePincode = (state, pincode, field) => {
    console.log("pincode : ", pincode);
    if (!pincode) {
      toastDisplayer("error", `Please enter pincode..!!`);
      return false;
    }
    var sData = "";
    if (field == "Billing") {
      sData = StateData.find((e) => e.code == state);
    } else {
      sData = ShipStateData.find((e) => e.code == state);
    }
    var fdata = allPincode.find(
      (item) => item.stateName == sData?.name.toUpperCase()
    );

    var getCode = pincode.toString().substring(0, 3);
    if (fdata != null) {
      if (
        fdata.pincodeRange.start <= parseInt(getCode) &&
        fdata.pincodeRange.end >= parseInt(getCode)
      ) {
        console.log("here");
        return true;
      } else {
        toastDisplayer("error", `Invalid ${field} pincode..!!`);
        return false;
      }
    }
  };

  const approvalBtnHandler = () => {
    const requiredFields = [
      "customerName",
      "customerType",
      "cmpType",
      "panNum",
      "OfficialPhone",
      "eMail",
      "bAddress1",
      "bStreet",
      "bZipCode",
      "bCity",
      "bCountry",
      "sAddress1",
      "sStreet",
      "sZipCode",
      "sCity",
      "sCountry",
      "transCry",
      "acctName",
      "account",
      "accountC",
      "branch",
      "pCntctPrsn",
      "pCntctPhone",
      "u_QIT_IS_MSME"
    ];

    const hasEmptyField = requiredFields.some((field) => !formData[field]);
    const hasEmptyField1 = requiredFields.find((field) => !formData[field]);
    scrollToFieldWithError(hasEmptyField1);
    if (formData?.bCountry == "IN") {
      if (formData?.bState == null || formData?.bState == "") {
        return toastDisplayer("error", "Billing state is required..!!");
      } else {
        var res = validatePincode(
          formData?.bState,
          formData?.bZipCode,
          "Billing"
        );
        if (!res) {
          return;
        }
      }
    }
    if (formData?.sCountry == "IN") {
      if (formData?.sState == null || formData?.sState == "") {
        return toastDisplayer("error", "Shipping state is required..!!");
      } else {
        var res = validatePincode(
          formData?.sState,
          formData?.sZipCode,
          "Shipping"
        );
        if (!res) {
          return;
        }
      }
    }
    if (hasEmptyField) {
    } else {
      if (formData?.accountC != formData?.account) {
        return toastDisplayer(
          "error",
          "Acc No. and Confirm Acc No. do not match"
        );
      }
      if (formData?.CustomerType == "D") {
        if (
          formData?.ifscNum == null ||
          formData?.ifscNum == "" ||
          formData?.ifscNum == undefined
        ) {
          return toastDisplayer("error", "IFSC code is required..!!");
        }
        if (!formData?.u_QIT_IS_MSME) {
          return toastDisplayer("error", "Please Choose MSME...!!");
        }
        if (formData?.u_QIT_IS_MSME == "Y" && formData?.u_QIT_MSME_TYPE == "") {
          return toastDisplayer("error", "Please Select MSME Type...!!");
        }
        if (
          formData?.u_QIT_IS_MSME == "Y" &&
          formData?.u_QIT_MSME_REGNO == ""
        ) {
          return toastDisplayer(
            "error",
            "Please Select MSME Registration Number...!!"
          );
        }
      }
      if (formData?.CustomerType == "I") {
        if (
          formData?.swiftNum == null ||
          formData?.swiftNum == "" ||
          formData?.swiftNum == undefined
        ) {
          return toastDisplayer("error", "Swift code is required..!!");
        }
      }
      setApprovalRejectPopUp(true);
    }
  };

  const handleCloseRejectApprovalPopup = () => {
    setApprovalRejectPopUp(false);
  };

  const handleEditSaveClick = async () => {
    setSaveBtnDisable(true);
    setLoading(true);

    var apiRes = await updateCustomer(customerdetail);
    if (apiRes.hasError) {
      setLoading(false);
      handleCloseRejectApprovalPopup();
      setSaveBtnDisable(false);
      toastDisplayer("error", apiRes.errorMessage);
      return navigate("/DetailCustomer");
    } else {
      setLoading(false);
      handleCloseRejectApprovalPopup();
      setSaveBtnDisable(false);
      setcustomerdetail({ createdByUserName: "" });
      toastDisplayer("success", "Customer data updated..!!");
      return navigate("/DetailCustomer");
    }
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    customerdetail[fieldName] = value;

    if (fieldName == "bCountry" || fieldName == "sCountry") {
      getCountryWiseStateData(value, fieldName);
    }
    if (fieldName == "bCity" || fieldName == "sCity") {
      if (value != null) {
        setcustomerdetail((prevState) => ({
          ...prevState,
          [fieldName]: value.toUpperCase(),
        }));
      }
    }

    if (fieldName == "bZipCode") {
      if (formData?.bCountry != null && formData?.bCountry == "IN") {
        if (formData?.bState != null) {
          validatePincode(formData?.bState, value, "Billing");
        }
      }
    }

    if (fieldName == "sZipCode") {
      if (formData?.sCountry != null && formData?.sCountry == "IN") {
        if (formData?.sState != null) {
          console.log("heere");
          validatePincode(formData?.sState, value, "Shipping");
        }
      }
    }

    if (sourceConfigData && sourceConfigData["bankSource"] == "S") {
      if (fieldName == "acctName") {
        var data = bankDetail.find((e) => e.bankCode == value);
        if (data?.account != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["accountC"]: true,
            ["account"]: true,
          }));
          handleInputChange("accountC", data?.account);
          handleInputChange("account", data?.account);
        } else {
          if (bankDetail.length > 0) {
            setBankDetailDisabled((prevState) => ({
              ...prevState,
              ["accountC"]: false,
              ["account"]: false,
            }));
          }
          handleInputChange("accountC", "");
          handleInputChange("account", "");
        }
        if (data?.branch != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["branch"]: true,
          }));
          handleInputChange("branch", data?.branch);
        } else {
          if (bankDetail.length > 0) {
            setBankDetailDisabled((prevState) => ({
              ...prevState,
              ["branch"]: false,
            }));
          }
          handleInputChange("branch", "");
        }
        if (data?.swiftNum != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["swiftNum"]: true,
          }));
          handleInputChange("swiftNum", data?.swiftNum);
        } else {
          if (bankDetail.length > 0) {
            setBankDetailDisabled((prevState) => ({
              ...prevState,
              ["swiftNum"]: false,
            }));
            handleInputChange("swiftNum", "");
          }
        }
      }
    }
  };

  const vendorType = [
    { value: "E", text: "Export" },
    { value: "D", text: "Domestic" },
  ];
  const cmpCatType = [
    { value: "M", text: "Manufacturer" },
    { value: "T", text: "Trader" },
  ];

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadedFileName(file.name);
    const base64 = await convertToBase64(file);
    setFormData((prevState) => ({
      ...prevState,
      ["cmpDocs"]: base64,
    }));
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll(".form-section");
    let foundAnySection = false;

    sections.forEach((section) => {
      const bounding = section.getBoundingClientRect();
      if (bounding.top >= 0 && bounding.top <= window.innerHeight) {
        if (!foundAnySection) {
          setActiveLink(section.id);
          foundAnySection = true;
        }
      }
    });
  };

  const AccNoComparison = useCallback(() => {
    return formData?.account;
  }, [formData?.account]);

  const chkBoxHandler = (e) => {
    if (e.value) {
      setFormData((prevState) => ({
        ...prevState,
        ["sAddress1"]: formData?.bAddress1,
        ["sAddress2"]: formData?.bAddress2,
        ["sCity"]: formData?.bCity,
        ["sCountry"]: formData?.bCountry,
        ["sState"]: formData?.bState,
        ["sStreet"]: formData?.bStreet,
        ["sZipCode"]: formData?.bZipCode,
      }));
      customerdetail.sAddress1 = formData?.bAddress1;
      customerdetail.sAddress2 = formData?.bAddress2;
      customerdetail.sCity = formData?.bCity;
      customerdetail.sCountry = formData?.bCountry;
      customerdetail.sState = formData?.bState;
      customerdetail.sStreet = formData?.bStreet;
      customerdetail.sZipCode = formData?.bZipCode;
    } else {
      setFormData((prevState) => ({
        ...prevState,
        ["sAddress1"]: "",
        ["sAddress2"]: "",
        ["sCity"]: "",
        ["sCountry"]: "",
        ["sState"]: "",
        ["sStreet"]: "",
        ["sZipCode"]: "",
      }));
      customerdetail.sAddress1 = "";
      customerdetail.sAddress2 = "";
      customerdetail.sCity = "";
      customerdetail.sCountry = "";
      customerdetail.sState = "";
      customerdetail.sStreet = "";
      customerdetail.sZipCode = "";
    }
  };

  return (
    <>
      {loading ? <LoadPanel visible="true" /> : ""}
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={
          customerdetail.status == "A"
            ? false
            : customerdetail.status == "R"
            ? true
            : customerdetail.status == "P"
            ? false
            : false
        }
        isBtn2Visisble={true}
        pageTitle={"Edit Details : Registration Form "}
        statusPage={
          customerdetail.status == "A"
            ? "Approved"
            : customerdetail.status == "R"
            ? "Rejected"
            : customerdetail.status == "P"
            ? "Pending"
            : ""
        }
        btnOption={btnOption3}
        btnOption2={btnOption2}
        clickHandler={approvalBtnHandler}
        clickHandler2={cancelHandler}
      />

      <div className="content-add-form">
        <div className="form-header-panel">
          <ul type="none">
            <li className={activeLink == "Reason" ? "active" : ""}>
              <Link to="#Reason" onClick={() => handleLinkClick("Reason")}>
                Verification Reason
              </Link>
            </li>
            <li className={activeLink == "Customer" ? "active" : ""}>
              <Link to="#Customer" onClick={() => handleLinkClick("Customer")}>
                Customer
              </Link>
            </li>
            <li className={activeLink == "Address" ? "active" : ""}>
              <Link to="#Address" onClick={() => handleLinkClick("Address")}>
                Registered Address
              </Link>
            </li>
            <li className={activeLink == "Details" ? "active" : ""}>
              <Link to="#Details" onClick={() => handleLinkClick("Details")}>
                Bank Details
              </Link>
            </li>
            <li className={activeLink == "Communication" ? "active" : ""}>
              <Link
                to="#Communication"
                onClick={() => handleLinkClick("Communication")}
              >
                Communication
              </Link>
            </li>
            <li className={activeLink == "Others" ? "active" : ""}>
              <Link to="#Others" onClick={() => handleLinkClick("Others")}>
                Others
              </Link>
            </li>
          </ul>
        </div>
        <form>
          <div className="form-body-content" onScroll={handleScroll}>
            {/* ----------- Verification Reason --------------*/}
            <section id="Reason" className="form-section">
              <span className="header-text">Verification Reason</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Reason"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("reason", e.value)}
                    // value={formData?.OfficialPhone || ""}
                    value={customerdetail.reason || "--"}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>
            {/* ----------- Customer --------------*/}
            <section id="Customer" className="form-section">
              <span className="header-text">Customer</span>
              <div className="section-title">
                <div
                  className={"setion-first-row common-row-check"}
                  id="vendor"
                >
                  <SelectBox
                    label="Customer Type "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("customerType", e.value)
                    }
                    value={customerdetail.customerType || "--"}
                    // value={formData?.vendorType || ""}
                    items={vendorType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                    id="CustomerType"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <TextBox
                    label="Customer Name as per GST"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("customerName", e.value)
                    }
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                    value={customerdetail.customerName || "--"}
                    id="CustomerName"
                    // value={formData?.customerName || ""}

                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Business Structure of Customer"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpType", e.value)
                    }
                    id="cmpType"
                    value={customerdetail.cmpType || "--"}
                    // value={formData?.customerName || ""}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Category of Customer"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    value={customerdetail?.cmpCatType || "--"}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                  ></TextBox>
                  <TextBox
                    label="Pan Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("panNum", e.value)}
                    value={customerdetail.panNum || "--"}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="GST Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("gstregNum", e.value)
                    }
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                    value={customerdetail?.gstregNum || "--"}
                  />
                </div>

                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Official Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("OfficialPhone", e.value)
                    }
                    value={customerdetail?.officialPhone || "--"}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Official Email Address "
                    labelMode="outside"
                    width={"49%"}
                    className="verify-email-text required"
                    onValueChanged={(e) => handleInputChange("eMail", e.value)}
                    value={customerdetail?.eMail || "--"}
                    readOnly={true}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>

            {/* ----------- Office Address --------------*/}
            <section id="Address" className="form-section">
              <div className="header-text">Registered Address</div>
              <div className="header-sub-text">Billing Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Address 1 "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress1", e.value)
                    }
                    value={customerdetail?.bAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bAddress1"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Address 2"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress2", e.value)
                    }
                    value={customerdetail?.bAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>

                <div className={"setion-first-row common-row"}>
                  {/*                  
                  <TextBox
                    label="Country"
                    labelMode="outside"
                    width={"49%"}
                    id="bCountry"
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    value={customerdetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="State"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={customerdetail?.bState || "--"}
                    id="bState"
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}

                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    value={customerdetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={customerdetail?.bState || "--"}
                    readOnly={isTxtFieldDisable}
                    searchEnabled={true}
                    items={StateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={customerdetail?.bCountry ? false : true}
                    id="bState"
                  ></SelectBox>
                </div>
                <div className={"setion-first-row common-row"}>
                  {/* <TextBox
                    label="City "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("bCity", e.value)}
                    value={customerdetail?.bCity || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bCity"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}
                  <Autocomplete
                    dataSource={CityData}
                    value={customerdetail?.bCity || ""}
                    valueExpr={"cityName"}
                    displayExpr={"cityName"}
                    labelMode="outside"
                    label="City "
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("bCity", e.value)}
                    showClearButton={true}
                    itemRender={(data) => (
                      <span style={{ marginLeft: ".5rem" }}>
                        {data.cityName}
                      </span>
                    )}
                    className="required"
                    id="bCity"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </Autocomplete>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={customerdetail?.bZipCode || ""}
                    readOnly={isTxtFieldDisable}
                    id="bZipCode"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bStreet", e.value)
                    }
                    id="bStreet"
                    value={customerdetail?.bStreet || "--"}
                    readOnly={isTxtFieldDisable}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  {/* <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }

                    value={customerdetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  {/* <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"49%"}
                    // valueChangeEvent="keyup"
                    onValueChanged={(e) => handleGetAddress(e.value, "B")}
                    value={formData?.bZipCode || ""}
                    readOnly={!isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}
                </div>
              </div>

              <CheckBox
                text={"Shipping address same as billing address"}
                // hint={"Shipping address same as billing address"}
                className="bill_addTxt"
                onValueChanged={chkBoxHandler}
              />

              <div className="header-sub-text">Shipping Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Ship From 1 "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress1", e.value)
                    }
                    value={customerdetail?.sAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sAddress1"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Ship From 2"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress2", e.value)
                    }
                    value={customerdetail?.sAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>

                <div className={"setion-first-row common-row"}>
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={customerdetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={customerdetail?.sState || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sState"
                    items={ShipStateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={customerdetail?.sCountry ? false : true}
                    searchEnabled={true}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  {/* <TextBox
                    label="State"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={customerdetail?.sState || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sState"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}
                </div>

                <div className={"setion-first-row common-row"}>
                  <Autocomplete
                    dataSource={CityData}
                    value={customerdetail?.sCity || ""}
                    valueExpr={"cityName"}
                    displayExpr={"cityName"}
                    labelMode="outside"
                    label="City "
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("sCity", e.value)}
                    showClearButton={true}
                    itemRender={(data) => (
                      <span style={{ marginLeft: ".5rem" }}>
                        {data.cityName}
                      </span>
                    )}
                    id="sCity"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </Autocomplete>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sZipCode", e.value)
                    }
                    value={customerdetail?.sZipCode || ""}
                    id="sZipCode"
                    readOnly={isTxtFieldDisable}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  {/* <TextBox
                    label="Country"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    id="sCountry"
                    value={customerdetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sStreet", e.value)
                    }
                    value={customerdetail?.sStreet || "--"}
                    id="sStreet"
                    readOnly={isTxtFieldDisable}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>

            {/* -----------  Bank Details --------------*/}

            <section id="Details" className="form-section">
              <span className="header-text">Bank Details</span>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  {/* <TextBox
                    label="Currency"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    value={customerdetail?.transCry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="transCry"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    label="Currency "
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={currency}
                    value={customerdetail?.transCry || ""}
                    id="transCry"
                    valueExpr={"currCode"}
                    displayExpr={(item) =>
                      item ? `${item.currCode} - ${item.currName}` : ""
                    }
                    readOnly={isTxtFieldDisable}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  {sourceConfigData && sourceConfigData["bankSource"] == "S" ? (
                    <SelectBox
                      onValueChanged={(e) =>
                        handleInputChange("acctName", e.value)
                      }
                      label="Bank Name "
                      labelMode="outside"
                      width={"49%"}
                      searchEnabled={true}
                      // disabled={enableOnVerification}
                      items={bankDetail}
                      value={customerdetail?.acctName || ""}
                      valueExpr={"bankCode"}
                      displayExpr={"bankName"}
                      // displayExpr={"currName"}
                      id="acctName"
                      className="required"
                    >
                      <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                    </SelectBox>
                  ) : (
                    <TextBox
                      label="Bank Name"
                      labelMode="outside"
                      width={"49%"}
                      id="acctName"
                      onValueChanged={(e) =>
                        handleInputChange("acctName", e.value)
                      }
                      value={customerdetail?.acctName || "--"}
                      readOnly={isTxtFieldDisable}
                      className="required"
                    >
                      <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                    </TextBox>
                  )}
                  {/* <TextBox
                    label="Bank Name"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("acctName", e.value)
                    }
                    id="acctName"
                    value={customerdetail?.acctName || "--"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}
                </div>

                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Account Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("account", e.value)
                    }
                    value={customerdetail?.account || "--"}
                    readOnly={bankDetailDisabled?.account ? true : false}
                    id="account"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>

                  <TextBox
                    label="Re-enter Account Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("accountC", e.value)
                    }
                    value={
                      sourceConfigData["bankSource"] == "S"
                        ? customerdetail?.account
                        : customerdetail?.accountC
                    }
                    readOnly={bankDetailDisabled?.account ? true : false}
                    id="accountC"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                      <CompareRule
                        message="Acc No. and Confirm Acc No. do not match"
                        comparisonTarget={AccNoComparison}
                      />
                    </Validator>
                  </TextBox>
                </div>

                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="IFSC Code "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("ifscNum", e.value)
                    }
                    value={customerdetail?.ifscNum || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Swift Code "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("swiftNum", e.value)
                    }
                    readOnly={bankDetailDisabled?.swiftNum ? true : false}
                    value={customerdetail?.swiftNum || ""}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Bank Branch/Address"
                    labelMode="outside"
                    width={"49%"}
                    id="branch"
                    onValueChanged={(e) => handleInputChange("branch", e.value)}
                    value={customerdetail?.branch || "--"}
                    readOnly={isTxtFieldDisable}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  {/* <TextBox
                    label="Payment Terms"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value)
                    }
                    value={customerdetail?.pymntTerms || "--"}
                    readOnly={isTxtFieldDisable}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value.toString())
                    }
                    label="Payment Terms"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={paymentTerms}
                    // value={formData?.pymntTerms || ""}
                    value={parseInt(customerdetail?.pymntTerms) || ""}
                    valueExpr={"groupNum"}
                    displayExpr={"pymntGroup"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Communication --------------*/}

            <section id="Communication" className="form-section">
              <span className="header-text">Communication</span>
              <div className="header-sub-text">Primary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"49%"}
                    id="pCntctPrsn"
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPrsn", e.value)
                    }
                    value={customerdetail?.pCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPhone", e.value)
                    }
                    value={customerdetail?.pCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                    id="pCntctPhone"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctEmail", e.value)
                    }
                    value={customerdetail?.pCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctDesg", e.value)
                    }
                    value={customerdetail?.pCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
              <div className="header-sub-text">Secondary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPrsn", e.value)
                    }
                    value={customerdetail?.sCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPhone", e.value)
                    }
                    value={customerdetail?.sCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctEmail", e.value)
                    }
                    value={customerdetail?.sCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctDesg", e.value)
                    }
                    value={customerdetail?.sCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Other --------------*/}

            <section id="Others" className="form-section">
              <span className="header-text">Others</span>
              <div className="header-sub-text">Taxation Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_IS_MSME", e.value)
                    }
                    label="Micro, Small & Medium Enterprises"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    value={customerdetail?.u_QIT_IS_MSME || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    className="required"
                  >
                    <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                  </SelectBox>
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_TYPE", e.value.toString())
                    }
                    label="MSME Types"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={msmeType}
                    value={customerdetail?.u_QIT_MSME_TYPE || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    disabled={
                      customerdetail?.u_QIT_IS_MSME
                        ? customerdetail?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                  />
                </div>
                <div className="setion-first-row common-row">
                  <TextBox
                    label="MSME Registration No."
                    labelMode="outside"
                    width={"49%"}
                    disabled={
                      customerdetail?.u_QIT_IS_MSME
                        ? customerdetail?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_REGNO", e.value)
                    }
                    value={customerdetail?.u_QIT_MSME_REGNO || ""}
                  />
                </div>
              </div>
              {/* <div className="header-sub-text">ISO Certificate Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("isoCheck", e.value)
                    }
                    label="Is ISO Certified?"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    // value={formData?.msmeCheck || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                 <TextBox
                    label="ISO no."
                    labelMode="outside"
                    width={"49%"}
                    disabled={
                      formData?.isoCheck
                        ? formData?.isoCheck == "N"
                          ? true
                          : false
                        : true
                    }
                    // onValueChanged={(e) =>
                    //   handleInputChange("msmeRegistrationNo", e.value)
                    // }
                    // value={formData?.msmeCheck || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>

                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload ISO Certificate."
                    )}
                  </label>
                  <input
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    // onChange={handleFileUpload}
                    accept=".pdf"
                    disabled={formData?.isoCheck
                      ? formData?.isoCheck == "N"
                        ? true
                        : false
                      : true}
                  />
                </div>
              </div> */}
              <div className="header-sub-text">More Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  {/* <TextBox
                    label="Sales Employee"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("salesEmp", e.value)
                    }
                    value={customerdetail?.salesEmp || ""}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("salesEmp", e.value.toString())
                    }
                    label="Sales Employee"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={salesEmp}
                    value={customerdetail?.salesEmp || ""}
                    valueExpr={"slpCode"}
                    displayExpr={"slpName"}
                  />
                  <TextBox
                    label="Broker"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("broker", e.value)}
                    value={customerdetail?.broker || ""}
                  />
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Remarks"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("remark", e.value)}
                    value={customerdetail?.remark || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Website Link"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("websiteLink", e.value)
                    }
                    value={customerdetail?.websiteLink || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>

                <div className="setion-first-row common-row">
                  <label className="uplaod_btn" for="file_upload">
                    <label className="material-symbols-outlined">upload</label>
                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload Customer company brochures."
                    )}
                  </label>
                  <input
                    //disabled={enableOnVerification}
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept=".pdf"
                  />
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>
      {approvalRejectPopUp && (
        <ConformationPopup
          headertext={"Send for Approval"}
          subtext={"Are you sure you want to send for approval?"}
          isVisible={approvalRejectPopUp}
          onHide={handleCloseRejectApprovalPopup}
          saveFunction={handleEditSaveClick}
          saveIcon={saveVendorIcon}
          cancelBtnTxt={"Discard"}
          saveBtnTxt={"Send for Approval"}
          isSaveBtnDisabled={saveBtnDisable}
        />
      )}
    </>
  );
}

export default CustomerEdit;
