import {
  Button,
  DataGrid,
  DateBox,
  SelectBox,
  //   TextBox,
} from "devextreme-react";
import TextBox, { Button as TextBoxButton } from "devextreme-react/text-box";
import React, { useEffect, useState } from "react";
import { Item } from "devextreme-react/cjs/accordion";
import {
  Column,
  Editing,
  Toolbar,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/cjs/data-grid";
import { getUserData } from "../../api/user-setting";
import { toastDisplayer } from "../../components/comman card/toast-displayer";
import { helpIcon } from "../../assets/icon";

const LogListing = ({ userAdded }) => {
  let dataGrid;
  const [userGridData, setUserGridData] = useState([]);
  const [loading, setloading] = useState(false);

  const getAllUserData = async () => {
    setloading(true);
    try {
      const response = await getUserData();
      setloading(false);
      setUserGridData(response.responseData);
    } catch {
      setloading(true);
      return toastDisplayer("error", "Something went wrong..!!");
    }
  };

  useEffect(() => {
    getAllUserData();
  }, [userAdded]);
  const now = new Date();
  const dateLabel = { "aria-label": "Date" };
  // {/* <div className="datagrid-container-class">
  //     <div>
  //     {/* <div style={{marginBottom: "-3.1rem"}}> */}
  //       <span style={{fontSize:"18px"}}>Existing Users</span>
  //     </div> */}
  const allSelectionStatus = [
    { value: "", text: "Select Module" },
    { value: "P", text: "Pending Customers" },
    { value: "A", text: "Approved Customers" },
    { value: "R", text: "Rejected Customers" },
  ];
  const statusData = [
    { status: "All", value: "" },
    { status: "Success", value: "S" },
    { status: "Error", value: "E" },
  ];
  const userHelpOptions = {
    icon: helpIcon,
    // onClick: () => showPopupHandler(),
  };
  const [filterStatus, setFilterStatus] = useState("All");

  const handleFilterChange = (newStatus) => {
    console.log(newStatus);
    setFilterStatus(newStatus);
    var filterValue = newStatus === "All" ? undefined : newStatus;
    if (dataGrid && dataGrid.instance) {
      console.log("here");
      dataGrid.instance.columnOption("logLevel", "filterValue", filterValue);
      dataGrid.instance.refresh();
    }
  };

  return (
    <>
      <DataGrid
        dataSource={userAdded}
        rowAlternationEnabled={true}
        showBorders={true}
        columnHidingEnabled={false}
        className="on-hover-data responsive-text"
        ref={(ref) => {
          dataGrid = ref;
        }}
      >
        <Item name="searchPanel" />
        <SearchPanel visible={true} width={300} placeholder="Search" />
        <Paging defaultPageSize={10} defaultPageIndex={0} />

        <Toolbar>
          <Item name="searchPanel" />
          <Item>
            <SelectBox
              width={"250px"}
              className="selectbox-right"
              valueExpr="value"
              displayExpr="status"
              value={filterStatus}
              placeholder="Status"
              items={statusData}
              onValueChanged={(e) => handleFilterChange(e.value)}
            />
          </Item>
        </Toolbar>
        <Scrolling columnRenderingMode="virtual" />

        <Column dataField={"module"} caption={"Module"}></Column>
        <Column
          dataField={"logLevel"}
          cellRender={(e) => {
            if (e.value == "S") {
              return "Success";
            }
            if (e.value == "E") {
              return "Eroor";
            }
          }}
          caption={"Status"}
        ></Column>
        <Column dataField={"logMessage"} caption={"Log Message"}></Column>
        <Column dataField={"loginUser"} caption={"User"}></Column>
        <Column
          dataField={"entryDate"}
          caption={"Log Date"}
          dataType="datetime"
          format="d/M/yyyy, HH:mm"
        ></Column>
        <Column dataField={"jsonPayload"} caption={"Data"}></Column>
      </DataGrid>
    </>
  );
  // </div>
};

export default LogListing;
