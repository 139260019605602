import React from "react";
import { IndexCards } from "../../components";
import PageHeader from "../../components/Page-Header/PageHeader";

export const CustomerMain = () => {
  return (
    <React.Fragment>
      <p className={"content-block"}>Home</p>

      {/* <PageHeader isBreadcrumbsVisible={false} pageTitle={"Home"} /> */}
      <IndexCards path={"/customer"} header={"Customer"} />
    </React.Fragment>
  );
};
