import React, { useEffect, useState } from "react";
// import Card from "../../components/card/Card";
import "./index-cards.scss";
import { useAuth } from "../../contexts/auth";
import { useScreenSize } from "../../utils/media-query";
// import { UseHeaderContext } from "../../contexts/headerContext";
import MainCard from "../main-cards/main-cards";

export default function IndexCards({ path, header }) {
  const { AuthRuleContect } = useAuth();
  const navigate = AuthRuleContect;

  let navobj = {},
    arr = [];

  navigate.forEach((value1, key) => {
    Object.keys(value1).forEach((value2, key) => {
      if (value2 === "items") {
        arr = value1[value2];
        navobj[value1["path"]] = arr;
      }
    });
  });

  const [Icon, SetIcon] = useState("keyboard_arrow_up");

  const HandleSetIcon = () => {
    if (String(Icon) === "keyboard_arrow_down") {
      SetIcon("keyboard_arrow_up");
    } else {
      SetIcon("keyboard_arrow_down");
    }
  };

  // const { isTreeviewOpen } = UseHeaderContext();
  const { isLarge } = useScreenSize();
  const [cardNumber, setcardNumber] = useState(null);

  useEffect(() => {
    setcardNumber(isLarge ? "card-number-lg" : "card-number-xxl");
  }, [isLarge]);
  // useEffect(() => {
  //   setcardNumber(
  //     isLarge && isTreeviewOpen ? "card-number-lg" : "card-number-xxl"
  //   );
  // }, [isLarge, isTreeviewOpen]);

  return (
    <React.Fragment>
      {navobj && path && navobj[path] ? (
        <div className="">
          <div className="index-card-container">
            <div className={"index-card-heading"}>
              {/* <span className={"line"}></span> */}
              <span
                className="index-card-title"
                onClick={() => HandleSetIcon()}
              >
                {header}
              </span>
              <span
                className="material-symbols-outlined icon"
                onClick={() => HandleSetIcon()}
              >
                {Icon}
              </span>
            </div>
            <div
              className={`grid-container ${cardNumber}`}
              style={{
                display: String(Icon) === "keyboard_arrow_down" ? "none" : "",
              }}
            >
              {navobj[path].map((value, key) => (
                <MainCard
                  key={key}
                  title={value["text"]}
                  description={"Here is the description for the particular"}
                  icon={value["icon"]}
                  path={value["path"]}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
