import React from "react";
import UserListing from "./UserListing";
import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
import { Button } from "devextreme-react";
import { useAuth } from "../../../contexts/auth";
import PageHeader from "../../../components/Page-Header/PageHeader";
// import { navigation } from '../../../app-navigation'

export const ResetPasswordMain = () => {
  const { AuthRuleContect } = useAuth();
  return (
    <>
      {/* <div className="content-header-block">
        <div
          className="content-header-block-breadcrumbs"
          style={{ width: "100%" }}
        >
          <Breadcrumbs navigation={AuthRuleContect} />
        </div>

        <div className="content-header-block-inner">
          <label>Changes Users Password</label>
         
        </div>
      </div> */}
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtnVisisble={false}
        pageTitle={"Changes Users Password"}
      />

      <div className="vendor-listing">
        <UserListing />
      </div>
    </>
  );
};
