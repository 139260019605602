import React, { useState, useEffect, useRef } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import SVG1 from "../../assets/SVGs/SVG1.svg";
import "./verify-container.scss";
import { approvedIcon } from "../../assets/icon";
import { useParams } from "react-router-dom";
import { getVerificationStatus } from "../../api/auth";
import rejectPng from "../../assets/images/rejectPng.png";
import pendingPng from "../../assets/images/pendingPng.png";
import qrcode from "qrcode";
import { Button } from "devextreme-react";
const VerifyMain = ({ setStatus }) => {
  const { encodedString } = useParams();
  const [userStatus, setUserStatus] = useState([]);
  const [showStatus, setShowStatus] = useState([]);
  const [showSetStatus, setShowSetStatus] = useState(true);
  useEffect(() => {
    if (setStatus == false) {
      setShowSetStatus(false);
    }
  }, [setStatus]);

  useEffect(() => {
    if (encodedString != null && setStatus == false) {
      const getStatus = async () => {
        let getResponse = await getVerificationStatus(atob(encodedString));
        if (!getResponse.hasError) {
          setShowStatus(true);
          setUserStatus(getResponse.responseData.userStatus);
        }
      };
      getStatus();
    }
  }, [encodedString]);

  const canvasRef = useRef(null);

  useEffect(() => {
    if (showSetStatus == true) {
      qrcode.toCanvas(
        canvasRef.current,
        process.env.REACT_APP_URL + "#/verificationStatus/" + btoa(encodedString),
        (error) => {
          if (error) {
            console.error("Error while genratting  QR code:", error);
          }
        }
      );
    }
  }, []);

  const handlePrintQr = () => {
    const imageCanvas = canvasRef.current.toDataURL("image/png");
    const printWindow = window.open("", "", "width=800,height=600");
    const QrView = `<div style="display:flex; justify-content: left; align-items:center;">
    <img src="${imageCanvas}" style="object-fit:cover; flex: 0 0 20%; margin: -.5rem;">
</div>`;
    printWindow.document.open();
    printWindow.document.write(QrView);
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 1000);
  };
  return (
    <>
      <header className={"header-component-login"}>
        <Toolbar className={"header-toolbar"}>
          <Item location={"before"} cssClass={"header-title logoClass"}>
            <img src={SVG1} alt="site logo" className="site-logo" />
          </Item>
          <Item
            location={"after"}
            locateInMenu={"auto"}
            cssClass={"link-Section"}
          >
            <div>
              <a href="">
                <span>Go back to registration page</span>
              </a>
            </div>
          </Item>
        </Toolbar>
      </header>
      <div className="verify-container">
        {showSetStatus ? (
          <div className="dx-card qr-container">
            <div className="qr-container-text-area">
              <div className="qr-container-text">
                Scan QR Code to Check Status
              </div>
              <div>If approved, you'll get the login details by email.</div>
            </div>
            <canvas ref={canvasRef} style={{ margin: "20px" }}></canvas>
            <div className="qr-container-footer">
              <Button
                text="Download"
                width={"100%"}
                stylingMode="outlined"
                height={44}
                className="print-qr"
                onClick={handlePrintQr}
              />
            </div>
          </div>
        ) : (
          <div className="dx-card success-container">
            <img
              src={
                userStatus
                  ? userStatus.status == "A"
                    ? approvedIcon
                    : userStatus.status == "R"
                    ? rejectPng
                    : pendingPng
                  : pendingPng
              }
              alt="Success"
              className={
                userStatus
                  ? userStatus.status == "R"
                    ? "reject-svg"
                    : userStatus.status == "P"
                    ? "pending-svg"
                    : ""
                  : ""
              }
            />{" "}
            <div className="verify-container-text-area">
              <div className="verify-container-text">
                {userStatus
                  ? userStatus.status == "A"
                    ? userStatus.type == "C"
                      ? "Congratulations! Your registration as a customer has been successfully approved."
                      : "Congratulations! Your registration as a vendor has been successfully approved."
                    : userStatus.status == "R"
                    ? userStatus.type == "C"
                      ? "Sorry! Your registration as a customer has been rejected."
                      : "Sorry! Your registration as a vendor has been rejected."
                    : userStatus.status == "P"
                    ? userStatus.type == "C"
                      ? "No decision taken yet! Your registration as a customer is in pending state."
                      : "No decision taken yet! Your registration as a vendor is in pending state."
                    : "Something Wrong..!!"
                  : "Something Wrong..!!"}
              </div>
              <div className="verify-container-sub-text">
                {userStatus
                  ? userStatus.status == "A"
                    ? "You'll get the login details via email."
                    : userStatus.status == "R"
                    ? "You can apply again, but not more than three times."
                    : userStatus.status == "P"
                    ? "You will be notified by email if it’s approved or rejected."
                    : ""
                  : ""}
              </div>
            </div>
          </div>
        )}
        {/* <div className="dx-card success-container">
          <img
            src={
              userStatus
                ? userStatus.status == "A"
                  ? approvedIcon
                  : userStatus.status == "R"
                  ? rejectPng
                  : pendingPng
                : pendingPng
            }
            alt="Success"
            className={userStatus
              ? userStatus.status == "R" ? "reject-svg" : userStatus.status == "P" ?"pending-svg" : "" : ""}
          />{" "}
          <div className="verify-container-text-area">
            <div className="verify-container-text">
               {userStatus
                  ? userStatus.status == "A"
                    ? userStatus.type == "C"
                      ? "Congratulations! Your registration as a customer has been successfully approved."
                      : "Congratulations! Your registration as a vendor has been successfully approved."
                    : userStatus.status == "R"
                    ? userStatus.type == "C"
                      ? "Sorry! Your registration as a customer has been rejected."
                      : "Sorry! Your registration as a vendor has been rejected."
                    : "No decision taken yet! Your registration as a customer is in pending state."
                  : userStatus.type == "C"
                  ? "No decision taken yet! Your registration as a customer is in pending state."
                  : "No decision taken yet! Your registration as a vendor is in pending state."}
            </div>
            <div className="verify-container-sub-text">
              {userStatus
                ? userStatus.status == "A"
                  ? "You'll get the login details via email."
                  : userStatus.status == "R"
                  ? "You can apply again, but not more than three times."
                  : "You will be notified by email if it’s approved or rejected."
                : "You will be notified by email if it’s approved or rejected."}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default VerifyMain;
