import PageHeader from "../../../components/Page-Header/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import React, { useCallback, useEffect, useState } from "react";
import "../add-vendor/addVendor.scss";
import { HashLink as Link } from "react-router-hash-link";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import { useAuth } from "../../../contexts/auth";
import { saveVendor, verifyVendor } from "../../../api/vendor";
import { rejectIcon, saveVendorIcon } from "../../../assets/icon";
import pdf from "../../../assets/images/pdf.png";
import ApprovePopup from "../../../components/Approval-popup/ApprovePopup";
import { Autocomplete, LoadPanel } from "devextreme-react";
import { requestAddressFromPin } from "../../../api/registerCustomer";

import {
  Validator,
  RequiredRule,
  CompareRule,
  EmailRule,
} from "devextreme-react/validator";
import { CheckBox } from "devextreme-react/check-box";
import ConformationPopup from "../../../components/conformation-popup/conformation-popup";
import { allCurrencies, allPincode } from "../../../utils/currency";
import { getAllCategory } from "../../../api/setting";
import {
  GetSAPBankDetail,
  GetSAPCurrency,
  getCityData,
  getCountryData,
  getPaymentTerms,
  getStateData,
} from "../../../api/commonAPI";
import { UseConfigContext } from "../../../contexts/cofigContext";

function VendorStatus() {
  const location = useLocation();
  const navigate = useNavigate();
  const [vendorDetail, setVendorDetail] = useState([]);
  const [isTxtFieldDisable, setIsTxtFieldDisable] = useState(true);
  const { state, pathname } = location;
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [activeLink, setActiveLink] = useState("Reason");
  const [formData, setFormData] = useState({
    createdByUserName: user["UserData"],
    bState: "",
    sState: "",
    u_QIT_MSME_REGNO: "",
    u_QIT_MSME_TYPE: "",
  });
  // const currency = allCurrencies;
  const [approvalRejectPopUp, setApprovalRejectPopUp] = useState(false);
  const [saveBtnDisable, setSaveBtnDisable] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [currency, setCurrency] = useState([]);
  const { getSourceData } = UseConfigContext();
  const [sourceConfigData, setSourceConfigData] = useState(getSourceData[0]);
  const [bankDetail, setBankDetail] = useState([]);

  useEffect(() => {
    if (state == null) {
      if (pathname == "/vendor/addvendor/VendorStatus")
        navigate("/vendor/addvendor");
      else navigate("/vendor/addvendor");
    } else {
      // setFrom(state.From);
      setVendorDetail(state.vendordetail[0]);
      setVendorDetail((prevState) => ({
        ...prevState,
        ["createdByUserName"]: user["UserData"],
      }));
      if (state.From == "A" && state.vendordetail[0].status == "R") {
        setIsTxtFieldDisable(false);
      }
    }
  }, []);

  const [paymentTerms, setPaymentTerms] = useState([]);

  const getPayemntTerms = async () => {
    var apiRes = await getPaymentTerms();
    if (!apiRes.hasError) {
      setPaymentTerms(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  useEffect(() => {
    getPayemntTerms();
  }, []);

  const btnOption3 = {
    icon: "",
    text: "For Approval",
  };

  const handleLinkClick = (activeLinkName) => {
    setActiveLink(activeLinkName);
  };

  const scrollToFieldWithError = (fieldName) => {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const approvalBtnHandler = () => {
    const requiredFields = [
      "vendorName",
      "vendorType",
      "cmpCatType",
      "cmpType",
      // "panNum",
      "officialPhone",
      "eMail",
      // "bAddress1",
      // "bStreet",
      // "bZipCode",
      // "bCity",
      // "bCountry",
      // "sAddress1",
      // "sStreet",
      // "sZipCode",
      // "sCity",
      // "sCountry",
      // "transCry",
      // "acctName",
      // "account",
      // "accountC",
      // "branch",
      // "pCntctPrsn",
      // "pCntctPhone",
      "u_QIT_IS_MSME"
    ];

    const hasEmptyField = requiredFields.some((field) => !formData[field]);
    const hasEmptyField1 = requiredFields.find((field) => !formData[field]);
    scrollToFieldWithError(hasEmptyField1);
    // if (formData?.bCountry == "IN") {
    //   if (formData?.bState == null || formData?.bState == "") {
    //     return toastDisplayer("error", "Billing state is required..!!");
    //   } else {
    //     var res = validatePincode(
    //       formData?.bState,
    //       formData?.bZipCode,
    //       "Billing"
    //     );
    //     if (!res) {
    //       return;
    //     }
    //   }
    // }
    // if (formData?.sCountry == "IN") {
    //   if (formData?.sState == null || formData?.sState == "") {
    //     return toastDisplayer("error", "Shipping state is required..!!");
    //   } else {
    //     var res = validatePincode(
    //       formData?.sState,
    //       formData?.sZipCode,
    //       "Shipping"
    //     );
    //     if (!res) {
    //       return;
    //     }
    //   }
    // }
    if (hasEmptyField) {
    } else {
      // if (formData?.accountC != formData?.account) {
      //   return toastDisplayer(
      //     "error",
      //     "Acc No. and Confirm Acc No. do not match"
      //   );
      // }
      // if (formData?.CustomerType == "D") {
      //   if (
      //     formData?.ifscNum == null ||
      //     formData?.ifscNum == "" ||
      //     formData?.ifscNum == undefined
      //   ) {
      //     return toastDisplayer("error", "IFSC code is required..!!");
      //   }
      // }
      // if (formData?.CustomerType == "I") {
      //   if (
      //     formData?.swiftNum == null ||
      //     formData?.swiftNum == "" ||
      //     formData?.swiftNum == undefined
      //   ) {
      //     return toastDisplayer("error", "Swift code is required..!!");
      //   }
      // }
      setApprovalRejectPopUp(true);
    }
  };

  const handleCloseRejectApprovalPopup = () => {
    setApprovalRejectPopUp(false);
    setSaveBtnDisable(false);
  };

  const handleRejectSaveClick = async () => {
    setSaveBtnDisable(true);
    var apiRes = await saveVendor(vendorDetail);
    if (apiRes.hasError) {
      return toastDisplayer("error", apiRes.errorMessage);
    } else {
      handleCloseRejectApprovalPopup();
      setSaveBtnDisable(false);
      setFormData({ createdByUserName: user["UserData"] });
      toastDisplayer("success", "Vendor data updated..!!");
      navigate("/vendor/addvendor");
    }
  };

  const validatePincode = (state, pincode, field) => {
    var sData = "";
    if (field == "Billing") {
      sData = StateData.find((e) => e.code == state);
    } else {
      sData = ShipStateData.find((e) => e.code == state);
    }
    var fdata = allPincode.find(
      (item) => item.stateName == sData?.name.toUpperCase()
    );

    var getCode = pincode.toString().substring(0, 3);
    if (fdata != null) {
      if (
        fdata.pincodeRange.start <= parseInt(getCode) &&
        fdata.pincodeRange.end >= parseInt(getCode)
      ) {
        return true;
      } else {
        toastDisplayer("error", `Invalid ${field} pincode..!!`);
        return false;
      }
    }
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    vendorDetail[fieldName] = value;
    if (fieldName == "vendorType") {
      if (value == "D") {
        handleInputChange("transCry", "INR");
      }
    }
    if (fieldName == "bCountry" || fieldName == "sCountry") {
      if (fieldName == "bCountry") {
        handleInputChange("bState", "");
      }
      if (fieldName == "sCountry") {
        handleInputChange("sState", "");
      }
      getCountryWiseStateData(value, fieldName);
    }
    if (fieldName == "bCity" || fieldName == "sCity") {
      if (value != null) {
        setVendorDetail((prevState) => ({
          ...prevState,
          [fieldName]: value.toUpperCase(),
        }));
      }
    }
    if (fieldName == "bZipCode") {
      if (formData?.bCountry != null && formData?.bCountry == "IN") {
        if (formData?.bState != null) {
          validatePincode(formData?.bState, value, "Billing");
        }
      }
    }

    if (fieldName == "sZipCode") {
      if (formData?.sCountry != null && formData?.sCountry == "IN") {
        if (formData?.sState != null) {
          validatePincode(formData?.sState, value, "Shipping");
        }
      }
    }
    if (sourceConfigData["bankSource"] == "S") {
      if (fieldName == "acctName") {
        var data = bankDetail.find((e) => e.bankCode == value);
        if (data?.account != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["accountC"]: true,
            ["account"]: true,
          }));
          handleInputChange("accountC", data?.account);
          handleInputChange("account", data?.account);
        } else {
          if (bankDetail.length > 0) {
            setBankDetailDisabled((prevState) => ({
              ...prevState,
              ["accountC"]: false,
              ["account"]: false,
            }));
          }
          handleInputChange("accountC", "");
          handleInputChange("account", "");
        }
        if (data?.branch != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["branch"]: true,
          }));
          handleInputChange("branch", data?.branch);
        } else {
          if (bankDetail.length > 0) {
            setBankDetailDisabled((prevState) => ({
              ...prevState,
              ["branch"]: false,
            }));
          }
          handleInputChange("branch", "");
        }
      }
    }
  };

  const vendorType = [
    { value: "I", text: "Import" },
    { value: "D", text: "Domestic" },
  ];

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadedFileName(file.name);
    const base64 = await convertToBase64(file);
    setVendorDetail((prevState) => ({
      ...prevState,
      ["cmpDocs"]: base64,
    }));
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll(".form-section");
    let foundAnySection = false;

    sections.forEach((section) => {
      const bounding = section.getBoundingClientRect();
      if (bounding.top >= 0 && bounding.top <= window.innerHeight) {
        if (!foundAnySection) {
          setActiveLink(section.id);
          foundAnySection = true;
        }
      }
    });
  };

  const AccNoComparison = useCallback(() => {
    return formData?.account;
  }, [formData?.account]);

  const chkBoxHandler = (e) => {
    if (e.value) {
      setFormData((prevState) => ({
        ...prevState,
        ["sAddress1"]: formData?.bAddress1,
        ["sAddress2"]: formData?.bAddress2,
        ["sCity"]: formData?.bCity,
        ["sCountry"]: formData?.bCountry,
        ["sState"]: formData?.bState,
        ["sStreet"]: formData?.bStreet,
        ["sZipCode"]: formData?.bZipCode,
      }));
      vendorDetail.sAddress1 = formData?.bAddress1;
      vendorDetail.sAddress2 = formData?.bAddress2;
      vendorDetail.sCity = formData?.bCity;
      vendorDetail.sCountry = formData?.bCountry;
      vendorDetail.sState = formData?.bState;
      vendorDetail.sStreet = formData?.bStreet;
      vendorDetail.sZipCode = formData?.bZipCode;
    } else {
      setFormData((prevState) => ({
        ...prevState,
        ["sAddress1"]: "",
        ["sAddress2"]: "",
        ["sCity"]: "",
        ["sCountry"]: "",
        ["sState"]: "",
        ["sStreet"]: "",
        ["sZipCode"]: "",
      }));
      vendorDetail.sAddress1 = "";
      vendorDetail.sAddress2 = "";
      vendorDetail.sCity = "";
      vendorDetail.sCountry = "";
      vendorDetail.sState = "";
      vendorDetail.sStreet = "";
      vendorDetail.sZipCode = "";
    }
  };
  const [categoryData, setCategoryData] = useState([]);

  const getCatData = async () => {
    var apiRes = await getAllCategory();
    if (!apiRes.hasError) {
      setCategoryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [ShipStateData, setShipStateData] = useState([]);
  const [bankDetailDisabled, setBankDetailDisabled] = useState({
    account: true,
    accountC: true,
    branch: true,
  });

  const getAllContryData = async () => {
    var apiRes = "";
    if (sourceConfigData["countrySource"] == "L") {
      apiRes = await getCountryData("L");
    } else {
      // SAP
      apiRes = await getCountryData("S");
    }
    if (!apiRes.hasError) {
      setCountryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCountryWiseStateData = async (country_code, fieldName) => {
    if (country_code != "" && country_code != null) {
      var apiRes = "";
      if (sourceConfigData["countrySource"] == "L") {
        apiRes = await getStateData(country_code);
      } else {
        // SAP
        apiRes = await getStateData(country_code);
      }

      if (!apiRes.hasError) {
        if (fieldName == "bCountry") {
          setStateData(apiRes.responseData);
        }
        if (fieldName == "sCountry") {
          setShipStateData(apiRes.responseData);
        }
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const getAllCityData = async () => {
    var apiRes = await getCityData();
    if (!apiRes.hasError) {
      setCityData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCurrencyData = async () => {
    if (sourceConfigData["currencySource"] == "L") {
      setCurrency(allCurrencies);
    } else {
      var apiRes = await GetSAPCurrency();
      if (!apiRes.hasError) {
        setCurrency(apiRes.responseData);
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const GetBankDetail = async () => {
    var apiRes = await GetSAPBankDetail();
    if (!apiRes.hasError) {
      setBankDetail(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  useEffect(() => {
    getCatData();
    getAllContryData();
    getCurrencyData();
    if (sourceConfigData["bankSource"] == "S") {
      GetBankDetail();
    }
    getAllCityData();
  }, []);

  const msmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];
  const ismsmeType = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];

  return (
    <>
      {loading ? <LoadPanel visible="true" /> : ""}
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={true}
        isBtn2Visisble={false}
        pageTitle={"Vendor Details : Decision"}
        statusPage={"Rejected"}
        btnOption={btnOption3}
        clickHandler={approvalBtnHandler}
      />

      <div className="content-add-form">
        <div className="form-header-panel">
          <ul type="none">
            <li className={activeLink == "Reason" ? "active" : ""}>
              <Link to="#Reason" onClick={() => handleLinkClick("Reason")}>
                Verification Reason
              </Link>
            </li>
            <li className={activeLink == "Vendor" ? "active" : ""}>
              <Link to="#Vendor" onClick={() => handleLinkClick("Vendor")}>
                Vendor
              </Link>
            </li>
            <li className={activeLink == "Address" ? "active" : ""}>
              <Link to="#Address" onClick={() => handleLinkClick("Address")}>
                Registered Address
              </Link>
            </li>
            <li className={activeLink == "Details" ? "active" : ""}>
              <Link to="#Details" onClick={() => handleLinkClick("Details")}>
                Bank Details
              </Link>
            </li>
            <li className={activeLink == "Communication" ? "active" : ""}>
              <Link
                to="#Communication"
                onClick={() => handleLinkClick("Communication")}
              >
                Communication
              </Link>
            </li>
            <li className={activeLink == "Others" ? "active" : ""}>
              <Link to="#Others" onClick={() => handleLinkClick("Others")}>
                Others
              </Link>
            </li>
          </ul>
        </div>
        <form>
          <div className="form-body-content" onScroll={handleScroll}>
            {/* ----------- Verification Reason --------------*/}
            <section id="Reason" className="form-section">
              <span className="header-text">Verification Reason</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Reason"
                    labelMode="outside"
                    width={"49%"}
                    // value={formData?.OfficialPhone || ""}
                    value={vendorDetail.reason || "--"}
                    readOnly={true}
                    id="reason"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>
            {/* ----------- Vendor --------------*/}
            <section id="Vendor" className="form-section">
              <span className="header-text">Vendor</span>
              <div className="section-title">
                <div className={"setion-first-row common-row"} id="vendor">
                  <SelectBox
                    label="Vendor Type "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("vendorType", e.value)
                    }
                    value={vendorDetail.vendorType || "--"}
                    // value={formData?.vendorType || ""}
                    items={vendorType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                    id="vendorType"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <TextBox
                    label="Vendor Name as per GST"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("vendorName", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={vendorDetail.vendorName || "--"}
                    id="vendorName"
                    // value={formData?.vendorName || ""}
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Business Structure of Vendor"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpType", e.value)
                    }
                    value={vendorDetail.cmpType || "--"}
                    // value={formData?.vendorName || ""}
                    readOnly={isTxtFieldDisable}
                    id="cmpType"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <SelectBox
                    label="Category of Vendor"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    // value={"MANUFACTURER"}
                    // selectedItem={vendorDetail.cmpCatType || "--"}
                    value={vendorDetail.cmpCatType || "--"}
                    // value={formData?.cmpCatType || ""}
                    items={categoryData}
                    valueExpr={"venCatName"}
                    displayExpr={"venCatName"}
                    readOnly={isTxtFieldDisable}
                    id="cmpCatType"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                </div>

                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Pan Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("panNum", e.value)}
                    value={vendorDetail.panNum || "--"}
                    readOnly={isTxtFieldDisable}
                    id="panNum"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="GST Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("gstregNum", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={vendorDetail?.gstregNum || "--"}
                  />
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Official Phone Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("officialPhone", e.value)
                    }
                    value={vendorDetail?.officialPhone || "--"}
                    readOnly={isTxtFieldDisable}
                    id="officialPhone"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Official Email Address "
                    labelMode="outside"
                    width={"49%"}
                    className="verify-email-text required"
                    onValueChanged={(e) => handleInputChange("eMail", e.value)}
                    value={vendorDetail?.eMail || "--"}
                    readOnly={true}
                    id="eMail"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>

            {/* ----------- Office Address --------------*/}
            <section id="Address" className="form-section">
              <div className="header-text">Registered Address</div>
              <div className="header-sub-text">Billing Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Address 1 "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress1", e.value)
                    }
                    value={vendorDetail?.bAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bAddress1"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="Address 2"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress2", e.value)
                    }
                    value={vendorDetail?.bAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row"}>
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    searchEnabled={true}
                    id="bCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    value={vendorDetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={vendorDetail?.bState || ""}
                    readOnly={isTxtFieldDisable}
                    id="bState"
                    searchEnabled={true}
                    items={StateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={vendorDetail?.bCountry ? false : true}
                  ></SelectBox>
                </div>
                <div className={"setion-first-row common-row"}>
                  <Autocomplete
                    dataSource={CityData}
                    value={vendorDetail?.bCity || ""}
                    valueExpr={"cityName"}
                    displayExpr={"cityName"}
                    labelMode="outside"
                    label="City "
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("bCity", e.value)}
                    showClearButton={true}
                    itemRender={(data) => (
                      <span style={{ marginLeft: ".5rem" }}>
                        {data.cityName}
                      </span>
                    )}
                    id="bCity"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </Autocomplete>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"49%"}
                    // onValueChanged={(e) => handleGetAddress(e.value, "B")}
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={vendorDetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bZipCode"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>

                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bStreet", e.value)
                    }
                    value={vendorDetail?.bStreet || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bStreet"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>
              </div>
              {vendorDetail.status == "R" && (
                <CheckBox
                  text={"Shipping address same as billing address"}
                  // hint={"Shipping address same as billing address"}
                  className="bill_addTxt"
                  onValueChanged={chkBoxHandler}
                />
              )}
              <div className="header-sub-text">Shipping Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Ship From 1 "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress1", e.value)
                    }
                    value={vendorDetail?.sAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sAddress1"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="Ship From 2"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress2", e.value)
                    }
                    value={vendorDetail?.sAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>

                <div className={"setion-first-row common-row"}>
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={vendorDetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={vendorDetail?.sState || ""}
                    readOnly={isTxtFieldDisable}
                    id="sState"
                    items={ShipStateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={vendorDetail?.sCountry ? false : true}
                    searchEnabled={true}
                  ></SelectBox>
                </div>

                <div className={"setion-first-row common-row"}>
                  <Autocomplete
                    dataSource={CityData}
                    value={vendorDetail?.sCity || ""}
                    valueExpr={"cityName"}
                    displayExpr={"cityName"}
                    labelMode="outside"
                    label="City "
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("sCity", e.value)}
                    showClearButton={true}
                    itemRender={(data) => (
                      <span style={{ marginLeft: ".5rem" }}>
                        {data.cityName}
                      </span>
                    )}
                    id="sCity"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </Autocomplete>

                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"49%"}
                    // onValueChanged={(e) => handleGetAddress(e.value, "S")}
                    onValueChanged={(e) =>
                      handleInputChange("sZipCode", e.value)
                    }
                    value={vendorDetail?.sZipCode || ""}
                    readOnly={isTxtFieldDisable}
                    id="sZipCode"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>

                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sStreet", e.value)
                    }
                    value={vendorDetail?.sStreet || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sStreet"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>
              </div>
            </section>

            {/* -----------  Bank Details --------------*/}

            <section id="Details" className="form-section">
              <span className="header-text">Bank Details</span>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    label="Currency "
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={currency}
                    value={vendorDetail?.transCry || ""}
                    valueExpr={"currCode"}
                    displayExpr={(item) =>
                      item ? `${item.currCode} - ${item.currName}` : ""
                    }
                    id="transCry"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </SelectBox>
                  {sourceConfigData["bankSource"] == "S" ? (
                    <SelectBox
                      onValueChanged={(e) =>
                        handleInputChange("acctName", e.value)
                      }
                      label="Bank Name"
                      labelMode="outside"
                      width={"49%"}
                      searchEnabled={true}
                      items={bankDetail}
                      value={vendorDetail?.acctName || ""}
                      valueExpr={"bankCode"}
                      displayExpr={"bankName"}
                      // displayExpr={"currName"}
                      id="acctName"
                      // className="required"
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </SelectBox>
                  ) : (
                    <TextBox
                      label="Bank Name"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("acctName", e.value)
                      }
                      value={vendorDetail?.acctName || "--"}
                      readOnly={isTxtFieldDisable}
                      id="acctName"
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                  )}
                </div>

                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Account Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("account", e.value)
                    }
                    value={vendorDetail?.account || ""}
                    readOnly={bankDetailDisabled?.account ? true : false}
                    // readOnly={isTxtFieldDisable}
                    id="account"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="Re-enter Account Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("accountC", e.value)
                    }
                    value={
                      sourceConfigData["bankSource"] == "S"
                        ? vendorDetail?.account
                        : vendorDetail?.accountC
                    }
                    readOnly={bankDetailDisabled?.account ? true : false}
                    // value={vendorDetail?.accountC || ""}
                    id="accountC"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                      <CompareRule
                        message="Acc No. and Confirm Acc No. do not match"
                        comparisonTarget={AccNoComparison}
                      />
                    </Validator> */}
                  </TextBox>
                </div>

                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="IFSC Code "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("ifscNum", e.value)
                    }
                    value={vendorDetail?.ifscNum || "--"}
                    readOnly={isTxtFieldDisable}
                    id="ifscNum"
                  />
                  <TextBox
                    label="Bank Branch/Address"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("branch", e.value)}
                    value={vendorDetail?.branch || ""}
                    readOnly={bankDetailDisabled?.branch ? true : false}
                    // readOnly={isTxtFieldDisable}
                    id="branch"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row"}>
                  {/* <TextBox
                    label="Payment Terms"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value)
                    }
                    value={vendorDetail?.pymntTerms || "--"}
                    readOnly={isTxtFieldDisable}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value.toString())
                    }
                    label="Payment Terms"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={paymentTerms}
                    // value={formData?.pymntTerms || ""}
                    value={parseInt(vendorDetail?.pymntTerms) || ""}
                    valueExpr={"groupNum"}
                    displayExpr={"pymntGroup"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Communication --------------*/}

            <section id="Communication" className="form-section">
              <span className="header-text">Communication</span>
              <div className="header-sub-text">Primary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPrsn", e.value)
                    }
                    value={vendorDetail?.pCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                    id="pCntctPrsn"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPhone", e.value)
                    }
                    value={vendorDetail?.pCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                    id="pCntctPhone"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctEmail", e.value)
                    }
                    value={vendorDetail?.pCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctDesg", e.value)
                    }
                    value={vendorDetail?.pCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
              <div className="header-sub-text">Secondary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPrsn", e.value)
                    }
                    value={vendorDetail?.sCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPhone", e.value)
                    }
                    value={vendorDetail?.sCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctEmail", e.value)
                    }
                    value={vendorDetail?.sCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctDesg", e.value)
                    }
                    value={vendorDetail?.sCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Other --------------*/}

            <section id="Others" className="form-section">
              <span className="header-text">Others</span>

              <div className="header-sub-text">Taxation Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_IS_MSME", e.value)
                    }
                    label="Micro, Small & Medium Enterprises"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    value={vendorDetail?.u_QIT_IS_MSME || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    className="required"
                  >
                    <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                  </SelectBox>
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_TYPE", e.value.toString())
                    }
                    label="MSME Types"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={msmeType}
                    value={vendorDetail?.u_QIT_MSME_TYPE || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    disabled={
                      vendorDetail?.u_QIT_IS_MSME
                        ? vendorDetail?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                  />
                </div>
                <div className="setion-first-row common-row">
                  <TextBox
                    label="MSME Registration No."
                    labelMode="outside"
                    width={"49%"}
                    disabled={
                      vendorDetail?.u_QIT_IS_MSME
                        ? vendorDetail?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_REGNO", e.value)
                    }
                    value={vendorDetail?.u_QIT_MSME_REGNO || ""}
                  />
                </div>
              </div>
              {/* <div className="header-sub-text">ISO Certificate Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("isoCheck", e.value)
                    }
                    label="Is ISO Certified?"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    // value={formData?.msmeCheck || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                 <TextBox
                    label="ISO no."
                    labelMode="outside"
                    width={"49%"}
                    disabled={
                      formData?.isoCheck
                        ? formData?.isoCheck == "N"
                          ? true
                          : false
                        : true
                    }
                    // onValueChanged={(e) =>
                    //   handleInputChange("msmeRegistrationNo", e.value)
                    // }
                    // value={formData?.msmeCheck || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>

                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload ISO Certificate."
                    )}
                  </label>
                  <input
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    // onChange={handleFileUpload}
                    accept=".pdf"
                    disabled={formData?.isoCheck
                      ? formData?.isoCheck == "N"
                        ? true
                        : false
                      : true}
                  />
                </div>
              </div> */}
              <div className="header-sub-text">More Details</div>

              <div className="section-title">
                <div className={"setion-first-row common-row"}>
                  <TextBox
                    label="Remarks"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("remark", e.value)}
                    value={vendorDetail?.remark || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Website Link"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("websiteLink", e.value)
                    }
                    value={vendorDetail?.websiteLink || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>

                <div className="setion-first-row common-row">
                  <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>
                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload Customer company brochures."
                    )}
                  </label>
                  <input
                    //disabled={enableOnVerification}
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept=".pdf"
                  />
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>
      {approvalRejectPopUp && (
        <ConformationPopup
          headertext={"Send for Approval"}
          subtext={"Are you sure you want to send for approval?"}
          isVisible={approvalRejectPopUp}
          onHide={handleCloseRejectApprovalPopup}
          saveFunction={handleRejectSaveClick}
          saveIcon={saveVendorIcon}
          cancelBtnTxt={"Discard"}
          saveBtnTxt={"Send for Approval"}
          isSaveBtnDisabled={saveBtnDisable}
        />
      )}
    </>
  );
}

export default VendorStatus;
