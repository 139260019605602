import React from "react";
import "./verifyVendor.scss";
import { VendorListing } from "../Vendor-lising/vendor-listing";
import PageHeader from "../../../components/Page-Header/PageHeader";

export const VerifyVendorMain = () => {
  const btnOption = {
    icon: "add",
    text: "Add Vendor",
  };

  return (
    <>
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={false}
        pageTitle={"Verify Vendors"}
      />

      <div className="vendor-listing">
        <VendorListing from="V" />
      </div>
    </>
  );
};
