import axios from "axios";
import { logToServer } from "./logger";
import axiosInstance from "./axiosInstance";
const API_URL = process.env.REACT_APP_API;

export const getAllCategory = async () => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.get(`${API_URL}/VendorCategoryMaster`);

    if (response.status === 200) {
      responseBody.hasError = false;
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Error while fetching data..!!";
      return responseBody;
    }
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const saveCategory = async (reqBody) => {
  const storedData = localStorage.getItem("AuthRule");
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  if (myCookieValue != null && userData != null && storedData != null) {
    try {
      const headers = {
        Authorization: `Bearer ${myCookieValue}`,
      };

      const response = await axiosInstance.post(
        `${API_URL}/VendorCategoryMaster/AddVenCategory`,
        reqBody
        // {
        //   headers: headers,
        // }
      );

      if (response.status === 200) {
        responseBody.hasError = false;
        responseBody.responseData = "Category added..!!";
        return responseBody;
      } else {
        responseBody.errorMessage = "Error while saving data..!!";
        return responseBody;
      }
    } catch (error) {
      // responseBody.errorMessage = error.response.data.statusMsg;
      responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
      return responseBody;
    }
  } else {
    responseBody.errorMessage = "User not authenticated..!!";
    return responseBody;
  }
};

export const deleteCategory = async (id) => {
  const storedData = localStorage.getItem("AuthRule");
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  if (myCookieValue != null && userData != null && storedData != null) {
    try {
      const headers = {
        Authorization: `Bearer ${myCookieValue}`,
      };

      const response = await axiosInstance.delete(
        `${API_URL}/VendorCategoryMaster/${id}`
      );

      if (response.status === 200) {
        responseBody.hasError = false;
        responseBody.responseData = "Category Deleted..!!";
        return responseBody;
      } else {
        responseBody.errorMessage = "Error while deleting data..!!";
        return responseBody;
      }
    } catch (error) {
      responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
      // responseBody.errorMessage = error.response.data.statusMsg;
      return responseBody;
    }
  } else {
    responseBody.errorMessage = "User not authenticated..!!";
    return responseBody;
  }
};

export const updateCategory = async (reqBody) => {
  const storedData = localStorage.getItem("AuthRule");
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  if (myCookieValue != null && userData != null && storedData != null) {
    try {
      const headers = {
        Authorization: `Bearer ${myCookieValue}`,
      };

      const response = await axiosInstance.put(
        `${API_URL}/VendorCategoryMaster/UpdateVendorCategory`,
        reqBody
      );

      if (response.status === 200) {
        responseBody.hasError = false;
        responseBody.responseData = "Category updated..!!";
        return responseBody;
      } else {
        responseBody.errorMessage = "Error while saving data..!!";
        return responseBody;
      }
    } catch (error) {
      responseBody.errorMessage = error.response.data.statusMsg;
      return responseBody;
    }
  } else {
    responseBody.errorMessage = "User not authenticated..!!";
    return responseBody;
  }
};

export const getConfigSource = async (branchId) => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.get(
      `${API_URL}/Config/GetConfig?BranchID=${branchId}`
    );

    if (response.status === 200) {
      responseBody.hasError = false;
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Error while fetching data..!!";
      return responseBody;
    }
  } catch (error) {
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const saveConfigSource = async (reqBody) => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.post(`${API_URL}/Config/SaveConfig`, reqBody);

    if (response.status === 200) {
      responseBody.hasError = false;
      responseBody.responseData = "Source saved..!!";
      return responseBody;
    } else {
      responseBody.errorMessage = "Error while saving data..!!";
      return responseBody;
    }
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};


