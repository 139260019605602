export const fakeData = [
    {
      Task_ID: 1,
      Task_Subject: "Vendor",
      Task_Parent_ID: 0,
    },
    {
      Task_ID: 11,
      Task_Subject: "Add Vendor",
      Task_Parent_ID: 1,
    },
    {
      Task_ID: 12,
      Task_Subject: "Verify Vendor",
      Task_Parent_ID: 1,
    },
    {
      Task_ID: 2,
      Task_Subject: "Customer",
      Task_Parent_ID: 0,
    },
    {
      Task_ID: 21,
      Task_Subject: "Add customer",
      Task_Parent_ID: 2,
    },
    {
      Task_ID: 22,
      Task_Subject: "Verify customer",
      Task_Parent_ID: 2,
    },
  ];

export   const navigation_main = [
    {
      text: "Vendor",
      path: "/vendor",
      icon: "keyboard_tab_rtl",
      hasAccess: true,
      items: [
        {
          text: "Add Vendor",
          path: "/vendor/addvendor",
          icon: "add",
          rightsAccess: ["F", "F", "F", "F"],
        },
        {
          text: "Verify Vendor",
          path: "/vendor/verifyvendor",
          icon: "rule",
          rightsAccess: ["F", "F", "F", "F"],
        },
      ],
    },
    {
      text: "Customer",
      path: "/customer",
      icon: "keyboard_tab",
      hasAccess: true,
      items: [
        {
          text: "Add customer",
          path: "/customer/addCustomer",
          icon: "add",
          rightsAccess: ["F", "F", "F", "F"],
        },
        {
          text: "Verify customer",
          path: "/customer/verifycustomer",
          icon: "rule",
          rightsAccess: ["F", "F", "F", "F"],
        },
      ],
    },
  ];

export  const StateCodes = [
    { name: "Jammu & Kashmir", code: '01', alphaCode: 'JK' },
    { name: "Himachal Pradesh", code: '02', alphaCode: 'HP' },
    { name: "Punjab", code: '03', alphaCode: 'PB' },
    { name: "Chandigarh", code: '04', alphaCode: 'CH' },
    { name: "Uttarakhand", code: '05', alphaCode: 'UT' },
    { name: "Haryana", code: '06', alphaCode: 'HR' },
    { name: "Delhi", code: '07', alphaCode: 'DL' },
    { name: "Rajasthan", code: '08', alphaCode: 'RJ' },
    { name: "Uttar Pradesh", code: '09', alphaCode: 'UP' },
    { name: "Bihar", code: '10', alphaCode: 'BH' },
    { name: "Sikkim", code: '11', alphaCode: 'SK' },
    { name: "Arunachal Pradesh", code: '12', alphaCode: 'AR' },
    { name: "Nagaland", code: '13', alphaCode: 'NL' },
    { name: "Manipur", code: '14', alphaCode: 'MN' },
    { name: "Mizoram", code: '15', alphaCode: 'MI' },
    { name: "Tripura", code: '16', alphaCode: 'TR' },
    { name: "Meghalaya", code: '17', alphaCode: 'ME' },
    { name: "Assam", code: '18', alphaCode: 'AS' },
    { name: "West Bengal", code: '19', alphaCode: 'WB' },
    { name: "Jharkhand", code: '20', alphaCode: 'JH' },
    { name: "Odisha", code: '21', alphaCode: 'OR' },
    { name: "Chattisgarh", code: '22', alphaCode: 'CT' },
    { name: "Madhya Pradesh", code: '23', alphaCode: 'MP' },
    { name: "Gujarat", code: '24', alphaCode: 'GJ' },
    { name: "Daman & Diu", code: '25', alphaCode: 'DD' },
    { name: "Dadra & Nagar Haveli", code: '26', alphaCode: 'DN' },
    { name: "Maharashtra", code: '27', alphaCode: 'MH' },
    { name: "Andhra Pradesh (Before Division)", code: '28', alphaCode: 'AP' },
    { name: "Karnataka", code: '29', alphaCode: 'KA' },
    { name: "Goa", code: '30', alphaCode: 'GA' },
    { name: "Lakshadweep", code: '31', alphaCode: 'LD' },
    { name: "Kerala", code: '32', alphaCode: 'KL' },
    { name: "Tamil Nadu", code: '33', alphaCode: 'TN' },
    { name: "Puducherry", code: '34', alphaCode: 'PY' },
    { name: "Andaman & Nicobar Islands", code: '35', alphaCode: 'AN' },
    { name: "Telangana", code: '36', alphaCode: 'TL' },
    { name: "Andhra Pradesh (Newly Added)", code: '37', alphaCode: 'AD' },
    { name: "Ladakh", code: '38', alphaCode: 'LA' },
    { name: "Other Territory", code: '97', alphaCode: '' },
    { name: "Centre Jurisdiction", code: '99', alphaCode: '' }
];
