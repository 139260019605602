import React, { useCallback, useEffect, useRef, useState } from "react";
import "../CustomeRegistration/customerRegistration.scss";
import {
  Autocomplete,
  CheckBox,
  LoadPanel,
  Popup,
  SelectBox,
} from "devextreme-react";
import { Button as TextBoxButton, TextBox } from "devextreme-react/text-box";
import OtpInput from "../../components/OtpInputBtn/OtpInput";
import {
  generateOtpFromMail,
  requestAddressFromPin,
} from "../../api/registerCustomer.js";
import { allCurrencies, allPincode } from "../../utils/currency.js";
import { useNavigate } from "react-router-dom";
import { toastDisplayer } from "../../components/comman card/toast-displayer.js";
import PageHeader from "../../components/Page-Header/PageHeader.js";
import { HashLink as Link } from "react-router-hash-link";
import {
  Validator,
  RequiredRule,
  CompareRule,
  EmailRule,
} from "devextreme-react/validator";
import { arrow_forward, saveVendorIcon } from "../../assets/icon.js";
import { saveVendor } from "../../api/vendor";
import ConformationPopup from "../../components/conformation-popup/conformation-popup";
import Logo from "../../assets/SVGs/SVG1.svg";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { getAllCategory } from "../../api/setting";
import {
  GetSAPBankDetail,
  GetSAPCurrency,
  getCityData,
  getCountryData,
  getGstDetail,
  getPaymentTerms,
  getStateData,
} from "../../api/commonAPI.js";
import { UseConfigContext } from "../../contexts/cofigContext";
import { StateCodes } from "../../components/Common-Object/common-object";
import success from ".././../assets/SVGs/success.svg";

function VendorRegistration() {
  const [currency, setCurrency] = useState([]);
  // const currency = allCurrencies;
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [approvalPopup, setShowApprovalPopup] = useState(false);
  const [enableOnVerification, setEnableOnVerification] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [bankDetail, setBankDetail] = useState([]);
  const { getSourceData } = UseConfigContext();
  console.log("getSourceData:", getSourceData);
  const [sourceConfigData, setSourceConfigData] = useState(getSourceData[0]);
  const [isGstVerified, setIsGstVerified] = useState(false);
  
  const vendorType = [
    { value: "I", text: "Import" },
    { value: "D", text: "Domestic" },
  ];
  const scrollToFieldWithError = (fieldName) => {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const getCatData = async () => {
    var apiRes = await getAllCategory();
    if (!apiRes.hasError) {
      setCategoryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [ShipStateData, setShipStateData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [bankDetailDisabled, setBankDetailDisabled] = useState({
    account: false,
    accountC: false,
    branch: false,
  });

  const getAllContryData = async () => {
    var apiRes = "";
    if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
      apiRes = await getCountryData("L");
    } else {
      // SAP
      apiRes = await getCountryData("S");
    }
    if (!apiRes.hasError) {
      setCountryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCountryWiseStateData = async (country_code, fieldName) => {
    if (country_code != "" && country_code != null) {
      var apiRes = await getStateData(country_code);
      if (!apiRes.hasError) {
        if (fieldName == "bCountry") {
          setStateData(apiRes.responseData);
        }
        if (fieldName == "sCountry") {
          setShipStateData(apiRes.responseData);
        }
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const getAllCityData = async () => {
    var apiRes = await getCityData();
    if (!apiRes.hasError) {
      setCityData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCurrencyData = async () => {
    if (sourceConfigData && sourceConfigData["currencySource"] == "L") {
      setCurrency(allCurrencies);
    } else {
      var apiRes = await GetSAPCurrency();
      if (!apiRes.hasError) {
        setCurrency(apiRes.responseData);
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };
  const [paymentTerms, setPaymentTerms] = useState([]);

  const getPayemntTerms = async () => {
    var apiRes = await getPaymentTerms();
    if (!apiRes.hasError) {
      setPaymentTerms(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const GetBankDetail = async () => {
    var apiRes = await GetSAPBankDetail();
    if (!apiRes.hasError) {
      setBankDetail(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const GetVendorGSTDetail = async (GSTINNO) => {
    var apiRes = await getGstDetail(GSTINNO);
    if (!apiRes.hasError) {
      setIsGstVerified(true);
       setFormData((prevState) => ({
      ...prevState,
      ["vendorName"]: apiRes.responseData.result.lgnm,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bAddress1"]: apiRes.responseData.result.pradr.addr.bnm,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bCountry"]: "india",
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bState"]:  apiRes.responseData.result.pradr.addr.stcd,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bCity"]:  apiRes.responseData.result.pradr.addr.loc,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bZipCode"]:  apiRes.responseData.result.pradr.addr.pncd,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bStreet"]:  apiRes.responseData.result.pradr.addr.st,
    }));
    const data = CountryData.find((item)=>item.name == "India");
    setFormData((prevState) => ({
      ...prevState,
      ["bCountry"]:  data.code,
    }));
    const stateData = StateCodes.find((item)=>item.name == apiRes.responseData.result.pradr.addr.stcd);
    setFormData((prevState) => ({
      ...prevState,
      ["bState"]:  stateData.alphaCode,
    }));
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };


 const [logo, setLogo] = useState("");

  useEffect(() => {
    getCatData();
    getAllContryData();
    getCurrencyData();
    // if (sourceConfigData && sourceConfigData["bankSource"] == "S") {
    //   GetBankDetail();
    // }
    if (getSourceData[0]?.bankSource == "S") {
      GetBankDetail(allCurrencies);
    }
    if (getSourceData[0]?.atcPath != "" ) {

      setLogo(getSourceData[0]?.atcPath);
    }
    getPayemntTerms();
    getAllCityData();
    console.log("here");
    setSourceConfigData(getSourceData[0]);
  }, [getSourceData]);

  const userType = "v";
  const navigate = useNavigate();

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //-------------------------- Email Logic ----------------------------
  const hanldePopupShow = async () => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const isMatch = regex.test(formData?.eMail);

    if (isMatch === false) {
      return toastDisplayer("error", "Enter a correct Mail ");
    }

    const type = "v";
    getOtpFromMail(formData?.eMail, type);
  };

  const [refFocused, setrefFocused] = useState(false);
  const getOtpFromMail = async (officialMail, type) => {
    setLoading(true);
    setrefFocused(true);

    const getOtpFromID = await generateOtpFromMail(officialMail, type);

    if (getOtpFromID.hasError === true) {
      setLoading(false);
      return toastDisplayer("error", getOtpFromID.errorMessage);
    } else {
      setLoading(false);
      setShowOtpPopup(true);
      return toastDisplayer("suceess", "OTP send successfully..!!");
    }
  };

  const handlePopupClose = () => {
    setrefFocused(false);
    setShowOtpPopup(false);
  };

  const hanldeShowApprovalPopup = () => {
    if(enableOnVerification){
      return toastDisplayer("error","OTP is not verified");
    }
    const requiredFields = [
      "vendorName",
      "vendorType",
      "CmpCatType",
      "cmpType",
      "OfficialPhone",
      "eMail",
      // "bAddress1",
      // "bStreet",
      // "bZipCode",
      // "bCity",
      // "bCountry",
      // "sAddress1",
      // "sStreet",
      // "sZipCode",
      // "sCity",
      // "sCountry",
      // "transCry",
      // "acctName",
      // "account",
      // "accountC",
      // "branch",
      // "pCntctPrsn",
      // "pCntctPhone",
      "u_QIT_IS_MSME"
    ];
    const emptyFields = [];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        emptyFields.push(field);
      }
    });

    const hasEmptyField = requiredFields.find((field) => !formData[field]);
    scrollToFieldWithError(hasEmptyField);
    if (formData?.bCountry == "IN") {
      if (formData?.bState == null || formData?.bState == "") {
        return toastDisplayer("error", "Billing state is required..!!");
      } else {
        var res = validatePincode(
          formData?.bState,
          formData?.bZipCode,
          "Billing"
        );
        if (!res) {
          return;
        }
      }
    }
    if (formData?.sCountry == "IN") {
      if (formData?.sState == null || formData?.sState == "") {
        return toastDisplayer("error", "Shipping state is required..!!");
      } else {
        var res = validatePincode(
          formData?.sState,
          formData?.sZipCode,
          "Shipping"
        );
        if (!res) {
          return;
        }
      }
    }
    console.log("HasEmpy : ",hasEmptyField);
    if (hasEmptyField) {
      if(hasEmptyField=='u_QIT_IS_MSME'){
        return toastDisplayer("error",`MSME number is required`)
      }
    } else {
      if (formData?.vendorType == "D") {
        if (!isGstVerified) {
          return toastDisplayer("error", "GSTIN is not verified");
        }
      }
      
      if (formData?.u_QIT_IS_MSME == "Y" && formData?.u_QIT_MSME_TYPE == "") {
        return toastDisplayer(
          "error",
          "MSME type is required."
        );
      }
      if (formData?.u_QIT_IS_MSME == "Y" && formData?.u_QIT_MSME_REGNO == "") {
        return toastDisplayer(
          "error",
          "MSME registration no is required."
        );
      }
      if (formData?.account != "" && formData?.accountC != formData?.account) {
        return toastDisplayer(
          "error",
          "Acc No. and Confirm Acc No. do not match"
        );
      }
      // if (formData?.vendorType == "D") {
      //   if(formData?.account != ""){
      //     if (
      //       formData?.ifscNum == null ||
      //       formData?.ifscNum == "" ||
      //       formData?.ifscNum == undefined
      //     ) {
      //       return toastDisplayer("error", "IFSC code is required..!!");
      //     }
      //   }
      // }
      // if (formData?.vendorType == "I") {
      //   if (
      //     formData?.swiftNum == null ||
      //     formData?.swiftNum == "" ||
      //     formData?.swiftNum == undefined
      //   ) {
      //     return toastDisplayer("error", "Swift code is required..!!");
      //   }
      // }
      setShowApprovalPopup(true);
    }
  };

  const hanldeApprovalPopupClose = () => {
    setShowApprovalPopup(false);
  };

  // const handleGetAddress = async (pinCode, addOf) => {
  //   if (pinCode.length === 6) {
  //     const getAddress = await requestAddressFromPin(pinCode);

  //     if (getAddress[0].PostOffice != null) {
  //       if (addOf == "B") {
  //         // setGenertedDataFromPin(getAddress[0].PostOffice[0]);
  //         handleInputChange("bZipCode", pinCode);
  //         handleInputChange("bState", getAddress[0].PostOffice[0].District);
  //         handleInputChange("bCity", getAddress[0].PostOffice[0].State);
  //         handleInputChange("bCountry", getAddress[0].PostOffice[0].Country);
  //       }
  //       if (addOf == "S") {
  //         // setGenertedDataFromPin(getAddress[0].PostOffice[0]);
  //         handleInputChange("sZipCode", pinCode);
  //         handleInputChange("sState", getAddress[0].PostOffice[0].District);
  //         handleInputChange("sCity", getAddress[0].PostOffice[0].State);
  //         handleInputChange("sCountry", getAddress[0].PostOffice[0].Country);
  //       }
  //     }
  //   }
  // };
  const [isApprovalBtnDisable, setIsApprovalBtnDisable] = useState(false);

  //----- Handle Save Data ---
  const handleSaveData = async () => {
    setLoading(true);
    setIsApprovalBtnDisable(true);
    var apiRes = await saveVendor(formData);
    hanldeApprovalPopupClose();
    setIsApprovalBtnDisable(false);
    if (apiRes.hasError) {
      setLoading(false);
      return toastDisplayer("error", apiRes.errorMessage);
    } else {
      setLoading(false);
      setFormData({ createdByUserName: "" });
      toastDisplayer("success", "Registration successfully..!!");
      navigate(`/showStatus/${apiRes.responseData.ecodedString}`);
    }
  };

  const [activeSection, setActiveSection] = useState("vendor");
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".formSection");
      let currentSection = "";

      sections.forEach((formSection) => {
        const sectionTop = formSection.offsetTop;
        const sectionHeight = formSection.clientHeight;
        if (window.scrollY >= sectionTop - sectionHeight / 1) {
          currentSection = formSection.getAttribute("id");
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Call handleScroll once to update active section on initial render

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // ================================================================
  const [activeLink, setActiveLink] = useState("Vendor");
  const [formData, setFormData] = useState({
    bAddress1:"",
    bStreet:"",
    bZipCode:"",
      bCity:"",
      bCountry:"",
      sAddress1:"",
      sStreet:"",
      sZipCode:"",
      sCity:"",
      sCountry:"",
      transCry:"",
      acctName:"",
      account:"",
      branch:"",
      pCntctPrsn:"",
      pCntctPhone:"",
    createdByUserName: "",
    pCnctDesg: "",
    sCnctDesg: "",
    pCnctEmail: "",
    sCnctEmail: "",
    sCntctPrsn: "",
    sCntctPhone: "",
    bState: "",
    sState: "",
    u_QIT_MSME_REGNO: "",
    u_QIT_MSME_TYPE: "",
  });
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadedFileName(file.name);
    const base64 = await convertToBase64(file);
    setFormData((prevState) => ({
      ...prevState,
      ["cmpDocs"]: base64,
    }));
  };

  const btnOption = {
    icon: "",
    text: "For Approval",
  };

  const handleLinkClick = (activeLinkName) => {
    setActiveLink(activeLinkName);
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll(".form-section");
    let foundAnySection = false;

    sections.forEach((section) => {
      const bounding = section.getBoundingClientRect();
      if (bounding.top >= 0 && bounding.top <= window.innerHeight) {
        if (!foundAnySection) {
          setActiveLink(section.id);
          foundAnySection = true;
        }
      }
    });
  };

  const chkBoxHandler = (e) => {
    // setChkState(e.value);
    if (e.value) {
      setFormData((prevState) => ({
        ...prevState,
        ["sAddress1"]: formData?.bAddress1,
        ["sAddress2"]: formData?.bAddress2,
        ["sCity"]: formData?.bCity,
        ["sCountry"]: formData?.bCountry,
        ["sState"]: formData?.bState,
        ["sStreet"]: formData?.bStreet,
        ["sZipCode"]: formData?.bZipCode,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        ["sAddress1"]: "",
        ["sAddress2"]: "",
        ["sCity"]: "",
        ["sCountry"]: "",
        ["sState"]: "",
        ["sStreet"]: "",
        ["sZipCode"]: "",
      }));
    }
  };

  const AccNoComparison = useCallback(() => {
    return formData?.account;
  }, [formData?.account]);

  const verifyEmailBtnOp = {
    icon: arrow_forward,
    style: {
      backgroundColor: "#ff0000", // Set your desired background color here
    },
    onClick: () => {
      hanldePopupShow();
    },
  };

  const validatePincode = (state, pincode, field) => {
    var sData = "";
    if (field == "Billing") {
      sData = StateData.find((e) => e.code == state);
    } else {
      sData = ShipStateData.find((e) => e.code == state);
    }
    var fdata = allPincode.find(
      (item) => item.stateName == sData?.name.toUpperCase()
    );

    var getCode = pincode.toString().substring(0, 3);
    if (fdata != null) {
      if (
        fdata.pincodeRange.start <= parseInt(getCode) &&
        fdata.pincodeRange.end >= parseInt(getCode)
      ) {
        console.log("here");
        return true;
      } else {
        toastDisplayer("error", `Invalid ${field} pincode..!!`);
        return false;
      }
    }
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    if (fieldName == "vendorType") {
      if (value == "D") {
        handleInputChange("transCry", "INR");
      }
    }
    if (fieldName == "bCountry" || fieldName == "sCountry") {
      getCountryWiseStateData(value, fieldName);
    }
    if (fieldName == "bCity" || fieldName == "sCity") {
      if (value != null) {
        setFormData((prevState) => ({
          ...prevState,
          [fieldName]: value.toUpperCase(),
        }));
      }
    }

    if (fieldName == "bZipCode") {
      if (formData?.bCountry != null && formData?.bCountry == "IN") {
        if (formData?.bState != null) {
          validatePincode(formData?.bState, value, "Billing");
        }
      }
    }

    // console.log("=============> ",fieldName);
    // console.log("=============> ",value);
    // if (fieldName == "gstregNum") {
    //   console.log("=============> ",formData?.gstregNum);
    //   GetVendorGSTDetail(formData?.gstregNum);
    // }
    if (fieldName == "gstregNum") {
      const gstinPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1}$/;
      const gstregNum = value;
      console.log("gstregNum : ",gstregNum);
      if (gstinPattern.test(gstregNum)) {
          const panNumber = gstregNum.substring(2, 12);
          setFormData((prevState) => ({
            ...prevState,
            ["panNum"]: panNumber,
          }));
          GetVendorGSTDetail(gstregNum);
        
      } else {
          console.log("Invalid GSTIN: ", gstregNum);
          // Handle invalid GSTIN case
      }
  }
  

    if (fieldName == "sZipCode") {
      if (formData?.sCountry != null && formData?.sCountry == "IN") {
        if (formData?.sState != null) {
          console.log("heere");
          validatePincode(formData?.sState, value, "Shipping");
        }
      }
    }
    if (sourceConfigData && sourceConfigData["bankSource"] == "S") {
      if (fieldName == "acctName") {
        var data = bankDetail.find((e) => e.bankCode == value);
        if (data?.account != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["accountC"]: true,
            ["account"]: true,
          }));
          handleInputChange("accountC", data?.account);
          handleInputChange("account", data?.account);
        } else {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["accountC"]: false,
            ["account"]: false,
          }));
          handleInputChange("accountC", "");
          handleInputChange("account", "");
        }
        if (data?.branch != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["branch"]: true,
          }));
          handleInputChange("branch", data?.branch);
        } else {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["branch"]: false,
          }));
          handleInputChange("branch", "");
        }
      }
    }
  };

  const msmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];
  const ismsmeType = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];

  return (
    <>
      {loading ? <LoadPanel visible="true" /> : ""}

      <div className="Main-body-Container">
        {/* -------------------- Header ---------------- */}

        <header className={"header-component-login"}>
          <Toolbar className={"header-toolbar"}>
            <Item location={"before"} cssClass={"header-title logoClass"}>
            {logo?
              <img src={logo} alt="site logo" className="site-logo" height={"50px"}/>
              :
              <img src={Logo} alt="site logo" className="site-logo" />
              }
            </Item>
            <Item
              location={"after"}
              locateInMenu={"auto"}
              cssClass={"link-Section"}
            >
              <div>
                <Link to="/login">
                  <span>Already a member? </span>
                  <span style={{ fontWeight: "500" }}>Sign In</span>
                </Link>
              </div>
            </Item>
          </Toolbar>
        </header>

        {/* -------------------- Inner body Conatiner --------------- */}

        <PageHeader
          isBreadcrumbsVisible={true}
          isBtn1Visisble={true}
          pageTitle={"Vendor Registration Form"}
          clickHandler={hanldeShowApprovalPopup}
          btnOption={btnOption}
        />

        <div className="content-add-form">
          <div className="form-header-panel">
            <ul type="none">
              <li className={activeLink == "Vendor" ? "active" : ""}>
                <Link to="#Vendor" onClick={() => handleLinkClick("Vendor")}>
                  Vendor
                </Link>
              </li>
              <li className={activeLink == "Address" ? "active" : ""}>
                <Link to="#Address" onClick={() => handleLinkClick("Address")}>
                  Registered Address
                </Link>
              </li>
              <li className={activeLink == "Details" ? "active" : ""}>
                <Link to="#Details" onClick={() => handleLinkClick("Details")}>
                  Bank Details
                </Link>
              </li>
              <li className={activeLink == "Communication" ? "active" : ""}>
                <Link
                  to="#Communication"
                  onClick={() => handleLinkClick("Communication")}
                >
                  Communication
                </Link>
              </li>
              <li className={activeLink == "Others" ? "active" : ""}>
                <Link to="#Others" onClick={() => handleLinkClick("Others")}>
                  Others
                </Link>
              </li>
            </ul>
          </div>
          <form>
            <div className="form-body-content" onScroll={handleScroll}>
              {/* ----------- Vendor --------------*/}
              <section id="Vendor" className="form-section">
                <span className="header-text">Vendor</span>
                <div className="section-title" id>
                  <div className="setion-first-row common-row" id="vendor">
                  <div style={{ width: "100%" }}>
                    <SelectBox
                      label="Vendor Type "
                      labelMode="outside"
                      // width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("vendorType", e.value)
                      }
                      value={formData?.vendorType || ""}
                      items={vendorType}
                      valueExpr={"value"}
                      displayExpr={"text"}
                      id="vendorType"
                      className="required"
                      validationMessagePosition={"bottom"}
                    >
                      <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                    </SelectBox>
                    </div>
                    <div style={{ width: "100%" }}>
                     <TextBox
                      label="GST Number"
                      labelMode="outside"
                      // width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("gstregNum", e.value)
                      }
                      value={formData?.gstregNum || ""}
                    />
                     {isGstVerified?<>
                      
                      <span
                        className="otp-terms-condition"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          paddingTop: "2px",
                        }}
                      >
                        <img
                          src={success}
                          alt="success"
                          height={20}
                          width={20}
                        />
                        <span>Verified Successfully</span>
                      </span>
                      </>:""}
                      </div>
                  </div>

                  <div className="setion-first-row common-row">
                  <TextBox
                      label="Vendor Name as per GST"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("vendorName", e.value)
                      }
                      value={formData?.vendorName || ""}
                      // validationMessageMode="always"
                      id="vendorName"
                      className="required"
                      validationMessagePosition={"bottom"}
                    >
                      <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                    </TextBox>
                    <TextBox
                      label="Business Structure of Vendor"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("cmpType", e.value)
                      }
                      value={formData?.cmpType || ""}
                      id="cmpType"
                      className="required"
                      validationMessagePosition={"bottom"}
                    >
                      <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                    </TextBox>
                 
                  </div>
                  <div className="setion-first-row common-row">
                  <SelectBox
                      label="Category of Vendor "
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("CmpCatType", e.value)
                      }
                      value={formData?.CmpCatType || ""}
                      items={categoryData}
                      valueExpr={"venCatName"}
                      displayExpr={"venCatName"}
                      id="CmpCatType"
                      className="required"
                      validationMessagePosition={"bottom"}
                    >
                      <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                    </SelectBox>
                    <TextBox
                      label="Pan Number"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("panNum", e.value)
                      }
                      value={formData?.panNum || ""}
                      id="panNum"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                   
                  </div>

                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Official Phone Number"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("OfficialPhone", e.value)
                      }
                      value={formData?.OfficialPhone || ""}
                      id="OfficialPhone"
                      className="required"
                      validationMessagePosition={"bottom"}
                    >
                      <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                    </TextBox>
                    <TextBox
                      label="Official Email Address "
                      labelMode="outside"
                      width={"49%"}
                      className="verify-email-text required"
                      valueChangeEvent="keyup"
                      onValueChanged={(e) => {
                        handleInputChange("eMail", e.value);
                      }}
                      readOnly={isEmailDisabled}
                      value={formData?.eMail || ""}
                      id="eMail"
                    >
                      <Validator>
                        <RequiredRule message="Required" />
                        <EmailRule message="Email is invalid" />
                      </Validator>
                      <TextBoxButton
                        name="email"
                        location="After"
                        options={verifyEmailBtnOp}
                      />
                    </TextBox>
                  </div>
                </div>
              </section>

              {/* ----------- Office Address --------------*/}
              <section id="Address" className="form-section">
                <div className="header-text">Registered Address</div>
                <div className="header-sub-text">Billing Address</div>
                <div className="section-title">
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Address 1 "
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("bAddress1", e.value)
                      }
                      value={formData?.bAddress1 || ""}
                      disabled={enableOnVerification}
                      id="bAddress1"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                    <TextBox
                      label="Address 2"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("bAddress2", e.value)
                      }
                      value={formData?.bAddress2 || ""}
                      disabled={enableOnVerification}
                    />
                  </div>
                  <div className="setion-first-row common-row">
                    <SelectBox
                      label="Country"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("bCountry", e.value)
                      }
                      searchEnabled={true}
                      id="bCountry"
                      items={CountryData}
                      displayExpr={(item) =>
                        item ? `${item.code} - ${item.name}` : ""
                      }
                      valueExpr={"code"}
                      value={formData?.bCountry || ""}
                      disabled={enableOnVerification}
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </SelectBox>
                    <SelectBox
                      label="State"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("bState", e.value)
                      }
                      id="bState"
                      searchEnabled={true}
                      items={StateData}
                      displayExpr={"name"}
                      valueExpr={"code"}
                      value={formData?.bState || ""}
                      disabled={formData?.bCountry ? false : true}
                    ></SelectBox>
                  </div>

                  <div className="setion-first-row common-row">
                    <Autocomplete
                      dataSource={CityData}
                      value={formData?.bCity || ""}
                      valueExpr={"cityName"}
                      displayExpr={"cityName"}
                      labelMode="outside"
                      label="City "
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("bCity", e.value)
                      }
                      showClearButton={true}
                      itemRender={(data) => (
                        <span style={{ marginLeft: ".5rem" }}>
                          {data.cityName}
                        </span>
                      )}
                      disabled={enableOnVerification}
                      id="bCity"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </Autocomplete>

                    <TextBox
                      label="Pin Code"
                      labelMode="outside"
                      width={"49%"}
                      // valueChangeEvent="keyup"
                      onValueChanged={(e) =>
                        handleInputChange("bZipCode", e.value)
                      }
                      value={formData?.bZipCode || ""}
                      disabled={enableOnVerification}
                      id="bZipCode"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                  </div>

                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Area"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("bStreet", e.value)
                      }
                      value={formData?.bStreet || ""}
                      disabled={enableOnVerification}
                      id="bStreet"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                  </div>
                </div>
                <CheckBox
                  text={"Shipping address same as billing address"}
                  // hint={"Shipping address same as billing address"}
                  className="bill_addTxt"
                  onValueChanged={chkBoxHandler}
                />
                <div className="header-sub-text">Shipping Address</div>
                <div className="section-title">
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Ship From 1"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sAddress1", e.value)
                      }
                      value={formData?.sAddress1 || ""}
                      disabled={enableOnVerification}
                      id="sAddress1"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                    <TextBox
                      label="Ship From 2"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sAddress2", e.value)
                      }
                      value={formData?.sAddress2 || ""}
                      disabled={enableOnVerification}
                    />
                  </div>

                  <div className="setion-first-row common-row">
                    <SelectBox
                      label="Country"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sCountry", e.value)
                      }
                      id="sCountry"
                      value={formData?.sCountry || ""}
                      items={CountryData}
                      displayExpr={(item) =>
                        item ? `${item.code} - ${item.name}` : ""
                      }
                      valueExpr={"code"}
                      disabled={enableOnVerification}
                      searchEnabled={true}
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" location="after" />
                      </Validator> */}
                    </SelectBox>
                    <SelectBox
                      label="State"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sState", e.value)
                      }
                      id="sState"
                      items={ShipStateData}
                      displayExpr={"name"}
                      valueExpr={"code"}
                      value={formData?.sState || ""}
                      disabled={formData?.sCountry ? false : true}
                      searchEnabled={true}
                    ></SelectBox>
                  </div>

                  <div className="setion-first-row common-row">
                    <Autocomplete
                      dataSource={CityData}
                      value={formData?.sCity || ""}
                      valueExpr={"cityName"}
                      displayExpr={"cityName"}
                      labelMode="outside"
                      label="City "
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sCity", e.value)
                      }
                      showClearButton={true}
                      itemRender={(data) => (
                        <span style={{ marginLeft: ".5rem" }}>
                          {data.cityName}
                        </span>
                      )}
                      id="sCity"
                      disabled={enableOnVerification}
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </Autocomplete>

                    <TextBox
                      label="Pin Code"
                      labelMode="outside"
                      width={"49%"}
                      // onValueChanged={(e) =>
                      //   handleInputChange("sZipCode", e.value)
                      // }
                      onValueChanged={(e) =>
                        handleInputChange("sZipCode", e.value)
                      }
                      value={formData?.sZipCode || ""}
                      disabled={enableOnVerification}
                      id="sZipCode"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                  </div>

                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Area"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sStreet", e.value)
                      }
                      value={formData?.sStreet || ""}
                      disabled={enableOnVerification}
                      id="sStreet"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                    {/* <TextBox
                      label="City "
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sCity", e.value)
                      }
                      value={formData?.sCity || ""}
                      disabled={enableOnVerification}
                      id="sCity"
                    className="required">
                      <Validator>
                        <RequiredRule message="Required" />
                      </Validator>
                    </TextBox> */}
                  </div>
                </div>
              </section>

              {/* -----------  Bank Details --------------*/}

              <section id="Details" className="form-section">
                <span className="header-text">Bank Details</span>
                <div className="section-title">
                  <div className="setion-first-row common-row">
                    <SelectBox
                      onValueChanged={(e) =>
                        handleInputChange("transCry", e.value)
                      }
                      label="Currency "
                      labelMode="outside"
                      width={"49%"}
                      searchEnabled={true}
                      disabled={enableOnVerification}
                      items={currency}
                      value={formData?.transCry || ""}
                      valueExpr={"currCode"}
                      displayExpr={(item) =>
                        item ? `${item.currCode} - ${item.currName}` : ""
                      }
                      // displayExpr={"currName"}
                      id="transCry"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </SelectBox>
                    {getSourceData[0]?.bankSource == "S" ? (
                      <SelectBox
                        onValueChanged={(e) =>
                          handleInputChange("acctName", e.value)
                        }
                        label="Bank Name "
                        labelMode="outside"
                        width={"49%"}
                        searchEnabled={true}
                        disabled={enableOnVerification}
                        items={bankDetail}
                        value={formData?.acctName || ""}
                        valueExpr={"bankCode"}
                        displayExpr={"bankName"}
                        // displayExpr={"currName"}
                        id="acctName"
                        // className="required"
                      >
                        {/* <Validator>
                          <RequiredRule message="Required" />
                        </Validator> */}
                      </SelectBox>
                    ) : (
                      <TextBox
                        label="Bank Name"
                        labelMode="outside"
                        width={"49%"}
                        onValueChanged={(e) =>
                          handleInputChange("acctName", e.value)
                        }
                        value={formData?.acctName || ""}
                        disabled={enableOnVerification}
                        id="acctName"
                      >
                        {/* <Validator>
                          <RequiredRule message="Required" />
                        </Validator> */}
                      </TextBox>
                    )}
                  </div>

                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Account Number"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("account", e.value)
                      }
                      value={formData?.account || ""}
                      readOnly={bankDetailDisabled?.account}
                      disabled={enableOnVerification}
                      id="account"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                    <TextBox
                      label="Re-Enter Account Number"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("accountC", e.value)
                      }
                      readOnly={bankDetailDisabled?.accountC}
                      value={formData?.accountC || ""}
                      disabled={enableOnVerification}
                      id="accountC"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                       <RequiredRule message="Required" /> 
                        <CompareRule
                          message="Acc No. and Confirm Acc No. do not match"
                          comparisonTarget={AccNoComparison}
                        />
                      </Validator> */}
                    </TextBox>
                  </div>
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="IFSC Code "
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("ifscNum", e.value)
                      }
                      value={formData?.ifscNum || ""}
                      disabled={enableOnVerification}
                    ></TextBox>
                    {/* <TextBox
                      label="Swift Code "
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("swiftNum", e.value)
                      }
                      value={formData?.swiftNum || ""}
                      disabled={enableOnVerification}
                    ></TextBox> */}
                    <TextBox
                      label="Bank Branch/Address"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("branch", e.value)
                      }
                      value={formData?.branch || ""}
                      disabled={enableOnVerification}
                      readOnly={bankDetailDisabled?.branch}
                      id="branch"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                  </div>

                  <div className="setion-first-row common-row">
                    {/* <TextBox
                      label="Payment Terms"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("pymntTerms", e.value)
                      }
                      value={formData?.pymntTerms || ""}
                      disabled={enableOnVerification}
                    /> */}
                    <SelectBox
                      onValueChanged={(e) =>
                        handleInputChange("pymntTerms", e.value.toString())
                      }
                      label="Payment Terms"
                      labelMode="outside"
                      width={"49%"}
                      searchEnabled={true}
                      items={paymentTerms}
                      // value={formData?.pymntTerms || ""}
                      value={parseInt(formData?.pymntTerms) || ""}
                      valueExpr={"groupNum"}
                      displayExpr={"pymntGroup"}
                      disabled={enableOnVerification}
                    />
                  </div>
                </div>
              </section>

              {/* -----------  Communication --------------*/}

              <section id="Communication" className="form-section">
                <span className="header-text">Communication</span>
                <div className="header-sub-text">Primary Contact Person</div>
                <div className="section-title">
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Full Name"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("pCntctPrsn", e.value)
                      }
                      value={formData?.pCntctPrsn || ""}
                      disabled={enableOnVerification}
                      id="pCntctPrsn"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                    <TextBox
                      label="Phone Number"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("pCntctPhone", e.value)
                      }
                      value={formData?.pCntctPhone || ""}
                      disabled={enableOnVerification}
                      id="pCntctPhone"
                      // className="required"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                  </div>
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Email Address "
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("pCnctEmail", e.value)
                      }
                      value={formData?.pCnctEmail || ""}
                      disabled={enableOnVerification}
                    />
                    <TextBox
                      label="Designation"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("pCnctDesg", e.value)
                      }
                      value={formData?.pCnctDesg || ""}
                      disabled={enableOnVerification}
                    />
                  </div>
                </div>
                <div className="header-sub-text">Secondary Contact Person</div>
                <div className="section-title">
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Full Name"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sCntctPrsn", e.value)
                      }
                      value={formData?.sCntctPrsn || ""}
                      disabled={enableOnVerification}
                    />
                    <TextBox
                      label="Phone Number"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sCntctPhone", e.value)
                      }
                      value={formData?.sCntctPhone || ""}
                      disabled={enableOnVerification}
                    />
                  </div>
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Email Address "
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sCnctEmail", e.value)
                      }
                      value={formData?.sCnctEmail || ""}
                      disabled={enableOnVerification}
                    />
                    <TextBox
                      label="Designation"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("sCnctDesg", e.value)
                      }
                      value={formData?.sCnctDesg || ""}
                      disabled={enableOnVerification}
                    />
                  </div>
                </div>
              </section>

              {/* -----------  Other --------------*/}

              <section id="Others" className="form-section">
                <span className="header-text">Others</span>
                <div className="header-sub-text">Taxation Details</div>
                <div className="section-title">
                  <div className="setion-first-row common-row">
                    <SelectBox
                      onValueChanged={(e) =>
                        handleInputChange("u_QIT_IS_MSME", e.value)
                      }
                      label="Micro, Small & Medium Enterprises"
                      labelMode="outside"
                      width={"49%"}
                      searchEnabled={true}
                      items={ismsmeType}
                      value={formData?.u_QIT_IS_MSME || ""}
                      valueExpr={"value"}
                      displayExpr={"text"}
                      className="required"
                      disabled={enableOnVerification}
                      id="u_QIT_IS_MSME"
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </SelectBox>
                    <SelectBox
                      onValueChanged={(e) =>
                        handleInputChange("u_QIT_MSME_TYPE", e.value.toString())
                      }
                      label="MSME Types"
                      labelMode="outside"
                      width={"49%"}
                      searchEnabled={true}
                      items={msmeType}
                      value={formData?.u_QIT_MSME_TYPE || ""}
                      valueExpr={"value"}
                      displayExpr={"text"}
                      disabled={
                        formData?.u_QIT_IS_MSME
                          ? formData?.u_QIT_IS_MSME == "N"
                            ? true
                            : false
                          : true
                      }
                    />
                  </div>
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="MSME Registration No."
                      labelMode="outside"
                      width={"49%"}
                      disabled={
                        formData?.u_QIT_IS_MSME
                          ? formData?.u_QIT_IS_MSME == "N"
                            ? true
                            : false
                          : true
                      }
                      
                      onValueChanged={(e) =>
                        handleInputChange("u_QIT_MSME_REGNO", e.value)
                      }
                      value={formData?.u_QIT_MSME_REGNO || ""}
                    />
                  </div>
                </div>
                {/* <div className="header-sub-text">ISO Certificate Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("isoCheck", e.value)
                    }
                    label="Is ISO Certified?"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    // value={formData?.msmeCheck || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                 <TextBox
                    label="ISO no."
                    labelMode="outside"
                    width={"49%"}
                    disabled={
                      formData?.isoCheck
                        ? formData?.isoCheck == "N"
                          ? true
                          : false
                        : true
                    }
                    // onValueChanged={(e) =>
                    //   handleInputChange("msmeRegistrationNo", e.value)
                    // }
                    // value={formData?.msmeCheck || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>

                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload ISO Certificate."
                    )}
                  </label>
                  <input
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    // onChange={handleFileUpload}
                    accept=".pdf"
                    disabled={formData?.isoCheck
                      ? formData?.isoCheck == "N"
                        ? true
                        : false
                      : true}
                  />
                </div>
              </div> */}
                <div className="header-sub-text">More Details</div>
                <div className="section-title">
                  <div className="setion-first-row common-row">
                    <TextBox
                      label="Remarks"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("remark", e.value)
                      }
                      value={formData?.remark || ""}
                      disabled={enableOnVerification}
                    />
                    <TextBox
                      label="Website Link"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("websiteLink", e.value)
                      }
                      value={formData?.websiteLink || ""}
                      disabled={enableOnVerification}
                    />
                    {/* </div> */}
                  </div>
                  <div className="setion-first-row common-row">
                    <label className="uplaod_btn" for="file_upload">
                      <label className="material-symbols-outlined">
                        upload
                      </label>

                      {uploadedFileName ? (
                        <p>{uploadedFileName}</p>
                      ) : (
                        "Upload Vendor company brochures."
                      )}
                    </label>
                    <input
                      //disabled={enableOnVerification}
                      type="file"
                      id="file_upload"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                      accept=".pdf"
                      disabled={enableOnVerification}
                    />
                  </div>
                </div>
              </section>
            </div>
          </form>
        </div>

        {approvalPopup && (
          <ConformationPopup
            headertext={"Send for Approval"}
            subtext={"Are you sure you want to send for approval?"}
            isVisible={approvalPopup}
            onHide={hanldeApprovalPopupClose}
            saveFunction={handleSaveData}
            saveIcon={saveVendorIcon}
            cancelBtnTxt={"Discard"}
            saveBtnTxt={"Send for Approval"}
            isSaveBtnDisabled={isApprovalBtnDisable}
          />
        )}

        <Popup
          className="otp_popup"
          height={"390px"}
          width={"480px"}
          visible={showOtpPopup}
        >
          <OtpInput
            handlePopupClose={handlePopupClose}
            officialMail={formData?.eMail}
            setEnableOnVerification={setEnableOnVerification}
            userType={userType}
            getOtpFromMail={getOtpFromMail}
            setrefFocused={refFocused}
            setrefFocused1={setrefFocused}
            otp={otp}
            setOtp={setOtp}
            setIsEmailDisabled={setIsEmailDisabled}
          />
        </Popup>
      </div>
    </>
  );
}

export default VendorRegistration;
