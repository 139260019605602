import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import React, { useCallback, useEffect, useState } from "react";
import "./addVendor.scss";
import PageHeader from "../../../components/Page-Header/PageHeader";
import ConformationPopup from "../../../components/conformation-popup/conformation-popup";
import { HashLink as Link } from "react-router-hash-link";
import { CheckBox } from "devextreme-react/check-box";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import { useAuth } from "../../../contexts/auth";
import { saveVendor } from "../../../api/vendor";
import { saveVendorIcon } from "../../../assets/icon";
import {
  Validator,
  RequiredRule,
  CompareRule,
  EmailRule,
} from "devextreme-react/validator";
import { useNavigate } from "react-router-dom";
import { allCurrencies, allPincode } from "../../../utils/currency";
import { requestAddressFromPin } from "../../../api/registerCustomer";
import { LoadPanel } from "devextreme-react";
import Autocomplete from "devextreme-react/autocomplete";
import { getAllCategory } from "../../../api/setting";
import {
  GetSAPBankDetail,
  GetSAPCurrency,
  getCityData,
  getCountryData,
  getGstDetail,
  getPaymentTerms,
  getStateData,
} from "../../../api/commonAPI";

import { UseConfigContext } from "../../../contexts/cofigContext";
import { StateCodes } from "../../../components/Common-Object/common-object";
import success from "../../../assets/SVGs/success.svg";
export const RegistrationVendorForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [currency, setCurrency] = useState([]);
  // const currency = allCurrencies;
  const [loading, setLoading] = useState(false);
  const [activeLink, setActiveLink] = useState("Vendor");
  const [approvalPopUp, setApprovalPopUp] = useState(false);
  const [saveBtnDisable, setSaveBtnDisable] = useState(false);
  // const [chkState, setChkState] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [formData, setFormData] = useState({
    bAddress1:"",
    bStreet:"",
    bZipCode:"",
      bCity:"",
      bCountry:"",
      sAddress1:"",
      sStreet:"",
      sZipCode:"",
      sCity:"",
      sCountry:"",
      transCry:"",
      acctName:"",
      account:"",
      branch:"",
      pCntctPrsn:"",
      pCntctPhone:"",
    createdByUserName: user["UserData"],
    pCnctDesg: "",
    sCnctDesg: "",
    pCnctEmail: "",
    sCnctEmail: "",
    sCntctPrsn: "",
    sCntctPhone: "",
    bState: "",
    sState: "",
    u_QIT_MSME_REGNO: "",
    u_QIT_MSME_TYPE: "",
  });
  const { getSourceData } = UseConfigContext();
  const [sourceConfigData, setSourceConfigData] = useState(getSourceData[0]);
  const [bankDetail, setBankDetail] = useState([]);
  const [isGstVerified, setIsGstVerified] = useState(false);
  const GetVendorGSTDetail = async (GSTINNO) => {
    var apiRes = await getGstDetail(GSTINNO);
    if (!apiRes.hasError) {
      setIsGstVerified(true);
       setFormData((prevState) => ({
      ...prevState,
      ["vendorName"]: apiRes.responseData.result.lgnm,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bAddress1"]: apiRes.responseData.result.pradr.addr.bnm,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bCountry"]: "india",
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bState"]:  apiRes.responseData.result.pradr.addr.stcd,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bCity"]:  apiRes.responseData.result.pradr.addr.loc,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bZipCode"]:  apiRes.responseData.result.pradr.addr.pncd,
    }));
       setFormData((prevState) => ({
      ...prevState,
      ["bStreet"]:  apiRes.responseData.result.pradr.addr.st,
    }));
    const data = CountryData.find((item)=>item.name == "India");
    setFormData((prevState) => ({
      ...prevState,
      ["bCountry"]:  data.code,
    }));
    const stateData = StateCodes.find((item)=>item.name == apiRes.responseData.result.pradr.addr.stcd);
    setFormData((prevState) => ({
      ...prevState,
      ["bState"]:  stateData.alphaCode,
    }));
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const msmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];
  const ismsmeType = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];

  const btnOption = {
    icon: "",
    text: "For Approval",
  };

  const handleLinkClick = (activeLinkName) => {
    setActiveLink(activeLinkName);
  };

  const scrollToFieldWithError = (fieldName) => {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const approvalBtnHandler = () => {
    const requiredFields = [
      "vendorName",
      "vendorType",
      "cmpCatType",
      "cmpType",
      "OfficialPhone",
      "eMail",
      // "bAddress1",
      // "bStreet",
      // "bZipCode",
      // "bCity",
      // "bCountry",
      // "sAddress1",
      // "sStreet",
      // "sZipCode",
      // "sCity",
      // "sCountry",
      // "transCry",
      // "acctName",
      // "account",
      // "accountC",
      // "branch",
      // "pCntctPrsn",
      // "pCntctPhone",
      "u_QIT_IS_MSME"
    ];

    const hasEmptyField = requiredFields.find((field) => !formData[field]);
    scrollToFieldWithError(hasEmptyField);
    if (formData?.bCountry == "IN") {
      if (formData?.bState == null || formData?.bState == "") {
        return toastDisplayer("error", "Billing state is required..!!");
      } else {
        var res = validatePincode(
          formData?.bState,
          formData?.bZipCode,
          "Billing"
        );
        if (!res) {
          return;
        }
      }
    }
    if (formData?.sCountry == "IN") {
      if (formData?.sState == null || formData?.sState == "") {
        return toastDisplayer("error", "Shipping state is required..!!");
      } else {
        var res = validatePincode(
          formData?.sState,
          formData?.sZipCode,
          "Shipping"
        );
        if (!res) {
          return;
        }
      }
    }
    if (hasEmptyField) {
      if(hasEmptyField=='u_QIT_IS_MSME'){
        return toastDisplayer("error",`MSME number is required.`)
      }
    } else {
      if (formData?.vendorType == "D") {
        if (!isGstVerified) {
          return toastDisplayer("error", "GSTIN is not verified");
        }
      }
      if (formData?.u_QIT_IS_MSME == "Y" && formData?.u_QIT_MSME_TYPE == "") {
        return toastDisplayer(
          "error",
          "MSME type is required."
        );
      }
      if (formData?.u_QIT_IS_MSME == "Y" && formData?.u_QIT_MSME_REGNO == "") {
        return toastDisplayer(
          "error",
          "MSME registration no is required."
        );
      }
      if (formData?.account != "" && formData?.accountC != formData?.account) {
        return toastDisplayer(
          "error",
          "Acc No. and Confirm Acc No. do not match"
        );
      }
      if (formData?.CustomerType == "D") {
        if(formData?.account != ""){
        if (
          formData?.ifscNum == null ||
          formData?.ifscNum == "" ||
          formData?.ifscNum == undefined
        ) {
          return toastDisplayer("error", "IFSC code is required..!!");
        }
      }
      }
      if (formData?.CustomerType == "I") {
        if (
          formData?.swiftNum == null ||
          formData?.swiftNum == "" ||
          formData?.swiftNum == undefined
        ) {
          return toastDisplayer("error", "Swift code is required..!!");
        }
      }
      setApprovalPopUp(true);
    }
  };

  const handleCloseConformPopup = () => {
    setApprovalPopUp(false);
  };

  const chkBoxHandler = (e) => {
    if (e.value) {
      setFormData((prevState) => ({
        ...prevState,
        ["sAddress1"]: formData?.bAddress1,
        ["sAddress2"]: formData?.bAddress2,
        ["sCity"]: formData?.bCity,
        ["sCountry"]: formData?.bCountry,
        ["sState"]: formData?.bState,
        ["sStreet"]: formData?.bStreet,
        ["sZipCode"]: formData?.bZipCode,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        ["sAddress1"]: "",
        ["sAddress2"]: "",
        ["sCity"]: "",
        ["sCountry"]: "",
        ["sState"]: "",
        ["sStreet"]: "",
        ["sZipCode"]: "",
      }));
    }
  };

  const handleSaveClick = async () => {
    setLoading(true);
    setSaveBtnDisable(true);
    var apiRes = await saveVendor(formData);
    if (apiRes.hasError) {
      setLoading(false);
      return toastDisplayer("error", apiRes.errorMessage);
    } else {
      setLoading(false);
      handleCloseConformPopup();
      setSaveBtnDisable(false);
      setFormData({ createdByUserName: user["UserData"] });
      toastDisplayer("success", "Vendor added..!!");
      return navigate("/vendor/addvendor");
    }
  };

  const validatePincode = (state, pincode, field) => {
    var sData = "";
    if (field == "Billing") {
      sData = StateData.find((e) => e.code == state);
    } else {
      sData = ShipStateData.find((e) => e.code == state);
    }
    var fdata = allPincode.find(
      (item) => item.stateName == sData?.name.toUpperCase()
    );

    console.log(fdata);

    var getCode = pincode.toString().substring(0, 3);
    console.log(getCode);
    if (fdata != null) {
      if (
        fdata.pincodeRange.start <= parseInt(getCode) &&
        fdata.pincodeRange.end >= parseInt(getCode)
      ) {
        console.log("here");
        return true;
      } else {
        toastDisplayer("error", `Invalid ${field} pincode..!!`);
        return false;
      }
    }
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

    if (fieldName == "vendorType") {
      if (value == "D") {
        handleInputChange("transCry", "INR");
      }
    }

    if (fieldName == "bCountry" || fieldName == "sCountry") {
      getCountryWiseStateData(value, fieldName);
    }

    if (fieldName == "bCity" || fieldName == "sCity") {
      if (value != null) {
        setFormData((prevState) => ({
          ...prevState,
          [fieldName]: value.toUpperCase(),
        }));
      }
    }

    if (fieldName == "bZipCode") {
      if (formData?.bCountry != null && formData?.bCountry == "IN") {
        if (formData?.bState != null) {
          validatePincode(formData?.bState, value, "Billing");
        }
      }
    }

    if (fieldName == "sZipCode") {
      if (formData?.sCountry != null && formData?.sCountry == "IN") {
        if (formData?.sState != null) {
          console.log("heere");
          validatePincode(formData?.sState, value, "Shipping");
        }
      }
    }

    if (fieldName == "gstregNum") {
      const gstinPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1}$/;
      const gstregNum = value;
      console.log("gstregNum : ",gstregNum);
      if (gstinPattern.test(gstregNum)) {
          const panNumber = gstregNum.substring(2, 12);
          setFormData((prevState) => ({
            ...prevState,
            ["panNum"]: panNumber,
          }));
          GetVendorGSTDetail(gstregNum);
        
      } else {
          console.log("Invalid GSTIN: ", gstregNum);
          // Handle invalid GSTIN case
      }
    }

    if (sourceConfigData && sourceConfigData["bankSource"] == "S") {
      if (fieldName == "acctName") {
        var data = bankDetail.find((e) => e.bankCode == value);
        if (data?.account != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["accountC"]: true,
            ["account"]: true,
          }));
          handleInputChange("accountC", data?.account);
          handleInputChange("account", data?.account);
        } else {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["accountC"]: false,
            ["account"]: false,
          }));
          handleInputChange("accountC", "");
          handleInputChange("account", "");
        }
        if (data?.branch != null) {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["branch"]: true,
          }));
          handleInputChange("branch", data?.branch);
        } else {
          setBankDetailDisabled((prevState) => ({
            ...prevState,
            ["branch"]: false,
          }));
          handleInputChange("branch", "");
        }
      }
    }

    
  };

  const vendorType = [
    { value: "I", text: "Import" },
    { value: "D", text: "Domestic" },
  ];
  const isMscme = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];
  const mscmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];

  const getCatData = async () => {
    var apiRes = await getAllCategory();
    if (!apiRes.hasError) {
      setCategoryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [ShipStateData, setShipStateData] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [bankDetailDisabled, setBankDetailDisabled] = useState({
    account: false,
    accountC: false,
    branch: false,
  });

  const getPayemntTerms = async () => {
    var apiRes = await getPaymentTerms();
    if (!apiRes.hasError) {
      setPaymentTerms(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getAllContryData = async () => {
    var apiRes = "";
    if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
      apiRes = await getCountryData("L");
    } else {
      // SAP
      apiRes = await getCountryData("S");
    }
    if (!apiRes.hasError) {
      setCountryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCountryWiseStateData = async (country_code, fieldName) => {
    if (country_code != "" && country_code != null) {
      var apiRes = "";
      if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
        apiRes = await getStateData(country_code);
      } else {
        // SAP
        apiRes = await getStateData(country_code);
      }

      if (!apiRes.hasError) {
        if (fieldName == "bCountry") {
          setStateData(apiRes.responseData);
        }
        if (fieldName == "sCountry") {
          setShipStateData(apiRes.responseData);
        }
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const getAllCityData = async () => {
    var apiRes = await getCityData();
    if (!apiRes.hasError) {
      setCityData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCurrencyData = async () => {
    if (sourceConfigData && sourceConfigData["currencySource"] == "L") {
      setCurrency(allCurrencies);
    } else {
      var apiRes = await GetSAPCurrency();
      if (!apiRes.hasError) {
        setCurrency(apiRes.responseData);
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const GetBankDetail = async () => {
    var apiRes = await GetSAPBankDetail();
    if (!apiRes.hasError) {
      setBankDetail(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  useEffect(() => {
    getCatData();
    getAllContryData();
    getCurrencyData();
    if (sourceConfigData && sourceConfigData["bankSource"] == "S") {
      GetBankDetail();
    }
    getPayemntTerms();
    getAllCityData();
  }, []);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [uploadedFileName, setUploadedFileName] = useState("");
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadedFileName(file.name);
    const base64 = await convertToBase64(file);
    setFormData((prevState) => ({
      ...prevState,
      ["cmpDocs"]: base64,
    }));
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll(".form-section");
    let foundAnySection = false;

    sections.forEach((section) => {
      const bounding = section.getBoundingClientRect();
      if (bounding.top >= 0 && bounding.top <= window.innerHeight) {
        if (!foundAnySection) {
          setActiveLink(section.id);
          foundAnySection = true;
        }
      }
    });
  };

  const AccNoComparison = useCallback(() => {
    return formData?.account;
  }, [formData?.account]);

  const CustomLabel = () => (
    <div>
      Pin Code<span style={{ color: "red" }}> *</span>
    </div>
  );

  return (
    <>
      {loading ? <LoadPanel visible="true" /> : ""}
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={true}
        pageTitle={"Vendor Registration Form"}
        clickHandler={approvalBtnHandler}
        btnOption={btnOption}
      />

      <div className="content-add-form">
        <div className="form-header-panel">
          <ul type="none">
            <li className={activeLink == "Vendor" ? "active" : ""}>
              <Link to="#Vendor" onClick={() => handleLinkClick("Vendor")}>
                Vendor
              </Link>
            </li>
            <li className={activeLink == "Address" ? "active" : ""}>
              <Link to="#Address" onClick={() => handleLinkClick("Address")}>
                Registered Address
              </Link>
            </li>
            <li className={activeLink == "Details" ? "active" : ""}>
              <Link to="#Details" onClick={() => handleLinkClick("Details")}>
                Bank Details
              </Link>
            </li>
            <li className={activeLink == "Communication" ? "active" : ""}>
              <Link
                to="#Communication"
                onClick={() => handleLinkClick("Communication")}
              >
                Communication
              </Link>
            </li>
            <li className={activeLink == "Others" ? "active" : ""}>
              <Link to="#Others" onClick={() => handleLinkClick("Others")}>
                Others
              </Link>
            </li>
          </ul>
        </div>
        <form>
          <div className="form-body-content" onScroll={handleScroll}>
            {/* ----------- Vendor --------------*/}
            <section id="Vendor" className="form-section">
              <span className="header-text">Vendor</span>
              <div className="section-title">
                <div className="setion-first-row common-row" id="vendor">
                <div style={{ width: "100%" }}>
                  <SelectBox
                    label={"Vendor Type"}
                    // label={<CustomLabel />}
                    labelMode="outside"
                    // width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("vendorType", e.value)
                    }
                    value={formData?.vendorType || ""}
                    items={vendorType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    id="vendorType"
                    className="required"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  </div>
                  <div style={{ width: "100%" }}>
                  <TextBox
                    label="GST Number"
                    labelMode="outside"
                    // width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("gstregNum", e.value)
                    }
                    value={formData?.gstregNum || ""}
                  />
                    {isGstVerified?<>
                      
                      <span
                        className="otp-terms-condition"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          paddingTop: "2px",
                        }}
                      >
                        <img
                          src={success}
                          alt="success"
                          height={20}
                          width={20}
                        />
                        <span>Verified Successfully</span>
                      </span>
                      </>:""}
                </div>
                </div>

                <div className="setion-first-row common-row">
                <TextBox
                    label="Vendor Name as per GST"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("vendorName", e.value)
                    }
                    value={formData?.vendorName || ""}
                    id="vendorName"
                    className="required"
                  >
                    <Validator>
                      <RequiredRule />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Business Structure of Vendor"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpType", e.value)
                    }
                    value={formData?.cmpType || ""}
                    id="cmpType"
                    className="required"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                 
                </div>
                <div className="setion-first-row common-row">
                <SelectBox
                    label="Category of Vendor"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    value={formData?.cmpCatType || ""}
                    items={categoryData}
                    valueExpr={"venCatName"}
                    displayExpr={"venCatName"}
                    id="cmpCatType"
                    className="required"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <TextBox
                    label="Pan Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("panNum", e.value)}
                    value={formData?.panNum || ""}
                    id="panNum"
                    // className="required"
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                 
                </div>

                <div className="setion-first-row common-row">
                  <TextBox
                    label="Official Phone Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("OfficialPhone", e.value)
                    }
                    value={formData?.OfficialPhone || ""}
                    id="OfficialPhone"
                    className="required"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Official Email Address "
                    labelMode="outside"
                    width={"49%"}
                    className="verify-email-text required"
                    onValueChanged={(e) => handleInputChange("eMail", e.value)}
                    value={formData?.eMail || ""}
                    id="eMail"
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </section>

            {/* ----------- Office Address --------------*/}
            <section id="Address" className="form-section">
              <div className="header-text">Registered Address</div>
              <div className="header-sub-text">Billing Address</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <TextBox
                    label="Address 1 "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress1", e.value)
                    }
                    value={formData?.bAddress1 || ""}
                    id="bAddress1"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="Address 2"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress2", e.value)
                    }
                    value={formData?.bAddress2 || ""}
                  />
                </div>

                <div className="setion-first-row common-row">
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    searchEnabled={true}
                    id="bCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    value={formData?.bCountry || ""}
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    id="bState"
                    searchEnabled={true}
                    items={StateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    value={formData?.bState || ""}
                    disabled={formData?.bCountry ? false : true}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </SelectBox>
                </div>

                <div className="setion-first-row common-row">
                  <Autocomplete
                    dataSource={CityData}
                    value={formData?.bCity || ""}
                    valueExpr={"cityName"}
                    displayExpr={"cityName"}
                    labelMode="outside"
                    label="City "
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("bCity", e.value)}
                    showClearButton={true}
                    itemRender={(data) => (
                      <span style={{ marginLeft: ".5rem" }}>
                        {data.cityName}
                      </span>
                    )}
                    id="bCity"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </Autocomplete>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"49%"}
                    // valueChangeEvent="keyup"
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={formData?.bZipCode || ""}
                    id="bZipCode"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>

                <div className="setion-first-row common-row">
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("bStreet", e.value)
                    }
                    value={formData?.bStreet || ""}
                    id="bStreet"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>
              </div>
              <CheckBox
                text={"Shipping address same as billing address"}
                // hint={"Shipping address same as billing address"}
                className="bill_addTxt"
                onValueChanged={chkBoxHandler}
              />
              <div className="header-sub-text">Shipping Address</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <TextBox
                    label="Ship From 1"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress1", e.value)
                    }
                    value={formData?.sAddress1 || ""}
                    id="sAddress1"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="Ship From 2"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress2", e.value)
                    }
                    value={formData?.sAddress2 || ""}
                  />
                </div>

                <div className="setion-first-row common-row">
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    id="sCountry"
                    value={formData?.sCountry || ""}
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" location="after" />
                    </Validator> */}
                  </SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    id="sState"
                    items={ShipStateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    value={formData?.sState || ""}
                    disabled={formData?.sCountry ? false : true}
                    searchEnabled={true}
                  ></SelectBox>
                </div>

                <div className="setion-first-row common-row">
                  <Autocomplete
                    dataSource={CityData}
                    value={formData?.sCity || ""}
                    valueExpr={"cityName"}
                    displayExpr={"cityName"}
                    labelMode="outside"
                    label="City "
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("sCity", e.value)}
                    showClearButton={true}
                    itemRender={(data) => (
                      <span style={{ marginLeft: ".5rem" }}>
                        {data.cityName}
                      </span>
                    )}
                    id="sCity"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </Autocomplete>

                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sZipCode", e.value)
                    }
                    value={formData?.sZipCode || ""}
                    id="sZipCode"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>

                <div className="setion-first-row common-row">
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sStreet", e.value)
                    }
                    value={formData?.sStreet || ""}
                    // className="required"
                    id="sStreet"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>
              </div>
            </section>

            {/* -----------  Bank Details --------------*/}

            <section id="Details" className="form-section">
              <span className="header-text">Bank Details</span>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  {/* <TextBox
                    label="Currency"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    value={formData?.transCry || ""}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    label="Currency "
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={currency}
                    value={formData?.transCry || ""}
                    valueExpr={"currCode"}
                    displayExpr={(item) =>
                      item ? `${item.currCode} - ${item.currName}` : ""
                    }
                    id="transCry"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </SelectBox>
                  {sourceConfigData && sourceConfigData["bankSource"] == "S" ? (
                    <SelectBox
                      onValueChanged={(e) =>
                        handleInputChange("acctName", e.value)
                      }
                      label="Bank Name "
                      labelMode="outside"
                      width={"49%"}
                      searchEnabled={true}
                      items={bankDetail}
                      value={formData?.acctName || ""}
                      valueExpr={"bankCode"}
                      displayExpr={"bankName"}
                      // displayExpr={"currName"}
                      id="acctName"
                      // className="required"
                      validationMessageMode="always"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </SelectBox>
                  ) : (
                    <TextBox
                      label="Bank Name"
                      labelMode="outside"
                      width={"49%"}
                      onValueChanged={(e) =>
                        handleInputChange("acctName", e.value)
                      }
                      value={formData?.acctName || ""}
                      id="acctName"
                      // className="required"
                      validationMessageMode="always"
                      validationMessagePosition={"bottom"}
                    >
                      {/* <Validator>
                        <RequiredRule message="Required" />
                      </Validator> */}
                    </TextBox>
                  )}
                </div>

                <div className="setion-first-row common-row">
                  <TextBox
                    label="Account Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("account", e.value)
                    }
                    readOnly={bankDetailDisabled?.account}
                    value={formData?.account || ""}
                    id="account"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="Re-enter Account Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("accountC", e.value)
                    }
                    readOnly={bankDetailDisabled?.accountC}
                    value={formData?.accountC || ""}
                    id="accountC"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                      <CompareRule
                        message="Acc No. and Confirm Acc No. do not match"
                        comparisonTarget={AccNoComparison}
                      />
                    </Validator> */}
                  </TextBox>
                </div>
                <div className="setion-first-row common-row">
                  <TextBox
                    label="IFSC Code "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("ifscNum", e.value)
                    }
                    value={formData?.ifscNum || ""}
                  />
                  <TextBox
                    label="Bank Branch/Address"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("branch", e.value)}
                    value={formData?.branch || ""}
                    id="branch"
                    readOnly={bankDetailDisabled?.branch}
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>

                <div className="setion-first-row common-row">
                  {/* <TextBox
                    label="Payment Terms"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value)
                    }
                    value={formData?.pymntTerms || ""}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value.toString())
                    }
                    label="Payment Terms"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={paymentTerms}
                    // value={formData?.pymntTerms || ""}
                    valueExpr={"groupNum"}
                    displayExpr={"pymntGroup"}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Communication --------------*/}

            <section id="Communication" className="form-section">
              <span className="header-text">Communication</span>
              <div className="header-sub-text">Primary Contact Person</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPrsn", e.value)
                    }
                    value={formData?.pCntctPrsn || ""}
                    id="pCntctPrsn"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPhone", e.value)
                    }
                    value={formData?.pCntctPhone || ""}
                    id="pCntctPhone"
                    // className="required"
                    validationMessagePosition={"bottom"}
                  >
                    {/* <Validator>
                      <RequiredRule message="Required" />
                    </Validator> */}
                  </TextBox>
                </div>
                <div className="setion-first-row common-row">
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctEmail", e.value)
                    }
                    value={formData?.pCnctEmail || ""}
                  />
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctDesg", e.value)
                    }
                    value={formData?.pCnctDesg || ""}
                  />
                </div>
              </div>
              <div className="header-sub-text">Secondary Contact Person</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPrsn", e.value)
                    }
                    value={formData?.sCntctPrsn || ""}
                  />
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPhone", e.value)
                    }
                    value={formData?.sCntctPhone || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctEmail", e.value)
                    }
                    value={formData?.sCnctEmail || ""}
                  />
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctDesg", e.value)
                    }
                    value={formData?.sCnctDesg || ""}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Other --------------*/}

            <section id="Others" className="form-section">
              <span className="header-text">Others</span>

              <div className="header-sub-text">Taxation Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_IS_MSME", e.value)
                    }
                    label="Micro, Small & Medium Enterprises"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    value={formData?.u_QIT_IS_MSME || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    id="u_QIT_IS_MSME"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_TYPE", e.value.toString())
                    }
                    label="MSME Types"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={msmeType}
                    value={formData?.u_QIT_MSME_TYPE || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    disabled={
                      formData?.u_QIT_IS_MSME
                        ? formData?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                    id="msmeType"
                    className="required"
                    validationMessagePosition={"bottom"}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                </div>
                <div className="setion-first-row common-row">
                  <TextBox
                    label="MSME Registration No."
                    labelMode="outside"
                    width={"49%"}
                    disabled={
                      formData?.u_QIT_IS_MSME
                        ? formData?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_REGNO", e.value)
                    }
                    value={formData?.u_QIT_MSME_REGNO || ""}
                  />
                </div>
              </div>
              {/* <div className="header-sub-text">IOS Details</div> */}
              <div className="header-sub-text">More details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <TextBox
                    label="Remarks"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) => handleInputChange("remark", e.value)}
                    value={formData?.remark || ""}
                  />
                  <TextBox
                    label="Website Link"
                    labelMode="outside"
                    width={"49%"}
                    onValueChanged={(e) =>
                      handleInputChange("websiteLink", e.value)
                    }
                    value={formData?.websiteLink || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                  <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>
                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload vendor company brochures."
                    )}
                  </label>
                  <input
                    //disabled={enableOnVerification}
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept=".pdf"
                  />
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>

      {approvalPopUp && (
        <ConformationPopup
          headertext={"Send for Approval"}
          subtext={"Are you sure you want to send for approval?"}
          isVisible={approvalPopUp}
          onHide={handleCloseConformPopup}
          saveFunction={handleSaveClick}
          saveIcon={saveVendorIcon}
          cancelBtnTxt={"Discard"}
          saveBtnTxt={"Send for Approval"}
          isSaveBtnDisabled={false}
        />
      )}
    </>
  );
};
