import axios from "axios";
import { logToServer } from "./logger";
import axiosInstance from "./axiosInstance";
const API_URL1 = process.env.REACT_APP_API;
// import CryptoJS from 'crypto-js';
const SECRET = process.env.REACT_APP_SECRET;
const domain = window.location.hostname;
const getDomainPart = (hostname) => {
  // Extract the desired part of the domain
  const parts = hostname.split('.');
  // Assuming the desired part is the first part of the domain
  return parts.length > 1 ? parts[0] : hostname;
};
const domainPart = getDomainPart(domain);
var varName = `REACT_APP_${domainPart}`;
const API_URL = process.env[varName];
console.log(domain); 
console.log("variable : ",varName); 

console.log("variable : ",process.env[varName]); 

async function checkAuthentication() {
  console.log("+++++++++++++++++++++++++++++++++++++++++++++");
  const storedData = localStorage.getItem("AuthRule");
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  if (myCookieValue != null && userData != null && storedData != null) {
    try {
      const headers = {
        Authorization: `Bearer ${myCookieValue}`,
      };
      console.log("+++++++++++++++++++++++++++++++++++++++++++++");
      const response = await axios.get(`${API_URL}/AuthUser/validate_jwt`, {
        headers: headers,
      });
      console.log("+++++++++++++++++++++++++++++++++++++++++++++",response);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      if (error.response.status === 401) {
        const getNewToken = await getNewAccessToken();
        if (getNewToken.isOK) {
          const myNewToken = getNewToken.data.access.accessToken;
          localStorage.setItem("accessToken", myNewToken);
          return true;
        }

      }
      return false;
    }
  } else {
    return false;
  }
}


async function getNewAccessToken() {
  try {
    const refresh = localStorage.getItem("refreshToken");
    const payload = {
      refreshToken: refresh,
    };
    const response = await axios.post(`${API_URL}/AuthUser/refresh`, payload);

    if (response.status === 200 || response.status === 201) {
      return {
        isOK: true,
        data: {
          access: response.data,
        },
      };
    } else {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("profile");
      localStorage.removeItem("User");
      localStorage.removeItem("AuthRule");
      localStorage.removeItem("WarehouseRule");
      localStorage.removeItem("cardcode");
      localStorage.removeItem("prevPath");
      return false;
    }
  } catch (error) {
    return false;
  }
}

export async function signIn(payload) {
  try {
    const response = await axios.post(`${API_URL}/AuthUser/LoginPost`, payload);
    if (response.data.statusCode == 200) {
      const datanew = {
        text: "Home",
        path: "/home",
        icon: "home",
      };
      const { refreshToken, accessToken } = response.data;
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("profile", response.data.profile);
      // Cookies.set("token", response.data.token, {
      //   expires: 1,
      //   path: "/",
      //   secure: true,
      //   sameSite: "none",
      // });

      localStorage.setItem("User", response.data.username);
      if (response.data.isVendor === "Y") {
        localStorage.setItem("cardcode", response.data.cardCode);
      }
      // Cookies.set("User", response.data.userName, {
      //   expires: 1,
      //   path: "/",
      //   secure: true,
      //   sameSite: "none",
      // });

      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;

      const w_Rule = response.data.wareHouse_Rule;
      const dataToStoreWarehouse = {
        w_Rule,
        expiration: expirationTime,
      };
      localStorage.setItem(
        "WarehouseRule",
        JSON.stringify(dataToStoreWarehouse)
      );

      const filteredData = response.data.encrypt_Authentication_Rule;

      if (checkAuthentication()) {
        await logToServer(
          "Login",
          "AuthUser",
          "LoginPost",
          "S",
          "Login successfully",
          JSON.stringify(payload.username),
          response.data.username
        );
        return {
          isOk: true,
          data: {
            // token: Cookies.get("token"),
            // UserData: Cookies.get("User"),
            refreshToken: localStorage.getItem("refreshToken"),
            accessToken: localStorage.getItem("accessToken"),
            UserData: localStorage.getItem("User"),
            Profile: localStorage.getItem("profile"),
          },
          Auth_Rule: filteredData,
        };
      }
    }
  } catch {
    await logToServer(
      "Login",
      "AuthUser",
      "LoginPost",
      "S",
      "Login unsuccessfully",
      JSON.stringify(payload.username)
    );
    return {
      isOk: false,
    };
  }
}

export async function getUser() {
  try {
    if (await checkAuthentication()) {
      const storedData = localStorage.getItem("AuthRule");

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const currentTime = new Date().getTime();

        if (parsedData.expiration >= currentTime) {
          return {
            isOk: true,
            data: {
              // token: Cookies.get("token"),
              // UserData: Cookies.get("User"),
              refreshToken: localStorage.getItem("refreshToken"),
              accessToken: localStorage.getItem("accessToken"),
              UserData: localStorage.getItem("User"),
              Profile: localStorage.getItem("profile"),
            },
            Auth_Rule: parsedData.encryptedString,
            // Auth_Rule: JSON.parse(localStorage.getItem("AuthRule"))
            //   .filteredData,
          };
        } else {
          // The data has expired, remove it from localStorage
          // Cookies.remove("token");
          // Cookies.remove("User");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("profile");
          localStorage.removeItem("User");
          localStorage.removeItem("AuthRule");
          localStorage.removeItem("WarehouseRule");
        }
      }
      return {
        isOk: true,
        data: {
          // token: Cookies.get("token"),
          // UserData: Cookies.get("User"),
          refreshToken: localStorage.getItem("refreshToken"),
          accessToken: localStorage.getItem("accessToken"),
          UserData: localStorage.getItem("User"),
        },
        Auth_Rule: [],
      };
    } else {
      // Cookies.remove("token");
      // Cookies.remove("User");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("profile");
      localStorage.removeItem("User");
      localStorage.removeItem("AuthRule");
      localStorage.removeItem("WarehouseRule");
      return {
        isOk: false,
      };
    }
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email, password) {
  try {
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}

export async function Logout() {
  try {
    // Cookies.remove("token");
    // Cookies.remove("User");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("profile");
    localStorage.removeItem("User");
    localStorage.removeItem("AuthRule");
    localStorage.removeItem("WarehouseRule");
    localStorage.removeItem("cardcode");
    localStorage.removeItem("prevPath");
    return {
      isOk: false,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

// get verification Status
export async function getVerificationStatus(encodedText) {
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    const newCode = encodeURIComponent(encodedText);
    const response = await axios.get(
      `${API_URL}/AuthUser/GetVerificationStatus?encodedText=${newCode}`
    );
    responseBody.responseData = response.data;
    return responseBody;
  } catch (error) {
    responseBody.hasError = true;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    // responseBody.errorMessage = responseBody.errorMessage =
    //   error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}
