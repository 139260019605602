import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../components/Page-Header/PageHeader";
import LogListing from "./LogListing";
import {
  Button,
  DataGrid,
  DateBox,
  Popup,
  SelectBox,
  //   TextBox,
} from "devextreme-react";
import TextBox, { Button as TextBoxButton } from "devextreme-react/text-box";
import { helpIcon } from "../../assets/icon";
import "./report.scss";
import { HelperPopupPage } from "../../components";
import { getUserData } from "../../api/user-setting";
import { toastDisplayer } from "../../components/comman card/toast-displayer";
import { getAllApiLog, getAllModules } from "../../api/reportAPI";
import { useAuth } from "../../contexts/auth";

function LogReport() {
  const btnOption = {
    icon: "add",
    text: "Add Vendor",
  };
  const { user } = useAuth();
  const dateLabel = { "aria-label": "Date" };
  const [moduleData, setModuleData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [AuthPopUp, setAuthPopUp] = useState(false);
  const [UserData, setUserData] = useState(false);
  const dataGridRefAuthRule = useRef();
  const [fromDate, setFromdate] = useState(null);
  const [toDate, setTodate] = useState(null);
  const [moduleName, setModuleName] = useState(null);
  const [selectedRowKeysOnChangeAuth, setSelectedRowKeysOnChangeAuth] =
    useState([]);
  const [selectedCopyRowKeysOnChangeAuth, setSelectedCopyRowKeysOnChangeAuth] =
    useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // {/* <div className="datagrid-container-class">
  //     <div>
  //     {/* <div style={{marginBottom: "-3.1rem"}}> */}
  //       <span style={{fontSize:"18px"}}>Existing Users</span>
  //     </div> */}
  const allSelectionStatus = [
    // { value: "", text: "Select Module" },
    { value: "P", text: "Pending Customers" },
    { value: "A", text: "Approved Customers" },
    { value: "R", text: "Rejected Customers" },
  ];
  const statusData = [
    { status: "All", value: "" },
    { status: "Success", value: "S" },
    { status: "Error", value: "E" },
  ];
  const userHelpOptions = {
    icon: helpIcon,
    onClick: () => showPopupHandler(),
  };
  const handleSearch = async () => {
    console.log("first");
    console.log("fromDate : ", fromDate);
    console.log("toDate : ", toDate);
    if (!fromDate) {
      return toastDisplayer("error", "Select from date...!!");
    }
    if (!toDate) {
      return toastDisplayer("error", "Select to date...!!");
    }

    const reqPayload = {
      fromDate: fromDate,
      toDate: toDate,
      module: moduleName || "",
      userName:user ? (user["UserData"] == "Admin" ? selectedRowKeysOnChangeAuth.length > 0
      ? selectedRowKeysOnChangeAuth[0].userName
      : "" : user["UserData"]) : user["UserData"]
        ,
      logLevel: "",
    };
    let result = await getAllApiLog(reqPayload);
    console.log(result);
    if (result.hasError) {
      return toastDisplayer("error", result.errorMessage);
    }
    if (result.responseData.logs.length <= 0) {
      return toastDisplayer("error", "No data found..!!");
    }
    setLogData(result.responseData.logs);
  };
  const showPopupHandler = () => {
    setAuthPopUp(true);
  };
  const handleCancel = () => {
    setAuthPopUp(false);
  };

  const getData = async () => {
    let result = await getUserData();
    if (result.hasError) {
      return toastDisplayer("error", result.errorMessage);
    }
    var data = result.responseData;
    data.unshift({ userName: "Admin" });
    setUserData(data);
  };

  const moduleDataHandler = async () => {
    let result = await getAllModules();
    if (result.hasError) {
      return toastDisplayer("error", result.errorMessage);
    }
    setModuleData(result.responseData);
  };

  useEffect(() => {
    getData();
    moduleDataHandler();
  }, []);

  const handleDataGridRowSelectionAuthRuleUser = async ({
    selectedRowKeys,
  }) => {
    setSelectedRowKeysOnChangeAuth(selectedRowKeys);
    const length = await selectedRowKeys.length;
    if (selectedRowKeys.length > 1) {
      const value = await dataGridRefAuthRule.current.instance.selectRows(
        selectedRowKeys[length - 1]
      );
      return selectedRowSetterApprove(value);
    } else {
      const value = await dataGridRefAuthRule.current.instance.selectRows(
        selectedRowKeys[0]
      );
      return selectedRowSetterApprove(value);
    }
  };

  const selectedRowSetterApprove = async (params) => {
    console.log(params);
    setSelectedRowKeysOnChangeAuth(params);
  };

  const handleSave = () => {
    console.log(selectedRowKeysOnChangeAuth);
    setAuthPopUp(false);
  };

  const handleValueChange = (e) => {
    const selectedDate = new Date(e.value);
    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + 1);
    setTodate(nextDay);
  };

  return (
    <>
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={false}
        pageTitle={"All the Log Reports"}
        clickHandler={""}
        btnOption={btnOption}
        btnText={"Add Vendor"}
      />
      <div className="vendor-listing">
        <div className="report-items">
          {/* <div style={{ width: "250px" }}> */}
          <DateBox
            placeholder="From Date"
            width={"250px"}
            inputAttr={dateLabel}
            type="date"
            onValueChanged={(e) => setFromdate(e.value)}
            label="Select From Date"
            labelMode="outside"
          />
          {/* </div> */}
          <DateBox
            placeholder="To Date"
            width={"250px"}
            inputAttr={dateLabel}
            onValueChanged={handleValueChange}
            type="date"
            // onValueChanged={(e) => setTodate(e.value)}
            label="Select To Date"
            labelMode="outside"
          />
          <SelectBox
            width={"250px"}
            className="selectbox-right"
            valueExpr="moduleName"
            displayExpr="moduleName"
            //   value={filterStatus}
            placeholder="Module"
            items={moduleData}
            onValueChanged={(e) => setModuleName(e.value)}
            label="Select Module"
            labelMode="outside"
          />

          <TextBox
            //   label="User Name"
            //   labelMode="outside"
            placeholder="User name"
            width={"250px"}
            className="seachBox"
            value={
              selectedRowKeysOnChangeAuth.length > 0
                ? selectedRowKeysOnChangeAuth[0].userName
                : ""
            }
            label="Select User Name"
            labelMode="outside"
            // disabled={true}
            disabled={user ? (user["UserData"] == "Admin" ? false : true) : true}
          >
            <TextBoxButton
              name="popupSearch"
              location="after"
              options={userHelpOptions}
              height={44}
              width={44}
              className="searchicon"
              style={{ "background-color": "#f6f6f6" }}
            />
          </TextBox>
          <Button
            style={{ margineTop: "24px" }}
            width={44}
            height={48}
            // text={"Search"}
            icon={"search"}
            labelMode="outside"
            onClick={handleSearch}
            className="searhBtn"
            // useSubmitBehavior={true}
          ></Button>
        </div>
        {logData.length > 0 && <LogListing userAdded={logData} />}
      </div>

      {AuthPopUp && (
        <Popup
          visible={true}
          height={window.innerHeight - 100}
          showCloseButton={false}
          className="initate-popup-css"
          showTitle={false}
          contentRender={() => (
            <HelperPopupPage
              title={"User Details"}
              caption={"Choose the user"}
              handleCancel={handleCancel}
              handleSave={handleSave}
              datagridData={UserData}
              keyExpr={"userName"}
              handleDataGridRowSelection={
                handleDataGridRowSelectionAuthRuleUser
              }
              dataGridRef={dataGridRefAuthRule}
              selectedRowKeys={selectedRowKeys}
            />
          )}
        ></Popup>
      )}
    </>
  );
}

export default LogReport;
