import React from "react";
import "./General-settings.scss";
import VendorCategory from "./Vendor-Category/vendor-category";
import SourceMain from "./Source/source-main";
import SystemLogo from "./SystemLogo/system-logo";

function GeneralSettings() {
  return (
    <>
      <div className="general-main">
        <div className="dx-card-box">
          <VendorCategory />
        </div>
        <div className="dx-hr-line"></div>
        <div className="dx-card-box">
          <SourceMain />
        </div>
        <div className="dx-hr-line"></div>
        <div className="dx-card-box">
          <SystemLogo />
        </div>
      </div>
    </>
  );
}

export default GeneralSettings;
