import React, { useEffect, useRef, useState } from "react";
import "./otp.scss";
import { Button } from "devextreme-react";
import { VerifyOtpByEmail } from "../../api/registerCustomer";
import { toastDisplayer } from "../comman card/toast-displayer";

const OtpInput = ({
  handlePopupClose,
  officialMail,
  setEnableOnVerification,
  userType,
  getOtpFromMail,
  otp,
  setOtp,
  setIsEmailDisabled,
  setrefFocused,
  setrefFocused1,
}) => {
  const length = 6;
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const inputRefs = useRef([]);

  useEffect(() => {
    const focusFirstInput = setTimeout(() => {
      if (setrefFocused) {
        inputRefs.current[0].focus();
      }
    }, 500);

    return () => clearTimeout(focusFirstInput);
  }, [setrefFocused]);

  const handleSubmiOtp = async () => {
    if (otp.length > 6) {
    }
    const responseOtp = otp.join("");
    const verifyMailByOtp = await VerifyOtpByEmail(
      officialMail,
      responseOtp,
      userType
    );
    if (verifyMailByOtp.hasError === true) {
      return toastDisplayer("error", verifyMailByOtp.errorMessage);
    } else {
      setrefFocused1(false);
      setEnableOnVerification(false);
      setOtp(new Array(length).fill(""));
      handlePopupClose();
      setIsEmailDisabled(true);
      return toastDisplayer("success", "Email verified successfully..!!");
    }
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];

    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const onOtpSubmit = (combinedOtp) => {
    // Add your logic here after OTP submission
  };

  const handleResendOtp = () => {
    // Add your logic to resend OTP
    setTimer(60); // Reset timer
  };
  const handleRetryClick = () => {
    getOtpFromMail(officialMail, userType);
    // Reset the timer to its initial value (e.g., 60 seconds)
    setTimer(60);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [timer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  return (
    <>
      <div className="popup">
        <div className="upper-section">
          <div className="popup-header">
            <div className="popup-header-logo">
              <span className="material-symbols-outlined">verified_user</span>
            </div>
            <div className="popup-header-btn">
              <Button icon="close" onClick={handlePopupClose} />
            </div>
          </div>

          <div className="popup-title">
            <div className="popup-main-title">
              <span>OTP Verification</span>
            </div>
            <div className="popup-sub-title">
              <span>Verify with OTP sent to {officialMail}</span>
            </div>
          </div>
        </div>
        <div className="main-container">
          <div className="otp-main">
            {otp.map((value, index) => {
              return (
                <input
                  key={index}
                  type="text"
                  ref={(input) => (inputRefs.current[index] = input)}
                  value={value}
                  onChange={(e) => handleChange(index, e)}
                  onClick={() => handleClick(index)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  className="otpInput"
                />
              );
            })}
          </div>
        </div>
        <div className="footer-main">
          <Button
            className="otpContinuebtn"
            text="continue"
            width={"100%"}
            height={"44px"}
            onClick={handleSubmiOtp}
          />
          <span className="Pre-text">
            {timer > 0
              ? `Didn’t get a OTP ? Retry in ${minutes
                  .toString()
                  .padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`
              : "Didn’t get a OTP ? "}
            {timer === 0 && (
              <span
                className="ResstartLink"
                href="#"
                onClick={handleRetryClick}
              >
                Click to resend
              </span>
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default OtpInput;
