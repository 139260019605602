import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
import { navigation } from "../../../app-navigation";
import { Button, Popup } from "devextreme-react";
import UserListing from "./UserListing";
import "./adduser.scss";
import AddUserPopup from "./AddUserPopup";
import { useAuth } from "../../../contexts/auth";
import PageHeader from "../../../components/Page-Header/PageHeader";

export const AddUserMain = () => {
  const { AuthRuleContect } = useAuth();
  const [showPopup, setShowPopup] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const handleApprovalRequest = () => {
    setShowPopup(true);
  };

  const hanldePopupClose = () => {
    setShowPopup(false);
  };

  const btnOption = {
    icon: "add",
    text: "New User",
  };

  return (
    <>
      <Popup
        visible={showPopup}
        height={"460px"}
        width={"80%"}
        className="request-popup"
      >
        <AddUserPopup
          hanldePopupClose={hanldePopupClose}
          headertext={"Send for Approval"}
          headerSubText={"Are you sure you want to send for approval?"}
          userAdded={userAdded}
          setUserAdded={setUserAdded}
        ></AddUserPopup>
      </Popup>
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={true}
        pageTitle={"Users"}
        clickHandler={handleApprovalRequest}
        btnOption={btnOption}
      />
      <div className="vendor-listing">
        <UserListing userAdded={userAdded} />
      </div>
    </>
  );
};
