import axios from "axios";
const API_URL = process.env.REACT_APP_API;
// const storedSessionValue = JSON.parse(sessionStorage.getItem("authState"));
// const { access, user, userAuth } = storedSessionValue;
// const storedData = userAuth;
// const userData = user;
// const token = access;
// const header = {
//   Authorization: `Bearer ${token}`,
// };
const axiosInstance = axios.create({
  baseURL: `${API_URL}`, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to refresh token
const refreshToken = async () => {
  const refresh = localStorage.getItem("refreshToken");
  const access = localStorage.getItem("accessToken");
  const refreshToken = refresh;
  const response = await axios.post(`${API_URL}/AuthUser/refresh`, {
    refreshToken: refreshToken,
  });
  const { accessToken } = response.data;
  localStorage.setItem("accessToken", accessToken);
  return accessToken;
};

// Request interceptor to add access token to request headers
axiosInstance.interceptors.request.use(
  async (config) => {
    const access = localStorage.getItem("accessToken");
    if (access) {
      config.headers.Authorization = `Bearer ${access}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshToken();
      axiosInstance.defaults.headers.Authorization = `Bearer ${newAccessToken}`;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;