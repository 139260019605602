import { Button, DataGrid, SelectBox } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { customers } from "../../../data";
import { Item } from "devextreme-react/cjs/accordion";
import {
  Column,
  Editing,
  Toolbar,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/cjs/data-grid";
// import DataGrid, {
//   Column,
//   Toolbar,
//   Item,
//   SearchPanel,
//   Selection,
//   Paging,
// } from "devextreme-react/data-grid";
import { getUserData } from "../../../api/user-setting";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";

const UserListing = ({ userAdded }) => {
  const [userGridData, setUserGridData] = useState([]);
  const [loading, setloading] = useState(false);

  const getAllUserData = async () => {
    setloading(true);
    try {
      const response = await getUserData();
      setloading(false);
      setUserGridData(response.responseData);
    } catch {
      setloading(true);
      return toastDisplayer("error", "Something went wrong..!!");
    }
  };

  useEffect(() => {
    getAllUserData();
  }, [userAdded]);
  // {/* <div className="datagrid-container-class">
  //     <div>
  //     {/* <div style={{marginBottom: "-3.1rem"}}> */}
  //       <span style={{fontSize:"18px"}}>Existing Users</span>
  //     </div> */}
  return (
    <>
      <DataGrid
        dataSource={userGridData}
        rowAlternationEnabled={true}
        showBorders={true}
        columnHidingEnabled={false}
        className="on-hover-data responsive-text"
      >
        <Item name="searchPanel" />
        <SearchPanel visible={true} width={300} placeholder="Search" />
        <Paging defaultPageSize={10} defaultPageIndex={0} />

        <Toolbar>
          <Item location="before">
            <div className="informer">
              <div className="count">
                <span className="toolbar-text-span">
                  Existing Users
                </span>
              </div>
            </div>
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
        <Scrolling columnRenderingMode="virtual" />

        <Column
          dataField={"userName"}
          caption={"User Name"}
          allowEditing={false}
        ></Column>

        <Column
          alignment={"left"}
          dataField={"mobileNo"}
          caption={"Mobile No"}
          allowEditing={false}
        ></Column>

        <Column
          alignment={"left"}
          dataField={"userEmail"}
          caption={"User Email"}
          allowEditing={false}
        ></Column>

        {/* <Column
            alignment={"left"}
            dataField={"gender"}
            caption={"Gender"}
            allowEditing={false}
          ></Column> */}
      </DataGrid>
    </>
  );
  // </div>
};

export default UserListing;
