import PageHeader from "../../../components/Page-Header/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import React, { useCallback, useEffect, useState } from "react";
// import "../add-vendor/addVendor.scss";
import { HashLink as Link } from "react-router-hash-link";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import { useAuth } from "../../../contexts/auth";
import { rejectIcon, saveVendorIcon } from "../../../assets/icon";
import ApprovePopup from "../../../components/Approval-popup/ApprovePopup";
import { verifyCustomer } from "../../../api/customer";
import pdf from "../../../assets/images/pdf.png";
import { LoadPanel } from "devextreme-react";
import { requestAddressFromPin } from "../../../api/registerCustomer";
import {
  GetSAPCurrency,
  getCountryData,
  getPaymentTerms,
  getSalesEmp,
  getStateData,
} from "../../../api/commonAPI";
import { UseConfigContext } from "../../../contexts/cofigContext";
import { allCurrencies } from "../../../utils/currency";

function CustomerStatus() {
  const location = useLocation();
  const navigate = useNavigate();
  const [customerDetail, setVendorDetail] = useState([]);
  const [isTxtFieldDisable, setIsTxtFieldDisable] = useState(true);
  const { state, pathname } = location;
  const { user } = useAuth();
  const [activeLink, setActiveLink] = useState("Reason");
  const [loading, setLoading] = useState(false);
  const [approvalPopUp, setApprovalPopUp] = useState(false);
  const [rejectPopUp, setRejectPopUp] = useState(false);
  const [rejectComment, setRejectComment] = useState(false);
  const [approveComment, setApprovecomment] = useState(false);
  const [from, setFrom] = useState(false);
  const [formData, setFormData] = useState({
    createdByUserName: user["UserData"],
  });

  const [paymentTerms, setPaymentTerms] = useState([]);

  const { getSourceData } = UseConfigContext();
  const [sourceConfigData, setSourceConfigData] = useState(getSourceData[0]);
  const [currency, setCurrency] = useState([]);

  const getPayemntTerms = async () => {
    var apiRes = await getPaymentTerms();
    if (!apiRes.hasError) {
      setPaymentTerms(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };
  const [salesEmp, setSalesEmp] = useState([]);

  const getSalesEmpdata = async () => {
    var apiRes = await getSalesEmp();
    if (!apiRes.hasError) {
      setSalesEmp(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };
  useEffect(() => {
    getPayemntTerms();
    getSalesEmpdata();

    getAllContryData();
    getCurrencyData();
  }, []);

  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [ShipStateData, setShipStateData] = useState([]);

  const getAllContryData = async () => {
    var apiRes = "";
    if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
      apiRes = await getCountryData();
    } else {
      // SAP
      apiRes = await getCountryData();
    }
    if (!apiRes.hasError) {
      setCountryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCountryWiseStateData = async (country_code, fieldName) => {
    if (country_code != "" && country_code != null) {
      var apiRes = "";
      if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
        apiRes = await getStateData(country_code);
      } else {
        // SAP
        apiRes = await getStateData(country_code);
      }
      if (!apiRes.hasError) {
        if (fieldName == "bCountry") {
          setStateData(apiRes.responseData);
        }
        if (fieldName == "sCountry") {
          setShipStateData(apiRes.responseData);
        }
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const getCurrencyData = async () => {
    if (sourceConfigData && sourceConfigData["currencySource"] == "L") {
      setCurrency(allCurrencies);
    } else {
      var apiRes = await GetSAPCurrency();
      if (!apiRes.hasError) {
        setCurrency(apiRes.responseData);
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  useEffect(() => {
    if (state == null) {
      if (pathname == "/customer/addCustomer/CustomerStatus")
        navigate("/customer/addCustomer");
      else navigate("/customer/verifycustomer");
    } else {
      setVendorDetail(state.customerdetail[0]);
      setFrom(state.From);
      if (state.From == "V") {
        setIsTxtFieldDisable(true);
      }
    }
  }, [customerDetail]);

  const btnOption = {
    icon: "",
    text: "Approve",
  };
  const btnOption2 = {
    icon: "",
    text: "Reject",
  };

  const handleLinkClick = (activeLinkName) => {
    setActiveLink(activeLinkName);
  };

  const handleCloseConformPopup = () => {
    setApprovalPopUp(false);
  };

  const handleRejectPopup = () => {
    setRejectPopUp(false);
  };

  const handleSaveClick = async (status, comment) => {
    setLoading(true);
    var reqBody = {
      loginUserName: user["UserData"],
      cardCode: customerDetail.customerCode,
      action: status,
      message: comment,
    };
    var apiRes = await verifyCustomer(reqBody, customerDetail.customerName);
    if (apiRes.hasError) {
      setLoading(false);
      return toastDisplayer("error", apiRes.errorMessage);
    } else {
      setLoading(false);
      handleCloseConformPopup();
      toastDisplayer("success", "Customer verified..!!");
      if (state.From == "V") {
        navigate("/customer/verifycustomer");
      } else if (state.From == "A") {
        navigate("/customer/addCustomer");
      }
    }
  };

  const handleRejectBtnClick = async () => {
    if (rejectComment == "" || rejectComment == null) {
      return toastDisplayer("error", "Reason is requied..!!");
    }
    handleSaveClick("R", rejectComment);
    setRejectComment("");
    setRejectPopUp(false);
  };

  const handleApproveBtnClick = async () => {
    if (approveComment == "" || approveComment == null) {
      return toastDisplayer("error", "Reason is requied..!!");
    }
    handleSaveClick("A", approveComment);
    setApprovecomment("");
    setApprovalPopUp(false);
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    if (fieldName == "bCountry" || fieldName == "sCountry") {
      getCountryWiseStateData(value, fieldName);
    }
  };

  const msmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];
  const ismsmeType = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];

  const vendorType = [
    { value: "E", text: "Export" },
    { value: "D", text: "Domestic" },
  ];
  const cmpCatType = [
    { value: "M", text: "Manufacturer" },
    { value: "T", text: "Trader" },
  ];

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFormData((prevState) => ({
      ...prevState,
      ["cmpDocs"]: base64,
    }));
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll(".form-section");
    let foundAnySection = false;

    sections.forEach((section) => {
      const bounding = section.getBoundingClientRect();
      if (bounding.top >= 0 && bounding.top <= window.innerHeight) {
        if (!foundAnySection) {
          setActiveLink(section.id);
          foundAnySection = true;
        }
      }
    });
  };

  const AccNoComparison = useCallback(() => {
    return formData?.account;
  }, [formData?.account]);

  const handleApproveClick = () => {
    setApprovalPopUp(true);
  };
  const handleRejectClick = () => {
    setRejectPopUp(true);
  };

  const handleApproveComment = (e) => {
    setApprovecomment(e);
  };
  const handleRejectComment = (e) => {
    setRejectComment(e);
  };

  // const handleGetAddress = async (pinCode, addOf) => {
  //   if (pinCode.length === 6) {
  //     const getAddress = await requestAddressFromPin(pinCode);

  //     if (getAddress[0].PostOffice != null) {
  //       if (addOf == "B") {
  //         // setGenertedDataFromPin(getAddress[0].PostOffice[0]);
  //         handleInputChange("bZipCode", pinCode);
  //         handleInputChange("bState", getAddress[0].PostOffice[0].District);
  //         handleInputChange("bCity", getAddress[0].PostOffice[0].State);
  //         handleInputChange("bCountry", getAddress[0].PostOffice[0].Country);
  //       }
  //       if (addOf == "S") {
  //         // setGenertedDataFromPin(getAddress[0].PostOffice[0]);
  //         handleInputChange("sZipCode", pinCode);
  //         handleInputChange("sState", getAddress[0].PostOffice[0].District);
  //         handleInputChange("sCity", getAddress[0].PostOffice[0].State);
  //         handleInputChange("sCountry", getAddress[0].PostOffice[0].Country);
  //       }
  //     }
  //   }
  // };

  const handleClick = () => {
    let pdfWindow = window.open(customerDetail?.cmpDocs);
    if (pdfWindow) {
      pdfWindow.document.write(`<body>
      <embed src="${customerDetail?.cmpDocs}" width="100%" height="100%">
      </body>
    `);
    }
  };
  return (
    <>
      {loading ? <LoadPanel visible="true" /> : ""}
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={
          from == "V" ? (customerDetail.status == "P" ? true : false) : false
        }
        isBtn2Visisble={
          from == "V" ? (customerDetail.status == "P" ? true : false) : false
        }
        pageTitle={
          customerDetail.status == "R"
            ? "Customer Details : Decision Rejected"
            : customerDetail.status == "A"
            ? "Customer Details : Decision Approved"
            : customerDetail.status == "P"
            ? "Customer Details : Decision Pending"
            : ""
        }
        statusPage={
          customerDetail.status == "R"
            ? "Rejected"
            : customerDetail.status == "A"
            ? "Approved"
            : customerDetail.status == "P"
            ? "Pending"
            : ""
        }
        btnOption={btnOption}
        btnOption2={btnOption2}
        clickHandler={handleApproveClick}
        clickHandler2={handleRejectClick}
      />

      <div className="content-add-form">
        <div className="form-header-panel">
          <ul type="none">
            <li className={activeLink == "Reason" ? "active" : ""}>
              <Link to="#Reason" onClick={() => handleLinkClick("Reason")}>
                Verification
              </Link>
            </li>
            <li className={activeLink == "Customer" ? "active" : ""}>
              <Link to="#Customer" onClick={() => handleLinkClick("Customer")}>
                Customer
              </Link>
            </li>
            <li className={activeLink == "Address" ? "active" : ""}>
              <Link to="#Address" onClick={() => handleLinkClick("Address")}>
                Registered Address
              </Link>
            </li>
            <li className={activeLink == "Details" ? "active" : ""}>
              <Link to="#Details" onClick={() => handleLinkClick("Details")}>
                Bank Details
              </Link>
            </li>
            <li className={activeLink == "Communication" ? "active" : ""}>
              <Link
                to="#Communication"
                onClick={() => handleLinkClick("Communication")}
              >
                Communication
              </Link>
            </li>
            <li className={activeLink == "Others" ? "active" : ""}>
              <Link to="#Others" onClick={() => handleLinkClick("Others")}>
                Others
              </Link>
            </li>
          </ul>
        </div>
        <form>
          <div className="form-body-content" onScroll={handleScroll}>
            {/* ----------- Verification Reason --------------*/}
            <section id="Reason" className="form-section">
              <span className="header-text">Verification</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Reason"
                    labelMode="outside"
                    width={"33%"}
                    // value={formData?.OfficialPhone || ""}
                    value={customerDetail.reason || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                    
                    <TextBox
                    label="SAP Customer Code"
                    labelMode="outside"
                    width={"33%"}
                    
                    value={customerDetail.sapcardCode || "--"}
                    visible={customerDetail.status == "A"? true : false}
                  >
                   
                  </TextBox>
                </div>
              </div>
            </section>
            {/* ----------- Customer --------------*/}
            <section id="Customer" className="form-section">
              <span className="header-text">Customer</span>
              <div className="section-title">
                <div
                  className={"setion-first-row common-row-check"}
                  id="vendor"
                >
                  <SelectBox
                    label="Customer Type "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("customerType", e.value)
                    }
                    value={customerDetail.customerType || "--"}
                    // value={formData?.vendorType || ""}
                    items={vendorType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  ></SelectBox>
                  <TextBox
                    label="Customer Name as per GST"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("customerName", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={customerDetail.customerName || "--"}
                    // value={formData?.vendorName || ""}
                  ></TextBox>
                  <TextBox
                    label="Business Structure of Customer"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpType", e.value)
                    }
                    value={customerDetail.cmpType || "--"}
                    // value={formData?.vendorName || ""}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Category of Customer"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    value={customerDetail.cmpCatType || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Pan Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("panNum", e.value)}
                    value={customerDetail.panNum || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="GST Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("gstregNum", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={customerDetail?.gstregNum || "--"}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Official Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("officialPhone", e.value)
                    }
                    value={customerDetail?.officialPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Official Email Address "
                    labelMode="outside"
                    width={"33%"}
                    className="verify-email-text"
                    onValueChanged={(e) => handleInputChange("eMail", e.value)}
                    value={customerDetail?.eMail || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
            </section>

            {/* ----------- Office Address --------------*/}
            <section id="Address" className="form-section">
              <div className="header-text">Registered Address</div>
              <div className="header-sub-text">Billing Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Billing Address 1 "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress1", e.value)
                    }
                    value={customerDetail?.bAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Billing Address 2"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress2", e.value)
                    }
                    value={customerDetail?.bAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bStreet", e.value)
                    }
                    value={customerDetail?.bStreet || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={customerDetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    value={customerDetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    value={customerDetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                  />
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={customerDetail?.bState || "--"}
                    readOnly={isTxtFieldDisable}
                    searchEnabled={true}
                    items={StateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={customerDetail?.bCountry ? false : true}
                    id="bState"
                  ></SelectBox>
                  {/* <TextBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={customerDetail?.bState || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                </div>

                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="City "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bCity", e.value)}
                    value={customerDetail?.bCity || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
              {/* <CheckBox
                text={"Shipping address same as billing address"}
                // hint={"Shipping address same as billing address"}
                className="bill_addTxt"
                onValueChanged={chkBoxHandler}
              /> */}
              <div className="header-sub-text">Shipping Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Billing Address 1 "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress1", e.value)
                    }
                    value={customerDetail?.sAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Billing Address 2"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress2", e.value)
                    }
                    value={customerDetail?.sAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sStreet", e.value)
                    }
                    value={customerDetail?.sStreet || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    // onValueChanged={(e) =>
                    //   handleInputChange("sZipCode", e.value)
                    // }
                    onValueChanged={(e) =>
                      handleInputChange("sZipCode", e.value)
                    }
                    value={customerDetail?.sZipCode || "--"}
                    readOnly={true}
                  ></TextBox>

                  {/* <TextBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={customerDetail?.sState || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>

                  <TextBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={customerDetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={customerDetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                  />
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={customerDetail?.sState || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sState"
                    items={ShipStateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={customerDetail?.sCountry ? false : true}
                    searchEnabled={true}
                  ></SelectBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="City "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sCity", e.value)}
                    value={customerDetail?.sCity || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
            </section>

            {/* -----------  Bank Details --------------*/}

            <section id="Details" className="form-section">
              <span className="header-text">Bank Details</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Currency"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    value={customerDetail?.transCry || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    label="Currency "
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={currency}
                    value={customerDetail?.transCry || ""}
                    id="transCry"
                    valueExpr={"currCode"}
                    displayExpr={(item) =>
                      item ? `${item.currCode} - ${item.currName}` : ""
                    }
                    readOnly={isTxtFieldDisable}
                  ></SelectBox>
                  <TextBox
                    label="Bank Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("acctName", e.value)
                    }
                    value={customerDetail?.acctName || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Account Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("account", e.value)
                    }
                    value={customerDetail?.account || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="IFSC Code "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("ifscNum", e.value)
                    }
                    value={customerDetail?.ifscNum || "--"}
                    readOnly={isTxtFieldDisable}
                  />

                  <TextBox
                    label="Swift Code "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("swiftNum", e.value)
                    }
                    value={customerDetail?.swiftNum || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Bank Branch/Address"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("branch", e.value)}
                    value={customerDetail?.branch || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Payment Terms"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value)
                    }
                    value={customerDetail?.pymntTerms || "--"}
                    readOnly={isTxtFieldDisable}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value.toString())
                    }
                    label="Payment Terms"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={paymentTerms}
                    // value={formData?.pymntTerms || ""}
                    value={parseInt(customerDetail?.pymntTerms) || ""}
                    valueExpr={"groupNum"}
                    displayExpr={"pymntGroup"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Communication --------------*/}

            <section id="Communication" className="form-section">
              <span className="header-text">Communication</span>
              <div className="header-sub-text">Primary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPrsn", e.value)
                    }
                    value={customerDetail?.pCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPhone", e.value)
                    }
                    value={customerDetail?.pCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctEmail", e.value)
                    }
                    value={customerDetail?.pCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctDesg", e.value)
                    }
                    value={customerDetail?.pCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
              <div className="header-sub-text">Secondary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPrsn", e.value)
                    }
                    value={customerDetail?.sCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPhone", e.value)
                    }
                    value={customerDetail?.sCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctEmail", e.value)
                    }
                    value={customerDetail?.sCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctDesg", e.value)
                    }
                    value={customerDetail?.sCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Other --------------*/}

            <section id="Others" className="form-section">
              <span className="header-text">Others</span>
              <div className="header-sub-text">Taxation Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row-check">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_IS_MSME", e.value)
                    }
                    label="Micro, Small & Medium Enterprises"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    value={customerDetail?.u_QIT_IS_MSME || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  />
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_TYPE", e.value.toString())
                    }
                    label="MSME Types"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={msmeType}
                    value={customerDetail?.u_QIT_MSME_TYPE || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="MSME Registration No."
                    labelMode="outside"
                    width={"33%"}
                    readOnly={isTxtFieldDisable}
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_REGNO", e.value)
                    }
                    value={customerDetail?.u_QIT_MSME_REGNO || ""}
                  />
                </div>
              </div>
              {/* <div className="header-sub-text">ISO Certificate Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("isoCheck", e.value)
                    }
                    label="Is ISO Certified?"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    // value={formData?.msmeCheck || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                 <TextBox
                    label="ISO no."
                    labelMode="outside"
                    width={"33%"}
                    disabled={
                      formData?.isoCheck
                        ? formData?.isoCheck == "N"
                          ? true
                          : false
                        : true
                    }
                    // onValueChanged={(e) =>
                    //   handleInputChange("msmeRegistrationNo", e.value)
                    // }
                    // value={formData?.msmeCheck || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>

                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload ISO Certificate."
                    )}
                  </label>
                  <input
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    // onChange={handleFileUpload}
                    accept=".pdf"
                    disabled={formData?.isoCheck
                      ? formData?.isoCheck == "N"
                        ? true
                        : false
                      : true}
                  />
                </div>
              </div> */}
              <div className="header-sub-text">More Details</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Sales Employee"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("salesEmp", e.value)
                    }
                    value={customerdetail?.salesEmp || ""}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("salesEmp", e.value.toString())
                    }
                    label="Sales Employee"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={salesEmp}
                    value={customerDetail?.salesEmp || ""}
                    valueExpr={"slpCode"}
                    displayExpr={"slpName"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Broker"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("broker", e.value)}
                    value={customerDetail?.broker || ""}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Remarks"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("remark", e.value)}
                    value={customerDetail?.remark || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Website Link"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("websiteLink", e.value)
                    }
                    value={customerDetail?.websiteLink || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  {customerDetail?.cmpDocs && (
                    <div className="docSection">
                      <label className="text-section">File</label>
                      <div className="doc-sub-section">
                        <a
                          href={customerDetail?.cmpDocs}
                          className="sub-section"
                          onClick={handleClick}
                        >
                          <img src={pdf} alt="PDF icon" />
                          <label className="text">{`QIT_${customerDetail?.customerName}.pdf`}</label>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>

      {approvalPopUp && (
        <ApprovePopup
          headertext={"Approval"}
          subtext={"Add reasons"}
          isVisible={approvalPopUp}
          onHide={handleCloseConformPopup}
          saveFunction={handleApproveBtnClick}
          saveIcon={saveVendorIcon}
          saveBtnTxt={"Approve"}
          handleInputChange={handleApproveComment}
        />
      )}
      {rejectPopUp && (
        <ApprovePopup
          headertext={"Rejection"}
          subtext={"Add reasons"}
          isVisible={rejectPopUp}
          onHide={handleRejectPopup}
          saveFunction={handleRejectBtnClick}
          saveIcon={rejectIcon}
          cancelBtnTxt={"Reject"}
          handleInputChange={handleRejectComment}
        />
      )}
    </>
  );
}

export default CustomerStatus;
