import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import Toolbar, { Item } from "devextreme-react/toolbar";
import "./LoginForm.scss";
import { Button, CheckBox, SelectBox } from "devextreme-react";
import { toastDisplayer } from "../comman card/toast-displayer";
import SVG1 from "../../assets/SVGs/SVG1.svg";
import loginSvg from "../../assets/SVGs/loginSvg.svg";
import { Link } from "react-router-dom";
import { UseConfigContext } from "../../contexts/cofigContext";
export default function LoginForm() {
  const { signIn } = useAuth();
  const [formData, setFormData] = useState({
    role: null,
    username: null,
    password: null,
  });
  // const [userRole, setuserRole] = useState(null);
  // const [userEmail, setuserEmail] = useState(null);
  const [rememberMe, setrememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showpwd, setshowpwd] = useState(false);
  const [passwordMode, setPasswordMode] = useState("password");
  const [logo, setLogo] = useState("");

  const { getSourceData } = UseConfigContext();
  useEffect(() => {

    if (getSourceData[0]?.atcPath != "" ) {

      setLogo(getSourceData[0]?.atcPath);
    }
  }, [getSourceData]);


  const handlePWDChange = (e) => {
    return setFormData({
      ...formData,
      ["password"]: e.value,
    });
  };

  const customerTypes = [
    { id: "V", cType: "Vendor" },
    { id: "C", cType: "Customer" },
    { id: "U", cType: "Vendor & Customer Management" },
  ];

  const handleUserNameChange = (e) => {
    return setFormData({
      ...formData,
      ["username"]: e.value,
    });
  };

  const handleUserRole = (e) => {
    return setFormData({
      ...formData,
      ["role"]: e.selectedItem.id,
    });
  };

  const handleSignin = async () => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData["username"]) {
      return toastDisplayer("error", "Enter User Name");
    }
    // else if (!emailRegex.test(userEmail)) {
    //   return toastDisplayer("error", "Invalid Email Address");
    // }
    if (!formData["role"]) {
      return toastDisplayer("error", "Select Role");
    }
    if (!formData["password"]) {
      return toastDisplayer("error", "Enter Password");
    }

    setLoading(true);
    const result = await signIn(formData["role"], formData);

    if (!result.isOk) {
      setLoading(false);
      return toastDisplayer("error", "Invalid User..!!");
    }
    if (result.isOk) {
      if (rememberMe) {
        const usernameObj = {
          value: formData["username"],
          expiration: new Date().getTime() + 7 * 24 * 60 * 60 * 1000,
        };
        localStorage.setItem("username", JSON.stringify(usernameObj));
      }
    }
  };
  useEffect(() => {
    const Storedusername = localStorage.getItem("username");

    if (Storedusername) {
      const parsedItem = JSON.parse(Storedusername);

      if (new Date().getTime() < parsedItem.expiration) {
        setFormData({
          ...formData,
          ["username"]: parsedItem.value,
        });
      } else {
        localStorage.removeItem("username");
      }
    }
  }, []);

  return (
    <>
      <header className={"header-component-login"}>
        <Toolbar className={"header-toolbar"}>
          <Item location={"before"} cssClass={"header-title logoClass"}>
          {logo?
              <img src={logo} alt="site logo" className="site-logo" height={"50px"} />
              :
              <img src={SVG1} alt="site logo" className="site-logo" />
              }
          </Item>
          <Item
            location={"after"}
            locateInMenu={"auto"}
            cssClass={"link-Section"}
          >
            <div>
              <Link to="/registrationselection">
                <span>New member? </span>
                <span style={{ fontWeight: "500" }}>Register </span>
              </Link>
            </div>
          </Item>
        </Toolbar>
      </header>
      <div className="login-container">
        <div className="login-container-left">
          <img src={loginSvg} alt="Login" className="login-svg" />{" "}
          <div>
            <div>Vendor & Customer</div> <div>Management Portal</div>
          </div>
        </div>
        <div className="login-container-right">
          <div className="login-form">
            <div className="login-container-right-header">
              <div className="header-title">
                <div className="header-main-title">
                  <span>Sign In</span>
                </div>
                <div className="header-sub-title">
                  <span>
                    If you are already a member you can signin with your
                    username and password.
                  </span>
                </div>
              </div>
            </div>
            <div className="login-container-right-body">
              <div className="inputField">
                <SelectBox
                  label="Role"
                  labelMode="outside"
                  dataSource={customerTypes}
                  displayExpr="cType"
                  onSelectionChanged={handleUserRole}
                  valueExpr="id"
                />
              </div>
              <div className="inputField">
                <TextBox
                  label="User Name"
                  labelMode="outside"
                  showClearButton={true}
                  valueChangeEvent="keyup"
                  onValueChanged={handleUserNameChange}
                  value={formData["username"] ? formData["username"] : ""}
                />
              </div>
              <div className="inputField">
                {" "}
                <TextBox
                  label="Password"
                  labelMode="outside"
                  value={formData["password"] ? formData["password"] : ""}
                  valueChangeEvent="keyup"
                  mode={passwordMode}
                  onValueChanged={handlePWDChange}
                  onEnterKey={() => handleSignin()}
                >
                  <TextBoxButton
                    name="password"
                    location="after"
                    options={{
                      icon: `${showpwd ? "eyeopen" : "eyeclose"}`,
                      stylingMode: "text",
                      onClick: () => {
                        setshowpwd(!showpwd);
                        setPasswordMode((prevPasswordMode) =>
                          prevPasswordMode === "text" ? "password" : "text"
                        );
                      },
                    }}
                  />
                </TextBox>
                <CheckBox
                  text="Remember me"
                  onValueChange={() => setrememberMe(!rememberMe)}
                  enableThreeStateBehavior={false}
                />
              </div>
            </div>
            <div className="login-container-right-footer">
              <Button
                text="Sign In"
                width={"100%"}
                height={"44px"}
                onClick={handleSignin}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
