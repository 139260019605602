import React from "react";
import GeneralSettings from "./Panel-Items/General-setttings";

function TabPanelItem({ data }) {
  if (data.title == "General Settings") {
    return (
      <>
        <GeneralSettings />
      </>
    );
  } else {
    return <></>;
  }
}

export default TabPanelItem;
