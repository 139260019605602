import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
 
const NotificationItem = ({ values, updateChkStatus }) => {
  const [showFullText, setShowFullText] = useState(false);
  const navigate = useNavigate();
  const handleClickReadMore = () => {
    setShowFullText(!showFullText);
  };
  const handleClick = (notificationText) => {
    if (notificationText.includes("Vendor")) {
      navigate("/vendor/addvendor");
    }
    if (notificationText.includes("Customer")) {
      navigate("/customer/addCustomer");
    }
  };
  return (
    <div
      className={`notification${
        String(values.chk_Status) === String("0") ? " unread" : ""
      }`}
      key={values.n_Id}
      onClick={() => {
        updateChkStatus(values.n_Id);
      }}
    >
      <div
        onClick={() => {
          handleClick(values.notification_Text);
        }}
      >
        <div className="notify-title">
          {showFullText ? values.notification_Text : values.notification_Text}
          {/* {showFullText ? values.notification_Text : `${values.notification_Text.slice(0, 37)}...`} */}
          {values.notification_Text.length > 37 && (
            <span
              className="read-more"
              style={{ color: "#4371B7" }}
              onClick={handleClickReadMore}
            >
              {showFullText ? " Read less" : " Read more"}
            </span>
          )}
        </div>
        <div className="notify-time">{values.timeLimit}</div>
      </div>
      {String(values.chk_Status) === String("0") && (
        <div className="notify-unread"></div>
      )}
    </div>
  );
};
 
export default NotificationItem;