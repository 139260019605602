import axios from "axios";
import { logToServer } from "./logger";
import { AddNotification } from "./notification";
import axiosInstance from "./axiosInstance";
const API_URL = process.env.REACT_APP_API;
const userData = localStorage.getItem("User");

export async function getAllVendor() {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.get(`${API_URL}/Vendor/GetVendor`);
    if (apiRes.status == 200) {
      await logToServer(
        "Vendor",
        "GetVendor",
        "GetVendor",
        "S",
        "GetVendor success..",
        "",
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      await logToServer(
        "Vendor",
        "GetVendor",
        "GetVendor",
        "E",
        "No data..",
        "",
        userData
      );
      responseBody.errorMessage = "No Data..!!";
    }
  } catch (error) {
    await logToServer(
      "Vendor",
      "GetVendor",
      "GetVendor",
      "E",
      error.StatusMsg,
      "",
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

export async function saveVendor(reqBody) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axios.post(`${API_URL}/Vendor/SaveVendor`, reqBody);
    if (apiRes.status == 200) {
      var req_Body = "";
      if (userData) {
        req_Body = {
          module: "Add Vendor",
          Sender_User_Name: userData,
          notification_Text: `${reqBody.vendorName} Vendor has been added`,
        };
      } else {
        req_Body = {
          module: "Add Vendor",
          notification_Text: `${reqBody.vendorName} Vendor has been added`,
        };
      }
      await AddNotification(req_Body);
      await logToServer(
        "Vendor",
        "SaveVendor",
        "SaveVendor",
        "S",
        "Save Vendor data..!!",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      await logToServer(
        "Vendor",
        "SaveVendor",
        "SaveVendor",
        "E",
        "Error save data..!!",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.errorMessage = "Not Save Data..!!";
    }
  } catch (error) {
    await logToServer(
      "Vendor",
      "SaveVendor",
      "SaveVendor",
      "E",
      "Error save data..!!",
      JSON.stringify(reqBody),
      userData
    );
    console.log("Error : ========> ",error.response.data);
    if (error.response?.data?.errors) {
      const data = error.response.data;
    
      // Check for the 'errors' object
      if (data.errors && typeof data.errors === 'object') {
        const fieldErrors = [];
        
        for (const [field, messages] of Object.entries(data.errors)) {
          messages.forEach(message => {
            fieldErrors.push(`${field}: ${message}`);
          });
        }
    
        responseBody.errorMessage = fieldErrors.join(' | ');
        return responseBody;
      } 

      // Fallback to general error message
      else {
        responseBody.errorMessage = responseBody.errorMessage =  
        error.response?.data?.statusMsg || error.response?.data?.errors || error.message;
        // responseBody.errorMessage = error.message || 'An unknown error occurred.';
      }
    } 
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage =  
      error.response?.data?.statusMsg || error.response?.data?.errors || error.message;
    return responseBody;
  }
}

export async function GetVendorDetail(vendorId) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.get(
      `${API_URL}/Vendor/GetVenDetail?vendorCode=${vendorId}`
    );
    if (apiRes.status == 200) {
      await logToServer(
        "Vendor",
        "GetVenDetail",
        "GetVenDetail",
        "S",
        "Get Vender details..!!",
        JSON.stringify(vendorId),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      await logToServer(
        "Vendor",
        "GetVenDetail",
        "GetVenDetail",
        "E",
        "Error while Get Vender details..!!",
        JSON.stringify(vendorId),
        userData
      );
      responseBody.errorMessage = "Error while fetching..!!";
    }
  } catch (error) {
    await logToServer(
      "Vendor",
      "GetVenDetail",
      "GetVenDetail",
      "E",
      "Error while Get Vender details..!!",
      JSON.stringify(vendorId),
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

export async function verifyVendor(reqBody,vendor) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.put(`${API_URL}/Vendor/VerifyVendor`, reqBody);
    if (apiRes.status == 200) {
      const req_Body = {
        module: "Verify Vendor",
        Sender_User_Name: userData,
        notification_Text: `${vendor} Vendor has been ${
          reqBody.action == "A"
            ? "Approved"
            : reqBody.action == "R"
            ? "Rejected"
            : ""
        }`,
      };

      await AddNotification(req_Body);
      await logToServer(
        "Vendor",
        "VerifyVendor",
        "VerifyVendor",
        "S",
        "VerifyVendor successfully..!!",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      await logToServer(
        "Vendor",
        "VerifyVendor",
        "VerifyVendor",
        "E",
        "Error while VerifyVendor..!!",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.errorMessage = "Not Save Data..!!";
    }
  } catch (error) {
    await logToServer(
      "Vendor",
      "VerifyVendor",
      "VerifyVendor",
      "E",
      error.StatusMsg,
      JSON.stringify(reqBody),
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = 
      error.response?.data?.statusMsg || error.response?.data?.errors || error.message;
    return responseBody;
  }
}

export async function GetVendorDetailByName(vendorName) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.post(`${API_URL}/Vendor/GetVenDetailByName`, {
      VendorName: vendorName,
    });
    if (apiRes.status == 200) {
      await logToServer(
        "Vendor",
        "Vendor",
        "GetVenDetailByName",
        "S",
        "successfully fetching vendor..",
        JSON.stringify(vendorName),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      await logToServer(
        "Vendor",
        "Vendor",
        "GetVenDetailByName",
        "E",
        "Error while fetching..",
        JSON.stringify(vendorName),
        userData
      );
      responseBody.errorMessage = "Error while fetching..!!";
    }
  } catch (error) {
    await logToServer(
      "Vendor",
      "Vendor",
      "GetVenDetailByName",
      "E",
      "Error while fetching..",
      JSON.stringify(vendorName),
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

export async function updateVendor(reqBody) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.put(`${API_URL}/Vendor/UpdateVendor`, reqBody);
    if (apiRes.status == 200) {
      var req_Body = "";
      if (userData) {
        req_Body = {
          module: "Update Vendor",
          Sender_User_Name: userData,
          notification_Text: `${reqBody.vendorName} Vendor has been Updated`,
        };
      } else {
        req_Body = {
          module: "Update Vendor",
          notification_Text: `${reqBody.vendorName} Vendor has been Updated`,
        };
      }
      await AddNotification(req_Body);
      await logToServer(
        "Vendor",
        "Vendor",
        "SaveVendor",
        "S",
        "Successfully Vendor Updated..",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Not Save Data..!!";
    }
  } catch (error) {
    await logToServer(
      "Vendor",
      "Vendor",
      "SaveVendor",
      "E",
      "error while Updating Vendor..",
      JSON.stringify(reqBody),
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}
