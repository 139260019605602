import axios from "axios";
const API_URL = process.env.REACT_APP_API;

export const getCountryData = async (value) => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var SUB_API_URL = "";
    if (value == "S") {
      SUB_API_URL = "GetCountryBySAP";
    } else {
      SUB_API_URL = "GetCountry";
    }
    const response = await axios.get(`${API_URL}/Common/${SUB_API_URL}`);
    responseBody.responseData = response.data;
    responseBody.hasError = false;
    return responseBody;
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const getStateData = async (country_code) => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axios.get(
      `${API_URL}/Common/GetState?country_code=${country_code}`
    );
    responseBody.responseData = response.data;
    responseBody.hasError = false;
    return responseBody;
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const getCityData = async () => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axios.get(
      `${API_URL}/Common/GetAllCity`
    );
    responseBody.responseData = response.data;
    responseBody.hasError = false;
    return responseBody;
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const getPaymentTerms = async () => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axios.get(`${API_URL}/Common/GetPaymentTerms`);
    responseBody.responseData = response.data;
    responseBody.hasError = false;
    return responseBody;
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const GetSAPCurrency = async () => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axios.get(`${API_URL}/Common/GetCurrency`);

    if (response.status === 200) {
      responseBody.hasError = false;
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Error while fetching data..!!";
      return responseBody;
    }
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const GetSAPBankDetail = async () => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axios.get(`${API_URL}/Common/GetBankDetails`);

    if (response.status === 200) {
      responseBody.hasError = false;
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Error while fetching data..!!";
      return responseBody;
    }
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const getSalesEmp = async () => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axios.get(`${API_URL}/Common/GetSalesEmp`);
    responseBody.responseData = response.data;
    responseBody.hasError = false;
    return responseBody;
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const getGstDetail = async (GSTNO) => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axios.get(`${API_URL}/Common/ValidateGSTIN?GSTIN=${GSTNO}`);
    if(response.data){
      const data = response.data;
      if(data.success){
        responseBody.responseData = response.data;
        console.log("response.data : ",response.data);
        responseBody.hasError = false;
      }else{
        responseBody.errorMessage = data.message;
        console.log("response.data : ",response.data);
        responseBody.hasError = true;
      }
    }
    return responseBody;
  } catch (error) {
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};
