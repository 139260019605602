import React, { useCallback, useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
// import { navigation } from "../../../app-navigation";
import { Button, CheckBox, Popup, TreeList } from "devextreme-react";
import TextBox, { Button as TextBoxButton } from "devextreme-react/text-box";
import { helpIcon, saveIcon } from "../../../assets/icon";
import {
  getUserAuthRole,
  getUserData,
  postAuthenticationRule,
} from "../../../api/user-setting";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import "./authorisation.scss";
import { SearchPanel } from "devextreme-react/cjs/data-grid";
import { Column, Item, Toolbar } from "devextreme-react/cjs/tree-list";
import { HelperPopupPage } from "../../../components";
import ConformationPopup from "../../../components/conformation-popup/conformation-popup";
import {
  fakeData,
  navigation_main,
} from "../../../components/Common-Object/common-object";
import { useAuth } from "../../../contexts/auth";
import PageHeader from "../../../components/Page-Header/PageHeader";
const AuthorisationMain = () => {
  const userHelpOptions = {
    icon: helpIcon,
    onClick: () => showPopupHandler(),
  };
  const { AuthRuleContect } = useAuth();
  const [AuthPopUp, setAuthPopUp] = useState(false);
  const [UserData, setUserData] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dataGridRefAuthRule = useRef();
  const [isModuleTreeVisible, setModuleTreeVisible] = useState(false);
  const [userCopyData, setUserCopyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const expandedKeys = [];
  const [selectedKeys, setSelectedKeys] = useState([]);
  const updatedStates = {};
  const [selectedRowKeysOnChangeAuth, setSelectedRowKeysOnChangeAuth] =
    useState([]);
  const [selectedCopyRowKeysOnChangeAuth, setSelectedCopyRowKeysOnChangeAuth] =
    useState([]);
  const [CopyAuthPopUp, CopysetAuthPopUp] = useState(false);
  const [isConformation, setisConformation] = useState(false);
  const [userAuthorizationRule, setuserAuthorizationRule] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});
  const dataGridCopyRefAuthRule = useRef();
  const [selectedCopyRowKeys, setSelectedCopyRowKeys] = useState([]);
  const showPopupHandler = () => {
    setAuthPopUp(true);
  };
  const handleCancel = () => {
    setAuthPopUp(false);
  };

  useEffect(() => {
    const getData = async () => {
      let result = await getUserData();
      if (result.hasError) {
        return toastDisplayer("error", result.errorMessage);
      }
      setUserData(result.responseData);
    };
    getData();
  }, []);

  const handleDataGridRowSelectionAuthRuleUser = async ({
    selectedRowKeys,
  }) => {
    setSelectedRowKeysOnChangeAuth(selectedRowKeys);
    const length = await selectedRowKeys.length;
    if (selectedRowKeys.length > 1) {
      const value = await dataGridRefAuthRule.current.instance.selectRows(
        selectedRowKeys[length - 1]
      );
      return selectedRowSetterApprove(value);
    } else {
      const value = await dataGridRefAuthRule.current.instance.selectRows(
        selectedRowKeys[0]
      );
      return selectedRowSetterApprove(value);
    }
  };
  const selectedRowSetterApprove = async (params) => {
    setSelectedRowKeysOnChangeAuth(params);
  };

  useEffect(() => {
    if (userAuthorizationRule) {
      findTaskIDWithAccess(userAuthorizationRule, fakeData);
    } else {
      fakeData.forEach((task) => {
        updatedStates[task.Task_ID] = {
          C: false,
          R: false,
          U: false,
          D: false,
        };
      });
      setCheckboxStates(updatedStates);
    }
  }, []);

  const copyToPopUpData = (id) => {
    const new_data = UserData.filter((user) => user.user_ID !== id);
    setUserCopyData(new_data);
  };
  function findTaskIDWithAccess(object, data) {
    setCheckboxStates(updatedStates);
    object.map((item) => {
      // if (item.hasAccess) {
      item.items.map((entry) => {
        const taskID = data.find(
          (sub_entry) =>
            sub_entry.Task_Subject.toLowerCase() === entry.text.toLowerCase()
        )?.Task_ID;
        setCheckboxStates((prevStates) => ({
          ...prevStates,
          [taskID]: {
            ...prevStates[taskID],
            C: entry.rightsAccess[0] === "T" ? true : false,
            R: entry.rightsAccess[1] === "T" ? true : false,
            U: entry.rightsAccess[2] === "T" ? true : false,
            D: entry.rightsAccess[3] === "T" ? true : false,
          },
        }));
      });
      // }
    });
  }

  const handleCopyPopupCancel = () => {
    CopysetAuthPopUp(false);
  };

  const handleSave = async () => {
    setSelectedRowKeys(selectedRowKeysOnChangeAuth);
    if (selectedRowKeysOnChangeAuth.length > 0) {
      setAuthPopUp(false);

      copyToPopUpData(selectedRowKeysOnChangeAuth[0].userName);
      const reqData = {
        userName: selectedRowKeysOnChangeAuth[0].userName,
      };
      const getUserAuthRoleRes = await getUserAuthRole(reqData);
      if (!getUserAuthRoleRes.hasError) {
        findTaskIDWithAccess(
          getUserAuthRoleRes.responseData.authentication_Rule,
          fakeData
        );
        setuserAuthorizationRule(
          getUserAuthRoleRes.responseData.authentication_Rule
        );
        // setdisableCopyText(false);
      } else {
        // setdisableCopyText(true);
        fakeData.forEach((task) => {
          updatedStates[task.Task_ID] = {
            C: false,
            R: false,
            U: false,
            D: false,
          };
        });
        setCheckboxStates(updatedStates);
      }
      setModuleTreeVisible(true);
    } else {
      return toastDisplayer("error", "Please select a user");
    }
  };

  const handleCopySave = async () => {
    const res = [];
    await Promise.all(
      selectedCopyRowKeysOnChangeAuth.map(async (element) => {
        const payload = {
          UserName: element,
          moduleCLasses: userAuthorizationRule,
        };
        const apiResponse = await postAuthenticationRule(payload);
        res.push(apiResponse);
      })
    );

    if (res.length > 0) {
      if (res.some((item) => item.hasError)) {
        res.forEach((item) => {
          if (item.hasError) {
            toastDisplayer("error", item.errorMessage);
          }
        });
      } else {
        toastDisplayer("success", "Copy rule successfully...!!");
      }
      CopysetAuthPopUp(false);
    } else {
      CopysetAuthPopUp(false);
      return toastDisplayer("error", "Something went wrong");
    }
  };

  const handleCheckboxValueChanged = useCallback(
    (taskId, action, value) => {
      setCheckboxStates((prevCheckboxStates) => ({
        ...prevCheckboxStates,
        [taskId]: {
          ...prevCheckboxStates[taskId],
          [action]: value,
        },
      }));
    },
    [setCheckboxStates]
  );
  const copyhelpOptions = {
    icon: helpIcon,
    onClick: async () => {
      copyshowPopupHandler();
    },
  };
  const copyshowPopupHandler = () => {
    CopysetAuthPopUp(true);
  };
  const handleAuthData = async () => {
    if (selectedRowKeysOnChangeAuth.length > 0) {
      setLoading(true);
      const finalNavigation = navigation_main.map((item) => {
        const childRights = item.items.map((subItem) => {
          const taskId = fakeData.find(
            (task) => task.Task_Subject === subItem.text
          )?.Task_ID;
          return {
            text: subItem.text,
            path: subItem.path,
            icon: subItem.icon,
            rightsAccess: [
              checkboxStates[taskId]?.C ? "T" : "F",
              checkboxStates[taskId]?.R ? "T" : "F",
              checkboxStates[taskId]?.U ? "T" : "F",
              checkboxStates[taskId]?.D ? "T" : "F",
            ],
          };
        });

        const parentHasAccess = childRights.some((child) =>
          child.rightsAccess.includes("T")
        );

        return {
          text: item.text,
          path: item.path,
          icon: item.icon,
          hasAccess: parentHasAccess,
          items: childRights,
        };
      });

      const payload = {
        userName: selectedRowKeysOnChangeAuth[0].userName,
        moduleCLasses: finalNavigation,
      };
      const apiResponse = await postAuthenticationRule(payload);
      if (apiResponse.hasError) {
        setisConformation(false);
        return toastDisplayer("error", apiResponse.errorMessage);
      } else {
        setisConformation(false);
        setSelectedRowKeys([]);
        setSelectedRowKeysOnChangeAuth([]);
        setModuleTreeVisible(false);
        setLoading(false);
        return toastDisplayer("success", apiResponse.responseData.statusMsg);
      }
    } else {
      setisConformation(false);
      setLoading(false);
      return toastDisplayer("error", "Please select a user");
    }
  };

  const handleDataGridCopyRowSelectionAuthRuleUser = async ({
    selectedRowKeys,
  }) => {
    setSelectedCopyRowKeysOnChangeAuth(selectedRowKeys);
  };

  const handleCloseConformPopup = () => {
    setisConformation(false);
  };
  const handleShowConformPopup = () => {
    setisConformation(true);
  };

  const btnOption = {
    text: "Authorise",
  };
  return (
    <>
      <ConformationPopup
        headertext={"Authorise Module to User"}
        subtext={"Are you sure you want to authorise modules to user?"}
        isVisible={isConformation}
        onHide={handleCloseConformPopup}
        saveFunction={handleAuthData}
        saveIcon={saveIcon}
        cancelBtnTxt="No"
        saveBtnTxt="Save"
      />
      {AuthPopUp && (
        <Popup
          visible={true}
          height={window.innerHeight - 100}
          showCloseButton={false}
          className="initate-popup-css"
          showTitle={false}
          contentRender={() => (
            <HelperPopupPage
              title={"User Details"}
              caption={"Choose the user"}
              handleCancel={handleCancel}
              handleSave={handleSave}
              datagridData={UserData}
              keyExpr={"userName"}
              handleDataGridRowSelection={
                handleDataGridRowSelectionAuthRuleUser
              }
              dataGridRef={dataGridRefAuthRule}
              selectedRowKeys={selectedRowKeys}
            />
          )}
        ></Popup>
      )}
      {CopyAuthPopUp && (
        <Popup
          visible={true}
          height={window.innerHeight - 100}
          showCloseButton={false}
          className="initate-popup-css"
          showTitle={false}
          contentRender={() => (
            <HelperPopupPage
              title={"Apply Same Authorisation Rule to Users"}
              caption={"Apply rules to users"}
              handleCancel={handleCopyPopupCancel}
              handleSave={handleCopySave}
              datagridData={userCopyData}
              keyExpr={"userName"}
              handleDataGridRowSelection={
                handleDataGridCopyRowSelectionAuthRuleUser
              }
              dataGridRef={dataGridCopyRefAuthRule}
              selectedRowKeys={selectedCopyRowKeys}
            />
          )}
        ></Popup>
      )}
      {/* <div className="content-header-block">
        <div
          className="content-header-block-breadcrumbs"
          style={{ width: "100%" }}
        >
          <Breadcrumbs navigation={AuthRuleContect} />
        </div>

        <div className="content-header-block-inner">
          <label>Authorise the User</label>
          <Button
            width={144}
            height={48}
            text="Authorise"
            // onClick={handleApprovalRequest}
            disabled={!isModuleTreeVisible}
            onClick={handleShowConformPopup}
          ></Button>
        </div>
      </div> */}
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={true}
        pageTitle={"Authorise the User"}
        clickHandler={handleShowConformPopup}
        btnOption={btnOption}
      />
      <div className="main-content-div">
        <div className="auth-title-section">
          <TextBox
            label="User Name"
            labelMode="outside"
            placeholder="Input text"
            width={"40%"}
            className="seachBox"
            value={
              selectedRowKeysOnChangeAuth.length > 0
                ? selectedRowKeysOnChangeAuth[0].userName
                : ""
            }
          >
            <TextBoxButton
              name="popupSearch"
              location="after"
              options={userHelpOptions}
              height={44}
              width={44}
              className="searchicon"
              style={{ "background-color": "#f6f6f6" }}
            />
          </TextBox>
        </div>
      </div>

      <div className="auth-container">
        {isModuleTreeVisible && (
          <div className="content-block">
            <div className="navigation-header-create-pro">
              {loading ? (
                // <Triangleloader />
                ""
              ) : (
                <>
                  <TreeList
                    dataSource={fakeData}
                    keyExpr="Task_ID"
                    parentIdExpr="Task_Parent_ID"
                    defaultExpandedRowKeys={expandedKeys}
                    defaultSelectedRowKeys={selectedKeys}
                    showRowLines={true}
                    showColumnLines={false}
                    columnAutoWidth={true}
                    className="tree-list-main"
                  >
                    <SearchPanel visible={true} />
                    <Column
                      dataField="Task_Subject"
                      caption="Modules"
                      width={300}
                    />
                    <Column dataField="Task_ID" visible={false} />
                    <Column
                      caption="Authorise"
                      // caption="Create"
                      alignment={"center"}
                      cellRender={(cellData) => {
                        const isSubRow = cellData.data.Task_Parent_ID !== 0;
                        if (isSubRow) {
                          return (
                            <>
                              <CheckBox
                                value={checkboxStates[cellData.data.Task_ID]?.C}
                                onValueChanged={(e) => {
                                  handleCheckboxValueChanged(
                                    cellData.data.Task_ID,
                                    "C",
                                    e.value
                                  );
                                }}
                              />
                            </>
                          );
                        }
                        return null;
                      }}
                    />
                    <Column
                      caption="Read"
                      visible={false}
                      alignment={"center"}
                      cellRender={(cellData) => {
                        const isSubRow = cellData.data.Task_Parent_ID !== 0;
                        if (isSubRow) {
                          return (
                            <div>
                              <CheckBox
                                value={checkboxStates[cellData.data.Task_ID]?.R}
                                onValueChanged={(e) => {
                                  handleCheckboxValueChanged(
                                    cellData.data.Task_ID,
                                    "R",
                                    e.value
                                  );
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Column
                      visible={false}
                      caption="Update"
                      alignment={"center"}
                      cellRender={(cellData) => {
                        const isSubRow = cellData.data.Task_Parent_ID !== 0;
                        if (isSubRow) {
                          return (
                            <div>
                              <CheckBox
                                value={checkboxStates[cellData.data.Task_ID]?.U}
                                onValueChanged={(e) => {
                                  handleCheckboxValueChanged(
                                    cellData.data.Task_ID,
                                    "U",
                                    e.value
                                  );
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Column
                      visible={false}
                      caption="Delete"
                      alignment={"center"}
                      cellRender={(cellData) => {
                        const isSubRow = cellData.data.Task_Parent_ID !== 0;
                        if (isSubRow) {
                          return (
                            <div>
                              <CheckBox
                                value={checkboxStates[cellData.data.Task_ID]?.D}
                                onValueChanged={(e) => {
                                  handleCheckboxValueChanged(
                                    cellData.data.Task_ID,
                                    "D",
                                    e.value
                                  );
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Toolbar className="header-toolbar-modules">
                      <Item location="before">
                        <div className="informer">
                          <span className="sub-text">All Modules</span>
                        </div>
                      </Item>
                      <Item name="searchPanel" />
                      <Item location="after">
                        <TextBox
                          placeholder="Pre-set Rule"
                          width={168}
                          className="report-right"
                        >
                          <TextBoxButton
                            name="popupSearch"
                            location="after"
                            options={copyhelpOptions}
                            height={44}
                            className="popup-icon"
                          />
                        </TextBox>
                      </Item>
                    </Toolbar>
                  </TreeList>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AuthorisationMain;
