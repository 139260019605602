// import { UseHeaderContext } from "../../contexts/headerContext";
// import "./NotificationDropdown.scss";
// import { useEffect, useState } from "react";

// function NotificationDropdown({ notificationData, updateChkStatus, countnotification, countAllnotification, GetALLNotificationData }) {
//   const [rotation, setRotation] = useState(0);
//   const { notifyDropdownRef, setisNotifyDropdownOpen } = UseHeaderContext();

//   // const [notificationData, setNotificationData] = useState([]);

//   const rotateButton = () => {
//     setRotation(rotation + 360);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         notifyDropdownRef.current &&
//         !notifyDropdownRef.current.contains(event.target)
//       ) {
//         const isIconClicked = event.target.classList.contains("bell-icon");
//         if (
//           !isIconClicked ||
//           notifyDropdownRef.current.contains(event.target)
//         ) {
//           setisNotifyDropdownOpen((prev) => !prev);
//         }
//       }
//     };

//     document.addEventListener("click", handleClickOutside);

//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, [notifyDropdownRef, setisNotifyDropdownOpen]);

//   return (
//     <div className="dropdown-background">
//       <div className="notifydropdown" ref={notifyDropdownRef}>
//         <div className="notifydropdown-header">
//           <div>
//             <div className="heading">Notifications</div>
//             <div className="sub-heading">
//               All the notifications at one place!
//             </div>
//           </div>
//           <span
//             className="rfcbtn material-symbols-outlined"
//             onClick={rotateButton}
//             style={{ transform: `rotate(${rotation}deg)` }}
//             title="Refresh"
//           >
//             cached
//           </span>
//         </div>
//         <div className="notifydropdown-body">
//           {notificationData.map((notification, key) => (
//             // <div
//             //   className={`notification ${notification.unread ? "unread" : ""}`}
//             //   key={key}
//             // >
//             //   <div>
//             //     <div className="notify-title">
//             //       {notification.validationRuleName}
//             //     </div>
//             //     <div className="notify-time">{notification.module}</div>
//             //     <div className="notify-time">{notification.message}</div>
//             //   </div>
//             //   {notification.unread && <div className="notify-unread"></div>}
//             // </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default NotificationDropdown;

import "./NotificationDropdown.scss";
import { useEffect, useState } from "react";
import Tabs, { Item as TabItem } from "devextreme-react/tabs";
import { UseHeaderContext } from "../../contexts/headerContext";
import NotificationItem from "./NotificationItem";
const TabsContent = ({
  setSelectedTab,
  countAllnotification,
  countnotification,
}) => {
  const [selectedTab1, setSelectedTab1] = useState(0);
  const onItemClick = (e) => {
    setSelectedTab(e.itemIndex);
    setSelectedTab1(e.itemIndex);
  };
  return (
    <Tabs
      width={300}
      selectedIndex={selectedTab1}
      onItemClick={onItemClick}
      id="selectTab"
    >
      <TabItem text="Today">
        {countnotification > 0
          ? "Today ( " + countnotification + " )"
          : "Today"}
      </TabItem>
      <TabItem text="ALL">
        {countAllnotification > 0
          ? "ALL ( " + countAllnotification + " )"
          : "ALL"}
      </TabItem>
    </Tabs>
  );
};

function NotificationDropdown({
  notificationData,
  readAllnotification,
  updateChkStatus,
  countnotification,
  countAllnotification,
  GetALLNotificationData,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [expandedNotifications, setExpandedNotifications] = useState({}); // Track expanded notifications
  const { notifyDropdownRef, setisNotifyDropdownOpen } = UseHeaderContext();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notifyDropdownRef.current &&
        !notifyDropdownRef.current.contains(event.target)
      ) {
        const isIconClicked = event.target.classList.contains("bell-icon");
        if (
          !isIconClicked ||
          notifyDropdownRef.current.contains(event.target)
        ) {
          setisNotifyDropdownOpen((prev) => {
            return !prev;
          });
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [notifyDropdownRef, setisNotifyDropdownOpen]);

  const handleClickReadMore = (n_Id) => {
    setExpandedNotifications((prevExpanded) => ({
      ...prevExpanded,
      [n_Id]: !prevExpanded[n_Id], // Toggle expanded state for the specific notification ID
    }));
  };

  return (
    <div className="dropdown-background">
      <div className="notifydropdown" ref={notifyDropdownRef}>
        <div className="notifydropdown-header">
          <div>
            <div className="heading">Notifcations</div>
            <div className="sub-heading">
              All the notifications at one place!
            </div>
          </div>
          <span className="readall-btn" onClick={readAllnotification}>
            Read All
          </span>
        </div>
        <TabsContent
          setSelectedTab={setSelectedTab}
          countnotification={countnotification}
          countAllnotification={countAllnotification}
        />
        <div className="notifydropdown-body">
          {selectedTab === 0
            ? notificationData.map((values) => (
                <>
                  <NotificationItem
                    key={values.n_Id}
                    values={values}
                    updateChkStatus={updateChkStatus}
                    onClickReadMore={handleClickReadMore}
                    expanded={expandedNotifications[values.n_Id]}
                  />
                </>
              ))
            : GetALLNotificationData.map((values) => (
                <NotificationItem
                  key={values.n_Id}
                  values={values}
                  updateChkStatus={updateChkStatus}
                  onClickReadMore={handleClickReadMore}
                  expanded={expandedNotifications[values.n_Id]}
                />
              ))}
        </div>
      </div>
    </div>
  );
}

export default NotificationDropdown;
