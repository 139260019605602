import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { toastDisplayer } from "../../api/qrgenerators";
import { useNavigation } from "../../contexts/navigation";
import debounce from "lodash/debounce";
import "./main-cards.scss";
import { useScreenSize } from "../../utils/media-query";
// import { UseHeaderContext } from "../../contexts/headerContext";

export default function MainCard({ title, description, icon, path, arrow }) {
  // Skeleton Loader
  const [loading, setLoading] = useState(true);

  const loaderStter = () => {
    return setLoading(false);
  };

  const showSimmer = debounce(loaderStter, 100);

  useEffect(() => {
    showSimmer();
  }, [showSimmer]);

  const { setNavigationData } = useNavigation();

  const handleNavigationClick = (data) => {
    setNavigationData(data);
  };

  // const { isTreeviewOpen } = UseHeaderContext();
  const { isLarge } = useScreenSize();
  const [cardWidth, setcardWidth] = useState(null);

  useEffect(() => {
    setcardWidth(isLarge ? "card-lg" : "card-md");
  }, [isLarge]);
  // useEffect(() => {
  //   setcardWidth(isLarge && isTreeviewOpen ? "card-lg" : "card-md");
  // }, [isLarge, isTreeviewOpen]);

  return (
    <>
      <div className={`dx-card `}>
        {!loading ? (
          <>
            <Link to={path} onClick={() => handleNavigationClick(path)}>
              <div className="card-header">
                <div className={"title"}>
                  <span className={"material-symbols-outlined card-icon"}>
                    {icon}
                  </span>
                  <div className={"heading"}>{title}</div>
                  <span className={"material-symbols-outlined arrow-up"}>
                    north_east
                  </span>
                </div>
              </div>
            </Link>
          </>
        ) : (
          <>
            <div className={"pin skeleton"}></div>
            <div className="card-header">
              <div className={"title skeleton"}></div>
            </div>
            <div className="card-body skeleton"></div>
            <div className="card-footer">
              <div className={"refresh-part skeleton"}></div>
              <div className={"number-part skeleton"}></div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
