import React, { useEffect, useState } from "react";
import { Button, SelectBox, TextBox } from "devextreme-react";
import UserImage from "../../assets/images/user.png";
import "./profile.scss";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import Breadcrumbs from "../../components/Bread-crums/BreadCrumbs";
import { toastDisplayer } from "../../components/comman card/toast-displayer";
import { editUserDetails, getUserDetails } from "../../api/user-setting";
import { useAuth } from "../../contexts/auth";
import PageHeader from "../../components/Page-Header/PageHeader";
import ConformationPopup from "../../components/conformation-popup/conformation-popup";
import { saveIcon } from "../../assets/icon";

export default function Profile() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedPass, setIsExpandedPass] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { AuthRuleContect, setUser, user } = useAuth();
  const [image, setImage] = useState();
  const [dispimage, setDispImage] = useState();
  const [userName, setUsername] = useState();
  const [oldpassword, setoldPassword] = useState();
  const [newpassword, setnewPassword] = useState();
  const [usergender, setUserGender] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [isConformation, setisConformation] = useState(false);
  // const [isChangeFound,setIsChangeFound] = useState(false);
  // const [isInitialRender, setIsInitialRender] = useState(true);
  const [formData, setFormData] = useState({
    userEmail: null,
    username: null,
    mobileNo: 0,
    gender: null,
    profilePicture: null,
  });
  const [oldformData, setoldFormData] = useState({
    userEmail: null,
    username: null,
    mobileNo: 0,
    gender: null,
    profilePicture: null,
  });
  const compareObjects = (obj1, obj2) => {
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return true; // Returns true if any property is different
      }
    }
    return false; // Returns false if all properties are same
  };

  // useEffect(() => {
  //   if (isInitialRender) {
  //     setIsInitialRender(false);
  //     return;
  //   }
  //   // Check if any property of formData has changed
  //   const isChanged = Object.keys(formData).some(key => {
  //     return formData[key] !== null && formData[key] !== undefined;
  //   });
  //   // Update formDataChanged state
  //   setIsChangeFound(isChanged);
  // }, [formData,isInitialRender]);

  const handleImageClick = (e) => {
    e.preventDefault();
    document.getElementById("profileImage").click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // setIsChangeFound(true);
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result);
        setFormData({
          ...formData,
          ["profilePicture"]: reader.result.split(",")[1],
        });
        setDispImage(reader.result.split(",")[1]);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageHover = (isHovering) => {
    setIsHovered(isHovering);
  };

  const handleEditProfile = async () => {
    var response = await editUserDetails(formData);

    if (!response.hasError) {
      setoldPassword("");
      setnewPassword("");
      const updatedData = { ...user, Profile: formData["profilePicture"] };

      setUser(updatedData);
      return toastDisplayer("success", "User Details Edited Successfully..!!");
    }
    if (
      response.errorMessage &&
      response.errorMessage.includes("Incorrect password")
    ) {
      return toastDisplayer("error", "Incorrect Old Password..!!");
    } else {
      return toastDisplayer("error", response.errorMessage);
    }
  };
  const gender = ["Male", "Female", "Prefer not to say"];

  const handleUserMobileChange = (e) => {
    // setIsChangeFound(true);
    return setFormData({
      ...formData,
      ["mobileNo"]: e.value,
    });
  };
  const handleGender = (e) => {
    // setIsChangeFound(true);
    setFormData({
      ...formData,
      ["gender"]: e.itemData,
    });
  };
  const handleEmail = (e) => {
    // setIsChangeFound(true);
    return setFormData({
      ...formData,
      ["userEmail"]: e.itemData,
    });
  };

  useEffect(() => {
    const getUserData = async () => {
      var response = await getUserDetails();
      if (!response.hasError) {
        setImage(
          "data:image/png;base64," + response.responseData[0].profilePicture
        );
        setFormData({
          ...formData,
          ["userEmail"]: response.responseData[0].userEmail,
          ["username"]: response.responseData[0].userName,
          ["mobileNo"]: response.responseData[0].mobileNo,
          ["gender"]: response.responseData[0].gender,
          ["profilePicture"]: response.responseData[0].profilePicture,
        });
        setoldFormData({
          ...formData,
          ["userEmail"]: response.responseData[0].userEmail,
          ["username"]: response.responseData[0].userName,
          ["mobileNo"]: response.responseData[0].mobileNo,
          ["gender"]: response.responseData[0].gender,
          ["profilePicture"]: response.responseData[0].profilePicture,
        });
      }
    };
    getUserData();
  }, []);

  const btnOption = {
    icon: "",
    text: "Save",
  };
  const handleCloseConformPopup = () => {
    setisConformation(false);
  };
  const handleShowConformPopup = () => {
    const isInitialRender = compareObjects(formData, oldformData);
    if (isInitialRender) {
      setisConformation(true);
    } else {
      return toastDisplayer("error", "No change found..!!");
    }
  };
  return (
    <React.Fragment>
      <ConformationPopup
        headertext={"Save Changes"}
        subtext={"Are you sure you want to save changes? "}
        isVisible={isConformation}
        onHide={handleCloseConformPopup}
        saveFunction={handleEditProfile}
        saveIcon={saveIcon}
        cancelBtnTxt="No"
        saveBtnTxt="Save"
      />
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={true}
        pageTitle={"Your Profile"}
        clickHandler={handleShowConformPopup}
        btnOption={btnOption}
      />
      {/* <div className="profile-content-header-block">
        <div
          className="content-header-block-breadcrumbs"
          style={{ width: "100%" }}
        >
          <Breadcrumbs navigation={AuthRuleContect} />
        </div>

        <div className="profile-content-header-block-inner">
          <span>Your Profile</span>
          <Button
            text="Add"
            type="default"
            width={124}
            height={44}
            onClick={handleEditProfile}
          />
        </div>
      </div> */}

      <div className="main-content-div">
        <div className="profile-title-section">
          <div
            className={`user-image-container ${isHovered ? "hovered" : ""}`}
            onMouseEnter={() => handleImageHover(true)}
            onMouseLeave={() => handleImageHover(false)}
          >
            <label htmlFor="profileImage">
              <img
                src={user["Profile"] != null ? image : UserImage}
                alt="Profile"
              />
            </label>
            {isHovered && (
              <div className="hover-icon" onClick={handleImageClick}>
                <AddAPhotoOutlinedIcon />
              </div>
            )}
            <input
              type="file"
              id="profileImage"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <div className="profile-detalis">
            <div className="doc-num">
              <span>{userName}</span>
            </div>
            <div className="sub-text">
              <span style={{ fontWeight: "400px", fontSize: "18px" }}>
                {formData["username"]}
              </span>
              {/* <span>{formData['username']}</span> */}
            </div>
            <div className="sub-text">
              Email : {formData["userEmail"]}
              {/* Email {`: ${formData['userEmail']}`} */}
            </div>
            <div className="sub-text">
              Gender : {formData["gender"]}
              {/* Gender {`: ${formData['gender']}`} */}
            </div>
          </div>
        </div>
      </div>

      <div className="edit-content-block">
        <div className="expandable-text">
          <span style={{ fontWeight: "400", fontSize: "18px" }}>
            Edit Profile
          </span>
        </div>
        <div className="user-popup">
          <div className="popup-body-main-container">
            <TextBox
              placeholder="User Email ID"
              label="Email Address"
              labelMode="outside"
              width={"33%"}
              showClearButton={true}
              valueChangeEvent="keyup"
              readOnly={true}
              onValueChanged={handleEmail}
              value={formData["userEmail"] ? formData["userEmail"] : ""}
            />
            <TextBox
              placeholder="Mobile no"
              width={"33%"}
              label="Mobile No."
              labelMode="outside"
              showClearButton={true}
              valueChangeEvent="keyup"
              onValueChanged={handleUserMobileChange}
              value={formData["mobileNo"] ? formData["mobileNo"] : ""}
            />
            <SelectBox
              items={gender}
              width={"33%"}
              labelMode="outside"
              label={"Gender"}
              onItemClick={handleGender}
              text={formData["gender"] ? formData["gender"] : "Select Gender"}
            ></SelectBox>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
