import { Routes, Route, Navigate } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./components";
import { useAuth } from "./contexts/auth";
import { useEffect, useState } from "react";
import { ProfilePage } from "./pages";
import CryptoJS from "crypto-js";
const SECRET = process.env.REACT_APP_SECRET;
export default function Content() {
  const { AuthRuleContect } = useAuth();

  const [userRoutes, setUserRoutes] = useState([]);

  function extractPaths(data) {
    const paths = [];

    for (const item of data) {
      paths.push(item.path);

      if (item.items) {
        paths.push(...extractPaths(item.items));
      }
    }

    return paths;
  }

  useEffect(() => {
    const paths = extractPaths(AuthRuleContect);

    const filteredObjects = routes.filter((obj) => {
      return paths.some((path) => obj.path.includes(path));
    });
    setUserRoutes(filteredObjects);
  }, [AuthRuleContect]);

  const storedData = localStorage.getItem("prevPath");
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        {userRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route
          path="*"
          element={
            <Navigate
              to={
                storedData || (userRoutes && userRoutes.length > 0)
                  ? userRoutes[0]?.path
                  : "home"
              }
            />
          }
        />
        <Route path="/Profile" element={<ProfilePage />} />
      </Routes>
      <Footer>
        Copyright © {new Date().getFullYear()}-{new Date().getFullYear() + 1}{" "}
        Quantum It Solution
      </Footer>
    </SideNavBarLayout>
  );
}
