import React, { useEffect, useState } from "react";
import "./RegistrationSelection.scss";
import Logo from "../../assets/SVGs/SVG1.svg";
import Login2 from "../../assets/SVGs/Login2.svg";
import { Button } from "devextreme-react";
import { useNavigate } from "react-router-dom";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Link } from "react-router-dom";
import { UseConfigContext } from "../../contexts/cofigContext";
export const RegistrationSelection = () => {
  const [logo, setLogo] = useState("");
  const navigate = useNavigate();

  const hanldeCustomerNavigate = () => {
    return navigate("/customerregistration");
  };
  const hanldeVendorNavigate = () => {
    return navigate("/vendorregistration");
  };
  const { getSourceData } = UseConfigContext();
  useEffect(() => {

    if (getSourceData[0]?.atcPath != "" ) {

      setLogo(getSourceData[0]?.atcPath);
    }
  }, [getSourceData]);
  return (
    <div className="container">
      <header className={"header-component-login"}>
        <Toolbar className={"header-toolbar"}>
          <Item location={"before"} cssClass={"header-title logoClass"}>
          {logo?
              <img src={logo} alt="site logo" className="site-logo" height={"50px"} />
              :
              <img src={Logo} alt="site logo" className="site-logo" />
              }
          </Item>
          <Item
            location={"after"}
            locateInMenu={"auto"}
            cssClass={"link-Section"}
          >
            <div>
              <Link to="/login">
                <span>Already a member? </span>
                <span style={{ fontWeight: "500" }}>Sign In</span>
              </Link>
            </div>
          </Item>
        </Toolbar>
      </header>
      {/* <div className="Header">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="Links">
          <Link to={"/login"}>
            <span>Already a member? </span>
            <span className="sign-links">Sign In</span>
          </Link>
        </div>
      </div> */}
      <div className="main-body">
        <div className="Left-Side">
          <div className="Image-title">
            <span className="frist-Line">Vendor & Customer </span>
            <span className="second-line">Management Portal</span>
          </div>
          <div className="image">
            <img src={Login2} alt="Logo" />
          </div>
        </div>
        <div className="Right-Side">
          <div className="selection-area">
            <div className="selection-area-section title-section">
              <span className="text1">Registration</span>
              <span className="text2">
                Before you proceed, please select the category you want to
                continue with.
              </span>
            </div>
            <div className="selection-area-section choosing-area">
              <div className="discription-section">
                <span className="title">As a Vendor</span>
                <span className="sub-title">Proceed to register as vendor</span>
              </div>
              <div className="arrow-btn">
                <Button
                  icon="arrowright"
                  height={"44px"}
                  width={"44px"}
                  className="forward-btn"
                  onClick={hanldeVendorNavigate}
                />
              </div>
            </div>
            <div className="selection-area-section choosing-area">
              <div className="discription-section">
                <span className="title">As a customer</span>
                <span className="sub-title">
                  Proceed to register as customer
                </span>
              </div>
              <div className="arrow-btn">
                <Button
                  icon="arrowright"
                  height={"44px"}
                  width={"44px"}
                  className="forward-btn"
                  onClick={hanldeCustomerNavigate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
