import {
  Button,
  Popup,
  SelectBox,
  TextBox,
  Button as TextBoxButton,
} from "devextreme-react";
import React, { useEffect, useState } from "react";
//   import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
//   import { navigation } from "../../../app-navigation";
//   import "./addCustomer.scss";
//   import ApprovalPopupContent from "../../../components/popup/Popup-component";
import { HashLink as Link } from "react-router-hash-link";
import ApprovalPopupContent from "../../../components/popup/Popup-component";
import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
import { useAuth } from "../../../contexts/auth";
import { requestAddressFromPin } from "../../../api/registerCustomer";
// import { navigation } from "../../../app-navigation";
import pdf from "../../../assets/images/pdf.png";
import { useNavigate } from "react-router-dom";
import { GetCustomerDetailByName } from "../../../api/customer";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import PageHeader from "../../../components/Page-Header/PageHeader";
import { getAllCategory } from "../../../api/setting";
import {
  GetSAPCurrency,
  getCountryData,
  getPaymentTerms,
  getSalesEmp,
  getStateData,
} from "../../../api/commonAPI";
import { allCurrencies } from "../../../utils/currency";
import { UseConfigContext } from "../../../contexts/cofigContext";
function CustomerDetailsMain() {
  const [showPopup, setShowPopup] = useState(false);
  const { AuthRuleContect } = useAuth();
  const [isTxtFieldDisable, setIsTxtFieldDisable] = useState(true);
  const [customerDetail, setVendorDetail] = useState([]);
  const [activeLink, setActiveLink] = useState("Reason");
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    createdByUserName: user["UserData"],
  });
  const navigate = useNavigate();

  const { getSourceData } = UseConfigContext();
  const [sourceConfigData, setSourceConfigData] = useState(getSourceData[0]);
  const [currency, setCurrency] = useState([]);

  const handleApprovalRequest = () => {
    setShowPopup(true);
  };
  const handleEdit = () => {
    // setIsTxtFieldDisable(false);
    navigate("/DetailCustomer/editCustomerDetails", {
      state: { customerDetail, From: "C" },
    });
  };
  const hanldePopupClose = () => {
    setShowPopup(false);
  };
  const handleLinkClick = (activeLinkName) => {
    setActiveLink(activeLinkName);
  };
  const handleScroll = () => {
    const sections = document.querySelectorAll(".form-section");
    let foundAnySection = false;

    sections.forEach((section) => {
      const bounding = section.getBoundingClientRect();
      if (bounding.top >= 0 && bounding.top <= window.innerHeight) {
        if (!foundAnySection) {
          setActiveLink(section.id);
          foundAnySection = true;
        }
      }
    });
  };
  const handleInputChange = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    if (fieldName == "bCountry" || fieldName == "sCountry") {
      getCountryWiseStateData(value, fieldName);
    }
  };
  const [categoryData, setCategoryData] = useState([]);
  const getCatData = async () => {
    var apiRes = await getAllCategory();
    if (!apiRes.hasError) {
      setCategoryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };
  const [paymentTerms, setPaymentTerms] = useState([]);

  const getPayemntTerms = async () => {
    var apiRes = await getPaymentTerms();
    if (!apiRes.hasError) {
      setPaymentTerms(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const [salesEmp, setSalesEmp] = useState([]);

  const getSalesEmpdata = async () => {
    var apiRes = await getSalesEmp();
    if (!apiRes.hasError) {
      setSalesEmp(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  useEffect(() => {
    getPayemntTerms();
    getCatData();
    getSalesEmpdata();

    getAllContryData();
    getCurrencyData();
  }, []);

  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [ShipStateData, setShipStateData] = useState([]);

  const getAllContryData = async () => {
    var apiRes = "";
    if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
      apiRes = await getCountryData();
    } else {
      // SAP
      apiRes = await getCountryData();
    }
    if (!apiRes.hasError) {
      setCountryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCountryWiseStateData = async (country_code, fieldName) => {
    if (country_code != "" && country_code != null) {
      var apiRes = "";
      if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
        apiRes = await getStateData(country_code);
      } else {
        // SAP
        apiRes = await getStateData(country_code);
      }
      if (!apiRes.hasError) {
        if (fieldName == "bCountry") {
          setStateData(apiRes.responseData);
        }
        if (fieldName == "sCountry") {
          setShipStateData(apiRes.responseData);
        }
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const getCurrencyData = async () => {
    if (sourceConfigData && sourceConfigData["currencySource"] == "L") {
      setCurrency(allCurrencies);
    } else {
      var apiRes = await GetSAPCurrency();
      if (!apiRes.hasError) {
        setCurrency(apiRes.responseData);
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const msmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];
  const ismsmeType = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];

  const vendorType = [
    { value: "E", text: "Export" },
    { value: "D", text: "Domestic" },
  ];
  const customerTypes = [
    { id: "I", cType: "International" },
    { id: "D", cType: "Domestic" },
  ];

  const helpOptions = {
    icon: "HelpIcons",
    onClick: async () => {
      // function goes here of sending email
    },
  };

  const handleClick = () => {
    let pdfWindow = window.open(customerDetail?.cmpDocs);
    if (pdfWindow) {
      pdfWindow.document.write(`<body>
        <embed src="${customerDetail?.cmpDocs}" width="100%" height="100%">
        </body>
      `);
    }
  };

  useEffect(() => {
    // setVendorDetail
    const getCustomerDetailsByName = async (userName) => {
      let data = await GetCustomerDetailByName(userName);
      if (data.hasError) {
        return toastDisplayer("error", data.errorMessage);
      } else {
        setVendorDetail(data.responseData[0]);
      }
    };
    getCustomerDetailsByName(user["UserData"]);
  }, []);

  const btnOption = {
    icon: "",
    text: "Edit Details",
  };

  return (
    <>
      <Popup
        visible={showPopup}
        height={"250px"}
        width={"500px"}
        className="request-popup"
      >
        {/* <div className="popup-container">

  <div className="PopupHeader">
    <div className="header-icon"><RoundaboutRight /></div>
    <div className="header-close-btn"><Button icon="close" height={44} onClick={hanldePopupClose}/></div>
  </div>

  <div className="PopupBody">
    <div className="body-main-container">
    <span>Send for Approval</span>
    </div>
    <div className="body-sub-container">
      Are you sure you want to send for Approval ?
    </div>
  </div>

  <div className="popupfooter">
    <Button text="Discard" width={216} height={44} className="popop-discard-btn" style={{backGroundColor:"white"}}/>
    <Button text="Send For Approval" width={216} height={44} className="popop-confirm-btn" />
  </div>

  </div> */}

        <ApprovalPopupContent
          hanldePopupClose={hanldePopupClose}
          headertext={"Send for Approval"}
          headerSubText={"Are you sure you want to send for approval?"}
        ></ApprovalPopupContent>
      </Popup>
      {/* <div className="content-header-block form-style">
        <div
          className="content-header-block-breadcrumbs"
          style={{ width: "100%" }}
        >
          <Breadcrumbs navigation={AuthRuleContect} />
        </div>

        <div className="content-header-block-inner ">
          <label className="section-title-header">Customer Form</label>
          <Button
            width={144}
            height={48}
            text="Edit Details"
            onClick={handleEdit}
          ></Button>
        </div>
      </div> */}
      <PageHeader
        isBtn1Visisble={customerDetail.status == "R" ? true : false}
        isBtn2Visisble={false}
        pageTitle={"Customer Form"}
        statusPage={""}
        btnOption={btnOption}
        clickHandler={handleEdit}
        isBreadcrumbsVisible={true}
      />

      <div className="content-add-form">
        <div className="form-header-panel">
          <ul type="none">
            <li className={activeLink == "Reason" ? "active" : ""}>
              <Link to="#Reason" onClick={() => handleLinkClick("Reason")}>
                Verification Reason
              </Link>
            </li>
            <li className={activeLink == "Customer" ? "active" : ""}>
              <Link to="#Customer" onClick={() => handleLinkClick("Customer")}>
                Customer
              </Link>
            </li>
            <li className={activeLink == "Address" ? "active" : ""}>
              <Link to="#Address" onClick={() => handleLinkClick("Address")}>
                Registered Address
              </Link>
            </li>
            <li className={activeLink == "Details" ? "active" : ""}>
              <Link to="#Details" onClick={() => handleLinkClick("Details")}>
                Bank Details
              </Link>
            </li>
            <li className={activeLink == "Communication" ? "active" : ""}>
              <Link
                to="#Communication"
                onClick={() => handleLinkClick("Communication")}
              >
                Communication
              </Link>
            </li>
            <li className={activeLink == "Others" ? "active" : ""}>
              <Link to="#Others" onClick={() => handleLinkClick("Others")}>
                Others
              </Link>
            </li>
          </ul>
        </div>
        <form>
          <div className="form-body-content" onScroll={handleScroll}>
            {/* ----------- Verification Reason --------------*/}
            <section id="Reason" className="form-section">
              <span className="header-text">Verification Reason</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Reason"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("reason", e.value)}
                    value={customerDetail.reason || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
            </section>
            {/* ----------- Customer --------------*/}
            <section id="Customer" className="form-section">
              <span className="header-text">Customer</span>
              <div className="section-title">
                <div
                  className={"setion-first-row common-row-check"}
                  id="vendor"
                >
                  <SelectBox
                    label="Customer Type "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("customerType", e.value)
                    }
                    value={customerDetail.customerType || "--"}
                    // value={formData?.vendorType || ""}
                    items={vendorType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  ></SelectBox>
                  <TextBox
                    label="Customer Name as per GST"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("customerName", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={customerDetail.customerName || "--"}
                    // value={formData?.vendorName || ""}
                  ></TextBox>
                  <TextBox
                    label="Business Structure of Customer"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpType", e.value)
                    }
                    value={customerDetail.cmpType || "--"}
                    // value={formData?.vendorName || ""}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Category of Customer"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    value={customerDetail?.cmpCatType || "--"}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                  ></TextBox>

                  <TextBox
                    label="Pan Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("panNum", e.value)}
                    value={customerDetail.panNum || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="GST Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("gstregNum", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={customerDetail?.gstregNum || "--"}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Official Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("OfficialPhone", e.value)
                    }
                    value={customerDetail?.officialPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Official Email Address "
                    labelMode="outside"
                    width={"33%"}
                    className="verify-email-text"
                    onValueChanged={(e) => handleInputChange("eMail", e.value)}
                    value={customerDetail?.eMail || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
            </section>

            {/* ----------- Office Address --------------*/}
            <section id="Address" className="form-section">
              <div className="header-text">Registered Address</div>
              <div className="header-sub-text">Billing Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Address 1 "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress1", e.value)
                    }
                    value={customerDetail?.bAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Address 2"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress2", e.value)
                    }
                    value={customerDetail?.bAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bStreet", e.value)
                    }
                    value={customerDetail?.bStreet || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={customerDetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    // valueChangeEvent="keyup"
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={customerDetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    value={customerDetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="bCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                  />
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={customerDetail?.bState || "--"}
                    readOnly={isTxtFieldDisable}
                    searchEnabled={true}
                    items={StateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={customerDetail?.bCountry ? false : true}
                    id="bState"
                  ></SelectBox>
                  {/* <TextBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    value={customerDetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={customerDetail?.bState || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                </div>

                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="City "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bCity", e.value)}
                    value={customerDetail?.bCity || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
              {/* <CheckBox
                text={"Shipping address same as billing address"}
                // hint={"Shipping address same as billing address"}
                className="bill_addTxt"
                onValueChanged={chkBoxHandler}
              /> */}
              <div className="header-sub-text">Shipping Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Ship From 1 "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress1", e.value)
                    }
                    value={customerDetail?.sAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Ship From 2"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress2", e.value)
                    }
                    value={customerDetail?.sAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sStreet", e.value)
                    }
                    value={customerDetail?.sStreet || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    // onValueChanged={(e) =>
                    //   handleInputChange("sZipCode", e.value)
                    // }
                    onValueChanged={(e) =>
                      handleInputChange("sZipCode", e.value)
                    }
                    value={customerDetail?.sZipCode || "--"}
                  ></TextBox>

                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={customerDetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                    searchEnabled={true}
                  ></SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={customerDetail?.sState || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sState"
                    items={ShipStateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={customerDetail?.sCountry ? false : true}
                    searchEnabled={true}
                  ></SelectBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="City "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sCity", e.value)}
                    value={customerDetail?.sCity || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
            </section>

            {/* -----------  Bank Details --------------*/}

            <section id="Details" className="form-section">
              <span className="header-text">Bank Details</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Currency"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    value={customerDetail?.transCry || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Bank Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("acctName", e.value)
                    }
                    value={customerDetail?.acctName || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Account Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("account", e.value)
                    }
                    value={customerDetail?.account || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="IFSC Code "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("ifscNum", e.value)
                    }
                    value={customerDetail?.ifscNum || "--"}
                    readOnly={isTxtFieldDisable}
                  />

                  <TextBox
                    label="Swift Code "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("swiftNum", e.value)
                    }
                    value={customerDetail?.ifscNum || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Bank Branch/Address"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("branch", e.value)}
                    value={customerDetail?.branch || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Payment Terms"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value)
                    }
                    value={customerDetail?.pymntTerms || "--"}
                    readOnly={isTxtFieldDisable}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value.toString())
                    }
                    label="Payment Terms"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={paymentTerms}
                    // value={formData?.pymntTerms || ""}
                    value={parseInt(customerDetail?.pymntTerms) || ""}
                    valueExpr={"groupNum"}
                    displayExpr={"pymntGroup"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Communication --------------*/}

            <section id="Communication" className="form-section">
              <span className="header-text">Communication</span>
              <div className="header-sub-text">Primary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPrsn", e.value)
                    }
                    value={customerDetail?.pCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPhone", e.value)
                    }
                    value={customerDetail?.pCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctEmail", e.value)
                    }
                    value={customerDetail?.pCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctDesg", e.value)
                    }
                    value={customerDetail?.pCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
              <div className="header-sub-text">Secondary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPrsn", e.value)
                    }
                    value={customerDetail?.sCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPhone", e.value)
                    }
                    value={customerDetail?.sCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctEmail", e.value)
                    }
                    value={customerDetail?.sCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctDesg", e.value)
                    }
                    value={customerDetail?.sCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Other --------------*/}

            <section id="Others" className="form-section">
              <span className="header-text">Others</span>
              <div className="header-sub-text">Taxation Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row-check">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_IS_MSME", e.value)
                    }
                    label="Micro, Small & Medium Enterprises"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    value={customerDetail?.u_QIT_IS_MSME || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  />
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_TYPE", e.value.toString())
                    }
                    label="MSME Types"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={msmeType}
                    value={customerDetail?.u_QIT_MSME_TYPE || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="MSME Registration No."
                    labelMode="outside"
                    width={"33%"}
                    readOnly={isTxtFieldDisable}
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_REGNO", e.value)
                    }
                    value={customerDetail?.u_QIT_MSME_REGNO || ""}
                  />
                </div>
              </div>
              {/* <div className="header-sub-text">ISO Certificate Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("isoCheck", e.value)
                    }
                    label="Is ISO Certified?"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    // value={formData?.msmeCheck || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                 <TextBox
                    label="ISO no."
                    labelMode="outside"
                    width={"33%"}
                    disabled={
                      formData?.isoCheck
                        ? formData?.isoCheck == "N"
                          ? true
                          : false
                        : true
                    }
                    // onValueChanged={(e) =>
                    //   handleInputChange("msmeRegistrationNo", e.value)
                    // }
                    // value={formData?.msmeCheck || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>

                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload ISO Certificate."
                    )}
                  </label>
                  <input
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    // onChange={handleFileUpload}
                    accept=".pdf"
                    disabled={formData?.isoCheck
                      ? formData?.isoCheck == "N"
                        ? true
                        : false
                      : true}
                  />
                </div>
              </div> */}
              <div className="header-sub-text">More Details</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Sales Employee"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("salesEmp", e.value)
                    }
                    value={customerDetail?.salesEmp || "--"}
                    readOnly={isTxtFieldDisable}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("salesEmp", e.value)
                    }
                    label="Sales Employee"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={salesEmp}
                    value={customerDetail?.salesEmp || ""}
                    valueExpr={"slpCode"}
                    displayExpr={"slpName"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Broker"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("broker", e.value)}
                    value={customerDetail?.broker || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Remarks"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("remark", e.value)}
                    value={customerDetail?.remark || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Website Link"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("websiteLink", e.value)
                    }
                    value={customerDetail?.websiteLink || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  {customerDetail?.cmpDocs && (
                    <div className="docSection">
                      <label className="text-section">File</label>
                      <div className="doc-sub-section">
                        <a
                          href={customerDetail?.cmpDocs}
                          className="sub-section"
                          onClick={handleClick}
                        >
                          <img src={pdf} alt="PDF icon" />
                          <label className="text">{`QIT_${customerDetail?.customerName}.pdf`}</label>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>
    </>
  );
}

export default CustomerDetailsMain;
