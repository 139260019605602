// import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
// import { Logout, getUser, signIn as sendSignInRequest } from '../api/auth';

// function AuthProvider(props) {
//   const [user, setUser] = useState();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     (async function () {
//       const result = await getUser();
//       if (result.isOk) {
//         setUser(result.data);
//       }

//       setLoading(false);
//     })();
//   }, []);

//   const signIn = useCallback(async (role,email, password) => {
//     const result = await sendSignInRequest(role,email, password);
//     if (result.isOk) {
//       setUser(result.data);
//     }
//     return result;
//   }, []);

//   // const signOut = useCallback(() => {
//   //   setUser(undefined);
//   // }, []);
//   const signOut = useCallback(() => {
//     const result = Logout();
//     if (!result.isOk) {
//       setUser(undefined);
//     }
//   }, []);

//   return (
//     <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
//   );
// }

// const AuthContext = createContext({ loading: false });
// const useAuth = () => useContext(AuthContext);

// export { AuthProvider, useAuth }
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { getUser, signIn as sendSignInRequest, Logout } from "../api/auth";
import CryptoJS from "crypto-js";
const SECRET = process.env.REACT_APP_SECRET;
function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [AuthRuleContect, setAuthRuleContext] = useState([]);

  function getRealAuthRule(authrule) {
    const ciphertext = authrule;
    const key = CryptoJS.enc.Utf8.parse(SECRET);

    try {
      const decryptedBytes = CryptoJS.AES.decrypt(ciphertext, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

      // Parse the decrypted text back to JSON
      const decryptedData = JSON.parse(decryptedText);
      return decryptedData;
    } catch (error) {
      console.error("Error decrypting data:", error);
    }
  }

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        const updatedData = {
          ...result.data,
          Profile: getRealAuthRule(localStorage.getItem("profile")),
        };

        setUser(updatedData);
        // setUser(result.data);
        setAuthRuleContext(getRealAuthRule(result.Auth_Rule));
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (role, payload) => {
    const result = await sendSignInRequest(payload);
    if (result.isOk) {
      const updatedData = {
        ...result.data,
        Profile: getRealAuthRule(localStorage.getItem("profile")),
      };

      setUser(updatedData);
      
      // setUser(result.data);
      const key = CryptoJS.enc.Utf8.parse(SECRET);

      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;
      const datanew = {
        text: "Home",
        path: "/home",
        icon: "home",
      };

      const dataLog = {
        text: "System Log",
        path: "/LogReport",
        icon: "description",
      };

      const getRealObj = getRealAuthRule(result.Auth_Rule);
      const filteredData = getRealObj
        .filter((section) => section.hasAccess)
        .map((section) => {
          return {
            ...section,
            items: section.items.filter((item) =>
              item.rightsAccess.includes("T")
            ),
          };
        });
      if (role == "U") {
        filteredData.unshift(datanew);
        filteredData.push(dataLog);
      }

      const jsonString = JSON.stringify(filteredData);
      const utf8Bytes = CryptoJS.enc.Utf8.parse(jsonString);
      let encryptedBytes = CryptoJS.AES.encrypt(utf8Bytes, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      let encryptedString = encryptedBytes.toString();
      const dataToStore = {
        encryptedString,
        expiration: expirationTime,
      };
      localStorage.setItem("AuthRule", JSON.stringify(dataToStore));
      setAuthRuleContext(filteredData);
    }
    return result;
  }, []);

  const signOut = useCallback(() => {
    const result = Logout();
    if (!result.isOk) {
      setUser(undefined);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, setUser, signIn, signOut, loading, AuthRuleContect }}
      {...props}
    />
  );
}

const AuthContext = createContext({ loading: true });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
