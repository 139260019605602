import {
  Button,
  Popup,
  SelectBox,
  TextBox,
  Button as TextBoxButton,
  Validator,
} from "devextreme-react";
import React, { useEffect, useState } from "react";
//   import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
//   import { navigation } from "../../../app-navigation";
//   import ApprovalPopupContent from "../../../components/popup/Popup-component";
import { HashLink as Link } from "react-router-hash-link";
import ApprovalPopupContent from "../../../components/popup/Popup-component";
import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
import { useAuth } from "../../../contexts/auth";
import { requestAddressFromPin } from "../../../api/registerCustomer";
// import { navigation } from "../../../app-navigation";
import pdf from "../../../assets/images/pdf.png";
import { useNavigate } from "react-router-dom";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import { GetVendorDetailByName } from "../../../api/vendor";
import { RequiredRule } from "devextreme-react/cjs/validator";
import PageHeader from "../../../components/Page-Header/PageHeader";
import {
  GetSAPBankDetail,
  GetSAPCurrency,
  getCountryData,
  getPaymentTerms,
  getStateData,
} from "../../../api/commonAPI";
import { UseConfigContext } from "../../../contexts/cofigContext";
function VendorDetailsMain() {
  const [showPopup, setShowPopup] = useState(false);
  const { AuthRuleContect } = useAuth();
  const [isTxtFieldDisable, setIsTxtFieldDisable] = useState(true);
  const [vendorDetail, setVendorDetail] = useState([]);
  const [activeLink, setActiveLink] = useState("Reason");
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    createdByUserName: user["UserData"],
    u_QIT_MSME_REGNO: "",
    u_QIT_MSME_TYPE: "",
  });

  const msmeType = [
    { value: "M", text: "Micro" },
    { value: "S", text: "Small" },
    { value: "D", text: "Medium" },
  ];
  const ismsmeType = [
    { value: "Y", text: "Yes" },
    { value: "N", text: "No" },
  ];

  const navigate = useNavigate();
  const [bankDetail, setBankDetail] = useState([]);
  const { getSourceData } = UseConfigContext();
  const [sourceConfigData, setSourceConfigData] = useState(getSourceData[0]);

  const handleApprovalRequest = () => {
    setShowPopup(true);
  };
  const handleEdit = () => {
    // setIsTxtFieldDisable(false);
    navigate("/InfoVendor/editVendorDetails", {
      state: { vendorDetail, From: "V" },
    });
  };
  const hanldePopupClose = () => {
    setShowPopup(false);
  };
  const handleLinkClick = (activeLinkName) => {
    setActiveLink(activeLinkName);
  };

  const [paymentTerms, setPaymentTerms] = useState([]);

  const getPayemntTerms = async () => {
    var apiRes = await getPaymentTerms();
    if (!apiRes.hasError) {
      setPaymentTerms(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  useEffect(() => {
    getPayemntTerms();
    getAllContryData();
    getCurrencyData();
    if (sourceConfigData && sourceConfigData["bankSource"] == "S") {
      GetBankDetail();
    }
  }, []);

  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [ShipStateData, setShipStateData] = useState([]);

  const GetBankDetail = async () => {
    var apiRes = await GetSAPBankDetail();
    if (!apiRes.hasError) {
      setBankDetail(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getAllContryData = async () => {
    var apiRes = "";
    if (sourceConfigData && sourceConfigData["countrySource"] == "L") {
      apiRes = await getCountryData("L");
    } else {
      // SAP
      apiRes = await getCountryData("S");
    }
    if (!apiRes.hasError) {
      setCountryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  const getCountryWiseStateData = async (country_code, fieldName) => {
    if (country_code != "" && country_code != null) {
      var apiRes = await getStateData(country_code);
      if (!apiRes.hasError) {
        if (fieldName == "bCountry") {
          setStateData(apiRes.responseData);
        }
        if (fieldName == "sCountry") {
          setShipStateData(apiRes.responseData);
        }
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const getCurrencyData = async () => {
    if (sourceConfigData && sourceConfigData["currencySource"] == "L") {
      setCurrency();
    } else {
      var apiRes = await GetSAPCurrency();
      if (!apiRes.hasError) {
        setCurrency(apiRes.responseData);
      } else {
        return toastDisplayer("error", apiRes.errorMessage);
      }
    }
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll(".form-section");
    let foundAnySection = false;

    sections.forEach((section) => {
      const bounding = section.getBoundingClientRect();
      if (bounding.top >= 0 && bounding.top <= window.innerHeight) {
        if (!foundAnySection) {
          setActiveLink(section.id);
          foundAnySection = true;
        }
      }
    });
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    if (fieldName == "bCountry" || fieldName == "sCountry") {
      getCountryWiseStateData(value, fieldName);
    }
  };

  const vendorType = [
    { value: "I", text: "Import" },
    { value: "D", text: "Domestic" },
  ];

  // const handleGetAddress = async (pinCode, addOf) => {
  //   if (pinCode.length === 6) {
  //     const getAddress = await requestAddressFromPin(pinCode);

  //     if (getAddress[0].PostOffice != null) {
  //       if (addOf == "B") {
  //         // setGenertedDataFromPin(getAddress[0].PostOffice[0]);
  //         handleInputChange("bZipCode", pinCode);
  //         handleInputChange("bState", getAddress[0].PostOffice[0].District);
  //         handleInputChange("bCity", getAddress[0].PostOffice[0].State);
  //         handleInputChange("bCountry", getAddress[0].PostOffice[0].Country);
  //       }
  //       if (addOf == "S") {
  //         // setGenertedDataFromPin(getAddress[0].PostOffice[0]);
  //         handleInputChange("sZipCode", pinCode);
  //         handleInputChange("sState", getAddress[0].PostOffice[0].District);
  //         handleInputChange("sCity", getAddress[0].PostOffice[0].State);
  //         handleInputChange("sCountry", getAddress[0].PostOffice[0].Country);
  //       }
  //     }
  //   }
  // };

  const helpOptions = {
    icon: "HelpIcons",
    onClick: async () => {
      // function goes here of sending email
    },
  };

  const handleClick = () => {
    let pdfWindow = window.open(vendorDetail?.cmpDocs);
    if (pdfWindow) {
      pdfWindow.document.write(`<body>
        <embed src="${vendorDetail?.cmpDocs}" width="100%" height="100%">
        </body>
      `);
    }
  };

  useEffect(() => {
    // setVendorDetail
    const getvendorDetailsByName = async (userName) => {
      let data = await GetVendorDetailByName(userName);
      if (data.hasError) {
        return toastDisplayer("error", data.errorMessage);
      } else {
        setVendorDetail(data.responseData[0]);
      }
    };
    getvendorDetailsByName(user["UserData"]);
  }, []);

  const cmpCatType = [
    { value: "M", text: "Manufacturer" },
    { value: "T", text: "Trader" },
  ];

  const btnOption = {
    icon: "",
    text: "Edit Details",
  };

  return (
    <>
      <Popup
        visible={showPopup}
        height={"250px"}
        width={"500px"}
        className="request-popup"
      >
        <ApprovalPopupContent
          hanldePopupClose={hanldePopupClose}
          headertext={"Send for Approval"}
          headerSubText={"Are you sure you want to send for approval?"}
        ></ApprovalPopupContent>
      </Popup>
      {/* <div className="content-header-block form-style">
        <div
          className="content-header-block-breadcrumbs"
          style={{ width: "100%" }}
        >
          <Breadcrumbs navigation={AuthRuleContect} />
        </div>

        <div className="content-header-block-inner ">
          <label className="section-title-header">Vendor Form</label>
          <Button
            width={144}
            height={48}
            text="Edit Details"
            onClick={handleEdit}
          ></Button>
        </div>
      </div> */}

      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={vendorDetail.status == "R" ? true : false}
        isBtn2Visisble={false}
        pageTitle={"Vendor Form"}
        statusPage={
          vendorDetail.status == "A"
            ? "Approved"
            : vendorDetail.status == "R"
            ? "Rejected"
            : vendorDetail.status == "P"
            ? "Pending"
            : ""
        }
        btnOption={btnOption}
        clickHandler={handleEdit}
      />

      <div className="content-add-form">
        <div className="form-header-panel">
          <ul type="none">
            <li className={activeLink == "Reason" ? "active" : ""}>
              <Link to="#Reason" onClick={() => handleLinkClick("Reason")}>
                Verification Reason
              </Link>
            </li>
            <li className={activeLink == "Vendor" ? "active" : ""}>
              <Link to="#Vendor" onClick={() => handleLinkClick("Vendor")}>
                Vendor
              </Link>
            </li>
            <li className={activeLink == "Address" ? "active" : ""}>
              <Link to="#Address" onClick={() => handleLinkClick("Address")}>
                Registered Address
              </Link>
            </li>
            <li className={activeLink == "Details" ? "active" : ""}>
              <Link to="#Details" onClick={() => handleLinkClick("Details")}>
                Bank Details
              </Link>
            </li>
            <li className={activeLink == "Communication" ? "active" : ""}>
              <Link
                to="#Communication"
                onClick={() => handleLinkClick("Communication")}
              >
                Communication
              </Link>
            </li>
            <li className={activeLink == "Others" ? "active" : ""}>
              <Link to="#Others" onClick={() => handleLinkClick("Others")}>
                Others
              </Link>
            </li>
          </ul>
        </div>
        <form>
          <div className="form-body-content" onScroll={handleScroll}>
            {/* ----------- Verification Reason --------------*/}
            <section id="Reason" className="form-section">
              <span className="header-text">Verification Reason</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Reason"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("reason", e.value)}
                    // value={formData?.OfficialPhone || ""}
                    value={vendorDetail.reason || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
            </section>
            {/* ----------- Vendor --------------*/}
            <section id="Vendor" className="form-section">
              <span className="header-text">Vendor</span>
              <div className="section-title" id>
                <div
                  className={"setion-first-row common-row-check"}
                  id="vendor"
                >
                  <SelectBox
                    label="Vendor Type "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("vendorType", e.value)
                    }
                    value={vendorDetail.vendorType || "--"}
                    // value={formData?.vendorType || ""}
                    items={vendorType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  ></SelectBox>
                  <TextBox
                    label="Vendor Name as per GST"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("vendorName", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={vendorDetail.vendorName || "--"}
                    // value={formData?.vendorName || ""}
                  ></TextBox>
                  <TextBox
                    label="Business Structure of Vendor"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpType", e.value)
                    }
                    value={vendorDetail.cmpType || "--"}
                    // value={formData?.vendorName || ""}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  {/* <SelectBox
                    label="Category of Vendor"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    value={vendorDetail.cmpCatType || "--"}
                    // value={formData?.cmpCatType || ""}
                    items={cmpCatType}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    readOnly={isTxtFieldDisable}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox> */}
                  <TextBox
                    label="Category of Vendor"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("cmpCatType", e.value)
                    }
                    value={vendorDetail.cmpCatType || "--"}
                    // readOnly={isTxtFieldDisable}
                    readOnly={true}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    label="Pan Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("panNum", e.value)}
                    value={vendorDetail.panNum || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="GST Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("gstregNum", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={vendorDetail?.gstregNum || "--"}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Official Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("OfficialPhone", e.value)
                    }
                    value={vendorDetail?.officialPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Official Email Address "
                    labelMode="outside"
                    width={"33%"}
                    className="verify-email-text"
                    onValueChanged={(e) => handleInputChange("eMail", e.value)}
                    value={vendorDetail?.eMail || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
            </section>

            {/* ----------- Office Address --------------*/}
            <section id="Address" className="form-section">
              <div className="header-text">Registered Address</div>
              <div className="header-sub-text">Billing Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Address 1 "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress1", e.value)
                    }
                    value={vendorDetail?.bAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Address 2"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bAddress2", e.value)
                    }
                    value={vendorDetail?.bAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bStreet", e.value)
                    }
                    value={vendorDetail?.bStreet || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={vendorDetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    // valueChangeEvent="keyup"
                    onValueChanged={(e) =>
                      handleInputChange("bZipCode", e.value)
                    }
                    value={vendorDetail?.bZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  {/* <TextBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    value={vendorDetail?.bCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("bCountry", e.value)
                    }
                    searchEnabled={true}
                    value={vendorDetail?.bCountry || "--"}
                    readOnly={true}
                    id="bCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    valueExpr={"code"}
                  ></SelectBox>
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bState", e.value)}
                    value={vendorDetail?.bState || "--"}
                    readOnly={true}
                    id="bState"
                    searchEnabled={true}
                    items={StateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={formData?.bCountry ? false : true}
                  ></SelectBox>
                </div>

                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="City "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("bCity", e.value)}
                    value={vendorDetail?.bCity || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
              {/* <CheckBox
                text={"Shipping address same as billing address"}
                // hint={"Shipping address same as billing address"}
                className="bill_addTxt"
                onValueChanged={chkBoxHandler}
              /> */}
              <div className="header-sub-text">Shipping Address</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Ship From 1 "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress1", e.value)
                    }
                    value={vendorDetail?.sAddress1 || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Ship From 2"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sAddress2", e.value)
                    }
                    value={vendorDetail?.sAddress2 || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Area"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sStreet", e.value)
                    }
                    value={vendorDetail?.sStreet || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    // onValueChanged={(e) =>
                    //   handleInputChange("sZipCode", e.value)
                    // }
                    onValueChanged={(e) =>
                      handleInputChange("sZipCode", e.value)
                    }
                    value={vendorDetail?.sZipCode || "--"}
                  ></TextBox>
                  <SelectBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={vendorDetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sCountry"
                    items={CountryData}
                    displayExpr={(item) =>
                      item ? `${item.code} - ${item.name}` : ""
                    }
                    searchEnabled={true}
                    valueExpr={"code"}
                  />
                  <SelectBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={vendorDetail?.sState || "--"}
                    readOnly={isTxtFieldDisable}
                    id="sState"
                    items={ShipStateData}
                    displayExpr={"name"}
                    valueExpr={"code"}
                    disabled={formData?.sCountry ? false : true}
                    searchEnabled={true}
                  >
                    <Validator>
                      <RequiredRule message="Required" />
                    </Validator>
                  </SelectBox>
                  {/* <TextBox
                    label="Country"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCountry", e.value)
                    }
                    value={vendorDetail?.sCountry || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="State"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sState", e.value)}
                    value={vendorDetail?.sState || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  {/* <TextBox
                    label="Pin Code"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sZipCode", e.value)
                    }
                    value={vendorDetail?.sZipCode || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="City "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("sCity", e.value)}
                    value={vendorDetail?.sCity || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
              </div>
            </section>

            {/* -----------  Bank Details --------------*/}

            <section id="Details" className="form-section">
              <span className="header-text">Bank Details</span>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  {/* <TextBox
                    label="Currency"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    value={vendorDetail?.transCry || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("transCry", e.value)
                    }
                    label="Currency "
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={currency}
                    value={vendorDetail?.transCry || ""}
                    valueExpr={"currCode"}
                    displayExpr={(item) =>
                      item ? `${item.currCode} - ${item.currName}` : ""
                    }
                    id="transCry"
                    readOnly={true}
                  />
                  <TextBox
                    label="Bank Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("acctName", e.value)
                    }
                    value={vendorDetail?.acctName || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Account Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("account", e.value)
                    }
                    value={vendorDetail?.account || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="IFSC Code "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("ifscNum", e.value)
                    }
                    value={vendorDetail?.ifscNum || "--"}
                    readOnly={isTxtFieldDisable}
                  />

                  {/* <TextBox
                    label="Swift Code "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("swiftNum", e.value)
                    }
                    value={vendorDetail?.ifscNum || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox> */}
                  <TextBox
                    label="Bank Branch/Address"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("branch", e.value)}
                    value={vendorDetail?.branch || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  {/* <TextBox
                    label="Payment Terms"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value)
                    }
                    value={vendorDetail?.pymntTerms || "--"}
                    readOnly={isTxtFieldDisable}
                  /> */}
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("pymntTerms", e.value.toString())
                    }
                    label="Payment Terms"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={paymentTerms}
                    // value={formData?.pymntTerms || ""}
                    value={parseInt(vendorDetail?.pymntTerms) || ""}
                    valueExpr={"groupNum"}
                    displayExpr={"pymntGroup"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Communication --------------*/}

            <section id="Communication" className="form-section">
              <span className="header-text">Communication</span>
              <div className="header-sub-text">Primary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPrsn", e.value)
                    }
                    value={vendorDetail?.pCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCntctPhone", e.value)
                    }
                    value={vendorDetail?.pCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  ></TextBox>
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctEmail", e.value)
                    }
                    value={vendorDetail?.pCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("pCnctDesg", e.value)
                    }
                    value={vendorDetail?.pCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
              <div className="header-sub-text">Secondary Contact Person</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Full Name"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPrsn", e.value)
                    }
                    value={vendorDetail?.sCntctPrsn || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Phone Number"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCntctPhone", e.value)
                    }
                    value={vendorDetail?.sCntctPhone || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  <TextBox
                    label="Email Address "
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctEmail", e.value)
                    }
                    value={vendorDetail?.sCnctEmail || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Designation"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("sCnctDesg", e.value)
                    }
                    value={vendorDetail?.sCnctDesg || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                </div>
              </div>
            </section>

            {/* -----------  Other --------------*/}

            <section id="Others" className="form-section">
              <span className="header-text">Others</span>
              <div className="header-sub-text">Taxation Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row-check">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_IS_MSME", e.value)
                    }
                    label="Micro, Small & Medium Enterprises"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    value={vendorDetail?.u_QIT_IS_MSME || ""}
                    readOnly={isTxtFieldDisable}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_TYPE", e.value.toString())
                    }
                    label="MSME Types"
                    labelMode="outside"
                    width={"33%"}
                    searchEnabled={true}
                    readOnly={isTxtFieldDisable}
                    items={msmeType}
                    value={vendorDetail?.u_QIT_MSME_TYPE || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                    disabled={
                      vendorDetail?.u_QIT_IS_MSME
                        ? vendorDetail?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                  />
                  <TextBox
                    label="MSME Registration No."
                    labelMode="outside"
                    width={"33%"}
                    disabled={
                      vendorDetail?.u_QIT_IS_MSME
                        ? vendorDetail?.u_QIT_IS_MSME == "N"
                          ? true
                          : false
                        : true
                    }
                    onValueChanged={(e) =>
                      handleInputChange("u_QIT_MSME_REGNO", e.value)
                    }
                    readOnly={isTxtFieldDisable}
                    value={vendorDetail?.u_QIT_MSME_REGNO || ""}
                  />
                </div>
              </div>
              {/* <div className="header-sub-text">ISO Certificate Details</div>
              <div className="section-title">
                <div className="setion-first-row common-row">
                  <SelectBox
                    onValueChanged={(e) =>
                      handleInputChange("isoCheck", e.value)
                    }
                    label="Is ISO Certified?"
                    labelMode="outside"
                    width={"49%"}
                    searchEnabled={true}
                    items={ismsmeType}
                    // value={formData?.msmeCheck || ""}
                    valueExpr={"value"}
                    displayExpr={"text"}
                  />
                 <TextBox
                    label="ISO no."
                    labelMode="outside"
                    width={"49%"}
                    disabled={
                      formData?.isoCheck
                        ? formData?.isoCheck == "N"
                          ? true
                          : false
                        : true
                    }
                    // onValueChanged={(e) =>
                    //   handleInputChange("msmeRegistrationNo", e.value)
                    // }
                    // value={formData?.msmeCheck || ""}
                  />
                </div>
                <div className="setion-first-row common-row">
                <label className="uplaod_btn" htmlFor="file_upload">
                    <label className="material-symbols-outlined">upload</label>

                    {uploadedFileName ? (
                      <p>{uploadedFileName}</p>
                    ) : (
                      "Upload ISO Certificate."
                    )}
                  </label>
                  <input
                    type="file"
                    id="file_upload"
                    style={{ display: "none" }}
                    // onChange={handleFileUpload}
                    accept=".pdf"
                    disabled={formData?.isoCheck
                      ? formData?.isoCheck == "N"
                        ? true
                        : false
                      : true}
                  />
                </div>
              </div> */}
              <div className="header-sub-text">More Details</div>
              <div className="section-title">
                <div className={"setion-first-row common-row-check"}>
                  <TextBox
                    label="Remarks"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) => handleInputChange("remark", e.value)}
                    value={vendorDetail?.remark || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  {/* </div>
                <div className={"setion-first-row common-row-check"}> */}
                  <TextBox
                    label="Website Link"
                    labelMode="outside"
                    width={"33%"}
                    onValueChanged={(e) =>
                      handleInputChange("websiteLink", e.value)
                    }
                    value={vendorDetail?.websiteLink || "--"}
                    readOnly={isTxtFieldDisable}
                  />
                  {vendorDetail?.cmpDocs && (
                    <div className="docSection">
                      <label className="text-section">File</label>
                      <div className="doc-sub-section">
                        <a
                          href={vendorDetail?.cmpDocs}
                          className="sub-section"
                          onClick={handleClick}
                        >
                          <img src={pdf} alt="PDF icon" />
                          <label className="text">{`QIT_${vendorDetail?.vendorName}.pdf`}</label>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>
    </>
  );
}

export default VendorDetailsMain;
