// import { Button } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "devextreme-react/button";
import { SelectBox } from "devextreme-react";
import "./source-main.scss";
import { UseConfigContext } from "../../../../contexts/cofigContext";
import { saveConfigSource } from "../../../../api/setting";
import { toastDisplayer } from "../../../../components/comman card/toast-displayer";
function SourceMain() {
  const [isCatExpand, setIsCatExpand] = useState(false);
  const { getSourceData, setSourceData } = UseConfigContext();
  const [getSource, setSource] = useState(getSourceData[0]);
  const handleGridExpand = () => {
    setIsCatExpand(!isCatExpand);
  };
  const Source = [
    { value: "L", text: "Local Data" },
    { value: "S", text: "From SAP" },
  ];

  const handleInputChange = (fieldName, value) => {
    setSource((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSaveClick = async () => {
    if (getSource != getSourceData[0]) {
      var apiRes = await saveConfigSource(getSource);
      if (!apiRes.hasError) {
        await setSourceData([getSource]);
        return toastDisplayer("success", "Source data saved..!!");
      } else {
        return toastDisplayer("error", apiRes.hasError);
      }
    }
  };

  return (
    <div className="vendor-category">
      <div className="vendor-category-card">
        <div className="dx-card-title" onClick={handleGridExpand}>
          Select Source
          {isCatExpand ? (
            <Button icon="chevronup" />
          ) : (
            <Button icon="chevrondown" />
          )}
        </div>
        <div className="add-btn-section">
          <Button
            className="add-btn"
            height={48}
            text={"Save source"}
            onClick={handleSaveClick}
          ></Button>
        </div>
      </div>
      {isCatExpand ? (
        <>
          <div className="source-panel">
            <SelectBox
              label="Country "
              labelMode="outside"
              width={"33%"}
              onValueChanged={(e) =>
                handleInputChange("countrySource", e.value)
              }
              value={getSource && getSource["countrySource"]}
              items={Source}
              valueExpr={"value"}
              displayExpr={"text"}
              // readOnly={isTxtFieldDisable}
            ></SelectBox>
            <SelectBox
              label="Currency "
              labelMode="outside"
              width={"33%"}
              onValueChanged={(e) =>
                handleInputChange("currencySource", e.value)
              }
              // value={vendorDetail.vendorType || "--"}
              value={getSource["currencySource"]}
              items={Source}
              valueExpr={"value"}
              displayExpr={"text"}
              // readOnly={isTxtFieldDisable}
            ></SelectBox>
            <SelectBox
              label="Bank "
              labelMode="outside"
              width={"33%"}
              onValueChanged={(e) => handleInputChange("bankSource", e.value)}
              // value={vendorDetail.vendorType || "--"}
              value={getSource["bankSource"]}
              items={Source}
              valueExpr={"value"}
              displayExpr={"text"}
              // readOnly={isTxtFieldDisable}
            ></SelectBox>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default SourceMain;
