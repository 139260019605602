import { Button, SelectBox } from "devextreme-react";
import React, { useEffect, useState } from "react";
import DataGrid, {
  Column,
  Paging,
  Toolbar,
  Item,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import { getAllCustomer, GetCustomerDetail } from "../../../api/customer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/auth";

export const CustomerListing = ({ from }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const handleIconClick = async (e) => {
    var apiRes = await GetCustomerDetail(e.data.customerCode);
    if (apiRes.hasError) {
      return toastDisplayer("error", apiRes.errorMessage);
    } else {
      var customerdetail = apiRes.responseData;
      if (from == "V") {
        navigate("/customer/verifycustomer/CustomerStatus", {
          state: { customerdetail, From: "V" },
        });
      } else if (from == "A") {
        // if (e.data.createdByUserName == "Admin") {
        if (e.data.status == "R" && e.data.createdStatus == "Company") {
          if (
            e.data.createdByUserName == user["UserData"] ||
            user["UserData"] == "Admin"
          ) {
            navigate("/customer/addCustomer/CustomerReject", {
              state: { customerdetail, From: "A" },
            });
          } else {
            navigate("/customer/addCustomer/CustomerStatus", {
              state: { customerdetail, From: "A" },
            });
          }
        } else {
          navigate("/customer/addCustomer/CustomerStatus", {
            state: { customerdetail, From: "A" },
          });
        }
        //  else {
        //   if (
        //     e.data.status == "R" &&
        //     e.data.createdStatus == "Company" &&
        //     e.data.createdByUserName == user["UserData"]
        //   ) {
        //     navigate("/customer/addCustomer/CustomerReject", {
        //       state: { customerdetail, From: "A" },
        //     });
        //   } else {
        //     navigate("/customer/addCustomer/CustomerStatus", {
        //       state: { customerdetail, From: "A" },
        //     });
        //   }
        // }
      }
    }
  };
  const [CustomerData, setCustomerData] = useState(null);
  let dataGrid;
  const [filterStatus, setFilterStatus] = useState("All");

  const allSelectionStatus = [
    { value: "All", text: "Registered Customers" },
    { value: "P", text: "Pending Customers" },
    { value: "A", text: "Approved Customers" },
    { value: "R", text: "Rejected Customers" },
  ];

  const getStatusColor = (status) => {
    const statusColors = {
      Approved: "#124d22",
      Pending: "#06548b",
      Rejected: "#AD1820",
    };

    return statusColors[status] || "#000";
  };

  const handleFilterChange = (newStatus) => {
    setFilterStatus(newStatus);
    var filterValue = newStatus === "All" ? undefined : newStatus;
    if (dataGrid && dataGrid.instance) {
      dataGrid.instance.columnOption("status", "filterValue", filterValue);
      dataGrid.instance.refresh();
    }
  };

  const handleAddedFilterChange = (newStatus) => {
    setFilterStatus(newStatus);
    var filterValue = newStatus === "All" ? undefined : newStatus;

    if (dataGrid && dataGrid.instance) {
      dataGrid.instance.columnOption(
        "createdStatus",
        "filterValue",
        filterValue
      );
      dataGrid.instance.refresh();
    }
  };

  const addedByFilter = [
    { value: "All", text: "All" },
    { value: "Company", text: "Company" },
    { value: "Customer", text: "Customer" },
  ];

  const mapStatusToLabel = (status) => {
    const statusMap = {
      A: { label: "Approved", color: getStatusColor("Approved") },
      R: { label: "Rejected", color: getStatusColor("Rejected") },
      P: { label: "Pending", color: getStatusColor("Pending") },
    };

    const { label, color } = statusMap[status] || {
      label: status,
      color: "black",
    };

    return (
      <span className="col-main" data-type={label}>
        <span className="status-circle" style={{ backgroundColor: color }} />
        <span data-type={label}>{label}</span>
      </span>
    );
  };

  const getCustomer = async () => {
    var data = await getAllCustomer();
    if (data.hasError) {
      return toastDisplayer("error", data.errorMessage);
    } else {
      setCustomerData(data.responseData);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <DataGrid
      width={"100%"}
      dataSource={CustomerData}
      rowAlternationEnabled={true}
      showBorders={true}
      columnHidingEnabled={false}
      className="on-hover-data responsive-text"
      columnAutoWidth={true}
      hoverStateEnabled={false}
      ref={(ref) => {
        dataGrid = ref;
      }}
      // defaultSorting={[{ dataField: "entryDate", sortOrder: "desc" }]}
    >
      <Paging defaultPageSize={6} defaultPageIndex={0} />
      <Toolbar>
        <Item location="before">
          <div className="informer">
            <div className="count">
              <span className="toolbar-text-span">
                List of Registered Customers
              </span>
            </div>
          </div>
        </Item>
        <Item name="searchPanel" />
        <Item location="after">
          <SelectBox
            width={"100%"}
            className="selectbox-right"
            valueExpr="value"
            displayExpr="text"
            value={filterStatus}
            placeholder="Registered Customers"
            items={allSelectionStatus}
            onValueChanged={(e) => handleFilterChange(e.value)}
          />
        </Item>
        <Item location="after">
          <SelectBox
            width={150}
            className="selectbox-right"
            valueExpr="value"
            displayExpr="text"
            placeholder="Added by"
            items={addedByFilter}
            onValueChanged={(e) => handleAddedFilterChange(e.value)}
          />
        </Item>
      </Toolbar>
      <SearchPanel
        visible={true}
        highlightCaseSensitive={true}
        width={351}
        placeholder="Search Customer details"
      />
      <Selection
        mode="multiple"
        alignment="left"
        className="dx-checkbox-icon-radius"
        items={allSelectionStatus}
        width={231}
      />
      <Column
        caption="CUSTOMER TYPE"
        dataField="customerType"
        alignment="left"
        cellRender={(cellData) => {
          if (cellData.value == "D") return "Domestic";
          else return "Export";
        }}
        width={150}
      />
      <Column
        width={100}
        alignment="center"
        cellRender={(cell) => (
          <Button onClick={() => handleIconClick(cell)}>
            <ArrowOutwardIcon style={{ color: "#525252" }} />
          </Button>
        )}
      />
      <Column
        width={200}
        caption="STATE"
        dataField="status"
        alignment="lEFT"
        cellRender={(cellData) => mapStatusToLabel(cellData.value)}
      />
      <Column
        width={200}
        caption="CREATE DATE"
        dataField="entryDate"
        alignment="lEFT"
        dataType="datetime"
        format="d/M/yyyy, HH:mm"
        // defaultSortIndex={0}
        // defaultSortOrder="desc"
      />
      <Column
        width={200}
        caption="UPDATED DATE"
        dataField="updateDate"
        alignment="lEFT"
        dataType="datetime"
        format="d/M/yyyy, HH:mm"
        defaultSortIndex={0}
        defaultSortOrder="desc"
        visible={false}
      />
      <Column
        caption="CUSTOMER NAME"
        dataField="customerName"
        alignment="LEFT"
      />
      <Column caption="PHONE NO" dataField="officialPhone" alignment="LEFT" />
      <Column caption="ADDED BY" dataField="createdStatus" alignment="LEFT" />
      <Column
        caption="VERIFICATION REASON"
        dataField="reason"
        alignment="LEFT"
      />
    </DataGrid>
  );
};
