// import { Button } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import "./vendor-category.scss";
import AddPopup from "./add-popup";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
  HeaderFilter,
  RequiredRule,
} from "devextreme-react/data-grid";
import {
  deleteCategory,
  getAllCategory,
  saveCategory,
  updateCategory,
} from "../../../../api/setting";
import { toastDisplayer } from "../../../../components/comman card/toast-displayer";
import { ValidationRule } from "devextreme-react/cjs/validator";

function VendorCategory() {
  const [isCatExpand, setIsCatExpand] = useState(false);
  const [addPopupVisible, setAddPopupVisible] = useState(false);
  const [categoryData, setCategoryData] = useState(false);
  const gridRef = useRef(null);

  const getCatData = async () => {
    var apiRes = await getAllCategory();
    if (!apiRes.hasError) {
      setCategoryData(apiRes.responseData);
    } else {
      return toastDisplayer("error", apiRes.errorMessage);
    }
  };

  useEffect(() => {
    getCatData();
  }, []);

  const handleAddPopup = async () => {
    if (!isCatExpand) {
      await setIsCatExpand(true);
    }

    if (gridRef.current && gridRef.current.instance) {
      const gridInstance = gridRef.current.instance;

      try {
        gridInstance.addRow();
      } catch (error) {
        console.error("Error adding row:", error);
      }
    } else {
      console.error("Grid instance not available.");
    }
  };
  const handleCancelPopup = () => {
    setAddPopupVisible(false);
  };
  const handleGridExpand = () => {
    setIsCatExpand(!isCatExpand);
  };

  const handleAddCategory = async (e) => {
    var newName = e.data.venCatName;
    if (newName) {
      if (!newName.trim()) {
        e.cancel = true;
        return toastDisplayer("error", "Category name is required..!!");
      } else {
        const reqBody = {
          cat_Name: newName,
        };
        var apiRes = await saveCategory(reqBody);
        if (apiRes.hasError) {
          return toastDisplayer("error", apiRes.errorMessage);
        } else {
          return toastDisplayer("success", apiRes.responseData);
        }
      }
    } else {
      e.cancel = true;
      return toastDisplayer("error", "Category name is required..!!");
    }
  };

  const handleRemoveCategory = async (e) => {
    var newId = e.data.venCatId;
    if (newId) {
      var apiRes = await deleteCategory(newId);
      if (apiRes.hasError) {
        return toastDisplayer("error", apiRes.errorMessage);
      } else {
        return toastDisplayer("success", apiRes.responseData);
      }
    } else {
      e.cancel = true;
      return toastDisplayer("error", "Category name is required..!!");
    }
  };

  const handleUpdateCategory = async (e) => {
    var newName = e.data.venCatName;
    if (newName) {
      if (!newName.trim()) {
        e.cancel = true;
        return toastDisplayer("error", "Category name is required..!!");
      } else {
        const reqBody = {
          cat_Id: e.data.venCatId,
          cat_Name: newName,
        };
        var apiRes = await updateCategory(reqBody);
        if (apiRes.hasError) {
          e.cancel = true;
          return toastDisplayer("error", apiRes.errorMessage);
        } else {
          return toastDisplayer("success", apiRes.responseData);
        }
      }
    } else {
      e.cancel = true;
      return toastDisplayer("error", "Category name is required..!!");
    }
  };

  return (
    <>
      <div className="vendor-category">
        <div className="vendor-category-card">
          <div className="dx-card-title" onClick={handleGridExpand}>
            Category
            {isCatExpand ? (
              <Button icon="chevronup" />
            ) : (
              <Button icon="chevrondown" />
            )}
          </div>
          <div className="add-section">
            <Button
              className="add-btn"
              // width={120}
              height={48}
              text={"Add Category"}
              onClick={handleAddPopup}
            ></Button>
          </div>
        </div>
        {isCatExpand ? (
          <>
            <DataGrid
              id="gridContainer"
              dataSource={categoryData}
              keyExpr="venCatId"
              allowColumnReordering={true}
              showBorders={true}
              ref={gridRef}
              onRowUpdated={handleUpdateCategory}
              onRowRemoved={handleRemoveCategory}
              onRowInserted={handleAddCategory}
              // onEditCanceled={""}
              visible={isCatExpand}
            >
              <Paging defaultPageSize={6} defaultPageIndex={0} />
              <Editing mode="row" allowUpdating={true} allowDeleting={true} />
              <Column caption="CATEGORY NAME" dataField="venCatName">
                {/* <ValidationRule
                  type="required"
                  message="Category name is required"
                /> */}
                <RequiredRule message="Required" />
              </Column>
            </DataGrid>
          </>
        ) : (
          ""
        )}
      </div>

      {addPopupVisible && (
        <AddPopup
          subtext={"You can create new vendor category"}
          headertext={"Create New vendor category"}
          isVisible={addPopupVisible}
          cancelFunction={handleCancelPopup}
        />
      )}
    </>
  );
}

export default VendorCategory;
