import { Button } from "devextreme-react";
import React from "react";
import { VendorListing } from "../Vendor-lising/vendor-listing";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
import { navigation } from "../../../app-navigation";
import { routes } from "../../../app-routes";
import PageHeader from "../../../components/Page-Header/PageHeader";

export const AddVendorMain = () => {
  const navigate = useNavigate();

  const handleAddVednor = () => {
    navigate("/vendor/addvendor/registration");
  };

  const btnOption = {
    icon: "add",
    text: "Add Vendor",
  };

  return (
    <>
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={true}
        pageTitle={"Registered Vendors"}
        clickHandler={handleAddVednor}
        btnOption={btnOption}
        btnText={"Add Vendor"}
      />

      <div className="vendor-listing">
        <VendorListing from="A" />
      </div>
    </>
  );
};
