import React, { useCallback, useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/Bread-crums/BreadCrumbs";
// import { navigation } from "../../../app-navigation";
import { Button, CheckBox, Popup, TreeList } from "devextreme-react";
import TextBox, { Button as TextBoxButton } from "devextreme-react/text-box";
import { Column, SearchPanel, Toolbar } from "devextreme-react/cjs/tree-list";
import { Item } from "devextreme-react/cjs/toolbar";
import "./notification.scss";
import { helpIcon, saveIcon } from "../../../assets/icon";
import { HelperPopupPage } from "../../../components";
import {
  addNotificationRule,
  getUserData,
  getUserNotificationRule,
} from "../../../api/user-setting";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import ConformationPopup from "../../../components/conformation-popup/conformation-popup";
import {
  fakeData,
  navigation_main,
} from "../../../components/Common-Object/common-object";
import { useAuth } from "../../../contexts/auth";
import PageHeader from "../../../components/Page-Header/PageHeader";
export const NotificatioMain = () => {
  const [loading, setLoading] = useState(false);
  const [isModuleTreeVisible, setModuleTreeVisible] = useState(false);
  const [NotificationUserPopUp, setNotificationUserPopUp] = useState(false);
  const [CopyNotificationUserPopUp, setCopyNotificationUserPopUp] =
    useState(false);
  const [UserData, setUserData] = useState(false);
  const { AuthRuleContect } = useAuth();
  const updatedStates = {
    11: {
      A: false,
      S: false,
      E: false,
    },
    12: {
      A: false,
      S: false,
      E: false,
    },
    21: {
      A: false,
      S: false,
      E: false,
    },
    22: {
      A: false,
      S: false,
      E: false,
    },
  };
  const expandedKeys = [];
  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dataGridRefNotificationRule = useRef();
  const dataGridRefCopyNotificationRule = useRef();
  const [userCopyData, setUserCopyData] = useState([]);
  const [
    selectedRowKeysOnChangeNotification,
    setSelectedRowKeysOnChangeNotification,
  ] = useState([]);
  const [isConformation, setisConformation] = useState(false);
  const [selectedCopyRowKeys, setSelectedCopyRowKeys] = useState([]);
  const [userNotificationRule, setuserNotificationRule] = useState([]);
  const [
    selectedCopyRowKeysOnChangeNotification,
    setSelectedCopyRowKeysOnChangeNotification,
  ] = useState([]);
  useEffect(() => {
    const getData = async () => {
      let result = await getUserData();
      if (result.hasError) {
        return toastDisplayer("error", result.errorMessage);
      }
      setUserData(result.responseData);
    };
    getData();
  }, []);

  const handleCheckboxValueChanged = useCallback(
    (taskId, action, value) => {
      setCheckboxStates((prevCheckboxStates) => ({
        ...prevCheckboxStates,
        [taskId]: {
          ...prevCheckboxStates[taskId],
          [action]: value,
        },
      }));
    },
    [setCheckboxStates]
  );
  const userHelpOptions = {
    icon: helpIcon,
    onClick: () => showPopupHandler(),
  };
  const showPopupHandler = () => {
    setNotificationUserPopUp(true);
  };
  const handleCancel = () => {
    setNotificationUserPopUp(false);
  };
  const handleCopyPopupCancel = () => {
    setCopyNotificationUserPopUp(false);
  };
  const handleDataGridRowSelectionNotificationRuleUser = async ({
    selectedRowKeys,
  }) => {
    setSelectedRowKeysOnChangeNotification(selectedRowKeys);
    const length = await selectedRowKeys.length;
    if (selectedRowKeys.length > 1) {
      const value =
        await dataGridRefNotificationRule.current.instance.selectRows(
          selectedRowKeys[length - 1]
        );
      return selectedRowSetterApprove(value);
    } else {
      const value =
        await dataGridRefNotificationRule.current.instance.selectRows(
          selectedRowKeys[0]
        );
      return selectedRowSetterApprove(value);
    }
  };
  const selectedRowSetterApprove = async (params) => {
    setSelectedRowKeysOnChangeNotification(params);
  };
  const copyToPopUpData = (id) => {
    const new_data = UserData.filter((user) => user.user_ID !== id);
    setUserCopyData(new_data);
  };
  const handleSave = async () => {
    setSelectedRowKeys(selectedRowKeysOnChangeNotification);
    if (selectedRowKeysOnChangeNotification.length > 0) {
      setNotificationUserPopUp(false);
      copyToPopUpData(selectedRowKeysOnChangeNotification[0].userName);
      const reqData = {
        userName: selectedRowKeysOnChangeNotification[0].userName,
      };
      const getUserAuthRoleRes = await getUserNotificationRule(reqData);
      if (!getUserAuthRoleRes.hasError) {
        setuserNotificationRule(
          getUserAuthRoleRes.responseData.notification_Rule
        );
        findTaskIDWithAccess(
          getUserAuthRoleRes.responseData.notification_Rule,
          fakeData
        );
        // setdisableCopyText(false);
      } else {
        // setdisableCopyText(true);
        fakeData.forEach((task) => {
          updatedStates[task.Task_ID] = {
            C: false,
            R: false,
            U: false,
            D: false,
          };
        });
        setCheckboxStates(updatedStates);
      }
      setModuleTreeVisible(true);
    } else {
      return toastDisplayer("error", "Please select a user");
    }
  };
  useEffect(() => {
    if (userNotificationRule) {
      findTaskIDWithAccess(userNotificationRule, fakeData);
    } else {
      fakeData.forEach((task) => {
        updatedStates[task.Task_ID] = {
          A: false,
          S: false,
          E: false,
        };
      });
      setCheckboxStates(updatedStates);
    }
  }, []);
  function findTaskIDWithAccess(object, data) {
    setCheckboxStates(updatedStates);
    if (object.length > 0) {
      object.map((item) => {
        item.items.map((entry) => {
          const taskID = data.find(
            (sub_entry) =>
              sub_entry.Task_Subject.toLowerCase() === entry.text.toLowerCase()
          )?.Task_ID;
          setCheckboxStates((prevStates) => ({
            ...prevStates,
            [taskID]: {
              ...prevStates[taskID],
              A: entry.n_Rule.application,
              S: entry.n_Rule.email,
              E: entry.n_Rule.sms,
            },
          }));
        });
      });
    }
  }

  const handleNotificationRuleData = async () => {
    if (selectedRowKeysOnChangeNotification.length > 0) {
      //   setLoading(true);
      const finalNavigation = navigation_main.map((item) => {
        const childRights = item.items.map((subItem) => {
          const taskId = fakeData.find(
            (task) => task.Task_Subject === subItem.text
          )?.Task_ID;
          return {
            text: subItem.text,
            path: subItem.path,
            icon: subItem.icon,
            n_Rule: {
              application: checkboxStates[taskId]?.A ? true : false,
              sms: checkboxStates[taskId]?.S ? true : false,
              email: checkboxStates[taskId]?.E ? true : false,
            },
          };
        });

        return {
          text: item.text,
          path: item.path,
          icon: item.icon,
          items: childRights,
        };
      });

      const payload = {
        userName: selectedRowKeysOnChangeNotification[0].userName,
        N_Rule_Details: finalNavigation,
      };
      const apiResponse = await addNotificationRule(payload);
      if (apiResponse.hasError) {
        setisConformation(false);
        return toastDisplayer("error", apiResponse.errorMessage);
      } else {
        setisConformation(false);
        setSelectedRowKeys([]);
        setSelectedRowKeysOnChangeNotification([]);
        setModuleTreeVisible(false);
        // setdisableCopyText(true);
        setLoading(false);
        return toastDisplayer("success", apiResponse.responseData.statusMsg);
      }
    } else {
      setisConformation(false);
      setLoading(false);
      return toastDisplayer("error", "Please select a user");
    }
  };

  const handleCopySave = async () => {
    const res = [];
    await Promise.all(
      selectedCopyRowKeysOnChangeNotification.map(async (element) => {
        const payload = {
          userName: element,
          N_Rule_Details: userNotificationRule,
        };
        const apiResponse = await addNotificationRule(payload);
        res.push(apiResponse);
      })
    );

    if (res.length > 0) {
      if (res.some((item) => item.hasError)) {
        res.forEach((item) => {
          if (item.hasError) {
            toastDisplayer("error", item.errorMessage);
          }
        });
      } else {
        toastDisplayer("success", "Copy rule successfully...!!");
      }
    } else {
      return toastDisplayer("error", "Something went wrong");
    }

    setCopyNotificationUserPopUp(false);
  };
  const handleDataGridCopyRowSelectionNotificationRuleUser = async ({
    selectedRowKeys,
  }) => {
    setSelectedCopyRowKeysOnChangeNotification(selectedRowKeys);
  };
  const copyshowPopupHandler = () => {
    setCopyNotificationUserPopUp(true);
  };

  const copyhelpOptions = {
    icon: helpIcon,
    onClick: async () => {
      copyshowPopupHandler();
    },
  };
  const handleCloseConformPopup = () => {
    setisConformation(false);
  };
  const handleShowConformPopup = () => {
    setisConformation(true);
  };
  const btnOption = {
    text: "Authorise",
  };
  return (
    <>
      <ConformationPopup
        headertext={"Assign Notification Rule to User"}
        subtext={"Are you sure you want to send notfications to user?"}
        isVisible={isConformation}
        onHide={handleCloseConformPopup}
        saveFunction={handleNotificationRuleData}
        saveIcon={saveIcon}
        cancelBtnTxt="No"
        saveBtnTxt="Save"
      />
      {NotificationUserPopUp && (
        <Popup
          visible={true}
          height={window.innerHeight - 100}
          showCloseButton={false}
          className="initate-popup-css"
          showTitle={false}
          contentRender={() => (
            <HelperPopupPage
              title={"User Details"}
              caption={"Choose the user"}
              handleCancel={handleCancel}
              handleSave={handleSave}
              datagridData={UserData}
              keyExpr={"userName"}
              handleDataGridRowSelection={
                handleDataGridRowSelectionNotificationRuleUser
              }
              dataGridRef={dataGridRefNotificationRule}
              selectedRowKeys={selectedRowKeys}
            />
          )}
        ></Popup>
      )}
      {CopyNotificationUserPopUp && (
        <Popup
          visible={true}
          height={window.innerHeight - 100}
          showCloseButton={false}
          className="initate-popup-css"
          showTitle={false}
          contentRender={() => (
            <HelperPopupPage
              title={"Apply Same Notification Rule to Users"}
              caption={"Apply rules to users"}
              handleCancel={handleCopyPopupCancel}
              handleSave={handleCopySave}
              datagridData={userCopyData}
              keyExpr={"userName"}
              handleDataGridRowSelection={
                handleDataGridCopyRowSelectionNotificationRuleUser
              }
              dataGridRef={dataGridRefCopyNotificationRule}
              selectedRowKeys={selectedCopyRowKeys}
            />
          )}
        ></Popup>
      )}
      {/* <div className="content-header-block">
        <div
          className="content-header-block-breadcrumbs"
          style={{ width: "100%" }}
        >
          <Breadcrumbs navigation={AuthRuleContect} />
        </div>

        <div className="content-header-block-inner">
          <label>Notification - User Wise</label>
          <Button
            width={144}
            height={48}
            text="Authorise"
            disabled={!isModuleTreeVisible}
            onClick={handleShowConformPopup}
          ></Button>
        </div>
      </div> */}
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={true}
        pageTitle={"Notification - User Wise"}
        clickHandler={handleShowConformPopup}
        btnOption={btnOption}
      />
      <div className="main-content-div">
        <div className="auth-title-section">
          <TextBox
            label="User Name"
            labelMode="outside"
            placeholder="Input text"
            width={"40%"}
            className="seachBox"
            value={
              selectedRowKeysOnChangeNotification.length > 0
                ? selectedRowKeysOnChangeNotification[0].userName
                : ""
            }
          >
            <TextBoxButton
              name="popupSearch"
              location="after"
              options={userHelpOptions}
              height={44}
              width={44}
            />
          </TextBox>
        </div>
      </div>

      <div className="auth-container">
        {isModuleTreeVisible && (
          <div className="content-block">
            <div className="navigation-header-create-pro">
              {loading ? (
                // <Triangleloader />
                ""
              ) : (
                <>
                  <TreeList
                    dataSource={fakeData}
                    keyExpr="Task_ID"
                    parentIdExpr="Task_Parent_ID"
                    defaultExpandedRowKeys={expandedKeys}
                    defaultSelectedRowKeys={selectedKeys}
                    showRowLines={true}
                    showColumnLines={false}
                    columnAutoWidth={true}
                    className="tree-list-main"
                  >
                    <SearchPanel visible={true} />

                    <Column
                      dataField="Task_Subject"
                      caption="Modules"
                      // width={200}
                      width={300}
                    />
                    <Column dataField="Task_ID" visible={false} />
                    <Column
                      alignment={"center"}
                      caption="Application"
                      // width={200}
                      cellRender={(cellData) => {
                        const isSubRow = cellData.data.Task_Parent_ID !== 0;
                        if (isSubRow) {
                          return (
                            <>
                              <CheckBox
                                value={checkboxStates[cellData.data.Task_ID]?.A}
                                onValueChanged={(e) => {
                                  handleCheckboxValueChanged(
                                    cellData.data.Task_ID,
                                    "A",
                                    e.value
                                  );
                                }}
                              />
                            </>
                          );
                        }
                        return null;
                      }}
                    />
                    <Column
                      alignment={"center"}
                      caption="SMS"
                      // width={200}
                      visible={false}
                      cellRender={(cellData) => {
                        const isSubRow = cellData.data.Task_Parent_ID !== 0;
                        if (isSubRow) {
                          return (
                            <div>
                              <CheckBox
                                value={checkboxStates[cellData.data.Task_ID]?.S}
                                onValueChanged={(e) => {
                                  handleCheckboxValueChanged(
                                    cellData.data.Task_ID,
                                    "S",
                                    e.value
                                  );
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Column
                      alignment={"center"}
                      caption="Email"
                      // width={200}
                      visible={false}
                      cellRender={(cellData) => {
                        const isSubRow = cellData.data.Task_Parent_ID !== 0;
                        if (isSubRow) {
                          return (
                            <div>
                              <CheckBox
                                value={checkboxStates[cellData.data.Task_ID]?.E}
                                onValueChanged={(e) => {
                                  handleCheckboxValueChanged(
                                    cellData.data.Task_ID,
                                    "E",
                                    e.value
                                  );
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }}
                    />

                    <Toolbar className="header-toolbar-modules">
                      <Item location="before">
                        <div className="informer">
                          <span className="sub-text">All Modules</span>
                        </div>
                      </Item>
                      <Item name="searchPanel" />
                      <Item location="after">
                        <TextBox
                          placeholder="Pre-set Rule"
                          width={168}
                          className="report-right"
                        >
                          <TextBoxButton
                            name="popupSearch"
                            location="after"
                            options={copyhelpOptions}
                            height={44}
                            className="popup-icon"
                          />
                        </TextBox>
                      </Item>
                    </Toolbar>
                  </TreeList>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
