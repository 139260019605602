import axios from "axios";
import { logToServer } from "./logger";
import axiosInstance from "./axiosInstance";
const API_URL = process.env.REACT_APP_API;
const userData = localStorage.getItem("User");
export async function addNotificationRule(payload) {
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.post(`${API_URL}/NotificationRule`, payload);
    await logToServer(
      "notification",
      "notification",
      "NotificationRule",
      "S",
      "Notification Added..",
      JSON.stringify(payload),
      userData
    );
    responseBody.responseData = response.data;
    return responseBody;
  } catch (error) {
    await logToServer(
      "notification",
      "notification",
      "NotificationRule",
      "E",
      "Notificationrule Error..",
      JSON.stringify(payload),
      userData
    );
    responseBody.hasError = true;
    // responseBody.errorMessage = responseBody.errorMessage =
    //   error.response?.data?.statusMsg || error.response?.data?.errors;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

export const getUserNotificationRule = async (reqObj) => {
  // const myCookieValue = Cookies.get("token");
  // const userData = Cookies.get("User");
  const myCookieValue = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("User");
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    if (myCookieValue != null && userData != null) {
      // const headers = {
      //   Authorization: `Bearer ${myCookieValue}`,
      // };
      const response = await axiosInstance.get(
        `${API_URL}/NotificationRule?id=${reqObj.id}`
      );
      await logToServer(
        "notification",
        "notification",
        "NotificationRule",
        "S",
        "Get Notificationrule success..",
        JSON.stringify(reqObj.id),
        userData
      );
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      await logToServer(
        "notification",
        "notification",
        "NotificationRule",
        "E",
        "Get Notificationrule error..",
        JSON.stringify(reqObj.id),
        userData
      );
      responseBody.hasError = true;
      responseBody.errorMessage = "UnAuthorise for add user";
      return responseBody;
    }
  } catch (error) {
    responseBody.hasError = true;
    // responseBody.errorMessage =
    //   error.response?.data?.errorMessage || error.response?.data?.errors;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const updateNotificationStatus = async (n_ID, u_ID) => {
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.post(
      `${API_URL}/NotificationMaster/updateNotificationStatus`,
      {
        n_Id: n_ID,
      }
    );
    await logToServer(
      "notification",
      "NotificationMaster",
      "updateNotificationStatus",
      "S",
      "updateNotificationStatus success..",
      JSON.stringify(n_ID),
      userData
    );
    responseBody.responseData = response.data;
    return responseBody;
  } catch (error) {
    await logToServer(
      "notification",
      "NotificationMaster",
      "updateNotificationStatus",
      "E",
      "updateNotificationStatus Error..",
      JSON.stringify(n_ID),
      userData
    );
    responseBody.hasError = true;
    // responseBody.errorMessage =
    //   error.response?.data?.errorMessage || error.response?.data?.errors;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const GetALLNotification = async (username) => {
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.get(
      `${API_URL}/NotificationMaster/GetALlNotification?userName=${username}`
    );
    await logToServer(
      "notification",
      "NotificationMaster",
      "GetALlNotification",
      "S",
      "GetALlNotification Success..",
      JSON.stringify(username),
      userData
    );
    responseBody.responseData = response.data;
    return responseBody;
  } catch (error) {
    await logToServer(
      "notification",
      "NotificationMaster",
      "GetALlNotification",
      "E",
      "GetALlNotification Error..",
      JSON.stringify(username),
      userData
    );
    responseBody.hasError = true;
    // responseBody.errorMessage =
      // error.response?.data?.errorMessage || error.response?.data?.errors;
      responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const readAllNotificationStatus = async (user) => {
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.post(
      `${API_URL}/NotificationMaster/readAllNotificationStatus`,
      {
        username: user,
      }
    );
    await logToServer(
      "notification",
      "NotificationMaster",
      "readAllNotificationStatus",
      "S",
      "readAllNotificationStatus Success..",
      JSON.stringify(user),
      userData
    );
    responseBody.responseData = response.data;
    return responseBody;
  } catch (error) {
    await logToServer(
      "notification",
      "NotificationMaster",
      "readAllNotificationStatus",
      "E",
      "readAllNotificationStatus Error..",
      JSON.stringify(user),
      userData
    );
    responseBody.hasError = true;
    // responseBody.errorMessage =
    //   error.response?.data?.errorMessage || error.response?.data?.errors;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

export const AddNotification = async (req_Body) => {
  const responseBody = {
    responseData: null,
    hasError: false,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.post(
      `${API_URL}/NotificationMaster`,
      req_Body
    );
    await logToServer(
      "notification",
      "NotificationMaster",
      "AddNotificationMaster",
      "E",
      "AddNotification Success..",
      JSON.stringify(req_Body),
      userData
    );
    responseBody.responseData = response.data;
    return responseBody;
  } catch (error) {
    await logToServer(
      "notification",
      "NotificationMaster",
      "AddNotificationMaster",
      "E",
      "AddNotification Error..",
      JSON.stringify(req_Body),
      userData
    );
    responseBody.hasError = true;
    // responseBody.errorMessage =
    //   error.response?.data?.errorMessage || error.response?.data?.errors;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};
