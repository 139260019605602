import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
  useState,
} from "react";
import TreeView from "devextreme-react/tree-view";
import { Button } from "devextreme-react";
import { useNavigation } from "../../contexts/navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { useScreenSize } from "../../utils/media-query";
import "./SideNavigationMenu.scss";

import * as events from "devextreme/events";
import { UseHeaderContext } from "../../contexts/headerContext";
import { useAuth } from "../../contexts/auth";

export default function SideNavigationMenu({
  children,
  selectedItemChanged,
  openMenu,
  // compactMode,
  onMenuReady,
}) {
  const { AuthRuleContect } = useAuth();
  const navigate = useNavigate();
  const { isTreeviewOpen, toggleTreeview, setMenuStatus, MenuStatus } =
    UseHeaderContext();

  const location = useLocation();
  const path = location.pathname;

  const { isLarge, isSmall } = useScreenSize();

  const [selectedPath, setSelectedPath] = useState(null);

  function normalizePath() {
    return AuthRuleContect.map((item) => ({
      ...item,
      expanded: isLarge,
      path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
    }));
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    navigationData: { currentPath },
    setNavigationData,
  } = useNavigation();

  const handleTreeViewItemClick = (e) => {
    if (isSmall && isTreeviewOpen) toggleTreeview();
    const treeView = treeViewRef.current.instance;
    const clickedPath = e.itemData.path;

    if (!isTreeviewOpen) {
      treeView.collapseAll();
    }

    setNavigationData(path);
    navigate(clickedPath);

    selectedItemChanged(e);
  };

  const treeViewRef = useRef(null);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }
    setSelectedPath(
      path.split("/").length > 2 ? path.split("/").slice(0, 4).join("/") : path
    );
    localStorage.setItem("prevPath", path);
    if (path != undefined) {
      treeView.collapseAll(path);
      treeView.selectItem(path);
      treeView.expandItem(`/${path.split("/")[1]}`);
    }

    if (!isTreeviewOpen) {
      treeView.collapseAll();
      treeView.selectItem(`/${path.split("/")[1]}`);
    }

    // if (isSmall) {
    //   treeView.collapseAll();
    //   treeView.selectItem(`/${path.split("/")[1]}`);
    // }
  }, [currentPath, isTreeviewOpen]);

  const itemRender = (items) => {
    return (
      <>
        <i
          className={`dx-icon material-symbols-outlined ${
            !isTreeviewOpen ? "treeview-icon" : ""
          }`}
        >
          {items.icon}
        </i>
        <span className="itemTxt">{items.text}</span>
      </>
    );
  };

  const clickHandler = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
    toggleTreeview();
  }, []);

  return (
    <div className={"side-navigation-menu"}>
      {children}
      <div className={"menu-container"}>
        <div className="dx-field-value">
          <Button
            icon="search"
            type="success"
            text="Search"
            className="searchHandler"
            onClick={clickHandler}
          />
        </div>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={handleTreeViewItemClick}
          onContentReady={onMenuReady}
          width={"100%"}
          itemRender={itemRender}
          animationEnabled={true}
          searchEnabled={true}
        />
      </div>
    </div>
  );
}
