import React from "react";
import Breadcrumbs from "../Bread-crums/BreadCrumbs";
import { Button } from "devextreme-react";
// import { navigation } from "../../app-navigation";
import "./PageHeader.scss";
import { useAuth } from "../../contexts/auth";

function PageHeader({
  isBtn1Visisble,
  isBtn2Visisble,
  pageTitle,
  btnOption,
  clickHandler,
  clickHandler2,
  btnOption2,
  statusPage,
  isBreadcrumbsVisible,
}) {
  const { AuthRuleContect } = useAuth();
  const getStatusColor = (status) => {
    const statusColors = {
      Approved: "#124d22",
      Pending: "#06548b",
      Rejected: "#AD1820",
    };

    return statusColors[status] || "#000";
  };
  return (
    <>
      <div className="content-header-block">
        {isBreadcrumbsVisible ? (
          <>
            <div
              className="content-header-block-breadcrumbs"
              style={{ width: "100%" }}
            >
              <Breadcrumbs navigation={AuthRuleContect} />
            </div>
          </>
        ) : (
          ""
        )}

        <div className="content-header-block-inner">
          <div>
            <label>{pageTitle}</label>
            {statusPage ? (
              <span className="col-main" data-type={statusPage}>
                <span
                  className="status-circle"
                  style={{ backgroundColor: getStatusColor(statusPage) }}
                />
                <span data-type={statusPage} className="status-text">
                  {statusPage}
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="btn-section">
            {isBtn2Visisble ? (
              <Button
                width={144}
                height={48}
                text={btnOption2.text}
                icon={btnOption2.icon}
                onClick={clickHandler2}
                useSubmitBehavior={true}
                stylingMode="outlined"
                className="rejectBtn"
              ></Button>
            ) : (
              ""
            )}
            {isBtn1Visisble ? (
              <Button
                width={150}
                height={48}
                text={btnOption.text}
                icon={btnOption.icon}
                onClick={clickHandler}
                useSubmitBehavior={true}
              ></Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHeader;
