// ApprovalPopup.js
import React, { useEffect, useRef, useState } from "react";
import { Popup, TextBox, Button } from "devextreme-react";
// import "../../../../components/conformation-popup/conformation.scss";

const AddPopup = ({
  headertext,
  subtext,
  isVisible,
  saveFunction,
  cancelFunction,
  handleInputChange,
}) => {
  const [remark1, setRemark1] = useState("");
  const textBoxRef = useRef(null);
  useEffect(() => {
    if (isVisible && textBoxRef.current) {
      textBoxRef.current.instance.focus();
    }
  }, [isVisible]);

  return (
    <Popup
      visible={isVisible}
      //   onHiding={onHide}
      // width={"auto"}
      height={280}
      width={480}
      showCloseButton={false}
      dragEnabled={false}
      showTitle={false}
      className="add-popup"
    >
      <div className="add-popup-main-container">
        <div className="release-popup-main">
          <div>
            <div className="conform-popup-header-text">{headertext}</div>
            <div className="conform-popup-sub-text">{subtext}</div>
          </div>
          <div className="popup-close-btn">
            <Button icon="close" onClick={cancelFunction} />
          </div>
        </div>

        <TextBox
          label="Vendor category name"
          labelMode="outside"
          width={"100%"}
          onValueChanged={(e) => handleInputChange(e.value)}
        ></TextBox>
        <div className="popup-footer">
          <Button
            text={"Cancel"}
            width={"100%"}
            height={44}
            onClick={cancelFunction}
            type="Cancel"
            className="cancelBtn"
          />

          <Button
            text={"Add"}
            type="default"
            width={"100%"}
            height={44}
            onClick={saveFunction}
            className="saveBtn"
          />
        </div>
      </div>
    </Popup>
  );
};

export default AddPopup;
