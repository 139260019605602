import axios from "axios";
import { logToServer } from "./logger";
import { AddNotification } from "./notification";
import axiosInstance from "./axiosInstance";
const API_URL = process.env.REACT_APP_API;
const userData = localStorage.getItem("User");

export async function getAllCustomer() {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };

  try {
    var apiRes = await axiosInstance.get(`${API_URL}/Customer/GetCustomer`);
    if (apiRes.status == 200) {
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      await logToServer(
        "Customer",
        "Customer",
        "GetCustomer",
        "S",
        "get allCustomer..",
        "",
        userData
      );
      return responseBody;
    } else {
      responseBody.errorMessage = "No Data..!!";
    }
  } catch (error) {
    await logToServer(
      "Customer",
      "Customer",
      "GetCustomer",
      "E",
      error.StatusMsg,
      "",
      userData
    );

    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;

    return responseBody;
  }
}

export async function saveCustomer(reqBody) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axios.post(`${API_URL}/Customer/SaveCustomer`, reqBody);
    if (apiRes.status == 200) {
      var req_Body = "";
      if (userData) {
        req_Body = {
          module: "Add customer",
          Sender_User_Name: userData,
          notification_Text: `${reqBody.CustomerName} Customer has been added`,
        };
      } else {
        req_Body = {
          module: "Add Customer",
          notification_Text: `${reqBody.CustomerName} Customer has been added`,
        };
      }
      await AddNotification(req_Body);
      await logToServer(
        "Customer",
        "Customer",
        "SaveCustomer",
        "S",
        "Successfully customer added..",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Not Save Data..!!";
    }
  } catch (error) {
    await logToServer(
      "Customer",
      "Customer",
      "SaveCustomer",
      "E",
      "error while adding customer..",
      JSON.stringify(reqBody),
      userData
    );
    responseBody.errorMessage = responseBody.errorMessage =
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

export async function GetCustomerDetail(vendorId) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.get(
      `${API_URL}/Customer/GetCustDetail?customerCode=${vendorId}`
    );
    if (apiRes.status == 200) {
      await logToServer(
        "Customer",
        "Customer",
        "GetCustDetail",
        "S",
        "successfully fetching customer..",
        JSON.stringify(vendorId),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      await logToServer(
        "Customer",
        "Customer",
        "GetCustDetail",
        "E",
        "Error while fetching..",
        JSON.stringify(vendorId),
        userData
      );
      responseBody.errorMessage = "Error while fetching..!!";
    }
  } catch (error) {
    await logToServer(
      "Customer",
      "Customer",
      "GetCustDetail",
      "E",
      "Error while fetching..",
      JSON.stringify(vendorId),
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

export async function verifyCustomer(reqBody,customer) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.put(`${API_URL}/Customer/VerifyCustomer`, reqBody);
    console.log("apiRes : ",apiRes);
    if (apiRes.status == 200) {
      const req_Body = {
        module: "Verify customer",
        Sender_User_Name: userData,
        notification_Text: `${customer} Customer has been ${
          reqBody.action == "A"
            ? "Approved"
            : reqBody.action == "R"
            ? "Rejected"
            : ""
        }`,
      };
      await AddNotification(req_Body);
      await logToServer(
        "Customer",
        "Customer",
        "VerifyCustomer",
        "S",
        "Successfully  Verify Customer..",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    }else if(apiRes.status == 400){
      responseBody.hasError = true;
      await logToServer(
        "Customer",
        "Customer",
        "VerifyCustomer",
        "E",
        "Error while VerifyCustomer..",
        JSON.stringify(reqBody),
        userData
      );
      // responseBody.errorMessage = error.response.data.statusMsg;
      responseBody.errorMessage = apiRes?.statusMsg;
      return responseBody;
    } else {
      await logToServer(
        "Customer",
        "Customer",
        "VerifyCustomer",
        "E",
        "Error while VerifyCustomer..",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.errorMessage = "Not Save Data..!!";
    }
  } catch (error) {
    // console.log("Error : ",error.response.data.statusMsg);
    await logToServer(
      "Customer",
      "Customer",
      "VerifyCustomer",
      "E",
      "Error while VerifyCustomer..",
      JSON.stringify(reqBody),
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = 
          error.response?.data?.statusMsg || error.response?.data?.errors || error.message;

    return responseBody;
  }
}

export async function GetCustomerDetailByName(customerName) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.post(`${API_URL}/Customer/GetCustDetailByName`, {
      customerName: customerName,
    });
    if (apiRes.status == 200) {
      await logToServer(
        "Customer",
        "Customer",
        "GetCustDetailByName",
        "S",
        "successfully fetching customer..",
        JSON.stringify(customerName),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      await logToServer(
        "Customer",
        "Customer",
        "GetCustDetailByName",
        "E",
        "Error while fetching..",
        JSON.stringify(customerName),
        userData
      );
      responseBody.errorMessage = "Error while fetching..!!";
    }
  } catch (error) {
    await logToServer(
      "Customer",
      "Customer",
      "GetCustDetailByName",
      "E",
      "Error while fetching..",
      JSON.stringify(customerName),
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}

export async function updateCustomer(reqBody) {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    var apiRes = await axiosInstance.put(`${API_URL}/Customer/UpdateCustomer`, reqBody);
    if (apiRes.status == 200) {
      var req_Body = "";
      if (userData) {
        req_Body = {
          module: "Update customer",
          Sender_User_Name: userData,
          notification_Text: `${reqBody.CustomerName} Customer has been Updated`,
        };
      } else {
        req_Body = {
          module: "Update Customer",
          notification_Text: `${reqBody.CustomerName} Customer has been Updated`,
        };
      }
      await AddNotification(req_Body);
      await logToServer(
        "Customer",
        "Customer",
        "SaveCustomer",
        "S",
        "Successfully customer Updated..",
        JSON.stringify(reqBody),
        userData
      );
      responseBody.hasError = false;
      responseBody.responseData = apiRes.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Not Save Data..!!";
    }
  } catch (error) {
    await logToServer(
      "Customer",
      "Customer",
      "SaveCustomer",
      "E",
      "error while Updating customer..",
      JSON.stringify(reqBody),
      userData
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
}
