 export const allCurrencies = [
  { currCode: "AED", currName: "AED - United Arab Emirates Dirham" },
  { currCode: "AFN", currName: "AFN - Afghan Afghani" },
  { currCode: "ALL", currName: "ALL - Albanian Lek" },
  { currCode: "AMD", currName: "AMD - Armenian Dram" },
  { currCode: "ANG", currName: "ANG - Netherlands Antillean Guilder" },
  { currCode: "AOA", currName: "AOA - Angolan Kwanza" },
  { currCode: "ARS", currName: "ARS - Argentine Peso" },
  { currCode: "AUD", currName: "AUD - Australian Dollar" },
  { currCode: "AWG", currName: "AWG - Aruban Florin" },
  { currCode: "AZN", currName: "AZN - Azerbaijani Manat" },
  { currCode: "BAM", currName: "BAM - Bosnia-Herzegovina Convertible Mark" },
  { currCode: "BBD", currName: "BBD - Barbadian Dollar" },
  { currCode: "BDT", currName: "BDT - Bangladeshi Taka" },
  { currCode: "BGN", currName: "BGN - Bulgarian Lev" },
  { currCode: "BHD", currName: "BHD - Bahraini Dinar" },
  { currCode: "BIF", currName: "BIF - Burundian Franc" },
  { currCode: "BMD", currName: "BMD - Bermudian Dollar" },
  { currCode: "BND", currName: "BND - Brunei Dollar" },
  { currCode: "BOB", currName: "BOB - Bolivian Boliviano" },
  { currCode: "BRL", currName: "BRL - Brazilian Real" },
  { currCode: "BSD", currName: "BSD - Bahamian Dollar" },
  { currCode: "BTN", currName: "BTN - Bhutanese Ngultrum" },
  { currCode: "BWP", currName: "BWP - Botswanan Pula" },
  { currCode: "BYN", currName: "BYN - Belarusian Ruble" },
  { currCode: "BZD", currName: "BZD - Belize Dollar" },
  { currCode: "CAD", currName: "CAD - Canadian Dollar" },
  { currCode: "CDF", currName: "CDF - Congolese Franc" },
  { currCode: "CHF", currName: "CHF - Swiss Franc" },
  { currCode: "CLP", currName: "CLP - Chilean Peso" },
  { currCode: "CNY", currName: "CNY - Chinese Yuan" },
  { currCode: "COP", currName: "COP - Colombian Peso" },
  { currCode: "CRC", currName: "CRC - Costa Rican Colón" },
  { currCode: "CUP", currName: "CUP - Cuban Peso" },
  { currCode: "CVE", currName: "CVE - Cape Verdean Escudo" },
  { currCode: "CZK", currName: "CZK - Czech Republic Koruna" },
  { currCode: "DJF", currName: "DJF - Djiboutian Franc" },
  { currCode: "DKK", currName: "DKK - Danish Krone" },
  { currCode: "DOP", currName: "DOP - Dominican Peso" },
  { currCode: "DZD", currName: "DZD - Algerian Dinar" },
  { currCode: "EGP", currName: "EGP - Egyptian Pound" },
  { currCode: "ERN", currName: "ERN - Eritrean Nakfa" },
  { currCode: "ETB", currName: "ETB - Ethiopian Birr" },
  { currCode: "EUR", currName: "EUR - Euro" },
  { currCode: "FJD", currName: "FJD - Fijian Dollar" },
  { currCode: "FKP", currName: "FKP - Falkland Islands Pound" },
  { currCode: "FOK", currName: "FOK - Fynsk Krone" },
  { currCode: "GBP", currName: "GBP - British Pound Sterling" },
  { currCode: "GEL", currName: "GEL - Georgian Lari" },
  { currCode: "GGP", currName: "GGP - Guernsey Pound" },
  { currCode: "GHS", currName: "GHS - Ghanaian Cedi" },
  { currCode: "GIP", currName: "GIP - Gibraltar Pound" },
  { currCode: "GMD", currName: "GMD - Gambian Dalasi" },
  { currCode: "GNF", currName: "GNF - Guinean Franc" },
  { currCode: "GTQ", currName: "GTQ - Guatemalan Quetzal" },
  { currCode: "GYD", currName: "GYD - Guyanaese Dollar" },
  { currCode: "HKD", currName: "HKD - Hong Kong Dollar" },
  { currCode: "HNL", currName: "HNL - Honduran Lempira" },
  { currCode: "HRK", currName: "HRK - Croatian Kuna" },
  { currCode: "HTG", currName: "HTG - Haitian Gourde" },
  { currCode: "HUF", currName: "HUF - Hungarian Forint" },
  { currCode: "IDR", currName: "IDR - Indonesian Rupiah" },
  { currCode: "ILS", currName: "ILS - Israeli New Shekel" },
  { currCode: "IMP", currName: "IMP - Isle of Man Pound" },
  { currCode: "INR", currName: "INR - Indian Rupee" },
  { currCode: "IQD", currName: "IQD - Iraqi Dinar" },
  { currCode: "IRR", currName: "IRR - Iranian Rial" },
  { currCode: "ISK", currName: "ISK - Icelandic Króna" },
  { currCode: "JEP", currName: "JEP - Jersey Pound" },
  { currCode: "JMD", currName: "JMD - Jamaican Dollar" },
  { currCode: "JOD", currName: "JOD - Jordanian Dinar" },
  { currCode: "JPY", currName: "JPY - Japanese Yen" },
  { currCode: "KES", currName: "KES - Kenyan Shilling" },
  { currCode: "KGS", currName: "KGS - Kyrgystani Som" },
  { currCode: "KHR", currName: "KHR - Cambodian Riel" },
  { currCode: "KID", currName: "KID - Kiribati Dollar" },
  { currCode: "KMF", currName: "KMF - Comorian Franc" },
  { currCode: "KRW", currName: "KRW - South Korean Won" },
  { currCode: "KWD", currName: "KWD - Kuwaiti Dinar" },
  { currCode: "KYD", currName: "KYD - Cayman Islands Dollar" },
  { currCode: "KZT", currName: "KZT - Kazakhstani Tenge" },
  { currCode: "LAK", currName: "LAK - Laotian Kip" },
  { currCode: "LBP", currName: "LBP - Lebanese Pound" },
  { currCode: "LKR", currName: "LKR - Sri Lankan Rupee" },
  { currCode: "LRD", currName: "LRD - Liberian Dollar" },
  { currCode: "LSL", currName: "LSL - Lesotho Loti" },
  { currCode: "LYD", currName: "LYD - Libyan Dinar" },
  { currCode: "MAD", currName: "MAD - Moroccan Dirham" },
  { currCode: "MDL", currName: "MDL - Moldovan Leu" },
  { currCode: "MGA", currName: "MGA - Malagasy Ariary" },
  { currCode: "MKD", currName: "MKD - Macedonian Denar" },
  { currCode: "MMK", currName: "MMK - Myanma Kyat" },
  { currCode: "MNT", currName: "MNT - Mongolian Tugrik" },
  { currCode: "MOP", currName: "MOP - Macanese Pataca" },
  { currCode: "MRU", currName: "MRU - Mauritanian Ouguiya" },
  { currCode: "MUR", currName: "MUR - Mauritian Rupee" },
  { currCode: "MVR", currName: "MVR - Maldivian Rufiyaa" },
  { currCode: "MWK", currName: "MWK - Malawian Kwacha" },
  { currCode: "MXN", currName: "MXN - Mexican Peso" },
  { currCode: "MYR", currName: "MYR - Malaysian Ringgit" },
  { currCode: "MZN", currName: "MZN - Mozambican Metical" },
  { currCode: "NAD", currName: "NAD - Namibian Dollar" },
  { currCode: "NGN", currName: "NGN - Nigerian Naira" },
  { currCode: "NIO", currName: "NIO - Nicaraguan Córdoba" },
  { currCode: "NOK", currName: "NOK - Norwegian Krone" },
  { currCode: "NPR", currName: "NPR - Nepalese Rupee" },
  { currCode: "NZD", currName: "NZD - New Zealand Dollar" },
  { currCode: "OMR", currName: "OMR - Omani Rial" },
  { currCode: "PAB", currName: "PAB - Panamanian Balboa" },
  { currCode: "PEN", currName: "PEN - Peruvian Nuevo Sol" },
  { currCode: "PGK", currName: "PGK - Papua New Guinean Kina" },
  { currCode: "PHP", currName: "PHP - Philippine Peso" },
  { currCode: "PKR", currName: "PKR - Pakistani Rupee" },
  { currCode: "PLN", currName: "PLN - Polish Zloty" },
  { currCode: "PYG", currName: "PYG - Paraguayan Guarani" },
  { currCode: "QAR", currName: "QAR - Qatari Rial" },
  { currCode: "RON", currName: "RON - Romanian Leu" },
  { currCode: "RSD", currName: "RSD - Serbian Dinar" },
  { currCode: "RUB", currName: "RUB - Russian Ruble" },
  { currCode: "RWF", currName: "RWF - Rwandan Franc" },
  { currCode: "SAR", currName: "SAR - Saudi Riyal" },
  { currCode: "SBD", currName: "SBD - Solomon Islands Dollar" },
  { currCode: "SCR", currName: "SCR - Seychellois Rupee" },
  { currCode: "SDG", currName: "SDG - Sudanese Pound" },
  { currCode: "SEK", currName: "SEK - Swedish Krona" },
  { currCode: "SGD", currName: "SGD - Singapore Dollar" },
  { currCode: "SHP", currName: "SHP - Saint Helena Pound" },
  { currCode: "SLL", currName: "SLL - Sierra Leonean Leone" },
  { currCode: "SOS", currName: "SOS - Somali Shilling" },
  { currCode: "SRD", currName: "SRD - Surinamese Dollar" },
  { currCode: "SSP", currName: "SSP - South Sudanese Pound" },
  { currCode: "STN", currName: "STN - São Tomé and Príncipe Dobra" },
  { currCode: "SYP", currName: "SYP - Syrian Pound" },
  { currCode: "SZL", currName: "SZL - Swazi Lilangeni" },
  { currCode: "THB", currName: "THB - Thai Baht" },
  { currCode: "TJS", currName: "TJS - Tajikistani Somoni" },
  { currCode: "TMT", currName: "TMT - Turkmenistani Manat" },
  { currCode: "TND", currName: "TND - Tunisian Dinar" },
  { currCode: "TOP", currName: "TOP - Tongan Pa'anga" },
  { currCode: "TRY", currName: "TRY - Turkish Lira" },
  { currCode: "TTD", currName: "TTD - Trinidad and Tobago Dollar" },
  { currCode: "TWD", currName: "TWD - New Taiwan Dollar" },
  { currCode: "TZS", currName: "TZS - Tanzanian Shilling" },
  { currCode: "UAH", currName: "UAH - Ukrainian Hryvnia" },
  { currCode: "UGX", currName: "UGX - Ugandan Shilling" },
  { currCode: "USD", currName: "USD - United States Dollar" },
  { currCode: "UYU", currName: "UYU - Uruguayan Peso" },
  { currCode: "UZS", currName: "UZS - Uzbekistan Som" },
  { currCode: "VES", currName: "VES - Venezuelan Bolívar" },
  { currCode: "VND", currName: "VND - Vietnamese Dong" },
  { currCode: "VUV", currName: "VUV - Vanuatu Vatu" },
  { currCode: "WST", currName: "WST - Samoan Tala" },
  { currCode: "XAF", currName: "XAF - Central African CFA Franc" },
  { currCode: "XCD", currName: "XCD - East Caribbean Dollar" },
  { currCode: "XDR", currName: "XDR - Special Drawing Rights" },
  { currCode: "XOF", currName: "XOF - West African CFA Franc" },
  { currCode: "XPF", currName: "XPF - CFP Franc" },
  { currCode: "YER", currName: "YER - Yemeni Rial" },
  { currCode: "ZAR", currName: "ZAR - South African Rand" },
  { currCode: "ZMW", currName: "ZMW - Zambian Kwacha" },
  { currCode: "ZWL", currName: "ZWL - Zimbabwean Dollar" }
];


export const allPincode = [          
  { stateCode: 1, stateName: "JAMMU AND KASHMIR", pincodeRange: { start: 180, end: 194 } },
    { stateCode: 2, stateName: "HIMACHAL PRADESH", pincodeRange: { start: 171, end: 177 } },
    { stateCode: 3, stateName: "PUNJAB", pincodeRange: { start: 140, end: 160 } },
    { stateCode: 4, stateName: "CHANDIGARH", pincodeRange: { start: 160, end: 160 } },
    { stateCode: 5, stateName: "UTTARAKHAND", pincodeRange: { start: 244, end: 263 } },
    { stateCode: 6, stateName: "HARYANA", pincodeRange: { start: 121, end: 136 } },
    { stateCode: 7, stateName: "DELHI", pincodeRange: { start: 110, end: 110 } },
    { stateCode: 8, stateName: "RAJASTHAN", pincodeRange: { start: 301, end: 345 } },
    { stateCode: 9, stateName: "UTTAR PRADESH", pincodeRange: { start: 201, end: 285 } },
    { stateCode: 10, stateName: "BIHAR", pincodeRange: { start: 800, end: 855 } },
    { stateCode: 11, stateName: "SIKKIM", pincodeRange: { start: 737, end: 737 } },
    { stateCode: 12, stateName: "ARUNACHAL PRADESH", pincodeRange: { start: 790, end: 792 } },
    { stateCode: 13, stateName: "NAGALAND", pincodeRange: { start: 797, end: 798 } },
    { stateCode: 14, stateName: "MANIPUR", pincodeRange: { start: 795, end: 795 } },
    { stateCode: 15, stateName: "MIZORAM", pincodeRange: { start: 796, end: 796 } },
    { stateCode: 16, stateName: "TRIPURA", pincodeRange: { start: 799, end: 799 } },
    { stateCode: 17, stateName: "MEGHALAYA", pincodeRange: { start: 793, end: 794 } },
    { stateCode: 18, stateName: "ASSAM", pincodeRange: { start: 781, end: 788 } },
    { stateCode: 19, stateName: "WEST BENGAL", pincodeRange: { start: 700, end: 743 } },
    { stateCode: 20, stateName: "JHARKHAND", pincodeRange: { start: 813, end: 835 } },
    { stateCode: 21, stateName: "ODISHA", pincodeRange: { start: 751, end: 770 } },
    { stateCode: 22, stateName: "CHHATTISGARH", pincodeRange: { start: 490, end: 497 } },
    { stateCode: 23, stateName: "MADHYA PRADESH", pincodeRange: { start: 450, end: 488 } },
    { stateCode: 24, stateName: "GUJARAT", pincodeRange: { start: 360, end: 396 } },
    { stateCode: 25, stateName: "DAMAN AND DIU", pincodeRange: { start: 362, end: 362 } },
    { stateCode: 25, stateName: "DAMAN AND DIU", pincodeRange: { start: 396, end: 396 } },
    { stateCode: 26, stateName: "DADRA AND NAGAR HAVELI", pincodeRange: { start: 396, end: 396 } },
    { stateCode: 27, stateName: "MAHARASHTRA", pincodeRange: { start: 400, end: 445 } },
    { stateCode: 29, stateName: "KARNATAKA", pincodeRange: { start: 560, end: 591 } },
    { stateCode: 30, stateName: "GOA", pincodeRange: { start: 403, end: 403 } },
    { stateCode: 31, stateName: "LAKSHADWEEP", pincodeRange: { start: 682, end: 682 } },
    { stateCode: 32, stateName: "KERALA", pincodeRange: { start: 670, end: 695 } },
    { stateCode: 33, stateName: "TAMIL NADU", pincodeRange: { start: 600, end: 643 } },
    { stateCode: 34, stateName: "PUDUCHERRY", pincodeRange: { start: 533, end: 533 } },
    { stateCode: 34, stateName: "PUDUCHERRY", pincodeRange: { start: 605, end: 605 } },
    { stateCode: 34, stateName: "PUDUCHERRY", pincodeRange: { start: 607, end: 607 } },
    { stateCode: 34, stateName: "PUDUCHERRY", pincodeRange: { start: 609, end: 609 } },
    { stateCode: 35, stateName: "ANDAMAN AND NICOBAR", pincodeRange: { start: 744, end: 744 } },
    { stateCode: 36, stateName: "TELANGANA", pincodeRange: { start: 500, end: 509 } },
    { stateCode: 37, stateName: "ANDHRA PRADESH", pincodeRange: { start: 500, end: 535 } }
]