import React from "react";
import PageHeader from "../../../components/Page-Header/PageHeader";
import { CustomerListing } from "../customer-listing/cutomer-listing";

export const VerifyCustomerMain = () => {
  return (
    <>
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtnVisisble={false}
        pageTitle={"Verify Customer"}
      />

      <div className="vendor-listing">
        <CustomerListing from={"V"} />
      </div>
    </>
  );
};
