import React from "react";
import "./addCustomer.scss";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/Page-Header/PageHeader";
import { CustomerListing } from "../customer-listing/cutomer-listing";

export const AddCustomerMain = () => {
  const navigate = useNavigate();

  const handleAddCustomer = () => {
    navigate("/customer/addcustomer/registration");
  };
  const btnOption = {
    icon: "add",
    text: "Add Customer",
  };
  return (
    <>
      <PageHeader
        isBreadcrumbsVisible={true}
        isBtn1Visisble={true}
        pageTitle={"Registered Customers"}
        clickHandler={handleAddCustomer}
        btnOption={btnOption}
      />

      <div className="vendor-listing">
        <CustomerListing from={"A"} />
      </div>
    </>
  );
};
