import axios from "axios";
import axiosInstance from "./axiosInstance";
const API_URL = process.env.REACT_APP_API;

export const getAllModules = async () => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.get(`${API_URL}/Common/GetAllModules`);

    if (response.status === 200) {
      responseBody.hasError = false;
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Error while fetching data..!!";
      return responseBody;
    }
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage =
      error.message ||
      error.response?.data?.statusMsg ||
      error.response?.data?.errors;
    return responseBody;
  }
};
export const getAllApiLog = async (reqPayload) => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  try {
    const response = await axiosInstance.post(`${API_URL}/Log/getLogs`, reqPayload);

    if (response.status === 200) {
      responseBody.hasError = false;
      responseBody.responseData = response.data;
      return responseBody;
    } else {
      responseBody.errorMessage = "Error while fetching data..!!";
      return responseBody;
    }
  } catch (error) {
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage =
      error.message ||
      error.response?.data?.statusMsg ||
      error.response?.data?.errors;
    return responseBody;
  }
};
