import { RoundaboutRight } from '@mui/icons-material';
import { Button } from 'devextreme-react';
import React, { useState } from 'react'

const ApprovalPopupContent = (
{
    hanldePopupClose,
    headertext,
    headerSubText
}
) => {

const closePopup =()=>{
    hanldePopupClose();
}



return (
  <>
  <div className="popup-container">

  <div className="PopupHeader">
    <div className="header-icon"><RoundaboutRight /></div>
    <div className="header-close-btn"><Button icon="close" height={44} onClick={closePopup}/></div>
  </div>

  <div className="PopupBody">
    <div className="body-main-container">
    <span>{headertext}</span>
    </div>
    <div className="body-sub-container">
    {headerSubText}
    </div>
  </div>

  <div className="popupfooter">
    <Button text="Discard" width={216} height={44} className="popop-discard-btn" style={{backGroundColor:"white" , border:"1px solid #c6c6c6"}}/>
    <Button text="Send For Approval" width={216} height={44} className="popop-confirm-btn" />
  </div>

  </div>
</>
  )
}

export default ApprovalPopupContent


