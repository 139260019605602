import { Button, DataGrid, SelectBox } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { Item } from "devextreme-react/cjs/accordion";
import {
  Column,
  Editing,
  Toolbar,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
} from "devextreme-react/cjs/data-grid";
import { changeUserPassword, getUserData } from "../../../api/user-setting";
import { toastDisplayer } from "../../../components/comman card/toast-displayer";
import ConformationPopup from "../../../components/conformation-popup/conformation-popup";
import { saveIcon } from "../../../assets/icon";

const UserListing = () => {
  const [userGridData, setUserGridData] = useState([]);
  const [loading, setloading] = useState(false);
  const [isConformation, setIsconformation] = useState(false);
  const [selectedData, setSetSelectedData] = useState({
    userEmail: null,
    newPassword: null,
    username: null,
  });
  const getAllUserData = async () => {
    setloading(true);
    try {
      const response = await getUserData();
      setloading(false);
      setUserGridData(response.responseData);
    } catch {
      setloading(true);
      return toastDisplayer("error", "Something went wrong..!!");
    }
  };

  useEffect(() => {
    getAllUserData();
  }, []);

  const handleRowUpdated = async (e) => {
    setIsconformation(true);
    const { userEmail, new_password, userName } = e.data;
    setSetSelectedData({
      userEmail: userEmail,
      newPassword: new_password,
      username: userName,
    });
  };

  const handleUpdatePassword = async () => {
    var reqObj = {
      email: selectedData.userEmail,
      user_Password: selectedData.newPassword,
    };
    var response = await changeUserPassword(reqObj);
    if (response.hasError) {
      handleCloseConformPopup();
      setSetSelectedData({
        userEmail: null,
        newPassword: null,
        username: null,
      });
      return toastDisplayer("error", response.errorMessage);
    } else {
      handleCloseConformPopup();
      setSetSelectedData({
        userEmail: null,
        newPassword: null,
        username: null,
      });
      return toastDisplayer(
        "success",
        `Password updated for user : ${selectedData.username}`
      );
    }
  };

  const handleCloseConformPopup = () => {
    setSetSelectedData({
      userEmail: null,
      newPassword: null,
      username: null,
    });
    setIsconformation(false);
  };

  return (
    <>
      <ConformationPopup
        headertext={"Save Changes"}
        subtext={"Are you sure you want to save changes?"}
        isVisible={isConformation}
        onHide={handleCloseConformPopup}
        saveFunction={handleUpdatePassword}
        saveIcon={saveIcon}
        cancelBtnTxt="No"
        saveBtnTxt="Save"
      />

      <DataGrid
        dataSource={userGridData}
        rowAlternationEnabled={true}
        showBorders={true}
        onRowUpdated={handleRowUpdated}
        columnHidingEnabled={false}
        className="on-hover-data responsive-text"
      >
        {/* <Toolbar className="toolbar-item">
            <Item location="before">
              <div className="informer">
                <span className="toolbar-text-span">Existing Users</span>
              </div>
            </Item>
            <Item name="searchPanel" />
          </Toolbar> */}
        <Toolbar>
          <Item location="before">
            <div className="informer">
              <div className="count">
                <span className="toolbar-text-span">Existing Users</span>
              </div>
            </div>
          </Item>
          <Item name="searchPanel" />
        </Toolbar>
        <Editing allowUpdating={true} useIcons={true} />

        <SearchPanel visible={true} width={300} placeholder="Search" />

        <Paging defaultPageSize={10} />
        <Paging defaultPageSize={10} defaultPageIndex={0} />
        <Scrolling columnRenderingMode="virtual" />

        <Column
          dataField={"userName"}
          caption={"USER NAME"}
          allowEditing={false}
        ></Column>
        <Column type="buttons" width={100}>
          <Button name="edit" />
          <Button name="delete" />
        </Column>
        <Column
          alignment={"left"}
          dataField={"new_password"}
          caption={"NEW PASSWORD"}
        ></Column>
        <Column
          alignment={"left"}
          dataField={"mobileNo"}
          caption={"MOBILE NO"}
          allowEditing={false}
        ></Column>
        <Column
          alignment={"left"}
          dataField={"userEmail"}
          caption={"USER EMAIL"}
          allowEditing={false}
        ></Column>

        {/* <Column
            alignment={"left"}
            dataField={"gender"}
            caption={"Gender"}
            allowEditing={false}
          ></Column> */}
      </DataGrid>
    </>
  );
};

export default UserListing;
