import React from "react";
import "./home.scss";
import { IndexCards } from "../../components";
import PageHeader from "../../components/Page-Header/PageHeader";

export default function Home() {
  return (
    <React.Fragment>
      {/* <PageHeader isBreadcrumbsVisible={true} pageTitle={""} /> */}
      <p className={"content-block"}>Home</p>
      <IndexCards path={"/vendor"} header={"Vendor"} />
      <IndexCards path={"/customer"} header={"Customer"} />
      <IndexCards path={"/usersettings"} header={"User Setting"} />
    </React.Fragment>
  );
}
