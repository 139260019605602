// import { Button } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "devextreme-react/button";
import { SelectBox } from "devextreme-react";
import "./source-main.scss";
import { UseConfigContext } from "../../../../contexts/cofigContext";
import { saveConfigSource } from "../../../../api/setting";
import { toastDisplayer } from "../../../../components/comman card/toast-displayer";
function SystemLogo() {
  const [isCatExpand, setIsCatExpand] = useState(false);
  const { getSourceData, setSourceData } = UseConfigContext();
  const [getSource, setSource] = useState(getSourceData[0]);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const handleGridExpand = () => {
    setIsCatExpand(!isCatExpand);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setUploadedFileName(file.name);
    const base64 = await convertToBase64(file);
    setSource((prevState) => ({
      ...prevState,
      ["atcPath"]: base64,
    }));
  };
 

  const handleSaveClick = async () => {
    if (getSource != getSourceData[0]) {
      var apiRes = await saveConfigSource(getSource);
      if (!apiRes.hasError) {
        await setSourceData([getSource]);
        return toastDisplayer("success", "Source data saved..!!");
      } else {
        return toastDisplayer("error", apiRes.hasError);
      }
    }
  };

  return (
    <div className="vendor-category">
      <div className="vendor-category-card">
        <div className="dx-card-title" onClick={handleGridExpand}>
          Select System Logo
          {isCatExpand ? (
            <Button icon="chevronup" />
          ) : (
            <Button icon="chevrondown" />
          )}
        </div>
        <div className="add-btn-section">
          <Button
            className="add-btn"
            height={48}
            text={"Save source"}
            onClick={handleSaveClick}
          ></Button>
        </div>
      </div>
      {isCatExpand ? (
        <>
          <div className="source-panel">
            <label className="uplaod_btn" for="file_upload">
              <label className="material-symbols-outlined">upload</label>

              {uploadedFileName ? (
                <p>{uploadedFileName}</p>
              ) : (
                "Upload Company Logo."
              )}
            </label>
            <input
              //disabled={enableOnVerification}
              type="file"
              id="file_upload"
              style={{ display: "none" }}
              onChange={handleFileUpload}
              accept=".jpg, .jpeg, .png, .gif, .bmp, .webp"
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default SystemLogo;
