import React, { createContext, useContext, useEffect, useState } from "react";
import { getConfigSource } from "../api/setting";
import { toastDisplayer } from "../components/comman card/toast-displayer";

const ConfigContext = createContext();
export function CofigProvider({ children }) {
  const [getSourceData, setSourceData] = useState([]);
  const [loading, setLoading] = useState(true);
  var sourceData = [];

  const fetSourceData = async () => {
    const apiRes = await getConfigSource(1);
    if (apiRes.hasError) {
      return toastDisplayer("error", apiRes.errorMessage);
    } else {
      sourceData = apiRes.responseData;
      setSourceData(apiRes.responseData);
      setLoading(false);
      // return apiRes.responseData;
    }
  };

  useEffect(() => {
    fetSourceData();
    console.log(sourceData);
  }, []);

  return (
    <ConfigContext.Provider
      value={{ getSourceData, setSourceData, loading, sourceData }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export function UseConfigContext() {
  return useContext(ConfigContext);
}
