import axios from "axios";
import { logToServer } from "./logger";
const API_URL = process.env.REACT_APP_API;
const userData = localStorage.getItem("User");
export const generateOtpFromMail = async (email, type) => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  const requestBody = {
    eMail: email,
    userType: type,
  };
  try {
    const response = await axios.post(
      `${API_URL}/Otp/GenerateOTP`,
      requestBody
    );
    await logToServer(
      "Otp",
      "Otp",
      "GenerateOTP",
      "S",
      "Generate OTP..",
      JSON.stringify(requestBody),
      userData ? userData : "No"
    );
    responseBody.responseData = response.data;
    responseBody.hasError = false;
    return responseBody;
  } catch (error) {
    await logToServer(
      "Otp",
      "Otp",
      "GenerateOTP",
      "E",
      "Error while Generate OTP..",
      JSON.stringify(requestBody),
      userData ? userData : "No"
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = 
      error.response?.data?.statusMsg || error.response?.data?.errors || error.message;
    return responseBody;
  }
};

export const VerifyOtpByEmail = async (email, otp, userType) => {
  let responseBody = {
    hasError: true,
    responseData: null,
    errorMessage: null,
  };
  const requestBody = {
    email: email,
    verifyOTP: otp,
    userType: userType,
  };

  try {
    const response = await axios.post(`${API_URL}/Otp/VerifyOTP`, requestBody);
    await logToServer(
      "Otp",
      "Otp",
      "VerifyOTP",
      "S",
      "Verify OTP Success..",
      JSON.stringify(requestBody),
      userData ? userData : "No"
    );
    responseBody.responseData = response.data;
    responseBody.hasError = false;
    return responseBody;
  } catch (error) {
    await logToServer(
      "Otp",
      "Otp",
      "VerifyOTP",
      "E",
      "Error while Verify OTP..",
      JSON.stringify(requestBody),
      userData ? userData : "No"
    );
    // responseBody.errorMessage = error.response.data.statusMsg;
    responseBody.errorMessage = responseBody.errorMessage = error.message ||
      error.response?.data?.statusMsg || error.response?.data?.errors;
    return responseBody;
  }
};

//--------------------- Pin code API -----------

export const requestAddressFromPin = async (pinCode) => {
  try {
    const response = await axios.get(
      `https://api.postalpincode.in/pincode/${pinCode}`
    );

    const responseData = response.data;
    return responseData;
  } catch (error) {}
};

//----------------------  Registor Customer --------------

export const saveCustomerDetail = async (
  commanDetails,
  billingAddress,
  shippingAddress
) => {
  const requestBody = {
    customerName: commanDetails.customerName,
    customerType: commanDetails.customerType,
    cmpType: commanDetails.businessStructure,
    officialPhone: commanDetails.phoneNumber,
    telephone: commanDetails.customerName,
    eMail: commanDetails.emailAddress,
    panNum: commanDetails.panNumber,
    gstregNum: commanDetails.gstNumber,
    bAddress1: billingAddress.address1,
    bAddress2: billingAddress.address2,
    bStreet: billingAddress.area,
    bZipCode: billingAddress.pincode,
    bCity: billingAddress.city,
    bState: billingAddress.state,
    bCountry: billingAddress.country,
    sAddress1: shippingAddress.address1,
    sAddress2: shippingAddress.address2,
    sStreet: shippingAddress.area,
    sZipCode: shippingAddress.pincode,
    sCity: shippingAddress.city,
    sState: shippingAddress.state,
    sCountry: shippingAddress.country,
    transCry: commanDetails.currency,
    acctName: commanDetails.bankName,
    account: commanDetails.accountNumber,
    ifscNum: commanDetails.isfcCode,
    swiftNum: commanDetails.swiftCode,
    branch: commanDetails.bankAddress,
    pymntTerms: commanDetails.paymentTerms,
    pCntctPrsn: commanDetails.fullName,
    pCntctPhone: commanDetails.phoneNumber2,
    pCnctEmail: commanDetails.emailAddress2,
    pCnctDesg: commanDetails.designation,
    sCntctPrsn: commanDetails.fullName2,
    sCntctPhone: commanDetails.phoneNumber3,
    sCnctEmail: commanDetails.emailAddress4,
    sCnctDesg: commanDetails.designation2,
    salesEmp: commanDetails.salesEmployes,
    broker: commanDetails.brokers,
    remark: commanDetails.remarks,
    createdByUserName: "",
    websiteLink: commanDetails.websitesLink,
    cmpDocs: commanDetails.uploadFiles,
  };

  try {
    const response = await axios.post(
      `${API_URL}/Customer/SaveCustomer`,
      requestBody
    );
    await logToServer(
      "Customer",
      "SaveCustomer",
      "SaveCustomer",
      "S",
      "SaveCustomer successfully..",
      JSON.stringify(requestBody),
      userData ? userData : "No"
    );
    const responseData = response.data;
    return responseData;
  } catch (error) {
    await logToServer(
      "Customer",
      "SaveCustomer",
      "SaveCustomer",
      "E",
      "Error SaveCustomer..",
      JSON.stringify(requestBody),
      userData ? userData : "No"
    );
    const errorBody = {
      hasError: true,
      errorText: error,
    };

    return errorBody;
  }
};
