import axios from "axios";
const API_URL = process.env.REACT_APP_API;
export const logToServer = async (
    module,
    controllerName,
    methodName,
    logLevel,
    logMessage,
    jsonPayload,
    loginUser
) => {
    const responseBody = {
        responseData: null,
        hasError: false,
        errorMessage: null,
    };
    const user = loginUser ? loginUser : "";
    const requestBody = {
        module,
        controllerName,
        methodName,
        logLevel,
        logMessage,
        jsonPayload,
        loginUser:user,
    };
    try {
        const response = await axios.post(`${API_URL}/Log/Save`, requestBody);
        responseBody.responseData = response.data[0];
        return responseBody;
    } catch (error) {
        responseBody.hasError = true;
        responseBody.errorMessage = responseBody.errorMessage =
            error.response?.data?.statusMsg || error.response?.data?.errors;
        return responseBody;
    }
};