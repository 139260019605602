import { Routes, Route, Navigate } from "react-router-dom";
import { SingleCard } from "./layouts";
import {
  LoginForm,
  ResetPasswordForm,
  ChangePasswordForm,
  CreateAccountForm,
  VerifyMain,
} from "./components";
import { CustomerRegistration } from "./pages/CustomeRegistration/CustomerRegistration";
import VendorRegistration from "./pages/VendorRegistration/VendorRegistration";
import { RegistrationSelection } from "./pages/registrattionSelection/RegistrationSelection";

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <SingleCard title="Sign on">
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path="/verificationStatus/:encodedString"
        element={
          <SingleCard title="Sign on">
            <VerifyMain setStatus={false} />
          </SingleCard>
        }
      />
      <Route
        path="/showStatus/:encodedString"
        element={
          <SingleCard title="Sign on">
            <VerifyMain setStatus={true} />
          </SingleCard>
        }
      />
      <Route
        path="/create-account"
        element={
          <SingleCard title="Sign Up">
            <CreateAccountForm />
          </SingleCard>
        }
      />
      <Route
        path="/reset-password"
        element={
          <SingleCard
            title="Reset Password"
            description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route
        path="/change-password/:recoveryCode"
        element={
          <SingleCard title="Change Password">
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route
        path="/registrationselection"
        element={
          <SingleCard title="Customer Registration">
            <RegistrationSelection />
          </SingleCard>
        }
      />
      <Route
        path="/customerregistration"
        element={
          <SingleCard title="Customer Registration">
            <CustomerRegistration />
          </SingleCard>
        }
      />
      <Route
        path="/vendorregistration"
        element={
          <SingleCard title="Customer Registration">
            <VendorRegistration />
          </SingleCard>
        }
      />
      <Route path="*" element={<Navigate to={"/login"} />}></Route>
    </Routes>
  );
}
