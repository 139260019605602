import {
  HomePage,
  TasksPage,
  ProfilePage,
  CustomerDetailsMain,
  AuthorisationMain,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";
import { VendorMain } from "./pages/vendor/vendor-main";
import { AddVendorMain } from "./pages/vendor/add-vendor/add-vendor-main";
import { VerifyVendorMain } from "./pages/vendor/verify-vendor/verify-vendor-main";
import { CustomerMain } from "./pages/customer/customer-main";
import { AddCustomerMain } from "./pages/customer/add-customer/add-customer-main";
import { VerifyCustomerMain } from "./pages/customer/verify-customer/verify-customer-main";
import { UserSettingMain } from "./pages/user-setting/user-setting-main";
import { AddUserMain } from "./pages/user-setting/add-user/add-user-main";
import { NotificatioMain } from "./pages/user-setting/notification/notificatio-main";
import { ResetPasswordMain } from "./pages/user-setting/reset-password/reset-password-main";
import { RegistrationVendorForm } from "./pages/vendor/add-vendor/RegistrationVendorForm";
import { RegistrationCustomerForm } from "./pages/customer/add-customer/RegistrationCustomerForm";
import VendorStatus from "./pages/vendor/Vendor-lising/vendor-status";
import CustomerStatus from "./pages/customer/customer-listing/customer-status";
import VendorReject from "./pages/vendor/Vendor-lising/vendor-reject";
import CustomerReject from "./pages/customer/customer-listing/customer-reject";
import CustomerEdit from "./pages/customer-panel/customer-details/customer-edit";
import VendorDetailsMain from "./pages/vendor-panel/vendor-details/vendor-detail-main";
import VendorEdit from "./pages/vendor-panel/vendor-details/vendor-edit";
import LogReport from "./pages/System-Log/LogReport";
import { SettingsMain } from "./pages/settings/settings-main";

export const routes = [
  // -------------- Customer Portal
  {
    path: "/DetailCustomer",
    element: CustomerDetailsMain,
  },
  {
    path: "/DetailCustomer/editCustomerDetails",
    element: CustomerEdit,
  },
  {
    path: "/InfoVendor",
    element: VendorDetailsMain,
  },
  {
    path: "/InfoVendor/editVendorDetails",
    element: VendorEdit,
  },
  {
    path: "/tasks",
    element: TasksPage,
  },
  {
    path: "/profile",
    element: ProfilePage,
  },

  //----------------- Home ------------------
  {
    path: "/home",
    element: HomePage,
  },

  //----------------- Vendor ------------------

  {
    path: "/vendor",
    element: VendorMain,
  },
  {
    path: "/vendor/addvendor",
    element: AddVendorMain,
  },
  {
    path: "/vendor/addvendor/VendorStatus",
    element: VendorStatus,
  },
  {
    path: "/vendor/addvendor/VendorReject",
    element: VendorReject,
  },
  {
    path: "/vendor/addvendor/registration",
    element: RegistrationVendorForm,
  },

  {
    path: "/vendor/verifyvendor",
    element: VerifyVendorMain,
  },
  {
    path: "/vendor/verifyvendor/VendorStatus",
    element: VendorStatus,
  },

  //----------------- Customer ------------------

  {
    path: "/customer",
    element: CustomerMain,
  },
  {
    path: "/customer/addCustomer",
    element: AddCustomerMain,
  },
  {
    path: "/customer/addcustomer/registration",
    element: RegistrationCustomerForm,
  },
  {
    path: "/customer/addcustomer/CustomerStatus",
    element: CustomerStatus,
  },
  {
    path: "/customer/addcustomer/CustomerReject",
    element: CustomerReject,
  },
  {
    path: "/customer/verifycustomer",
    element: VerifyCustomerMain,
  },
  {
    path: "/customer/verifycustomer/CustomerStatus",
    element: CustomerStatus,
  },

  //----------------- User Setting ------------------

  {
    path: "/usersettings",
    element: UserSettingMain,
  },
  {
    path: "/usersettings/adduser",
    element: AddUserMain,
  },
  {
    path: "/usersettings/resetpassword",
    element: ResetPasswordMain,
  },
  {
    path: "/usersettings/authorisationd",
    element: AuthorisationMain,
  },
  {
    path: "/usersettings/notification",
    element: NotificatioMain,
  },

  // ======================= settings
  {
    path: "/settings",
    element: SettingsMain,
  },

  // ======================= Log report
  {
    path: "/LogReport",
    element: LogReport,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
